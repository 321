/**
 * @description Convert numbers to persian digits if language is persian
 * @example 1234 becomes ۱۲۳۴
 *
 * @param value
 * @param isFarsi
 */
export const translateNumber = (value: string | number, isFarsi: boolean = false): string => {
  if (typeof value === 'number') {
    value = value.toString();
  }

  if (isFarsi) {
    let length: number = value.length;
    let returnValue: string = '';

    while (length--) {
      const charCode = value.charCodeAt(length);
      returnValue =
        (charCode >= 48 && charCode <= 57 ? String.fromCharCode(1728 + charCode) : value[length]) +
        returnValue;
    }
    return returnValue;
  }
  return value;
};

export const translateText = (
  translation: string | any,
  locale: string,
  ...params: Array<string | number>
): string => {
  if (!translation) {
    // return 'NO SUCH TRANSLATION KEY';
    return '';
  }

  if (typeof translation !== 'string') {
    const values: Array<string> = [translation['zero'], translation['one']];

    translation = values[params[0] as number] || translation['other'] || values[1] || values[0];
  }

  return fillTranslationWithParams(translation, locale, ...params);
};

function fillTranslationWithParams(
  translation: string,
  locale: string,
  ...params: Array<string | number>
) {
  params.forEach((param: string | number, index: number) => {
    const replacement = new RegExp(escapeRegExp(`{${index}}`), 'g');

    translation = translation.replace(replacement, translateNumber(param, locale === 'fa'));
  });
  return translation.trim();
}

function escapeRegExp(value: string): string {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

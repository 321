import { MessageTypes } from '@components/AlertMessage/AlertMessage';

import type { Translations } from '@wegoTypes/translations';

export const handleCopyToClipboard = async (code: string) => {
  await navigator.clipboard.writeText(code);
};

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

type AlertMessageContents = {
  title: string;
  message: string;
  buttonLabel: string;
  type: MessageTypes;
};

export function getErrorAlertContents(
  err: {
    message: string;
    status: string;
  },
  isDatesValid: boolean,
  translations: Translations,
): AlertMessageContents {
  if (!isOnline()) {
    return {
      title: translations.lost_connection_header as string,
      message: translations.lost_connection_message as string,
      buttonLabel: translations.click_to_retry as string,
      type: MessageTypes.NO_INTERNET,
    };
  }
  switch (err.status) {
    case '429':
      return {
        title: translations.rate_limited_error as string,
        message: translations.rate_limited_error_title as string,
        buttonLabel: translations.click_to_retry as string,
        type: MessageTypes.MAX_SEARCH_LIMIT,
      };

    case '422':
      if (!isDatesValid) {
        return {
          title: translations.err_invalid_date as string,
          message: translations.err_invalid_date_message as string,
          buttonLabel: '',
          type: MessageTypes.INVALID_DATE,
        };
      }
      return {
        title: translations.error_no_results as string,
        message: `${err.status}: ${err.message}`,
        buttonLabel: translations.click_to_retry as string,
        type: MessageTypes.SOMETHING_WRONG,
      };

    case '503':
      return {
        title: translations.server_error_header_temporarily as string,
        message: translations.server_error_message_temporarily as string,
        buttonLabel: '',
        type: MessageTypes.SOMETHING_WRONG,
      };

    case '500':
      return {
        title: translations.server_error_header as string,
        message: translations.error_server_message as string,
        buttonLabel: translations.click_to_retry as string,
        type: MessageTypes.SOMETHING_WRONG,
      };

    default:
      return {
        title: err.status,
        message: `${err.message}`,
        buttonLabel: translations.click_to_retry as string,
        type: MessageTypes.SOMETHING_WRONG,
      };
  }
}

function isOnline() {
  return window.navigator.onLine;
}

export function isValidDate(dateString?: string, allowYesterday?: boolean): boolean {
  if (!dateString) return false;
  // Check the format using a regular expression YYYY-MM-DD
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const [year, month, day] = dateString.split('-').map(Number);

  // Check if the date is valid
  const date = new Date(year, month - 1, day);
  if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
    return false;
  }

  // Check if the date is expired
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const allowedDate = new Date(currentDate);
  if (allowYesterday) {
    allowedDate.setDate(currentDate.getDate() - 1); // Allow yesterday
  }
  const inputDate = new Date(year, month - 1, day);
  inputDate.setHours(0, 0, 0, 0);

  if (inputDate < allowedDate) {
    return false;
  }

  return true;
}

export function hexToRgba(hex: string, opacity: number): string {
  // Remove leading # if present
  let sanitizedHex = hex.replace(/^#/, '');

  // If shorthand hex (e.g., #abc), convert it to full form (e.g., #aabbcc)
  if (sanitizedHex.length === 3) {
    sanitizedHex = sanitizedHex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Extract the red, green, and blue components
  const r = parseInt(sanitizedHex.substring(0, 2), 16);
  const g = parseInt(sanitizedHex.substring(2, 4), 16);
  const b = parseInt(sanitizedHex.substring(4, 6), 16);

  // Ensure opacity is between 0 and 1
  const alpha = Math.max(0, Math.min(1, opacity));

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const separateFirstItem = <T>(array: T[]): [T[], T[]] => {
  if (array.length === 0) {
    return [[], []];
  }

  return [[array[0]], array.slice(1)];
};

export function removePrefixZeroFromTimeString(time: string) {
  return time.replace(/^0(\d)h/, '$1h').replace(/\b0(\d)m\b/, '$1m');
}

export function convertDurationInMinutesToHoursAndMinutes(durationInMinutes: number) {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  return (
    (hours ? `${hours}h` : '') + (hours && minutes ? ' ' : '') + (minutes ? `${minutes}m` : '')
  );
}

export function findClosest(values: Array<number>, currentValue: number): number | undefined {
  if (values[0] === values[1] && currentValue < values[0]) {
    return 0;
  } else if (values[0] === values[1] && currentValue > values[0]) {
    return 1;
  } else {
    const { index: closestIndex } =
      values.reduce<{ distance: number; index: number } | null>(
        (acc, value: number, index: number) => {
          const distance = Math.abs(currentValue - value);

          if (acc === null || distance < acc.distance || distance === acc.distance) {
            return { distance, index };
          }

          return acc;
        },
        null,
      ) ?? {};

    return closestIndex;
  }
}

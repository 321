import { notUndefinedAndNull } from '@helpers/array';

function loadGPTClientLibrary(): typeof googletag {
  const elementId: string = 'gptScript';
  const document$: Document = document;
  const existingScriptElement = document$.getElementById(elementId);

  if (!existingScriptElement) {
    window.googletag = window.googletag || { cmd: [] };

    const scriptElement = document$.createElement('script');
    scriptElement.id = elementId;
    scriptElement.defer = true;
    scriptElement.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    document$.head.appendChild(scriptElement);
  }
  return window.googletag;
}

export function loadGPTAds(
  slots: Array<{
    adUnitPath: string;
    htmlDivId: string;
    size: googletag.GeneralSize;
    targetParams?: { [key: string]: string | Array<string> };
  }>,
  collapseEmptyDivs: boolean,
  disableInitialLoad: boolean,
  enableSingleRequest: boolean,
  enableLazyLoad?: boolean,
  lazyLoadOptions?: {
    fetchMarginPercent: number;
    renderMarginPercent?: number;
    mobileScaling?: number;
  },
): Promise<Array<googletag.Slot>> {
  return new Promise<Array<googletag.Slot>>((resolve) => {
    const googleTag = loadGPTClientLibrary();

    googleTag.cmd.push(() => {
      const pubAdsService = googleTag.pubads();

      const googleTagSlots = slots
        .map<googletag.Slot | undefined>(({ adUnitPath, htmlDivId, size, targetParams }) => {
          const slot = googleTag.defineSlot(adUnitPath, size, htmlDivId);

          if (!!slot) {
            slot.clearTargeting();

            if (targetParams !== undefined) {
              for (const key in targetParams) {
                slot.setTargeting(key, targetParams[key]);
              }
            }

            slot.addService(pubAdsService);

            return slot;
          }
        })
        .filter(notUndefinedAndNull);

      if (enableLazyLoad) {
        if (lazyLoadOptions) {
          pubAdsService.enableLazyLoad(lazyLoadOptions);
        } else {
          pubAdsService.enableLazyLoad();
        }

        // used for debugging
        pubAdsService.addEventListener('slotRequested', (event) => {
          console.log('fetched ', event.slot.getSlotElementId());
        });

        pubAdsService.addEventListener('slotOnload', (event) => {
          console.log('rendered', event.slot.getSlotElementId());
        });
      }

      if (collapseEmptyDivs) {
        pubAdsService.collapseEmptyDivs();
      }

      if (disableInitialLoad) {
        pubAdsService.disableInitialLoad();
      }

      if (enableSingleRequest) {
        pubAdsService.enableSingleRequest();
      }

      googleTag.enableServices();

      resolve(googleTagSlots);
    });
  });
}

export function addSlotEventListener<T extends keyof googletag.events.EventTypeMap>(
  event: T,
  listener: (event: googletag.events.EventTypeMap[T]) => void,
): Promise<void> {
  return new Promise((resolve) => {
    const googleTag = loadGPTClientLibrary();

    googleTag.cmd.push(() => {
      const pubAdsService = googleTag.pubads();

      pubAdsService.addEventListener(event, listener);

      resolve();
    });
  });
}

export function removeSlotEventListener<T extends keyof googletag.events.EventTypeMap>(
  event: T,
  listener: (event: googletag.events.EventTypeMap[T]) => void,
): Promise<void> {
  return new Promise((resolve) => {
    const googleTag = loadGPTClientLibrary();

    googleTag.cmd.push(() => {
      const pubAdsService = googleTag.pubads();

      pubAdsService.removeEventListener(event, listener);

      resolve();
    });
  });
}

export function showGPTAds(divOrSlot: string | HTMLDivElement | googletag.Slot): Promise<void> {
  return new Promise((resolve) => {
    const googleTag = loadGPTClientLibrary();

    googleTag.cmd.push(() => {
      googleTag.display(divOrSlot);

      resolve();
    });
  });
}

export function unloadGPTAds(slots?: Array<googletag.Slot>): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    const googleTag = loadGPTClientLibrary();

    googleTag.cmd.push(() => {
      resolve(googleTag.destroySlots(slots));
    });
  });
}

export function refreshGPTAds(
  slots?: Array<googletag.Slot> | null,
  opt_options?: { changeCorrelator: boolean },
): Promise<void> {
  return new Promise((resolve) => {
    const googleTag = loadGPTClientLibrary();

    googleTag.cmd.push(() => {
      const pubAdsService = googleTag.pubads();

      pubAdsService.refresh(slots, opt_options);

      resolve();
    });
  });
}

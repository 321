import { clsx } from '@alexis/helpers/clsx';
import React, { useMemo } from 'react';

import { translateNumber, translateText } from '@wego/alexis/helpers/translation';

import Image from '@components/Image';
import Price from '@components/Price';

import {
  cloudflareImageTransformations,
  cloudinaryImageTransformations,
  isCloudinarySrc,
} from '@helpers/imageTransformations';
import { getReviewScoreDisplay } from '@helpers/review';

import StarIcon from '@icons/star_rounded.svg';

import { useReviewRatingType } from '@hooks/useReviewRatingType';

import styles from '@styles/components/home/PopularHotelCard.module.scss';

import { Currency } from '@wegoTypes/currency';
import { HotelPopularHotel } from '@wegoTypes/hotels/hotelPopularHotel';
import { Translations } from '@wegoTypes/translations';

interface PopularHotelCardProps {
  currency: Currency;
  exchangeRate: ExchangeRate;
  hotel: HotelPopularHotel;
  href: string;
  locale: string;
  translations: Translations;
}

const PopularHotelCard: React.FC<PopularHotelCardProps> = ({
  currency,
  exchangeRate,
  hotel,
  href,
  locale,
  translations,
}): JSX.Element => {
  const { getReviewRatingTypeColorClassName } = useReviewRatingType();

  const noImagePlaceholderSrc = cloudinaryImageTransformations(
    'https://assets.wego.com/image/upload/v1639463186/web/misc/hotel-image-placeholder.png',
    'h_184',
    'c_fill',
    'f_auto',
    'fl_lossy',
    'q_auto:low',
    'g_auto',
  );

  const imageSrc = useMemo<string>(() => {
    if (!!hotel.images && hotel.images.length > 0) {
      if (isCloudinarySrc(hotel.images[0].url)) {
        return cloudinaryImageTransformations(
          hotel.images[0].url,
          'h_368',
          'c_fill',
          'f_auto',
          'fl_lossy',
          'q_auto:best',
          'g_auto',
        );
      } else {
        return cloudflareImageTransformations(hotel.images[0].url, {
          format: 'auto',
          height: 368,
          quality: 90,
        });
      }
    }
    return '';
  }, [hotel]);

  return (
    <a className={clsx(styles.container)} href={href} target='_blank' rel='noreferrer'>
      <Image
        className={styles.image}
        isLazy={true}
        noImagePlaceholderSrc={noImagePlaceholderSrc}
        placeholderSrc={noImagePlaceholderSrc}
        src={imageSrc}
      />

      <div className={styles.content}>
        {/* Hotel Name */}
        <div className={styles.name}>{hotel.name}</div>

        <div className={styles.starAndDistrict}>
          {/* Star */}
          {!!hotel.star ? (
            <>
              {[...Array(hotel.star).keys()].map((value: number) => {
                return <StarIcon key={value} className={styles.star} data-testid='star' />;
              })}
            </>
          ) : null}

          {/* District */}
          {!!hotel.district ? <div className={styles.district}>{hotel.district.name}</div> : null}
        </div>

        {/* Review */}
        {!!hotel.reviews && hotel.reviews.length > 0 ? (
          <div className={styles.review}>
            <div
              className={clsx(
                styles.score,
                getReviewRatingTypeColorClassName(hotel.reviews[0].score, styles),
              )}
            >
              {translateNumber(getReviewScoreDisplay(hotel.reviews[0].score), locale === 'fa')}
            </div>

            <div className={styles.reviewCount}>
              {translateText(translations.count_review, locale, hotel.reviews[0]?.count)}
            </div>
          </div>
        ) : null}

        {/* Rate */}
        <div className={styles.rate}>
          {!!hotel.rates && hotel.rates.length > 0 ? (
            <>
              <div className={styles.priceType}>{translations.hotel_per_night}</div>
              <Price
                className={styles.price}
                currency={currency}
                exchangeRate={exchangeRate}
                isInline={true}
                locale={locale}
                price={{
                  amount: hotel.rates[0].price.amount,
                  amountUsd: hotel.rates[0].price.amountUsd,
                  currencyCode: hotel.rates[0].price.currencyCode,
                }}
              />
            </>
          ) : (
            <div className={styles.noRate}>{translations.get_rates}</div>
          )}
        </div>
      </div>
    </a>
  );
};

export default PopularHotelCard;

export const appendSearchParams = (url: string, searchParam: any): string => {
  let urlWithSearchParams: string = url.slice();

  const searchParamKeys = Object.keys(searchParam);

  if (searchParamKeys.length > 0) {
    urlWithSearchParams = `${urlWithSearchParams}${
      urlWithSearchParams.includes('?') ? '&' : '?'
    }${searchParamKeys
      .map((searchParamKey) => {
        if (Array.isArray(searchParam[searchParamKey])) {
          const searchParamValues: Array<any> = searchParam[searchParamKey];

          return searchParamValues
            .map((searchParamValue) => `${searchParamKey}[]=${searchParamValue}`)
            .join('&');
        }
        return `${searchParamKey}=${searchParam[searchParamKey]}`;
      })
      .join('&')}`;
  }
  return urlWithSearchParams;
};

export const updateSearchParam = (
  search: string,
  oldSearchParam: string,
  newSearchParam?: string,
): string => {
  const lowerCaseSearch = search.toLowerCase();
  const lowerCaseOldSearchParam = oldSearchParam.toLowerCase();

  if (!!newSearchParam) {
    // Add or replace search param
    const lowerCaseNewSearchParam = newSearchParam.toLowerCase();

    if (!!lowerCaseOldSearchParam && lowerCaseSearch.includes(lowerCaseOldSearchParam)) {
      return lowerCaseSearch.replace(lowerCaseOldSearchParam, lowerCaseNewSearchParam);
    }
    return !!lowerCaseSearch
      ? `${lowerCaseSearch}&${lowerCaseNewSearchParam}`
      : `?${lowerCaseNewSearchParam}`;
  } else {
    // Remove search param
    if (!!lowerCaseOldSearchParam && lowerCaseSearch.includes(lowerCaseOldSearchParam)) {
      const regexMatchResult = lowerCaseSearch.match(`\\?${lowerCaseOldSearchParam}[\\&]?`);

      if (!!regexMatchResult) {
        const newSearch = lowerCaseSearch.replace(regexMatchResult[0], '?');

        return newSearch.length > 1 ? newSearch : '';
      }
      return lowerCaseSearch.replace(`&${lowerCaseOldSearchParam}`, '');
    }
    return lowerCaseSearch;
  }
};

import { HotelLandmark } from '@wegoTypes/hotels/hotelLandmarks';
import { HotelMetaSearchFilter } from '@wegoTypes/hotels/hotelMetaSearchFilter';
import { HotelViewModel } from '@wegoTypes/hotels/hotelViewModel';
import { HotelSearchResultCity } from '@wegoTypes/hotels/metasearch/hotelEntity';
import { HotelSearchResultSearch } from '@wegoTypes/hotels/metasearch/hotelSearchResultSearch';
import { MetasearchHotelRateProvider } from '@wegoTypes/hotels/metasearch/metasearchHotelRateProvider';

import { HotelMetaSearchActionTypes } from './types';

export function changeHotelMetaSearch(search: HotelSearchResultSearch): {
  type: HotelMetaSearchActionTypes;
  payload: HotelSearchResultSearch;
} {
  return { type: HotelMetaSearchActionTypes.ChangeHotelMetaSearch, payload: search };
}

export function changeRateProviders(rateProviders: Array<MetasearchHotelRateProvider>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<MetasearchHotelRateProvider>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeHotelRateProviders, payload: rateProviders };
}

export function changeCities(cities: Array<HotelSearchResultCity>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelSearchResultCity>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeHotelCities, payload: cities };
}

export function changeAmenitiesFilter(amenitiesFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeAmenitiesFilter, payload: amenitiesFilter };
}

export function changeBookingOptionsFilter(bookingOptionsFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return {
    type: HotelMetaSearchActionTypes.ChangeBookingOptionsFilter,
    payload: bookingOptionsFilter,
  };
}

export function changeBrandsFilter(brandsFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeBrandsFilter, payload: brandsFilter };
}

export function changeChainsFilter(chainsFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeChainsFilter, payload: chainsFilter };
}

export function changeDistrictsFilter(districtsFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeDistrictsFilter, payload: districtsFilter };
}

export function changePropertyTypesFilter(propertyTypesFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return {
    type: HotelMetaSearchActionTypes.ChangePropertyTypesFilter,
    payload: propertyTypesFilter,
  };
}

export function changeRateAmenitiesFilter(rateAmenitiesFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return {
    type: HotelMetaSearchActionTypes.ChangeRateAmenitiesFilter,
    payload: rateAmenitiesFilter,
  };
}

export function changeReviewerGroupsFilter(reviewerGroupsFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return {
    type: HotelMetaSearchActionTypes.ChangeReviewerGroupsFilter,
    payload: reviewerGroupsFilter,
  };
}

export function changeStarsFilter(starsFilter: Array<HotelMetaSearchFilter>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelMetaSearchFilter>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeStarsFilter, payload: starsFilter };
}

export function changeHotelViewModels(hotelViewModels: Array<HotelViewModel>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelViewModel>;
} {
  return { type: HotelMetaSearchActionTypes.ChangeHotelViewModels, payload: hotelViewModels };
}

export function changeSponsoredAdHotelViewModels(hotelViewModels: Array<HotelViewModel>): {
  type: HotelMetaSearchActionTypes;
  payload: Array<HotelViewModel>;
} {
  return {
    type: HotelMetaSearchActionTypes.ChangeSponsoredAdHotelViewModels,
    payload: hotelViewModels,
  };
}

export const changeHotelLandmarks = (
  landmarks: Array<HotelLandmark>,
): { type: HotelMetaSearchActionTypes; payload: Array<HotelLandmark> } => ({
  type: HotelMetaSearchActionTypes.ChangeHotelLandmarks,
  payload: landmarks,
});

export function resetHotelMetaSearch(): { type: HotelMetaSearchActionTypes; payload: undefined } {
  return { type: HotelMetaSearchActionTypes.ResetHotelMetaSearch, payload: undefined };
}

import React from 'react';

import { cloudinaryImageTransformations } from '@helpers/imageTransformations';

import { Translations } from '@wegoTypes/translations';

import styles from './PopularBrands.module.scss';

interface PopularBrandsProps {
  translations: Translations;
  popularBrands: Array<HomepageHotelsPopularBrand>;
  pageType: string;
}

const PopularBrands: React.FC<PopularBrandsProps> = ({ translations, popularBrands, pageType }) => {
  if (pageType !== 'hotels' || popularBrands.length <= 0) return null;

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <div className={styles.title}>{translations.chains_brands}</div>
        <div className={styles.message}>{translations.book_favourite_hotels}</div>
      </div>

      <div className={styles.logos}>
        {popularBrands.slice(0, 8).map((popularBrand) => {
          const filename = popularBrand.imageUrl.split('/').slice(-1);

          return (
            <div key={popularBrand.code} className={styles.logo}>
              <img
                src={cloudinaryImageTransformations(
                  `https://assets.wego.com/image/upload/v20220803/hotels/brands/${filename}`,
                  'h_64',
                  'c_fit',
                  'f_auto',
                  'fl_lossy',
                  'q_auto:low',
                )}
                alt={popularBrand.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopularBrands;

import cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';

import { WEGO_PREFERRED_PAYMENT_METHODS_COOKIE_KEY } from '@constants/cookie';

const getDefaultPreferredPaymentMethods = () => {
  try {
    return JSON.parse(cookies.get(WEGO_PREFERRED_PAYMENT_METHODS_COOKIE_KEY)!) as number[];
  } catch {
    return;
  }
};

const EMPTY_ARRAY: number[] = [];

const MAX_ALLOWED_PAYMENT_METHODS = 10;

const usePreferredPaymentMethods = (availablePaymentMethods: HomepageFlightsPaymentMethod[]) => {
  const [defaultPreferredPaymentMethods, setDefaultPreferredPaymentMethods] = useState<
    number[] | undefined
  >(() => getDefaultPreferredPaymentMethods());
  const [preferredPaymentMethods, setPreferredPaymentMethods] = useState<number[] | undefined>(
    () => defaultPreferredPaymentMethods,
  );

  useEffect(() => {
    setPreferredPaymentMethods((prev) => {
      if (!prev && availablePaymentMethods.length) {
        return availablePaymentMethods
          .filter((paymentMethod) => paymentMethod.isPopular)
          .map((paymentMethod) => paymentMethod.id);
      }

      return prev;
    });
  }, [availablePaymentMethods]);

  const togglePreferredPaymentMethod = useCallback(
    (paymentMethodId: number, shouldSave?: boolean) => {
      setPreferredPaymentMethods((prev) => {
        if (!prev) return;

        let newPreferredPaymentMethods = prev;

        if (prev.includes(paymentMethodId)) {
          newPreferredPaymentMethods = prev.filter((id) => id !== paymentMethodId);
        } else if (prev.length < MAX_ALLOWED_PAYMENT_METHODS) {
          newPreferredPaymentMethods = [...prev, paymentMethodId];
        }

        if (shouldSave) {
          cookies.set(
            WEGO_PREFERRED_PAYMENT_METHODS_COOKIE_KEY,
            JSON.stringify(newPreferredPaymentMethods),
          );
          setDefaultPreferredPaymentMethods(newPreferredPaymentMethods);
        }

        return newPreferredPaymentMethods;
      });
    },
    [setPreferredPaymentMethods],
  );

  const savePreferredPaymentMethods = useCallback(() => {
    cookies.set(WEGO_PREFERRED_PAYMENT_METHODS_COOKIE_KEY, JSON.stringify(preferredPaymentMethods));
    setDefaultPreferredPaymentMethods(getDefaultPreferredPaymentMethods());
  }, [preferredPaymentMethods]);

  const resetPreferredPaymentMethods = useCallback(() => {
    setPreferredPaymentMethods(
      defaultPreferredPaymentMethods !== undefined
        ? defaultPreferredPaymentMethods
        : availablePaymentMethods
            .filter((paymentMethod) => paymentMethod.isPopular)
            .map((paymentMethod) => paymentMethod.id),
    );
  }, [defaultPreferredPaymentMethods, availablePaymentMethods]);

  return {
    defaultPreferredPaymentMethods,
    preferredPaymentMethods: preferredPaymentMethods || EMPTY_ARRAY,
    togglePreferredPaymentMethod,
    savePreferredPaymentMethods,
    resetPreferredPaymentMethods,
    setPreferredPaymentMethods,
  };
};

export default usePreferredPaymentMethods;

import { useSelector } from 'react-redux';

import { getSupportedPaymentTypes } from '@apis/flight';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

const getSupportedPaymentTypesKey = 'supported-payment-types';

export const useGetSupportedPaymentTypes = (
  options?: UseAxiosQueryOptions<HomepageFlightsPaymentMethod[]>,
) => {
  const apiBaseUrl = API_BASE_URL;
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [
      getSupportedPaymentTypesKey,
      {
        apiBaseUrl,
        locale,
        currentSiteCode: currentSiteCode.countryCode,
      },
    ],
    queryFn: () => getSupportedPaymentTypes(apiBaseUrl, locale, currentSiteCode.countryCode),
    staleTime: 1000 * 60 * 30, // 30 minutes
    ...options,
  });
};

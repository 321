export const MAXIMUM_PASSENGER_LIMIT = 9;

export const FLIGHT_SEARCH_RESULTS_HEADER_HEIGHT = 71;

export const PAYMENT_METHOD_FILTER_BASED_ON_POS: { [key: string]: Array<string> } = {
  default: [],
  KW: ['knet'],
  SA: ['mada'],
};

export const LOW_RATE_CURRENCIES: Array<string> = [
  'COP',
  'IDR',
  'IRR',
  'KRW',
  'LBP',
  'VND',
  'TZS',
  'UGX',
  'CDF',
];

export const recommendationTypeTranslationMap: Record<FlightFareRecommendationType, string> = {
  BOW: 'book_on_wego',
  DIRECT_AIRLINE: 'airline_direct',
  FEATURED_OTA: 'featured',
};

export const TEST_FEATURES_PARAM = 'test_features';

export const FLIGHT_SCHEDULE_NORMALISATION = 'flight_schedule_normalisation';

export const EXCLUDED_PROVIDERS_PARAM = 'ep';

export const OFFER_ENGINE = 'offer_engine';

export const IS_OFFER_ENGINE_ENABLED_PARAM = 'is_offer_engine_enabled';

export const SHOW_FARE_CALENDAR_PARAM = 'show_fare_calendar';
export const SHOW_FARE_CALENDAR_6_MONTHS_PARAM = '6_months';

export const DIRECT_ONLY_PARAM = 'direct_only';

export const MOCK_BAGGAGE_FILTER = 'mock_baggage_filter';

export const WEGO_GREAT_ESCAPE_BASE_URL = 'https://map.wego.com';

export const INSPIRATIONAL_SHOPPING_CORE_CONFIGS_KEY =
  'dw_fmeta478_inspirational_shopping_mvp_dweb';

export const AUTOCOMPLETE_CORE_CONFIG_KEY = 'w_fmeta606_autocomplete_all_markets';

export const LAZYLOADING_ADS_WEB_CORE_CONFIG_KEY = 'w_fmeta1206_lazy_loading_ads_web_v1';

export const NATIVE_AD_CORE_CONFIG_KEY = 'dw_fmeta900_native_ads_dweb';

export const JWP_AD_DWEB_CORE_CONFIG_KEY = 'dw_fmeta1008_new_video_ads_player_v1_dweb';

export const FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY = 'dw_fmeta1688_flight_search_2_dweb_v2_2';

export const CACHE_FLIGHT_SEARCH_CORE_CONFIG_KEY = 'dw_fmeta1543_caching_logic_v1_dweb';

export const AIRLINE_BACKFILL_CORE_CONFIG_KEY = 'dw_fmeta1535_airline_backfill_dweb_v1';

export const FLIGHT_OFFER_ENGINE_INTEGRATION_CORE_CONFIG_KEY =
  'flight_offer_engine_integration_ab_test';

export const FARE_CALENDAR_CORE_CONFIG_KEY = 'dw_fmeta1663_fare_calendar_dweb_v1';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { isGoogleBot } from '../helpers/bots';
import { webEngageSetUserAttribute } from '../helpers/webEngage';
import { changeTranslations } from '../redux/actions/translationsActions';

interface TranslationsProps {
  locale: string;
}

const Translations: React.FC<TranslationsProps> = ({ locale }): null => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeTranslations(locale));

    if (!isGoogleBot) {
      webEngageSetUserAttribute('language', locale);
    }
  }, [locale]);

  return null;
};

export default Translations;

import { clsx } from '@alexis/helpers/clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY } from '@constants/flight';

import { GlobalContext } from '@context/GlobalContext';

import { getTranslationsState } from '@redux/selectors';

import type { FlightSearchType } from '@wegoTypes/flights/flightSearchType';

import styles from './FlightSearchTypePicker.module.scss';

export interface FlightSearchTypePickerProps {
  searchType: FlightSearchType;
  onSearchTypeChange: (searchType: FlightSearchType) => void;
}

const pickers: Array<{ type: FlightSearchType; label: string }> = [
  {
    type: 'oneWay',
    label: 'one_way',
  },
  {
    type: 'roundTrip',
    label: 'round_trip',
  },
  {
    type: 'multiCity',
    label: 'multi_city',
  },
];

const FlightSearchTypePicker: React.FC<FlightSearchTypePickerProps> = ({
  searchType,
  onSearchTypeChange,
}) => {
  const translations = useSelector(getTranslationsState);

  const { userCentricCoreConfigs } = useContext(GlobalContext);

  const flightSearch2CoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY,
  );
  const isFlightSearch2 = flightSearch2CoreConfig?.value === '1';

  return (
    <div className={clsx(styles.container, isFlightSearch2 && styles.flightSearch2)}>
      <div className={styles.searchTypes}>
        {pickers.map((picker) => (
          <button
            key={picker.type}
            className={clsx(styles.searchTypeButton, searchType === picker.type && styles.selected)}
            onClick={() => onSearchTypeChange(picker.type)}
            type='button'
          >
            {translations[picker.label]}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FlightSearchTypePicker;

import { genzoTrackImpression } from '@alexis/helpers/genzo';
import { matchPath } from 'react-router-dom';

import { HotelViewModel } from '@wegoTypes/hotels/hotelViewModel';
import type { MetasearchHotelRate } from '@wegoTypes/hotels/metasearch/metasearchHotelRate';
import { RateViewModel } from '@wegoTypes/hotels/rateViewModel';

import { convertHotelRatePriceToPrice } from '../helpers/price';
import {
  flightSearchResultsPaths,
  flightsPaths,
  hotelBookingPaths,
  hotelDetailsPaths,
  hotelSearchResultsPaths,
  hotelsPaths,
} from './path';

export function impressionLogHotelSearchResult(
  impressionType: 'page_load' | 'sort' | 'filter' | 'map_load',
  apiBaseUrl: string,
  wegoAnalyticsClientId: string,
  wegoAnalyticsClientSessionId: string,
  eventId: string | null,
  hotelViewModels: Array<HotelViewModel>,
  priceOptions: PriceOptions,
  pageViewId: string,
  hotelMetaSearchId: string,
  adsTypePosition: { [position: number]: 'banner' | 'native' },
  userHash?: string | undefined,
): void {
  let impressionLogObjects: Array<ImpressionLogObject> = [];

  if (hotelViewModels.length !== 0) {
    hotelViewModels.slice(0, 100).map((hotelViewModel, index) => {
      const bestDeal: RateViewModel = hotelViewModel.filteredRates[0];
      const bestDealPrice = convertHotelRatePriceToPrice(
        bestDeal.price,
        priceOptions.showTotalPrice,
        priceOptions.isIncludingTaxOrFee,
      );

      impressionLogObjects.push({
        ad_above: adsTypePosition[index + 1] ?? 'no_ad',
        cheapest_price_usd: bestDealPrice.amountUsd,
        id: hotelViewModel.hotelId.toString(),
        itinerary_category: !!hotelViewModel.sponsoredAd ? 'sponsored' : 'meta',
        num_providers: hotelViewModel.providerRatesCounts.length,
        order: index + 1,
        top_provider: bestDeal.provider.code,
      });
    });
  }

  genzoTrackImpression(
    impressionType,
    apiBaseUrl,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
    eventId,
    impressionLogObjects,
    'hotels_search_results',
    pageViewId,
    hotelMetaSearchId,
    null,
    userHash,
  );
}

export function impressionLogHotelCardClick(
  typeOfClick: 'sponsoredAd' | 'rate' | 'detail',
  apiBaseUrl: string,
  wegoAnalyticsClientId: string,
  wegoAnalyticsClientSessionId: string,
  eventId: string | null,
  selectedHotelViewModel: HotelViewModel,
  priceOptions: PriceOptions,
  pageViewId: string,
  hotelMetaSearchId: string,
  order: number,
  adsTypePosition: { [position: number]: 'banner' | 'native' },
  selectedRate: RateViewModel,
  userHash?: string | undefined,
): void {
  let impressionLogObjects: Array<ImpressionLogObject> = [];

  const bestDeal: RateViewModel = selectedHotelViewModel.filteredRates[0];
  const bestDealPrice = convertHotelRatePriceToPrice(
    bestDeal.price,
    priceOptions.showTotalPrice,
    priceOptions.isIncludingTaxOrFee,
  );
  const selectedRatePrice = convertHotelRatePriceToPrice(
    selectedRate.price,
    priceOptions.showTotalPrice,
    priceOptions.isIncludingTaxOrFee,
  );

  let impressionType = 'click';
  let clicked_provider = null;
  let clicked_price_usd = null;

  if (typeOfClick === 'sponsoredAd' || typeOfClick === 'rate') {
    clicked_provider = selectedRate.provider.code;
    clicked_price_usd = selectedRatePrice.amountUsd;
    impressionType = 'handoff';
  }

  impressionLogObjects.push({
    ad_above: adsTypePosition[order] ?? 'no_ad',
    cheapest_price_usd: bestDealPrice.amountUsd,
    clicked_provider,
    clicked_price_usd,
    id: selectedHotelViewModel.hotelId.toString(),
    itinerary_category: !!selectedHotelViewModel.sponsoredAd ? 'sponsored' : 'meta',
    num_providers: selectedHotelViewModel.providerRatesCounts.length,
    order,
    top_provider: bestDeal.provider.code,
  });

  genzoTrackImpression(
    impressionType,
    apiBaseUrl,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
    eventId,
    impressionLogObjects,
    'hotels_search_results',
    pageViewId,
    hotelMetaSearchId,
    null,
    userHash,
  );
}

export function impressionLogHotelDetail(
  impressionType: 'page_load' | 'filter' | 'deals_interaction',
  apiBaseUrl: string,
  wegoAnalyticsClientId: string,
  wegoAnalyticsClientSessionId: string,
  eventId: string | null,
  rates: MetasearchHotelRate[],
  priceOptions: PriceOptions,
  pageViewId: string,
  hotelMetaSearchId: string,
  userHash?: string | undefined,
): void {
  let impressionLogObjects: Array<ImpressionLogObject> = [];

  if (rates.length !== 0) {
    rates.slice(0, 100).map((rate, index) => {
      const ratePrice = convertHotelRatePriceToPrice(
        rate.price,
        priceOptions.showTotalPrice,
        priceOptions.isIncludingTaxOrFee,
      );

      impressionLogObjects.push({
        cheapest_price_usd: ratePrice.amountUsd,
        id: rate.id,
        order: index + 1,
        parent_id: rate.hotelId.toString(),
        top_provider: rate.provider.code,
      });
    });
  }

  genzoTrackImpression(
    impressionType,
    apiBaseUrl,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
    eventId,
    impressionLogObjects,
    'hotels_details',
    pageViewId,
    hotelMetaSearchId,
    null,
    userHash,
  );
}

export function impressionLogOnHotelRateClick(
  apiBaseUrl: string,
  wegoAnalyticsClientId: string,
  wegoAnalyticsClientSessionId: string,
  eventId: string | null,
  bestRate: MetasearchHotelRate,
  selectedRate: MetasearchHotelRate,
  priceOptions: PriceOptions,
  pageViewId: string,
  hotelMetaSearchId: string,
  order: number,
  userHash?: string | undefined,
): void {
  let impressionLogObjects: Array<ImpressionLogObject> = [];
  const bestRatePrice = convertHotelRatePriceToPrice(
    bestRate.price,
    priceOptions.showTotalPrice,
    priceOptions.isIncludingTaxOrFee,
  );
  const selectedRatePrice = convertHotelRatePriceToPrice(
    selectedRate.price,
    priceOptions.showTotalPrice,
    priceOptions.isIncludingTaxOrFee,
  );

  impressionLogObjects.push({
    cheapest_price_usd: bestRatePrice.amountUsd,
    clicked_price_usd: selectedRatePrice.amountUsd,
    clicked_provider: selectedRate.provider.code,
    id: selectedRate.id,
    order: order,
    parent_id: selectedRate.hotelId.toString(),
    top_provider: bestRate.provider.code,
  });

  genzoTrackImpression(
    'handoff',
    apiBaseUrl,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
    eventId,
    impressionLogObjects,
    'hotels_details',
    pageViewId,
    hotelMetaSearchId,
    null,
    userHash,
  );
}

export function getPageNameByPathName(locale: string, pathname: string): string {
  const isFlightSearchResult = flightsPaths.some((path) =>
    flightSearchResultsPaths.some(
      (pattern) =>
        matchPath(`${locale}/${path}/${pattern}`, pathname) ||
        matchPath(`${path}/${pattern}`, pathname),
    ),
  );
  const isHotelSearchResult = hotelsPaths.some((path) =>
    hotelSearchResultsPaths.some(
      (pattern) =>
        matchPath(`${locale}/${path}/${pattern}`, pathname) ||
        matchPath(`${path}/${pattern}`, pathname),
    ),
  );
  const isHotelDetails = hotelsPaths.some((path) =>
    hotelDetailsPaths.some(
      (pattern) =>
        matchPath(`${locale}/${path}/${pattern}`, pathname) ||
        matchPath(`${path}/${pattern}`, pathname),
    ),
  );
  const isHotelBooking = hotelsPaths.some((path) =>
    hotelBookingPaths.some(
      (pattern) =>
        matchPath(`${locale}/${path}/${pattern}`, pathname) ||
        matchPath(`${path}/${pattern}`, pathname),
    ),
  );
  return isFlightSearchResult
    ? 'flight_search_results'
    : isHotelSearchResult
    ? 'hotel_search_results'
    : isHotelDetails
    ? 'hotel_details'
    : isHotelBooking
    ? 'hotel_booking'
    : 'home';
}

import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import axios from 'axios';

const getShopCashBaseUrl = () => {
  const hostname = window.location.hostname;
  return isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname)
    ? 'https://api.shopcashstaging.com/sc/shopcash'
    : 'https://api.shopcash.com/sc/shopcash';
};

export interface ShopCashWegoAuthResponse {
  data: {
    accessToken: string;
    refreshToken: string;
  };
}

export const shopCashSignIn = async (
  idToken: string,
  siteCode: string,
  locale: string,
): Promise<ShopCashWegoAuthResponse> => {
  const shopCashBaseUrl = getShopCashBaseUrl();
  const url = `${shopCashBaseUrl}/v1/auth/wego`;

  const response = await axios.post<ShopCashWegoAuthResponse>(
    url,
    {
      data: {
        siteCode: siteCode,
        language: locale,
        appType: 'WEB_APP',
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + idToken,
      },
    },
  );

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.statusText);
};

export interface ShopCashAccountResponse {
  data: {
    createdAt: string;
    csRestoreId: string | null;
    id: string;
    lastSignIn: string;
    ledger: ShopCashLedger;
    preferences: {
      emailNotificationsEnabled: boolean;
      pushNotificationsEnabled: boolean;
    };
    profile: {
      address: string | null;
      dob: string | null;
      email: string | null;
      gender: string | null;
      id: string;
      name: string | null;
      userUid: string;
    };
    providers: Array<string>;
    referral: {
      code: string;
    };
    siteCode: string;
    userHash: string;
    wegoUserHash: string;
  };
}

export interface ShopCashLedger {
  availableBalance: number;
  currencyCode: string;
  displayBalance: number;
  lifetimeEarning: number;
  lifetimeWithdrawn: number;
  pendingBalance: number;
}

export const getShopCashAccount = async (accessToken: string): Promise<ShopCashAccountResponse> => {
  const shopCashBaseUrl = getShopCashBaseUrl();
  const url = `${shopCashBaseUrl}/v1/account`;

  const response = await axios.get<ShopCashAccountResponse>(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.statusText);
};

import { useEffect } from 'react';

import { loadClarityScript } from '@helpers/clarity';

export const useClarityCustomTag = (key: string, value: string | undefined) => {
  useEffect(() => {
    if (!value) return;
    loadClarityScript().then((clarity) => {
      clarity('set', key, value as string);
    });
  }, [value]);
};

import { useState } from 'react';
import { useSelector } from 'react-redux';

import Drawer from '@components/Drawer';

import HamburgerMenuIcon from '@icons/hamburger-menu.svg';

import { getTranslationsState } from '@redux/selectors';

import styles from './PreferencesLayout.module.scss';
import PreferencesSideNavigation from './PreferencesSideNavigation';

type PreferencesLayoutProps = {
  children: React.ReactNode;
  localeParam?: string;
};

const PreferencesLayout: React.FC<PreferencesLayoutProps> = ({ children, localeParam }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const translations = useSelector(getTranslationsState);

  return (
    <div className={styles.container}>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} title='Menu'>
        <PreferencesSideNavigation localeParam={localeParam} />
      </Drawer>
      <div className={styles.layout}>
        <aside className={styles.sidebar}>
          <PreferencesSideNavigation localeParam={localeParam} />
        </aside>
        <main className={styles.content}>
          <button className={styles.hamburgerButton} onClick={() => setIsDrawerOpen(true)}>
            <HamburgerMenuIcon />
            <span>{translations.menu}</span>
          </button>
          {children}
        </main>
      </div>
    </div>
  );
};

export default PreferencesLayout;

import { appendSearchParams } from '@alexis/helpers/searchParam';
import axios from 'axios';
import type { CancelToken } from 'axios';
import sha256 from 'crypto-js/sha256';
import { Observable, share, switchMap } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';

import { User } from '@wego/alexis/types/helpers/user';

import { getGenzoIdentifierId } from '@helpers/genzoIdentifierId';

import { HotelRoom } from '@wegoTypes/hotelBooking/hotelRooms';
import { GuestRoom } from '@wegoTypes/hotels/guestRoom';
import { Hotel } from '@wegoTypes/hotels/hotel';
import {
  HotelGuestReview,
  REVIEW_GUEST_TYPE,
  REVIEW_SORT_BY,
} from '@wegoTypes/hotels/hotelGuestReview';
import { HotelPopularHotel } from '@wegoTypes/hotels/hotelPopularHotel';
import { HotelRecommendedHotel } from '@wegoTypes/hotels/hotelRecommendedHotel';
import { HotelsLatestReviews } from '@wegoTypes/hotels/metasearch/hotelLatestReviews';
import { MetaSearchHotelPopular } from '@wegoTypes/hotels/metasearch/metaSearchHotelPopular';

export async function getHotel(
  apiBaseUrl: string,
  hotelId: number,
  locale: string,
  cancelToken: CancelToken,
): Promise<Hotel> {
  const searchParams: any = {
    locale,
    app_type: 'WEB_APP',
  };

  const url = appendSearchParams(`${apiBaseUrl}/hotels/hotels/${hotelId}`, searchParams);

  const response = await axios.get<Hotel>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

type GuestReviewParams = {
  locale?: string;
  sorts?: Array<`${REVIEW_SORT_BY}`>;
  page?: number;
  per_page?: number;
  provider_codes?: string[];
  guest_type?: `${REVIEW_GUEST_TYPE}`;
  queries?: string[];
};

export async function getGuestReviews(
  apiBaseUrl: string,
  hotelId: number,
  params: GuestReviewParams,
  cancelToken: CancelToken,
): Promise<HotelGuestReview> {
  const searchParams = {
    reviewScale: 10,
    app_type: 'WEB_APP',
    ...params,
  };

  const url = appendSearchParams(
    `${apiBaseUrl}/hotels/v2/hotels/${hotelId}/reviews/search`,
    searchParams,
  );

  const response = await axios.get<HotelGuestReview>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getCityPopularHotels(
  apiBaseUrl: string,
  cityCode: string,
  locale: string,
  currencyCode: string,
  currentSiteCode: string,
  perPageCount: number,
): Promise<Array<HotelPopularHotel>> {
  const searchParams: any = {
    app_type: 'WEB_APP',
    city_code: cityCode,
    currency_code: currencyCode,
    locale,
    per_page: perPageCount,
    property_type_id: 1,
    rates_count: 1,
    site_code: currentSiteCode.toUpperCase(),
  };

  const url = appendSearchParams(`${apiBaseUrl}/hotels/hotels`, searchParams);

  const response = await axios.get<Array<HotelPopularHotel>>(url, { withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getCityRecommendedHotels(
  apiBaseUrl: string,
  cityCode: string,
  locale: string,
): Promise<Array<HotelRecommendedHotel>> {
  const searchParams: any = {
    city_code: cityCode,
    locale,
    app_type: 'WEB_APP',
  };

  const url = appendSearchParams(`${apiBaseUrl}/hotels/recommended/collections`, searchParams);

  const response = await axios.get<Array<HotelRecommendedHotel>>(url, { withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export const getHotelRoom = async (
  roomTypeId: string,
  cancelToken: CancelToken,
): Promise<HotelRoom> => {
  const url = `${API_BASE_URL}/hotels/hotel_rooms/${roomTypeId}`;

  const response = await axios.get<HotelRoom>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export async function getHotelReviews(
  apiBaseUrl: string,
  cityCode: string,
  locale: string,
): Promise<HotelsLatestReviews> {
  const searchParams: any = {
    locale,
  };

  const url = appendSearchParams(`${apiBaseUrl}/hotels/v1/city/${cityCode}/reviews`, searchParams);

  const response = await axios.get<HotelsLatestReviews>(url, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export function getTopHotels(
  apiBaseUrl: string,
  searchParams: {
    checkIn: string;
    checkOut: string;
    currencyCode: string;
    cityCode?: string;
    siteCode: string;
    locale: string;
    room: GuestRoom;
  },
  user: User | null,
  wegoAnalyticsClientId: string,
  wegoAnalyticsSessionId: string,
  extraBodyParams?: { [key: string]: string },
): Observable<MetaSearchHotelPopular> {
  const body = {
    appType: 'WEB_APP',
    deviceType: 'DESKTOP',
    search: {
      ...searchParams,
    },
    ...(!!extraBodyParams && extraBodyParams),
  };
  return fromFetch(`${apiBaseUrl}/v3/metasearch/hotels/searches/popular`, {
    method: 'POST',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...(!!getGenzoIdentifierId() && {
        'X-Wego-Genzo-Identifier-Id': getGenzoIdentifierId()!,
      }),
      'X-Wego-Client-Id': wegoAnalyticsClientId!,
      'X-Wego-Session-Id': wegoAnalyticsSessionId!,
      ...(!!user?.email && { 'X-Wego-Hashed-User-Email': sha256(user.email).toString() }),
    },
    body: JSON.stringify(body),
  }).pipe(
    switchMap<Response, Promise<MetaSearchHotelPopular>>(async (response: Response) => {
      if (response.ok) {
        return await response.json();
      }
      throw new Error(response.status.toString());
    }),
    share({
      resetOnError: false,
      resetOnComplete: false,
      resetOnRefCountZero: false,
    }),
  );
}

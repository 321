import { share } from 'rxjs';
import type { Observable } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { switchMap, repeat } from 'rxjs/operators';

export function exchangeRateObservable(url: string): Observable<Array<ExchangeRate>> {
  return fromFetch(url, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  }).pipe(
    switchMap<Response, Promise<Array<ExchangeRate>>>(async (response: Response) => {
      if (response.ok) {
        return (await response.json()) as Array<ExchangeRate>;
      }
      throw new Error(response.statusText);
    }),
    repeat({ delay: 3600000 }),
    share({
      resetOnError: false,
      resetOnComplete: false,
      resetOnRefCountZero: false,
    }),
  );
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

export function useIntersectionObserver<T extends HTMLElement>(
  options?: IntersectionObserverInit,
): [React.RefObject<T>, boolean] {
  const ref = useRef<T>(null);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const intersectionObserverCallback = useCallback<IntersectionObserverCallback>(
    (entries: Array<IntersectionObserverEntry>) => {
      const [entry] = entries;

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        setIsVisible(true);
      }
    },
    [],
  );

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      if (!!ref.current) {
        const observer = new IntersectionObserver(intersectionObserverCallback, options);

        observer.observe(ref.current);

        return () => {
          observer.disconnect();
        };
      }
    } else {
      setIsVisible(true);
    }
  }, [ref, ref.current, options]);

  return [ref, isVisible];
}

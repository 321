import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { FlightRecentSearch } from '@wegoTypes/flights/flightRecentSearch';

import { addOneDay } from './hotel';

export function flightsRouteTranslation(locale: string): string {
  const flightsRouteTranslations: any = {
    en: 'flights',
    de: 'fluge',
    es: 'vuelos',
    fr: 'vols',
    id: 'tiket-pesawat',
    it: 'voli',
    ms: 'penerbangan',
    nl: 'vluchten',
    pl: 'loty',
    pt: 'voos',
    sv: 'flyg',
    vi: 've-may-bay',
  };

  return flightsRouteTranslations[locale] || flightsRouteTranslations['en'];
}

export function hotelsRouteTranslation(locale: string): string {
  const hotelsRouteTranslations: any = {
    en: 'hotels',
    de: 'hotels',
    es: 'hoteles',
    fr: 'hotels',
    id: 'hotel',
    it: 'hotel',
    ms: 'hotel',
    nl: 'hotels',
    pl: 'hotele',
    pt: 'hoteis',
    sv: 'hotell',
    vi: 'khach-san',
  };

  return hotelsRouteTranslations[locale] || hotelsRouteTranslations['en'];
}

export function airlinesRouteTranslation(locale: string): string {
  const airlinesRouteTranslations: any = {
    en: 'airlines',
    de: 'fluggesellschaften',
    es: 'aerolineas',
    fr: 'compagnies-aeriennes',
    id: 'maskapai',
    it: 'compagnie-aeree',
    ms: 'syarikat-penerbangan',
    nl: 'luchtvaartmaatschappijen',
    pl: 'linie-lotnicze',
    pt: 'companhias-aereas',
    sv: 'flygbolagflygbolag',
    vi: 'hang-hang-khong',
  };

  return airlinesRouteTranslations[locale] || airlinesRouteTranslations['en'];
}

export function airportsRouteTranslation(locale: string): string {
  const airportsRouteTranslations: any = {
    en: 'airports',
    de: 'flughafen',
    es: 'aeropuertos',
    fr: 'aeroports',
    id: 'bandara',
    it: 'aeroporti',
    ms: 'lapangan-terbang',
    nl: 'luchthavens',
    pl: 'lotniska',
    pt: 'aeroportos',
    sv: 'flygplatser',
    vi: 'san-bay',
  };

  return airportsRouteTranslations[locale] || airportsRouteTranslations['en'];
}

export function flightsToRouteTranslation(locale: string): string {
  const flightsToRouteTranslations: any = {
    en: 'flights-to',
    de: 'fluge-nach',
    es: 'vuelos-a',
    fr: 'vols-a-destination-de',
    id: 'tiket-pesawat-ke',
    it: 'voli-per',
    ms: 'penerbangan-ke',
    nl: 'vluchten-naar',
    pl: 'loty-do',
    pt: 'voos-para',
    sv: 'flyg-till',
    vi: 'chuyen-bay-den',
  };

  return flightsToRouteTranslations[locale] || flightsToRouteTranslations['en'];
}

export function schedulesRouteTranslation(locale: string): string {
  const schedulesRouteTranslations: any = {
    en: 'schedules',
    de: 'flugplane',
    es: 'horarios',
    fr: 'vols-a-destination-de',
    id: 'jadwal',
    it: 'orari',
    ms: 'jadual',
    nl: 'schema-s',
    pl: 'rozklady',
    pt: 'horarios',
    sv: 'tidtabeller',
    vi: 'lich-bay',
  };

  return schedulesRouteTranslations[locale] || schedulesRouteTranslations['en'];
}

export function cheapFlightsToRouteTranslation(locale: string, permalink: string) {
  const cheapFlightsToRouteTranslations: any = {
    en: `cheap-flights-to-${permalink}`,
    de: `gunstige-fluge-nach-${permalink}`,
    es: `vuelos-baratos-a-${permalink}`,
    fr: `vols-pas-chers-a-destination-de-${permalink}`,
    id: `tiket-murah-ke-${permalink}`,
    it: `voli-low-cost-per-${permalink}`,
    ms: `tiket-murah-ke-${permalink}`,
    nl: `goedkope-vluchten-naar-${permalink}`,
    pl: `tanie-loty-do-${permalink}`,
    pt: `voos-baratos-para-${permalink}`,
    sv: `billiga-flyg-till-${permalink}`,
    vi: `nhung-chuyen-bay-gia-re-den-${permalink}`,
  };

  return cheapFlightsToRouteTranslations[locale] || cheapFlightsToRouteTranslations['en'];
}

export function cheapestFlightsFromToRouteTranslation(
  locale: string,
  fromPermalink: string,
  toPermalink: string,
) {
  const cheapestFlightsFromToRouteTranslations: any = {
    en: `cheapest-flights-from-${fromPermalink}-to-${toPermalink}`,
    de: `gunstigste-fluge-ab-${fromPermalink}-nach-${toPermalink}`,
    es: `vuelos-mas-baratos-desde-${fromPermalink}-a-${toPermalink}`,
    fr: `vols-les-moins-chers-au-depart-de-${fromPermalink}-a-destination-de-${toPermalink}`,
    id: `tiket-murah-dari-${fromPermalink}-ke-${toPermalink}`,
    it: `voli-piu-economici-da-${fromPermalink}-a-${toPermalink}`,
    ms: `tiket-murah-dari-${fromPermalink}-ke-${toPermalink}`,
    nl: `goedkope-vluchten-vanaf-${fromPermalink}-naar-${toPermalink}`,
    pl: `najtansze-loty-z-${fromPermalink}-do-${toPermalink}`,
    pt: `voos-mais-baratos-de-${fromPermalink}-para-${toPermalink}`,
    sv: `billigaste-flyg-fran-${fromPermalink}-to-${toPermalink}`,
    vi: `chuyen-bay-re-nhat-tu-${fromPermalink}-den-${toPermalink}`,
  };

  return (
    cheapestFlightsFromToRouteTranslations[locale] || cheapestFlightsFromToRouteTranslations['en']
  );
}

export function destinationsRouteTranslation(locale: string): string {
  const destinationsRouteTranslations: any = {
    en: 'destinations',
    de: 'ziele',
    es: 'destinos',
    id: 'destinasi',
    it: 'mete',
    ms: 'destinasi',
    nl: 'bestemmingen',
    pl: 'destynacje',
    pt: 'destinos',
    sv: 'destinationer',
    vi: 'diem-den',
  };

  return destinationsRouteTranslations[locale] || destinationsRouteTranslations['en'];
}

export function chaletsRouteTranslation(locale: string) {
  const chaletsRouteTranslations: any = {
    ar: 'campaigns/chalets',
  };

  return chaletsRouteTranslations[locale] || chaletsRouteTranslations['ar'];
}

export function flightsRoute(currentSite: CurrentSite, localeParam: string | undefined): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/${flightsRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}`;
}

export function hotelsRoute(currentSite: CurrentSite, localeParam: string | undefined): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/${hotelsRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}`;
}

export function flightsToRoute(currentSite: CurrentSite, localeParam: string | undefined): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/${flightsToRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}`;
}

export function airlinesRoute(currentSite: CurrentSite, localeParam: string | undefined): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/${airlinesRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}`;
}

export function destinationsRoute(
  currentSite: CurrentSite,
  localeParam: string | undefined,
): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/${destinationsRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}`;
}

export function airportsRoute(currentSite: CurrentSite, localeParam: string | undefined): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/${airportsRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}`;
}

export function schedulesRoute(currentSite: CurrentSite, localeParam: string | undefined): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/${schedulesRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}`;
}

export function preferencesRoute(localeParam: string | undefined): string {
  return '/' + [localeParam, 'preferences'].filter(Boolean).join('/');
}

export function walletRoute(localeParam: string | undefined): string {
  return `${!!localeParam ? `/${localeParam}` : ''}/preferences/wallet-overview`;
}

export function flightToHotelRoute(
  currentSite: CurrentSite,
  localeParam: string | undefined,
  hotelSearchInfo: {
    cityCode: string;
    checkInDate: string;
    checkOutDate: string;
  },
): string {
  const { cityCode, checkInDate, checkOutDate } = hotelSearchInfo;
  return `${!!localeParam ? `/${localeParam}` : ''}/${hotelsRouteTranslation(
    localeParam || currentSite.defaultLocale,
  )}/searches/${cityCode}/${checkInDate}/${checkOutDate}`;
}

export function hotelsNavUrl(
  isFlightSearchResultPage: boolean,
  flightSearchesInStore: FlightRecentSearch[],
  currentSite: CurrentSite,
  localeParam: string | undefined,
): string {
  const checkOutDate =
    flightSearchesInStore[0]?.legSearches[1]?.outboundDate ||
    addOneDay(flightSearchesInStore[0]?.legSearches[0]?.outboundDate);

  const hotelSearchInfo: {
    cityCode: string;
    checkInDate: string;
    checkOutDate: string;
  } = {
    cityCode: flightSearchesInStore[0]?.legSearches[0].arrivalCityCode ?? '',
    checkInDate: flightSearchesInStore[0]?.legSearches[0]?.outboundDate ?? '',
    checkOutDate: checkOutDate ?? '',
  };

  return isFlightSearchResultPage &&
    hotelSearchInfo.cityCode &&
    hotelSearchInfo.checkInDate &&
    hotelSearchInfo.checkOutDate
    ? flightToHotelRoute(currentSite, localeParam, hotelSearchInfo)
    : hotelsRoute(currentSite, localeParam);
}

import { Currency, CurrencyCodes } from '@wegoTypes/currency';

const currencyMap: Record<CurrencyCodes, Currency> = {
  USD: {
    code: 'USD',
    symbol: 'US$',
    symbolFirst: true,
    name: 'US Dollar',
    translationKey: 'currency_us_dollar',
  },
  AED: {
    code: 'AED',
    symbol: 'AED',
    symbolFirst: true,
    name: 'Emirates Dirham',
    translationKey: 'currency_emirates_dirham',
  },
  AOA: {
    code: 'AOA',
    symbol: 'Kz',
    symbolFirst: false,
    name: 'Angolan Kwanza',
    separator: ' ',
    translationKey: 'currency_angolan_kwanza',
  },
  ARS: {
    code: 'ARS',
    symbol: '$',
    symbolFirst: true,
    name: 'Argentine Peso',
    separator: '.',
    translationKey: 'currency_argentine_peso',
  },
  AUD: {
    code: 'AUD',
    symbol: 'A$',
    symbolFirst: true,
    name: 'Australian Dollar',
    translationKey: 'currency_australian_dollar',
  },
  AZN: {
    code: 'AZN',
    symbol: '₼',
    symbolFirst: false,
    name: 'Azerbaijani Manat',
    translationKey: 'currency_azerbaijani_manat',
  },
  BDT: {
    code: 'BDT',
    symbol: '৳',
    symbolFirst: true,
    name: 'Bangladeshi Taka',
    translationKey: 'currency_bangladeshi_taka',
  },
  BHD: {
    code: 'BHD',
    symbol: 'BHD',
    symbolFirst: true,
    name: 'Bahraini Dinar',
    translationKey: 'currency_bahraini_dinar',
  },
  BRL: {
    code: 'BRL',
    symbol: 'R$',
    symbolFirst: true,
    name: 'Brazilian Real',
    separator: '.',
    translationKey: 'currency_brazilian_real',
  },
  BWP: {
    code: 'BWP',
    symbol: 'P',
    symbolFirst: true,
    name: 'Botswana Pula',
    translationKey: 'currency_botswana_pula',
  },
  CAD: {
    code: 'CAD',
    symbol: 'C$',
    symbolFirst: true,
    name: 'Canadian Dollar',
    translationKey: 'currency_canadian_dollar',
  },
  CDF: {
    code: 'CDF',
    symbol: 'FC',
    symbolFirst: false,
    name: 'Congolese Franc',
    separator: ' ',
    translationKey: 'currency_congolese_franc',
  },
  CHF: {
    code: 'CHF',
    symbol: 'Fr',
    symbolFirst: true,
    name: 'Swiss Franc',
    translationKey: 'currency_swiss_franc',
  },
  CLP: {
    code: 'CLP',
    symbol: 'CLP$',
    symbolFirst: true,
    name: 'Chilean Peso',
    translationKey: 'currency_chilean_peso',
  },
  CNY: {
    code: 'CNY',
    symbol: '¥',
    symbolFirst: true,
    name: 'Chinese Yuan',
    translationKey: 'currency_chinese_yuan',
  },
  COP: {
    code: 'COP',
    symbol: 'COL$',
    symbolFirst: true,
    name: 'Colombian Peso',
    separator: '.',
    translationKey: 'currency_colombian_peso',
  },
  DZD: {
    code: 'DZD',
    symbol: 'DZD',
    symbolFirst: true,
    name: 'Algerian Dinar',
    translationKey: 'currency_algerian_dinar',
  },
  EGP: {
    code: 'EGP',
    symbol: 'EGP',
    symbolFirst: true,
    name: 'Egyptian Pound',
    translationKey: 'currency_egyptian_pound',
  },
  ETB: {
    code: 'ETB',
    symbol: 'Br',
    symbolFirst: true,
    name: 'Ethiopian Birr',
    translationKey: 'currency_ethiopian_birr',
  },
  EUR: {
    code: 'EUR',
    symbol: '€',
    symbolFirst: false,
    name: 'Euro',
    separator: ' ',
    translationKey: 'currency_euro',
  },
  GBP: {
    code: 'GBP',
    symbol: '£',
    symbolFirst: true,
    name: 'British Pound',
    translationKey: 'currency_british_pound',
  },
  GHS: {
    code: 'GHS',
    symbol: '₵',
    symbolFirst: true,
    name: 'Ghana Cedi',
    translationKey: 'currency_ghana_cedi',
  },
  HKD: {
    code: 'HKD',
    symbol: 'HK$',
    symbolFirst: true,
    name: 'Hong Kong Dollar',
    translationKey: 'currency_hong_kong_dollar',
  },
  IDR: {
    code: 'IDR',
    symbol: 'Rp',
    symbolFirst: true,
    name: 'Indonesian Rupiah',
    separator: '.',
    translationKey: 'currency_indonesian_rupiah',
  },
  INR: {
    code: 'INR',
    symbol: '₹',
    symbolFirst: true,
    name: 'Indian Rupee',
    translationKey: 'currency_indian_rupee',
  },
  IQD: {
    code: 'IQD',
    symbol: 'د.ع',
    symbolFirst: true,
    name: 'Iraqi Dinar',
    translationKey: 'currency_iraqi_dinar',
  },
  IRR: {
    code: 'IRR',
    symbol: 'ریال',
    symbolFirst: true,
    name: 'Iranian Rial',
    translationKey: 'currency_iranian_rial',
  },
  JOD: {
    code: 'JOD',
    symbol: 'JOD',
    symbolFirst: true,
    name: 'Jordanian Dinar',
    translationKey: 'currency_jordanian_dinar',
  },
  JPY: {
    code: 'JPY',
    symbol: '¥',
    symbolFirst: true,
    name: 'Japanese Yen',
    translationKey: 'currency_japanese_yen',
  },
  KES: {
    code: 'KES',
    symbol: 'KSh',
    symbolFirst: true,
    name: 'Kenyan Shilling',
    translationKey: 'currency_kenyan_shilling',
  },
  KRW: {
    code: 'KRW',
    symbol: '₩',
    symbolFirst: true,
    name: 'Korean Won',
    translationKey: 'currency_korean_won',
  },
  KWD: {
    code: 'KWD',
    symbol: 'KWD',
    symbolFirst: true,
    name: 'Kuwaiti Dinar',
    translationKey: 'currency_kuwaiti_dinar',
  },
  LBP: {
    code: 'LBP',
    symbol: 'LBP',
    symbolFirst: true,
    name: 'Lebanese Pound',
    translationKey: 'currency_lebanese_pound',
  },
  LKR: {
    code: 'LKR',
    symbol: '₨',
    symbolFirst: false,
    name: 'Sri Lanka Rupee',
    translationKey: 'currency_sri_lanka_rupee',
  },
  LYD: {
    code: 'LYD',
    symbol: 'LYD',
    symbolFirst: true,
    name: 'Libyan dinar',
    translationKey: 'currency_libyan_dinar',
  },
  MAD: {
    code: 'MAD',
    symbol: 'MAD',
    symbolFirst: true,
    name: 'Moroccan Dirham',
    translationKey: 'currency_moroccan_dirham',
  },
  MOP: {
    code: 'MOP',
    symbol: 'MOP$',
    symbolFirst: true,
    name: 'Macanese Pataca',
    translationKey: 'currency_macanese_pataca',
  },
  MUR: {
    code: 'MUR',
    symbol: 'Rs',
    symbolFirst: true,
    name: 'Mauritian Rupee',
    translationKey: 'currency_mauritian_rupee',
  },
  MXN: {
    code: 'MXN',
    symbol: 'MEX$',
    symbolFirst: true,
    name: 'Mexican Peso',
    translationKey: 'currency_mexican_peso',
  },
  MYR: {
    code: 'MYR',
    symbol: 'RM',
    symbolFirst: true,
    name: 'Malaysian Ringgit',
    translationKey: 'currency_malaysian_ringgit',
  },
  MZN: {
    code: 'MZN',
    symbol: 'MTn',
    symbolFirst: false,
    name: 'Mozambican Metical',
    separator: ' ',
    translationKey: 'currency_mozambican_metical',
  },
  NAD: {
    code: 'NAD',
    symbol: 'N$',
    symbolFirst: true,
    name: 'Namibian Dollar',
    translationKey: 'currency_namibian_dollar',
  },
  NGN: {
    code: 'NGN',
    symbol: '₦',
    symbolFirst: false,
    name: 'Nigerian Naira',
    translationKey: 'currency_nigerian_naira',
  },
  NZD: {
    code: 'NZD',
    symbol: 'NZ$',
    symbolFirst: true,
    name: 'New Zealand Dollar',
    translationKey: 'currency_new_zealand_dollar',
  },
  OMR: {
    code: 'OMR',
    symbol: 'OMR',
    symbolFirst: true,
    name: 'Omani Riyal',
    translationKey: 'currency_omani_riyal',
  },
  PHP: {
    code: 'PHP',
    symbol: '₱',
    symbolFirst: true,
    name: 'Philippines Peso',
    translationKey: 'currency_philippines_peso',
  },
  PKR: {
    code: 'PKR',
    symbol: 'PKR',
    symbolFirst: true,
    name: 'Pakistani Rupee',
    translationKey: 'currency_pakistani_rupee',
  },
  PLN: {
    code: 'PLN',
    symbol: 'zł',
    symbolFirst: false,
    name: 'Polish Złoty',
    separator: ' ',
    translationKey: 'currency_polish_z_oty',
  },
  QAR: {
    code: 'QAR',
    symbol: 'QAR',
    symbolFirst: true,
    name: 'Qatari Riyal',
    translationKey: 'currency_qatari_riyal',
  },
  RUB: {
    code: 'RUB',
    symbol: 'р.',
    symbolFirst: false,
    name: 'Russian Rouble',
    separator: ' ',
    translationKey: 'currency_russian_rouble',
  },
  RWF: {
    code: 'RWF',
    symbol: 'RF',
    symbolFirst: false,
    name: 'Rwandan Franc',
    translationKey: 'currency_rwandan_franc',
  },
  SAR: {
    code: 'SAR',
    symbol: 'SAR',
    symbolFirst: true,
    name: 'Saudi Arabian Riyal',
    translationKey: 'currency_saudi_arabian_riyal',
  },
  SDG: {
    code: 'SDG',
    symbol: 'SDG',
    symbolFirst: true,
    name: 'Sudanese Pound',
    translationKey: 'currency_sudanese_pound',
  },
  SEK: {
    code: 'SEK',
    symbol: 'kr',
    symbolFirst: false,
    name: 'Swedish Krona',
    separator: ' ',
    translationKey: 'currency_swedish_krona',
  },
  SGD: {
    code: 'SGD',
    symbol: 'S$',
    symbolFirst: true,
    name: 'Singapore Dollar',
    translationKey: 'currency_singapore_dollar',
  },
  SYP: {
    code: 'SYP',
    symbol: '£',
    symbolFirst: true,
    name: 'Syrian pound',
    translationKey: 'currency_syrian_pound',
  },
  THB: {
    code: 'THB',
    symbol: '฿',
    symbolFirst: true,
    name: 'Thai Baht',
    translationKey: 'currency_thai_baht',
  },
  TND: {
    code: 'TND',
    symbol: 'TND',
    symbolFirst: true,
    name: 'Tunisian Dinar',
    translationKey: 'currency_tunisian_dinar',
  },
  TRY: {
    code: 'TRY',
    symbol: '₺',
    symbolFirst: true,
    name: 'Turkey Lira',
    separator: ',',
    translationKey: 'currency_turkey_lira',
  },
  TWD: {
    code: 'TWD',
    symbol: 'NT$',
    symbolFirst: true,
    name: 'Taiwan Dollar',
    translationKey: 'currency_taiwan_dollar',
  },
  TZS: {
    code: 'TZS',
    symbol: 'TSh',
    symbolFirst: true,
    name: 'Tanzanian Shilling',
    translationKey: 'currency_tanzanian_shilling',
  },
  UGX: {
    code: 'UGX',
    symbol: 'USh',
    symbolFirst: true,
    name: 'Ugandan Shilling',
    translationKey: 'currency_ugandan_shilling',
  },
  VND: {
    code: 'VND',
    symbol: '₫',
    symbolFirst: false,
    name: 'Vietnam Dong',
    separator: '.',
    translationKey: 'currency_vietnam_dong',
  },
  XAF: {
    code: 'XAF',
    symbol: 'FCFA',
    symbolFirst: false,
    name: 'Central African CFA Franc',
    separator: ' ',
    translationKey: 'currency_central_african_cfa_franc',
  },
  XOF: {
    code: 'XOF',
    symbol: 'CFA',
    symbolFirst: false,
    name: 'West African Franc',
    separator: ' ',
    translationKey: 'currency_west_african_franc',
  },
  ZAR: {
    code: 'ZAR',
    symbol: 'R',
    symbolFirst: true,
    name: 'South African Rand',
    translationKey: 'currency_south_african_rand',
  },
  ZMW: {
    code: 'ZMW',
    symbol: 'K',
    symbolFirst: true,
    name: 'Zambian Kwacha',
    translationKey: 'currency_zambian_kwacha',
  },
};

const getCurrency = (code: CurrencyCodes): Currency | undefined => {
  const currency: Currency | undefined =
    currencyMap[code.toUpperCase() as CurrencyCodes] ?? undefined;

  if (!!currency) {
    return formatCurrency(currency);
  }
  return currency;
};

const formatCurrency = (currency: any): Currency => {
  return {
    code: currency.code,
    symbol: currency.symbol,
    symbolFirst: currency.symbolFirst,
    name: currency.name,
    separator: currency.separator || ',',
    translationKey: currency.translationKey,
  };
};

export const getCurrencyConfig = (code: CurrencyCodes): Currency | undefined => {
  return getCurrency(code);
};

export const currenciesConfig: Array<Currency> = Object.entries(currencyMap).map<Currency>(
  (currencyConfig) => formatCurrency(currencyConfig[1]),
);

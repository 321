import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

import { getLocaleParam } from '@helpers/locale';

import { useIsAuthNew } from '@hooks/useIsAuthNew';

import { getUserState } from '@redux/selectors';

const AuthenticatedRoutes = () => {
  const user = useSelector(getUserState);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isAuthNew, coreConfigsLoaded } = useIsAuthNew();

  if (!coreConfigsLoaded) {
    return <></>;
  }

  if (!user) {
    const localeParam = getLocaleParam();
    if (!isAuthNew) {
      searchParams.set('modal', 'login');
    }

    const navigateTo = `/${localeParam ? localeParam + '/' : ''}?${searchParams.toString()}`;

    searchParams.delete('modal');
    return (
      <Navigate
        to={navigateTo}
        replace
        state={{ previousPath: `${location.pathname}?${searchParams.toString()}` }}
      />
    );
  }

  return <Outlet />;
};

export default AuthenticatedRoutes;

import { SprinklrChatSupportedLocale } from '@wegoTypes/sprinklrChat';

export const SPRINKLR_CHAT_SUPPORTED_LOCALE = [
  'ar',
  'en',
  'ca',
  'zh-HANS',
  'zh-HANT',
  'cs',
  'da',
  'nl',
  'de',
  'et',
  'fi',
  'fr',
  'hu',
  'id',
  'it',
  'ko',
  'lv',
  'nb',
  'pl',
  'pt',
  'pt-BR',
  'ro',
  'ru',
  'sk',
  'sl',
  'es',
  'es-LA',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
] as const;

export const APP_LOCALE_TO_SPRINKLR_CHAT_LOCALE_MAP: Record<string, SprinklrChatSupportedLocale> = {
  'zh-cn': 'zh-HANS',
  'zh-tw': 'zh-HANT',
  'zh-hk': 'zh-HANT',
};

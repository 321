import Spinner, { SpinnerProps } from '@alexis/components/Spinner';
import { CSSProperties, forwardRef, RefObject } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import {
  ButtonBorderWidth,
  ButtonColorScheme,
  ButtonSize,
  ButtonVariant,
} from '@components/Button/type';

import styles from './Button.module.scss';

const isSpinnerColorScheme = (
  colorScheme: string,
): colorScheme is Required<SpinnerProps>['colorScheme'] =>
  ['green', 'orange', 'red', 'grey'].includes(colorScheme);

export type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'ref'> & {
  className?: string;
  colorScheme?: ButtonColorScheme;
  disabled?: boolean;
  fullWidth?: boolean;
  light?: boolean;
  pill?: boolean;
  ref?: RefObject<HTMLButtonElement>;
  size?: ButtonSize;
  style?: CSSProperties;
  variant?: ButtonVariant;
  isLoading?: boolean;
  borderWidth?: ButtonBorderWidth;
  iconPlacement?: 'leading' | 'trailing';
  icon?: React.ReactElement;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      colorScheme = 'green',
      disabled,
      fullWidth,
      isLoading = false,
      light,
      onClick,
      pill = true,
      size = 'lg',
      type: typeProp,
      variant = 'solid',
      borderWidth = 'thin',
      icon,
      iconPlacement,
      ...restProps
    },
    ref,
  ) => {
    const defaultType = onClick ? 'button' : 'submit';
    const type = typeProp ?? defaultType;

    return (
      <button
        {...restProps}
        disabled={disabled}
        ref={ref}
        type={type}
        onClick={onClick}
        className={clsx(
          styles.button,
          styles[size],
          styles[variant],
          styles[colorScheme],
          styles[borderWidth],
          light && styles.light,
          disabled && styles.disabled,
          isLoading && styles.isLoading,
          pill && styles.pill,
          fullWidth && styles.fullWidth,
          className,
        )}
      >
        {iconPlacement === 'leading' && icon ? icon : null}
        {isLoading && isSpinnerColorScheme(colorScheme) ? (
          <Spinner colorScheme={colorScheme} invert={variant === 'outline' || light} />
        ) : null}
        <div className={styles.content}>{children}</div>
        {iconPlacement === 'trailing' && icon ? icon : null}
      </button>
    );
  },
);

export default Button;

type GetPrependedCountryNameParams = {
  code: string;
  locale: string;
  name: string;
};

export const getPrependedCountryName = ({ code, locale, name }: GetPrependedCountryNameParams) => {
  if (locale !== 'en') return name;

  const countries = [
    'bs',
    'ky',
    'cf',
    'km',
    'cz',
    'do',
    'fk',
    'gm',
    'im',
    'mv',
    'mh',
    'nl',
    'ph',
    'sb',
    'tc',
    'ae',
    'gb',
    'us',
    'vi',
    'vg',
  ];

  return countries.includes(code.toLowerCase()) ? `The ${name}` : name;
};

export enum FlightSearchResultSearchParams {
  Price = 'price',
  LiveTestProviderCodes = 'live_test_provider_codes',
  DepartDuration = 'depart_duration',
  ReturnDuration = 'return_duration',
  StopoverDuration = 'stopover_duration',
  DepartLegDepartureTime = 'departLeg_departure_time',
  DepartLegDepartureTimeBlocks = 'departLeg_departure_time_blocks',
  DepartLegArrivalTimeBlocks = 'departLeg_arrival_time_blocks',
  ReturnLegDepartureTimeBlocks = 'returnLeg_departure_time_blocks',
  ReturnLegArrivalTimeBlocks = 'returnLeg_arrival_time_blocks',
  DepartLegArrivalTime = 'departLeg_arrival_time',
  ReturnLegDepartureTime = 'returnLeg_departure_time',
  ReturnLegArrivalTime = 'returnLeg_arrival_time',
  Airlines = 'airlines',
  Stopovers = 'stopovers',
  Stops = 'stops',
  Providers = 'providers',
  ProviderCodes = 'provider_codes',
  Experience = 'experience',
  TripOptions = 'trip_options',
  Alliances = 'alliances',
  Origin = 'origin',
  Destination = 'destination',
  StopoverOptions = 'stopover_options',
  SelectedPaymentMethod = 'selected_payment_methods',
}

import { useSelector } from 'react-redux';

import { getFlightsPopularCities } from '@apis/places';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

export const getFlightsPopularCitiesKey = 'flights-popular-cities';

type GetFlightsPopularCitiesParams = {
  isDeparture: boolean;
  perPageCount: number;
};

export const useGetFlightsPopularCities = (
  params: GetFlightsPopularCitiesParams,
  options?: UseAxiosQueryOptions<Array<PlacesFlightsPopularCity>>,
) => {
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [
      getFlightsPopularCitiesKey,
      { locale, code: currentSiteCode.countryCode, ...params },
    ],
    queryFn: () =>
      getFlightsPopularCities(
        locale,
        currentSiteCode.countryCode,
        params.isDeparture,
        params.perPageCount,
      ),
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  });
};

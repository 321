import { getCurrencyDisplayFormat } from '@apis/currency';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { CurrencyFormat } from '@wegoTypes/currency';

const getCurrencyDisplayFormatKey = 'currency-display-format';

export const useGetCurrencyDisplayFormat = (options?: UseAxiosQueryOptions<CurrencyFormat>) => {
  return useAxiosQuery({
    queryKey: [getCurrencyDisplayFormatKey],
    queryFn: () => getCurrencyDisplayFormat(),
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 30,
    ...options,
  });
};

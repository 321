import {
  Fee,
  PaymentMethodFee,
} from '@wego/payments-react-component/dist/types/components/PaymentForm/models';
import { useSelector } from 'react-redux';

import { getPaymentFees } from '@apis/flight';

import { useAxiosQuery } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

export type FeePrice = Fee & { amountInUsd: number };

export type PaymentFee = PaymentMethodFee & {
  fee: FeePrice;
};

const useGetPaymentFees = (paymentOrderId: string) => {
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery<PaymentFee[]>({
    queryKey: [
      'getPaymentFees',
      {
        locale,
        currentSiteCode: currentSiteCode.countryCode,
        paymentOrderId,
      },
    ],
    queryFn: () => getPaymentFees(paymentOrderId),
    enabled: !!paymentOrderId,
  });
};

export default useGetPaymentFees;

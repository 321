import { datadogRum } from '@datadog/browser-rum';
import React, { useEffect, useMemo } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import InvaliDateIcon from '@icons/invalid_date.svg';
import MaxSearchLimitIcon from '@icons/max_search_limit.svg';
import NoInternetIcon from '@icons/no_internet_connection.svg';
import NoResultIcon from '@icons/no_result.svg';
import SomethingWrongIcon from '@icons/something_wrong.svg';

import styles from './AlertMessage.module.scss';

export enum MessageTypes {
  NO_RESULTS = 'NO_RESULTS',
  INVALID_DATE = 'INVALID_DATE',
  NO_INTERNET = 'NO_INTERNET',
  SOMETHING_WRONG = 'SOMETHING_WRONG',
  MAX_SEARCH_LIMIT = 'MAX_SEARCH_LIMIT',
}

interface AlertMessageProps {
  buttonLabel: string;
  className?: string;
  error?: Error;
  message: string;
  title?: string;
  type: MessageTypes;
  onClick(): void;
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  buttonLabel,
  className,
  error,
  message,
  title,
  type,
  onClick,
}) => {
  const getIconByType = (type: MessageTypes) => {
    switch (type) {
      case MessageTypes.NO_RESULTS:
        return NoResultIcon;
      case MessageTypes.INVALID_DATE:
        return InvaliDateIcon;
      case MessageTypes.NO_INTERNET:
        return NoInternetIcon;
      case MessageTypes.SOMETHING_WRONG:
        return SomethingWrongIcon;
      case MessageTypes.MAX_SEARCH_LIMIT:
        return MaxSearchLimitIcon;
      default:
        return NoResultIcon;
    }
  };

  const errorId = useMemo(() => {
    return crypto.randomUUID();
  }, []);

  // When passed with the error prop, send it to datadog for easier finding of error logs
  useEffect(() => {
    if (error) {
      const errorContext = {
        errorType: type,
        errorMessage: error.message,
        errorTitle: title,
        errorId,
      };
      datadogRum.addError(error, errorContext);
    }
  }, []);

  const Icon = getIconByType(type);

  return (
    <div className={clsx(styles.container, className)}>
      <Icon />

      {!!title ? <div className={styles.title}>{title}</div> : null}

      {/* used for debugging with datadog. Dont need translation */}
      <div className={styles.message}>Error ID: {errorId}</div>

      <div className={styles.message}>{message}</div>

      {buttonLabel && <button onClick={onClick}>{buttonLabel}</button>}
    </div>
  );
};

export default AlertMessage;

export const API_ENDPOINT = {
  CONFIRM_ADDONS: '/metasearch/bookings/flights/addons/confirm',
  DOWNLOAD_FLIGHT_TICKET: '/metasearch/bookings/flights/confirmation/fetch/{0}',
  GET_BOW_FLIGHT_FAREID: '/v3/metasearch/flights/google/create',
  GET_FLIGHT_SEARCH: '/v3/metasearch/flights/searches/{0}',
  GET_PAYMENT_METHODS: '/flights/payment_methods',
  GET_FLIGHT_BOOKING_DETAILS: '/metasearch/bookings/flights/v2/details',
  GET_FLIGHT_AMENITIES: '/v3/metasearch/flights/amenities/{0}',
  GET_FLIGHT_BOOKING_HISTORY_CANCELLED: '/metasearch/bookings/flights/cancelled_trips',
  GET_FLIGHT_BOOKING_HISTORY_COMPLETED: '/metasearch/bookings/flights/completed_trips',
  GET_FLIGHT_BOOKING_HISTORY_UPCOMPING: '/metasearch/bookings/flights/upcoming_trips',
  GET_FLIGHT_BOOKING_STATUS: '/metasearch/bookings/flights/status',
  GET_FLIGHT_FARE_REVALIDATION: '/metasearch/bookings/flights/v2/fare/revalidate',
  GET_FLIGHT_FARES_RULES: '/metasearch/bookings/flights/v2/fare-rules',
  GET_FLIGHT_FARES_V2: '/metasearch/bookings/flights/v2/fare/{0}/compare',
  GET_FLIGHT_PASSENGER_DYNAMIC_FORMS: '/metasearch/bookings/flights/dynamic-forms/{0}',
  GET_FLIGHT_PAYMENT_OPTIONS: '/metasearch/bookings/flights/v2/payments/options',
  GET_FLIGHT_PAYMENT: '/metasearch/bookings/flights/payments/{0}',
  GET_FLIGHT_TRIP_DETAILS: '/v5/metasearch/flights/trips/{0}',
  GET_INSURANCE: '/metasearch/bookings/flights/addons/insurance',
  POST_FLIGHT_PAYMENT: '/metasearch/bookings/flights/payments',
  RELEASE_FLIGHT_PROMO: '/metasearch/bookings/flights/promocodes/release',
  RESERVE_FLIGHT_PROMO: '/metasearch/bookings/flights/promocodes/reserve',
  SUBMIT_PASSENGER_INFO: '/metasearch/bookings/flights/passenger-info',
  TRAVELLER_LIST: '/users/travellers',
};

export const FIRST_NAME_MAX_LENGTH = 24;
export const LAST_NAME_MAX_LENGTH = 29;

export const PHONE_NUMBER_MAX_LENGTH = 15;

export const ALLOWED_MAX_AGE = 130;

export const SALUTATION = {
  Mr: 'MR',
  Mrs: 'MRS',
  Ms: 'MS',
  Master: 'MASTER',
  Miss: 'MISS',
};

export const IS_PAYMENT_ATTEMPTED = 'isPaymentAttempted';
export const IS_SINGLE_BRANDEDFARE = 'isSingleBrandedFare';

export const LAST_SELECTED_PAYMENT_OPTION = 'lastSelectedPaymentOption';

export const CHARGE_TYPE = {
  CANCEL: 'CANCEL',
  EXCHANGE: 'EXCHANGE',
  REFUND: 'REFUND',
};

export const BOOKING_HISTORY_TYPE = {
  UPCOMING: 'UPCOMING',
  PAST: 'PAST',
  CANCELLED: 'CANCELLED',
} as const;

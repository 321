import Spinner from '@alexis/components/Spinner';
import { wegoResendConfirmation } from '@alexis/helpers/authentications';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateText } from '@wego/alexis/helpers/translation';
import Input from '@wego/alexis/src/react/Input/Input';

import { getIsRtlState } from '@redux/selectors';

import type { Translations } from '@wegoTypes/translations';

import CloseIcon from '../icons/close.svg';
import styles from '../styles/components/ResendConfirmation.module.scss';

interface ResendConfirmationProps {
  className?: string;
  domainBasedApiBaseUrl: string;
  locale: string;
  onBackToLogin(): void;
  onClose(): void;
  translations: Translations;
}

const ResendConfirmation: React.FC<ResendConfirmationProps> = ({
  className,
  domainBasedApiBaseUrl,
  locale,
  onBackToLogin,
  onClose,
  translations,
}) => {
  const [email, setEmail] = useState<string>('');
  const [isEmailValidated, setIsEmailValidated] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResendConfirmationSuccessful, setIsResendConfirmationSuccessful] =
    useState<boolean>(false);
  const isRtl = useSelector(getIsRtlState);

  useEffect(() => {
    if (isEmailValidated) {
      setIsEmailValidated(false);
    }

    if (emailHasError) {
      setEmailHasError(false);
    }

    if (!!emailErrorMessage) {
      setEmailErrorMessage(undefined);
    }
  }, [email]);

  async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();

    let hasError = false;
    setEmailHasError(false);

    if (!/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsEmailValidated(true);
      setEmailHasError(true);

      if (email.length === 0) {
        setEmailErrorMessage(translations.required_fields as string);
      } else {
        setEmailErrorMessage(
          translateText(translations.invalid, locale, translations.email_address as string),
        );
      }
      hasError = true;
    }

    if (!hasError) {
      setIsEmailValidated(true);

      try {
        setIsLoading(true);
        await wegoResendConfirmation(domainBasedApiBaseUrl, email);

        setIsResendConfirmationSuccessful(true);
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);

          if (
            error.response.data.error.email === 'Email not found' ||
            error.response.data.error_code === 120
          ) {
            setEmailHasError(true);
            setEmailErrorMessage(translations.not_found as string);
          }

          if (error.response.data.error_code === 150) {
            setEmailHasError(true);
            setEmailErrorMessage(error.response.data.error.email);
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      }
      setIsLoading(false);
    }
  }

  return (
    <form
      className={clsx(styles.container, className)}
      onSubmit={handleFormSubmit}
      noValidate={true}
    >
      <div className={styles.header}>
        <CloseIcon className={styles.icon} onClick={onClose} />
        <div className={styles.title}>{translations.resend_confirmations}</div>
      </div>
      <div className={styles.content}>
        {isResendConfirmationSuccessful ? (
          <>
            <div className={styles.title}>{translations.mail_sent}</div>
            <br />
            <div className={styles.message}>{translations.mail_sent_desc}</div>

            <button type='button' onClick={onBackToLogin}>
              {translations.to_log_in_page}
            </button>
          </>
        ) : null}

        {!isResendConfirmationSuccessful ? (
          <>
            <div className={styles.message}>{translations.check_confirmations}</div>

            <Input
              autoComplete={'on'}
              className={styles.input}
              hasError={emailHasError}
              id={'email'}
              isRtl={isRtl}
              isValidated={isEmailValidated}
              message={emailErrorMessage}
              name={'email'}
              onChange={(value: string) => setEmail(value.trim())}
              placeholder={translations.email_address as string}
              type={'text'}
              value={email}
            />

            <div className={styles.message}>{translations.users_confirmations}</div>

            <button type='submit' disabled={isLoading}>
              {isLoading ? <Spinner className={styles.spinner} /> : translations.send}
            </button>
          </>
        ) : null}
      </div>
    </form>
  );
};

export default ResendConfirmation;

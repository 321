import { useSelector } from 'react-redux';

import { getBlogs } from '@apis/blogs';

import { BlogType } from '@components/Blogs/blog';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

export const getBlogKey = 'blog';

export const useBlogQuery = <TResult = Array<BlogType>>(
  options?: UseAxiosQueryOptions<Array<BlogType>, TResult>,
) => {
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [
      getBlogKey,
      {
        locale,
        currentSiteCode: currentSiteCode.countryCode,
      },
    ],
    queryFn: () => getBlogs(locale, currentSiteCode.countryCode),
    ...options,
  });
};

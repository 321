import type { ExchangeRateProps } from 'props/exchangeRateProps';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { isGoogleBot } from '../helpers/bots';
import { webEngageSetUserAttribute } from '../helpers/webEngage';
import { exchangeRateObservable } from '../observables/exchangeRateObservables';
import { changeExchangeRate } from '../redux/actions/exchangeRateActions';
import { changeShopCashUser } from '../redux/actions/shopCashActions';

const ExchangeRate: React.FC<ExchangeRateProps> = ({
  apiBaseUrl,
  currency,
  shopCashUser,
}): null => {
  const [exchangeRates, setExchangeRates] = useState<Array<ExchangeRate>>([]);

  const dispatch = useDispatch();

  // Query for currency
  useEffect(() => {
    const subscription = exchangeRateObservable(
      `${apiBaseUrl}/places/v1/currencies/latest`,
    ).subscribe({
      next: (value: Array<ExchangeRate>) => {
        setExchangeRates(value);
      },
      complete: () => console.log('CurrenciesObservable done'),
      error: (err) => console.log(err),
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [apiBaseUrl]);

  // Set exchange rate of selected currency to redux store
  useEffect(() => {
    const exchangeRate = exchangeRates.find((exchangeRate) => exchangeRate.code === currency.code);

    if (exchangeRate) {
      dispatch(changeExchangeRate(exchangeRate));
    }

    if (!isGoogleBot) {
      webEngageSetUserAttribute('currency', currency.code);
    }
  }, [currency, exchangeRates]);

  useEffect(() => {
    if (!!shopCashUser?.ledgerUsd || !shopCashUser?.ledger || !exchangeRates) return;

    const exchangeRate = exchangeRates.find(
      (exchangeRate) => exchangeRate.code === shopCashUser.ledger.currencyCode,
    );

    if (exchangeRate) {
      const { rate } = exchangeRate;
      const ledgerUsd = {
        availableBalance: shopCashUser.ledger.availableBalance / rate,
        displayBalance: shopCashUser.ledger.displayBalance / rate,
        lifetimeEarning: shopCashUser.ledger.lifetimeEarning / rate,
        lifetimeWithdrawn: shopCashUser.ledger.lifetimeWithdrawn / rate,
        pendingBalance: shopCashUser.ledger.pendingBalance / rate,
      };
      dispatch(changeShopCashUser({ ...shopCashUser, ledgerUsd }));
    }
  }, [shopCashUser, exchangeRates]);

  return null;
};

export default ExchangeRate;

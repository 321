import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';

import Carousel from '@components/Carousel';

import { useBlogQuery } from '@hooks/api/others/useBlogQuery';

import { Translations } from '@wegoTypes/translations';

import BlogCard from './BlogCard';
import styles from './BlogsSection.module.scss';

type BlogProps = {
  blogUrl: string;
  translations: Translations;
  isRtl: boolean;
};

const BlogsSection = ({ blogUrl, translations, isRtl }: BlogProps) => {
  const [searchParams] = useSearchParams();
  const bowOnlyParam = searchParams.get('bow_only');

  const { data: blogs } = useBlogQuery();

  return (
    <>
      {blogs && blogs.length > 0 && bowOnlyParam !== 'true' ? (
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {translations.title_stories}

            <a href={blogUrl} target='_blank' rel='noreferrer'>
              {translations.see_all_stories}

              <i className={clsx(styles.chevron, isRtl ? styles.left : styles.right)}></i>
            </a>
          </div>

          <Carousel
            className={styles.blogs}
            isRtl={isRtl}
            itemLength={blogs.length}
            itemWidth={384}
            scrollWidth={384}
          >
            {blogs.map((blog) => (
              <BlogCard key={blog.id} blog={blog} translations={translations} />
            ))}
          </Carousel>
        </div>
      ) : null}
    </>
  );
};

export default BlogsSection;

import Checkbox from '@alexis/components/Checkbox';
import { clsx } from '@alexis/helpers/clsx';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { translateNumber } from '@wego/alexis/helpers/translation';

import { MAXIMUM_PASSENGER_LIMIT } from '@constants/flight';

import { useFlightContext } from '@context/FlightContext';

import { cabinClassDisplay, passengerCountDisplay } from '@helpers/flight';

import { getIsRtlState, getLocaleState, getTranslationsState } from '@redux/selectors';

import type { CabinClass } from '@wegoTypes/flights/cabinClass';

import styles from './FlightInformationPickers.module.scss';

export interface FlightInformationPickersProps {
  adultCount: number;
  cabinClass: CabinClass;
  childCount: number;
  infantCount: number;
  availablePaymentMethods: Array<HomepageFlightsPaymentMethod>;
  onCabinClassChange: (cabinClass: CabinClass) => void;
  onPassengerCountDecrease: (type: PassengerType) => void;
  onPassengerCountIncrease: (type: PassengerType) => void;
  onPaymentMethodToggle: (id: number) => void;
  preferredPaymentMethods: number[];
}

const FlightInformationPickers: React.FC<FlightInformationPickersProps> = ({
  adultCount,
  cabinClass,
  childCount,
  infantCount,
  availablePaymentMethods,
  onCabinClassChange,
  onPassengerCountDecrease,
  onPassengerCountIncrease,
  onPaymentMethodToggle,
  preferredPaymentMethods,
}) => {
  const translations = useSelector(getTranslationsState);
  const locale = useSelector(getLocaleState);
  const isRtl = useSelector(getIsRtlState);

  const { isFlightSearch2 } = useFlightContext();

  const passengerPickerDropdownRef = useRef<HTMLDivElement>(null);

  const [isPassengerPickerExpanded, setIsPassengerPickerExpanded] = useState<boolean>(false);
  const [expandPassengerPickerFrom, setExpandPassengerPickerFrom] = useState<'left' | 'right'>();
  const [isCabinClassPickerExpanded, setIsCabinClassPickerExpanded] = useState<boolean>(false);
  const [isPaymentTypePickerExpanded, setIsPaymentTypePickerExpanded] = useState<boolean>(false);
  const [showMorePaymentMethods, setShowMorePaymentMethods] = useState<boolean>(false);

  const handlePassengerPickerClick = useCallback<(event: React.MouseEvent<HTMLDivElement>) => void>(
    (event) => {
      if (isRtl) {
        if (
          document.body.offsetWidth - 56 - event.currentTarget.offsetLeft >
          passengerPickerDropdownRef.current!.clientWidth
        ) {
          setExpandPassengerPickerFrom('left');
        } else {
          setExpandPassengerPickerFrom('right');
        }
      } else {
        if (
          event.currentTarget.offsetLeft + event.currentTarget.clientWidth - 56 >
          passengerPickerDropdownRef.current!.clientWidth
        ) {
          setExpandPassengerPickerFrom('right');
        } else {
          setExpandPassengerPickerFrom('left');
        }
      }

      setIsPassengerPickerExpanded(true);
    },
    [isRtl, passengerPickerDropdownRef],
  );

  const handleCabinClassChange = useCallback<(cabinClass: CabinClass) => void>(
    (cabinClass) => {
      onCabinClassChange(cabinClass);
      setIsCabinClassPickerExpanded(false);
    },
    [onCabinClassChange],
  );

  // Set all picker focus to false if body is clicked
  useEffect(() => {
    if (isPassengerPickerExpanded || isCabinClassPickerExpanded || isPaymentTypePickerExpanded) {
      const handleBodyOnClick = (): void => {
        if (isPassengerPickerExpanded) {
          setIsPassengerPickerExpanded(false);
        }

        if (isCabinClassPickerExpanded) {
          setIsCabinClassPickerExpanded(false);
        }

        if (isPaymentTypePickerExpanded) {
          setIsPaymentTypePickerExpanded(false);
        }
      };

      document.body.addEventListener('click', handleBodyOnClick);

      return () => {
        document.body.removeEventListener('click', handleBodyOnClick);
      };
    }
  }, [isCabinClassPickerExpanded, isPassengerPickerExpanded, isPaymentTypePickerExpanded]);

  return (
    <div className={clsx(styles.container, isFlightSearch2 && styles.flightSearch2)}>
      {/* Passenger picker */}
      <div
        data-testid='searchForm_noOfPassenger'
        data-pw='searchForm_noOfPassenger'
        className={clsx(styles.buttonFilter, isPassengerPickerExpanded && styles.expanded)}
        onClick={handlePassengerPickerClick}
      >
        {passengerCountDisplay(adultCount, childCount, infantCount, translations, locale)}
        <i className={clsx(styles.triangle, styles.down)}></i>

        <div
          className={clsx(
            styles.passengerPickerDropdown,
            expandPassengerPickerFrom === 'left' && styles.openFromLeft,
            expandPassengerPickerFrom === 'right' && styles.openFromRight,
          )}
          onClick={(e) => e.stopPropagation()}
          ref={passengerPickerDropdownRef}
        >
          {/* Adult */}
          <div
            data-testid='noOfPassengers_adults'
            data-pw='noOfPassengers_adults'
            className={styles.passenger}
          >
            <div className={styles.type}>
              <i className={clsx(styles.icon, styles.adultGrey)}></i>

              <div>
                <div className={styles.label}>{translations.Adults}</div>
                <div className={styles.requirement}>{translations.adult_age}</div>
              </div>
            </div>

            <div className={styles.stepperContainer}>
              <button
                aria-label='Add'
                className={styles.minusSign}
                type='button'
                disabled={adultCount < 2}
                onClick={() => onPassengerCountDecrease('adult')}
              ></button>
              <div className={styles.counter}>{translateNumber(adultCount, locale === 'fa')}</div>
              <button
                aria-label='Remove'
                className={styles.plusSign}
                type='button'
                disabled={adultCount + childCount + infantCount === MAXIMUM_PASSENGER_LIMIT}
                onClick={() => onPassengerCountIncrease('adult')}
              ></button>
            </div>
          </div>

          {/* Child */}
          <div
            data-testid='noOfPassengers_children'
            data-pw='noOfPassengers_children'
            className={styles.passenger}
          >
            <div className={styles.type}>
              <i className={clsx(styles.icon, styles.childGrey)}></i>

              <div>
                <div className={styles.label}>{translations.children}</div>
                <div className={styles.requirement}>{translations.child_age}</div>
              </div>
            </div>

            <div className={styles.stepperContainer}>
              <button
                aria-label='Add'
                className={styles.minusSign}
                type='button'
                disabled={childCount < 1}
                onClick={() => onPassengerCountDecrease('child')}
              ></button>
              <div className={styles.counter}>{translateNumber(childCount, locale === 'fa')}</div>
              <button
                aria-label='Remove'
                className={styles.plusSign}
                type='button'
                disabled={adultCount + childCount + infantCount === MAXIMUM_PASSENGER_LIMIT}
                onClick={() => onPassengerCountIncrease('child')}
              ></button>
            </div>
          </div>

          {/* Infant */}
          <div
            data-testid='noOfPassengers_infants'
            data-pw='noOfPassengers_infants'
            className={styles.passenger}
          >
            <div className={styles.type}>
              <i className={clsx(styles.icon, styles.infantGrey)}></i>

              <div>
                <div className={styles.label}>{translations.infants}</div>
                <div className={styles.requirement}>{translations.infant_age}</div>
              </div>
            </div>

            <div className={styles.stepperContainer}>
              <button
                aria-label='Add'
                className={styles.minusSign}
                type='button'
                disabled={infantCount < 1}
                onClick={() => onPassengerCountDecrease('infant')}
              ></button>
              <div className={styles.counter}>{translateNumber(infantCount, locale === 'fa')}</div>
              <button
                aria-label='Remove'
                className={styles.plusSign}
                type='button'
                disabled={
                  infantCount === adultCount ||
                  adultCount + childCount + infantCount === MAXIMUM_PASSENGER_LIMIT
                }
                onClick={() => onPassengerCountIncrease('infant')}
              ></button>
            </div>
          </div>

          <div className={styles.applyContainer}>
            <button
              data-pw='noOfPassengers_applyBtn'
              onClick={() => setIsPassengerPickerExpanded(false)}
              type='button'
            >
              {((translations.apply as string) || '').toLowerCase()}
            </button>
          </div>
        </div>
      </div>

      {/* Cabin class picker */}
      <div
        data-pw='searchForm_cabinClass'
        data-testid='searchForm_cabinClass'
        className={clsx(styles.buttonFilter, isCabinClassPickerExpanded && styles.expanded)}
        onClick={() => setIsCabinClassPickerExpanded(true)}
      >
        {cabinClassDisplay(cabinClass, translations)}
        <i className={clsx(styles.triangle, styles.down)}></i>

        <div
          data-testid='searchForm_cabinClassPicker'
          data-pw='searchForm_cabinClassPicker'
          className={styles.cabinClassPickerDropdown}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={clsx(styles.cabinClass, cabinClass === 'economy' && styles.selected)}
            onClick={() => handleCabinClassChange('economy')}
          >
            {translations.economy}
          </div>
          <div
            className={clsx(styles.cabinClass, cabinClass === 'premium_economy' && styles.selected)}
            onClick={() => handleCabinClassChange('premium_economy')}
          >
            {translations.premium_economy}
          </div>
          <div
            className={clsx(styles.cabinClass, cabinClass === 'business' && styles.selected)}
            onClick={() => handleCabinClassChange('business')}
          >
            {translations.business_class}
          </div>
          <div
            className={clsx(styles.cabinClass, cabinClass === 'first' && styles.selected)}
            onClick={() => handleCabinClassChange('first')}
          >
            {translations.first}
          </div>
        </div>
      </div>

      {/* Payment type picker */}
      <div
        data-pw='searchForm_paymentMethods'
        className={clsx(styles.buttonFilter, isPaymentTypePickerExpanded && styles.expanded)}
        onClick={() => setIsPaymentTypePickerExpanded(true)}
      >
        {preferredPaymentMethods.length > 0 && preferredPaymentMethods.length}{' '}
        {translations.payment_types}
        <i className={clsx(styles.triangle, styles.down)}></i>
        <div className={styles.paymentTypePickerDropdown} onClick={(e) => e.stopPropagation()}>
          <div className={styles.message}>{translations.select_payment_types}</div>

          <div className={styles.paymentMethods}>
            {availablePaymentMethods
              .slice(0, showMorePaymentMethods ? availablePaymentMethods.length : 6)
              .map((paymentMethod) => (
                <div
                  key={paymentMethod.id}
                  className={styles.paymentMethod}
                  onClick={() => onPaymentMethodToggle(paymentMethod.id)}
                >
                  <Checkbox
                    className={styles.checkbox}
                    isChecked={preferredPaymentMethods.includes(paymentMethod.id)}
                  />
                  <div className={styles.name}>{paymentMethod.name}</div>
                </div>
              ))}
          </div>

          {availablePaymentMethods.length > 6 && !showMorePaymentMethods ? (
            <div
              data-pw='paymentMethods_showMoreBtn'
              className={styles.showMore}
              onClick={() => setShowMorePaymentMethods(true)}
            >
              + {translations.show_more}
            </div>
          ) : null}

          {showMorePaymentMethods ? (
            <div className={styles.showMore} onClick={() => setShowMorePaymentMethods(false)}>
              - {translations.show_less}
            </div>
          ) : null}

          <div className={styles.message}>{translations.tips}</div>

          <div className={styles.applyContainer}>
            <button
              data-pw='paymentMethod_applyBtn'
              onClick={() => setIsPaymentTypePickerExpanded(false)}
              type='button'
            >
              {((translations.apply as string) || '').toLowerCase()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightInformationPickers;

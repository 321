import { translateNumber } from '@wego/alexis/helpers/translation';

export const enPublicHolidaysLabel: string = 'Public Holidays';

export const enShortMonthLabels: Array<string> = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const enMonthLabels: Array<string> = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const enShortDayLabels: Array<string> = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

export const enDayLabels: Array<string> = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

// formatType === 0 : Convert date to ddd, DD MMM (Tue, 12 Nov) format.
// formatType === 1 : Convert date to DD MMM YYYY (12 Nov 2016) format.
// formatType === 2 : Convert date to DD MMM (12 Nov) format.
// formatType === 3 : Convert date to MMM D, YYYY (Nov 9, 2016) format.
// formatType === 4 : Convert date to DD/MM/YYYY (12/09/2016) format.
// formatType === 5 : Convert date to ddd, DD MMM YYYY (Tue, 12 Nov 2016) format.
export function dateDisplayFormat(
  date: Date,
  monthLabels: Array<string> = enShortMonthLabels,
  dayLabels: Array<string> = enShortDayLabels,
  isFarsi: boolean = false,
  formatType: number = 0,
): string {
  const dayStringLabel = dayLabels[date.getDay()];
  const monthStringLabel = monthLabels[date.getMonth()];

  const dateLabel = translateNumber(date.getDate().toString().padStart(2, '0'), isFarsi);
  const monthLabel = translateNumber((date.getMonth() + 1).toString().padStart(2, '0'), isFarsi);
  const yearLabel = translateNumber(date.getFullYear(), isFarsi);

  switch (formatType) {
    case 0: // ddd, DD MMM (Tue, 12 Nov)
      return `${dayStringLabel}, ${dateLabel} ${monthStringLabel}`;
    case 1: // DD MMM YYYY (12 Nov 2016)
      return `${dateLabel} ${monthStringLabel} ${yearLabel}`;
    case 2: // DD MMM (12 Nov)
      return `${dateLabel} ${monthStringLabel}`;
    case 3: {
      // MMM D, YYYY (Nov 9, 2016)
      const noStartPaddingDateLabel = translateNumber(date.getDate().toString(), isFarsi);

      return `${monthStringLabel} ${noStartPaddingDateLabel}, ${yearLabel}`;
    }
    case 4: // DD/MM/YYYY (12/09/2016)
      return `${dateLabel}/${monthLabel}/${yearLabel}`;
    case 5: // ddd, DD MMM YYYY(Tue, 12 Nov 2016)
      return `${dayStringLabel}, ${dateLabel} ${monthStringLabel} ${yearLabel}`;
    default:
      return '';
  }
}

// Convert date to yyyy-MM-dd (2019-11-28) format.
export function dateApiFormat(date: Date): string {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
}

export function numberOfNights(fromDateMilliseconds: number, toDateMilliseconds: number): number {
  return Math.round(Math.abs(toDateMilliseconds - fromDateMilliseconds) / 86400000); //1 Day = 1000*60*60*24
}

/**
 * @description Get +08:00 out from "Tue Jun 30 2020 23:11:14 GMT+0800 (Singapore Standard Time)"
 * @param date
 */
export function getGMTOffsetFromDate(date: Date): string {
  const dateString = date.toString();
  const gmtString = dateString.match(/(?:GMT)([-+]\d*)/)![1]; // Example: +0800

  const gmtStringSplit = [...gmtString];
  gmtStringSplit.splice(3, 0, ':');

  return gmtStringSplit.join('');
}

export function toISOStringWithTimezone(date: Date): string {
  const currentDate = date.getDate();
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const currentHours = date.getHours();
  const currentMinutes = date.getMinutes();
  const currentSeconds = date.getSeconds();

  const currentDateTime = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${currentDate
    .toString()
    .padStart(2, '0')}T${currentHours.toString().padStart(2, '0')}:${currentMinutes
    .toString()
    .padStart(2, '0')}:${currentSeconds.toString().padStart(2, '0')}`;

  return `${currentDateTime}${getGMTOffsetFromDate(date)}`;
}

export function leadTime(date: Date): string {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const leadtimeDays = numberOfNights(today.getTime(), date.getTime());

  return leadtimeDays < 2
    ? 'lastminute'
    : leadtimeDays < 8
    ? 'sameweek'
    : leadtimeDays < 31
    ? 'samemonth'
    : 'advance';
}

/**
 *
 * @param dateStr  YYYY-MM-DD to date ( eg:2023/04/06 -> Wed Apr 05 2023 00:00:00 GMT-0500 (Central Daylight Time) )
 * @description Why cannot pass with new Date('YYYY-MM-DD'), as the day is not correct for (-ve timezonecountry, eg:US)
 * @returns timestamp
 *
 **/
export function getLocalDate(dateStr: string): Date {
  const [year, month, day] = dateStr.split('-');
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
}

import React, { useCallback, useEffect, useState } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateNumber } from '@wego/alexis/helpers/translation';

import { Currency } from '@wegoTypes/currency';

import styles from '../styles/components/Price.module.scss';

interface PriceProps {
  className?: string;
  currency: Currency;
  exchangeRate: ExchangeRate;
  isInline?: boolean;
  isStrikethrough?: boolean;
  locale: string;
  price: Price;
  showDecimal?: boolean;
  style?: React.CSSProperties;
  // dataPw is the id that is used for automation testing by playwright
  dataPw?: string;
  id?: string;
  googleCrawlerForBowEnabled?: boolean;
}

const Price: React.FC<PriceProps> = ({
  className,
  currency,
  exchangeRate,
  isInline,
  isStrikethrough,
  locale,
  price,
  showDecimal,
  style,
  dataPw,
  id,
  googleCrawlerForBowEnabled,
}) => {
  const [showAdditionalZeroPlaceholder, setShowAdditionalZeroPlaceholder] =
    useState<boolean>(false);
  const [amountDisplay, setAmountDisplay] = useState<string>('');

  const [isNegative, setIsNegative] = useState<boolean>(false);

  useEffect(() => {
    const decimalPoints: number = showDecimal ? currency.numberOfDecimals || 2 : 0;
    const isPriceCurrencySameAsSelectedCurrency = price.currencyCode === currency.code;

    let amount = isPriceCurrencySameAsSelectedCurrency
      ? price.amount
      : price.amountUsd * exchangeRate.rate;

    if (amount < 0) {
      amount = amount * -1;
      setIsNegative(true);
    } else {
      setIsNegative(false);
    }

    if (isBigValueCurrency(currency.code) && amount > 99999) {
      amount = amount / 1000;
      setShowAdditionalZeroPlaceholder(true);
    } else {
      setShowAdditionalZeroPlaceholder(false);
    }

    const stringAmount =
      isBigValueCurrency(currency.code) || amount > 99999
        ? amount.toFixed(0)
        : amount.toFixed(decimalPoints);
    const stringAmountLength = stringAmount.length;

    let modValue: number = 0;

    if (!(isBigValueCurrency(currency.code) || amount > 99999) && showDecimal) {
      modValue = stringAmountLength - 3 > 3 ? stringAmountLength % 3 : 0;
    } else {
      modValue = stringAmountLength > 3 ? stringAmountLength % 3 : 0;
    }

    setAmountDisplay(
      translateNumber(
        `${
          !!modValue ? `${stringAmount.substr(0, modValue)}${currency.separator}` : ''
        }${stringAmount.substr(modValue).replace(/(\d{3})(?=\d)/g, `$1${currency.separator}`)}`,
        locale === 'fa',
      ),
    );
  }, [price, locale, currency, exchangeRate, showDecimal]);

  const isBigValueCurrency = useCallback<(currencyCode: string) => boolean>(
    (currencyCode: string) => {
      return /COP|IDR|IRR|KRW|LBP|VND/.test(currencyCode.toUpperCase());
    },
    [],
  );

  const symbolPlaceholderView = useCallback<(isInline: boolean) => JSX.Element>(
    (isInline: boolean) => {
      if (isInline) {
        return <span className={styles.symbol}>{currency.symbol}</span>;
      }
      return <sup className={styles.symbol}>{currency.symbol}</sup>;
    },
    [currency],
  );

  return (
    <div
      data-pw={dataPw}
      data-testid='price'
      className={clsx(styles.container, isStrikethrough && styles.strikethrough, className)}
      style={style}
      id={id}
    >
      {isNegative ? <>-&nbsp;</> : null}

      {currency.symbolFirst ? symbolPlaceholderView(!!isInline) : null}

      <span
        id={googleCrawlerForBowEnabled ? 'totalPrice' : undefined}
        data-currency-code={googleCrawlerForBowEnabled ? currency.code : undefined}
        data-price-accuracy={googleCrawlerForBowEnabled ? (showDecimal ? 2 : 0) : undefined}
        className={clsx(
          styles.amount,
          !showAdditionalZeroPlaceholder && !currency.symbolFirst && styles.withRightMargin,
        )}
      >
        {amountDisplay}
      </span>

      {showAdditionalZeroPlaceholder ? (
        isInline ? (
          <span className={clsx(!currency.symbolFirst && styles.withRightMargin)}>
            {translateNumber(`${currency.separator}000`, locale === 'fa')}
          </span>
        ) : (
          <sup>{translateNumber(`${currency.separator}000`, locale === 'fa')}</sup>
        )
      ) : null}

      {!currency.symbolFirst ? symbolPlaceholderView(!!isInline) : null}
    </div>
  );
};

export default Price;

import { Link, LinkProps, useSearchParams } from 'react-router-dom';

const PreferencesSideNavigationLink: React.FC<LinkProps & { to: string; localeParam?: string }> = ({
  to,
  localeParam,
  ...restProps
}) => {
  const [searchParams] = useSearchParams();
  const isExternal = typeof to === 'string' && to.startsWith('http');
  const splitPathname = to.split('?');
  const extractSearchParams = splitPathname.length > 1 ? to.split('?')[1] : '';

  if (isExternal) return <a href={to} target='_blank' rel='noopener noreferrer' {...restProps} />;

  return (
    <Link
      to={{
        pathname: localeParam ? '/' + localeParam + splitPathname[0] : splitPathname[0],
        search: new URLSearchParams(extractSearchParams + '&' + searchParams.toString()).toString(),
      }}
      {...restProps}
    />
  );
};

export default PreferencesSideNavigationLink;

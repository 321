export const FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY = 'flightRecentSearch';

export const FLIGHT_RECENT_SEARCHES_FOR_COMPARING_LOCAL_STORAGE_KEY =
  'flightRecentSearchesForComparing';

export const FLIGHT_SEARCH_RESPONSE_LOCAL_STORAGE_KEY = 'flightMetasearchApiResponse';

export const SESSION_ID_LOCAL_STORAGE_KEY = 'sessionId';

export const HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY = 'hotelRecentSearch';

export const CURRENCY_LOCAL_STORAGE_KEY = 'currency';

export const PRICE_OPTIONS_LOCAL_STORAGE_KEY = 'priceOptions';

export const USER_LOCAL_STORAGE_KEY = 'user';

export const HERO_IMAGE_ID_LOCAL_STORAGE_KEY = 'heroImageId';

export const GENZO_IDENTIFIER_ID = 'genzoIdentifierId';

export const FLIGHT_COMPARISON_PROVIDERS = 'flightComparisonProviders';
export const NEW_AUTH = 'newAuth';

export const HOTEL_CROSS_SELLING_POPUP_COUNT_LOCAL_STORAGE_KEY = 'hotelCrossSellingPopupCount';

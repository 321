import Checkbox from '@alexis/components/Checkbox';
import { clsx } from '@alexis/helpers/clsx';
import { dateApiFormat, getLocalDate } from '@alexis/helpers/date';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import { gtmTrack } from '@alexis/helpers/gtm';
import { localStorageSave } from '@alexis/helpers/localStorage';
import { appendSearchParams } from '@alexis/helpers/searchParam';
import { SELECTED_LEG_ID_PLS_PARAM } from '@pages/FlightSearchResult/constants';
import { useQueryClient } from '@tanstack/react-query';
import type { CabinClass } from 'flights/cabinClass';
import type { FlightSearch } from 'flights/flightSearch';
import type { FlightSearchType } from 'flights/flightSearchType';
import { cloneDeep, difference } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ContentErrorBoundary from '@components/ErrorBoundary/ContentError/ContentErrorBoundary';
import LegSearch from '@components/flights/LegSearch';
import { mapLegSearchesToSearchDetails } from '@components/flights/SearchFormCollapseView/helper';

import { DIRECT_ONLY_PARAM, FLIGHT_SEARCH_RESULTS_HEADER_HEIGHT } from '@constants/flight';
import { GTM_ID } from '@constants/general';

import { useFlightContext } from '@context/FlightContext';

import {
  getUpdatedLegSearchesWhenLegSearchFieldChange,
  getUpdatedLegSearchesWhenAddingFlight,
  getPreCheckedStatusForFlightComparisonProviders,
  updateFlightComparisonProviderCheckedStatus,
} from '@helpers/flight';
import { scrollToTop } from '@helpers/utils';

import { useGetFlightComparisonProviders } from '@hooks/api/flights/useGetFlightComparisonProviders';
import { updateFlightRecentSearchDetailsKey } from '@hooks/api/flights/useGetUpdateFlightRecentSearchDetails';
import {
  getLocationDetailsKey,
  useGetLocationDetails,
} from '@hooks/api/places/useGetLocationDetails';
import useLocaleParam from '@hooks/useLocaleParam';
import usePreferredPaymentMethods from '@hooks/usePreferredPaymentMethods';

import {
  getCurrencyState,
  getCurrentSiteState,
  getIsRtlState,
  getLocaleState,
  getPageViewIdState,
  getTranslationsState,
  getTriggerSearchCounterState,
  getUserState,
} from '@redux/selectors';

import { FlightSearchResultSearchParams } from '@wegoTypes/flights/flightSearchParams';

import { FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY } from '../../constants/localStorage';
import {
  convertLegParamToLegs,
  convertLegSearchesToParam,
  deleteFilterStatesFromSearchParam,
  getComparisonProviderLabelText,
  getNearestMulticityLegSearchOutboundDateMilliseconds,
  isRoundTrip,
} from '../../helpers/flight';
import { flightsRoute } from '../../helpers/routeTranslation';
import { useViewportSize } from '../../hooks/useViewportSize';
import SearchIcon from '../../icons/search.svg';
import { updateTriggerSearchCounter } from '../../redux/actions/triggerSearchCounterActions';
import styles from '../../styles/components/flights/FlightSearchLayout.module.scss';
import FlightInformationPickers from './FlightInformationPickers';
import FlightSearchTypePicker from './FlightSearchTypePicker';
import SearchFormCollapseView from './SearchFormCollapseView';

interface FlightSearchLayoutProps {
  availablePaymentMethods: HomepageFlightsPaymentMethod[];
  geolocationCoordinates: GeolocationCoordinates | undefined;
  gtmAuth: string;
  gtmPreview: string;
  homepage: Homepage | undefined;
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
}

const FlightSearchLayout: React.FC<FlightSearchLayoutProps> = ({
  availablePaymentMethods,
  geolocationCoordinates,
  gtmAuth,
  gtmPreview,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
}) => {
  const user = useSelector(getUserState);
  const triggerSearchCounter = useSelector(getTriggerSearchCounterState);
  const translations = useSelector(getTranslationsState);
  const locale = useSelector(getLocaleState);
  const currency = useSelector(getCurrencyState);
  const currentSite = useSelector(getCurrentSiteState);
  const isRtl = useSelector(getIsRtlState);
  const pageViewId = useSelector(getPageViewIdState).home;

  const { setFlightSearchFormHeight, flightSearchFormHeight, isFlightSearch2AndNotMulticity } =
    useFlightContext();

  const { width } = useViewportSize();
  const isTablet = width < 1024;
  const isMediumScreen = width >= 1024 && width < 1280;

  const flightSearchRefs = useRef<Array<LegSearchHandles>>([]);
  const searchFormRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const [searchType, setSearchTypes] = useState<FlightSearchType>('oneWay');

  const [legSearches, setLegSearches] = useState<Array<LegSearch>>([
    {
      inboundDateMilliseconds: undefined,
      inboundPlace: undefined,
      outboundDateMilliseconds: undefined,
      outboundPlace: undefined,
    },
  ]);
  const [lastSavedInboundDateMillisecond, setLastSavedInboundDateMillisecond] = useState(0);

  const [viewMode, setViewMode] = useState<'collapsed' | 'expanded'>('collapsed');
  const [isStickySearchLayout, setIsStickySearchLayout] = useState<boolean>(false);

  const [adultCount, setAdultCount] = useState<number>(1);
  const [childCount, setChildCount] = useState<number>(0);
  const [infantCount, setInfantCount] = useState<number>(0);
  const [cabinClass, setCabinClass] = useState<CabinClass>('economy');

  const [flightComparisonProviders, setFlightComparisonProviders] = useState<
    Array<FlightComparisonProvider>
  >([]);

  const [isPlaceAndDateDetailsParsedFromUrl, setIsPlaceAndDateDetailsParsedFromUrl] =
    useState<boolean>(false);

  const { preferredPaymentMethods, togglePreferredPaymentMethod, setPreferredPaymentMethods } =
    usePreferredPaymentMethods(availablePaymentMethods);

  const dispatch = useDispatch();

  const { pathname, search, state } = useLocation();

  const navigate = useNavigate();

  const params = useParams<{
    leg?: string;
    cabinClass?: CabinClass;
    adultCount?: string;
    childCount?: string;
    infantCount?: string;
  }>();

  const [searchParams] = useSearchParams();
  const bowOnlyParam = searchParams.get('bow_only');

  const isMultiCitySearch = searchType === 'multiCity';
  const isInCollapseView = viewMode === 'collapsed' && (isTablet || isMultiCitySearch);

  const isDirectFlightOnly = searchParams.has(DIRECT_ONLY_PARAM);

  // Get locale param
  const localeParam = useLocaleParam();

  const paymentMethodIdsFromURL = useMemo(
    () =>
      searchParams
        .get('payment_methods')
        ?.split(',')
        .map((item) => parseInt(item)) || [],
    [searchParams],
  );

  const legs = convertLegParamToLegs(params.leg || '');

  const locationCodes = legs.flatMap((leg) => {
    return [
      leg.departureAirportCode ?? `c${leg.departureCityCode}`,
      leg.arrivalAirportCode ?? `c${leg.arrivalCityCode}`,
    ];
  });

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let hasError = false;

    const gtmData = {
      event: 'eventTracked',
      eventAction: 'search',
      eventCategory: 'flights',
      eventLabel: searchType,
    };

    gtmTrack(GTM_ID, gtmAuth, gtmPreview, gtmData);

    for (let i = 0; i < legSearches.length; i++) {
      const flightSearchHasError = flightSearchRefs.current[i].validate();

      if (flightSearchHasError) {
        hasError = true;
      }
    }

    if (!hasError) {
      if (wegoAnalyticsClientId && wegoAnalyticsClientSessionId) {
        const eventData = {
          id: pageViewId,
          category: 'search_form',
          object: searchType.toLocaleLowerCase(),
          action: 'search_cta',
          value: 'successful',
        };

        genzoTrackActionEvent(
          API_BASE_URL,
          wegoAnalyticsClientId,
          wegoAnalyticsClientSessionId,
          user?.userHash,
          eventData,
        );
      }

      const searchParams = new URLSearchParams(search);

      if (!searchParams.has('sort')) {
        searchParams.append('sort', 'score');
      }

      if (!searchParams.has('order')) {
        searchParams.append('order', 'desc');
      }

      const selectedPaymentMethodIds = preferredPaymentMethods;

      if (selectedPaymentMethodIds.length > 0) {
        searchParams.set('payment_methods', selectedPaymentMethodIds.join(','));
      } else {
        searchParams.delete('payment_methods');
      }

      searchParams.delete('selected_trip_id');
      searchParams.delete('search_id');
      searchParams.delete(SELECTED_LEG_ID_PLS_PARAM);

      const legParam = convertLegSearchesToParam(legSearches);

      scrollToTop();

      localStorageSave<FlightSearch>(FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY, {
        searchType,
        legSearches,
        adultCount,
        childCount,
        infantCount,
        cabinClass,
        locale,
      });

      queryClient.invalidateQueries({ queryKey: [getLocationDetailsKey] });
      queryClient.invalidateQueries({ queryKey: [updateFlightRecentSearchDetailsKey] });

      const updatedURLSearchParams = deleteFilterStatesFromSearchParam(searchParams);

      if (isDirectFlightOnly) {
        updatedURLSearchParams.set(FlightSearchResultSearchParams.Stops, '0');
      }

      const updatedSearchParams = updatedURLSearchParams.toString();

      const searchResultPathname = `${flightsRoute(
        currentSite,
        localeParam,
      )}/searches/${legParam}/${cabinClass}/${adultCount}a:${childCount}c:${infantCount}i`.toLowerCase();

      setViewMode('collapsed');

      if (
        searchResultPathname.toLowerCase() === pathname &&
        difference(paymentMethodIdsFromURL, selectedPaymentMethodIds).length === 0 &&
        difference(selectedPaymentMethodIds, paymentMethodIdsFromURL).length === 0
      ) {
        dispatch(updateTriggerSearchCounter(triggerSearchCounter + 1));
      }

      if (flightComparisonProviders.length > 0 && flightComparisonProviders[0].preChecked) {
        const searchParams = {
          currency_code: currency.code,
          cabin: cabinClass,
          outbound_date: dateApiFormat(new Date(legSearches[0].outboundDateMilliseconds!)),
          ...(searchType === 'roundTrip' && {
            inbound_date: dateApiFormat(new Date(legSearches[0].inboundDateMilliseconds!)),
          }),
          adults_count: adultCount,
          ...(childCount > 0 && { children_count: childCount }),
          ...(infantCount > 0 && { infants_count: infantCount }),
          wc: wegoAnalyticsClientId,
          ws: wegoAnalyticsClientSessionId,
          ...(isDirectFlightOnly && { stops: 0 }),
        };

        const handoffUrl = appendSearchParams(
          flightComparisonProviders[0].handoffUrl,
          searchParams,
        );

        // setTimeout is to prevent popup blocker
        setTimeout(() => {
          window.location.href = handoffUrl;
        }, 1);

        window.open(
          `${window.location.origin}${searchResultPathname}${
            updatedSearchParams ? `?${updatedSearchParams}` : ''
          }`,
          '_blank',
        );
      } else {
        navigate(
          { pathname: searchResultPathname.toLowerCase(), search: updatedSearchParams },
          { state },
        );
      }
    } else if (wegoAnalyticsClientId && wegoAnalyticsClientSessionId) {
      const eventData = {
        id: pageViewId,
        category: 'search_form',
        object: searchType.toLocaleLowerCase(),
        action: 'search_cta',
        value: 'unsuccessful',
      };

      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        user?.userHash,
        eventData,
      );
    }
  };

  const handleAddLegSearch = useCallback<() => void>(() => {
    const updatedLegSearches = getUpdatedLegSearchesWhenAddingFlight(legSearches);

    setLegSearches(updatedLegSearches);
  }, [legSearches]);

  const handleSearchTypeChange = useCallback<(searchType: FlightSearchType) => void>(
    (searchType) => {
      const searchParams = new URLSearchParams(search);
      const updatedSearchParams = deleteFilterStatesFromSearchParam(searchParams).toString();

      navigate({ pathname, search: updatedSearchParams }, { replace: true, state });

      setSearchTypes(searchType);
      setViewMode('expanded');

      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);
      const newLegSearches = cloneLegSearches.slice(0, 1);

      if (searchType === 'oneWay' && legSearches.length > 0) {
        newLegSearches[0].inboundDateMilliseconds = undefined;
        setLegSearches(newLegSearches);
      } else if (searchType === 'roundTrip' && legSearches.length > 0) {
        newLegSearches[0].inboundDateMilliseconds = lastSavedInboundDateMillisecond;
        setLegSearches(newLegSearches);
      } else if (searchType === 'multiCity' && legSearches.length === 1) {
        cloneLegSearches[0].inboundDateMilliseconds = undefined;
        cloneLegSearches.push(
          {
            outboundPlace:
              cloneLegSearches[0].inboundPlace !== 'Anywhere'
                ? cloneLegSearches[0].inboundPlace
                : undefined,
            inboundPlace: undefined,
            outboundDateMilliseconds: undefined,
            inboundDateMilliseconds: undefined,
          },
          {
            outboundPlace: undefined,
            inboundPlace: undefined,
            outboundDateMilliseconds: undefined,
            inboundDateMilliseconds: undefined,
          },
        );

        setLegSearches(cloneLegSearches);
      }
    },
    [lastSavedInboundDateMillisecond, legSearches, navigate, pathname, search, state],
  );

  const handleLegSearchFieldChange = useCallback<
    (legSearchIndex: number, field: FlightSearchFields, value: InboundPlace | number) => void
  >(
    (legSearchIndex, field, value) => {
      if (field === 'inboundDateMilliseconds' && searchType === 'oneWay') {
        setSearchTypes('roundTrip');
      }

      if (
        field === 'inboundPlace' ||
        field === 'outboundPlace' ||
        field === 'swapOutboundInboundPlace'
      ) {
        searchParams.delete(DIRECT_ONLY_PARAM);

        navigate({ pathname, search: searchParams.toString() }, { replace: true, state });
      }

      const updatedLegSearches = getUpdatedLegSearchesWhenLegSearchFieldChange(
        legSearches,
        legSearchIndex,
        field,
        value,
      );
      setLegSearches(updatedLegSearches);
    },
    [legSearches, searchParams, pathname, state, navigate],
  );

  const handleRemoveReturnDate = useCallback<() => void>(() => {
    setSearchTypes('oneWay');

    const updatedLegSearches = getUpdatedLegSearchesWhenLegSearchFieldChange(
      legSearches,
      0,
      'inboundDateMilliseconds',
      undefined,
    );
    setLegSearches(updatedLegSearches);
  }, [legSearches]);

  const handleDateReset = useCallback(
    (legSearchIndex: number) => {
      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);
      const legSearch = cloneLegSearches[legSearchIndex];

      legSearch.outboundDateMilliseconds = undefined;
      legSearch.inboundDateMilliseconds = undefined;

      setLegSearches(cloneLegSearches);
    },
    [legSearches],
  );

  const handleRemoveLegSearch = useCallback<(legSearchIndex: number) => void>(
    (legSearchIndex) => {
      const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);

      cloneLegSearches.splice(legSearchIndex, 1);

      setLegSearches(cloneLegSearches);
    },
    [legSearches],
  );

  const handlePassengerCountDecrease = useCallback<(type: PassengerType) => void>(
    (type) => {
      switch (type) {
        case 'adult':
          if (adultCount === infantCount) {
            setInfantCount((prev) => prev - 1);
          }
          setAdultCount((prev) => prev - 1);
          break;
        case 'child':
          setChildCount((prev) => prev - 1);
          break;
        case 'infant':
          setInfantCount((prev) => prev - 1);
          break;
      }
    },
    [adultCount, infantCount],
  );

  const handlePassengerCountIncrease = useCallback<(type: PassengerType) => void>((type) => {
    switch (type) {
      case 'adult':
        setAdultCount((prev) => prev + 1);
        break;
      case 'child':
        setChildCount((prev) => prev + 1);
        break;
      case 'infant':
        setInfantCount((prev) => prev + 1);
        break;
    }
  }, []);

  const handleCabinClassChange = useCallback<(cabinClass: CabinClass) => void>((cabinClass) => {
    setCabinClass(cabinClass);
  }, []);

  const handleFlightComparisonProviderToggle = useCallback<
    (code: string, isSelected: boolean) => void
  >(
    (code, isSelected) => {
      const cloneComparisonProviders =
        cloneDeep<Array<FlightComparisonProvider>>(flightComparisonProviders);

      const comparisonProvider = cloneComparisonProviders.find(
        (cloneComparisonProvider) => cloneComparisonProvider.provider.code === code,
      );

      comparisonProvider!.preChecked = isSelected;

      updateFlightComparisonProviderCheckedStatus(code, isSelected);

      setFlightComparisonProviders(cloneComparisonProviders);
    },
    [flightComparisonProviders],
  );

  const handleChangeViewMode = useCallback(() => {
    setViewMode('expanded');
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (e) => {
    if (e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();

      const toDatePicker = document.getElementById('to-input-container');

      if (toDatePicker) {
        toDatePicker.focus();
      } else {
        document.getElementById('from-input-container')?.focus();
      }
    }
  };

  //Sticky search layout
  useEffect(() => {
    const handleWindowScroll = (ev: Event) => {
      const window = ev.currentTarget as Window;

      if (window.scrollY > FLIGHT_SEARCH_RESULTS_HEADER_HEIGHT) {
        setIsStickySearchLayout(true);
      } else {
        setIsStickySearchLayout(false);
      }
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  useEffect(() => {
    setFlightSearchFormHeight(searchFormRef.current?.clientHeight ?? 0);
  }, [searchFormRef.current?.clientHeight, searchType, viewMode, flightComparisonProviders.length]);

  // Save the last inbound date for round trip
  useEffect(() => {
    if (legSearches[0]?.inboundDateMilliseconds) {
      setLastSavedInboundDateMillisecond(legSearches[0].inboundDateMilliseconds);
    }
  }, [legSearches]);

  // Detect searchTypes from params
  useEffect(() => {
    const legs = convertLegParamToLegs(params.leg || '');

    if (isRoundTrip(legs)) {
      setSearchTypes('roundTrip');
    } else if (legs.length === 1) {
      setSearchTypes('oneWay');
    } else {
      setSearchTypes('multiCity');
    }
  }, [params.leg]);

  // Get dates and places from the URL
  const { fetchStatus } = useGetLocationDetails(
    {
      locationCodes,
    },
    {
      onSuccessCallback: (locationDetails) => {
        let initialLegSearches: Array<LegSearch> = [];

        if (isRoundTrip(legs)) {
          initialLegSearches = [
            {
              outboundDateMilliseconds: getLocalDate(legs[0].outboundDate).getTime(),
              outboundPlace: locationDetails.find((location) => {
                return legs[0].departureAirportCode
                  ? location.airportCode === legs[0].departureAirportCode
                  : location.cityCode === legs[0].departureCityCode;
              }),
              inboundDateMilliseconds: getLocalDate(legs[1].outboundDate).getTime(),
              inboundPlace: locationDetails.find((location) => {
                return legs[1].departureAirportCode
                  ? location.airportCode === legs[1].departureAirportCode
                  : location.cityCode === legs[1].departureCityCode;
              }),
            },
          ];
        } else {
          initialLegSearches = legs.map<LegSearch>((leg) => ({
            inboundDateMilliseconds: undefined,
            outboundDateMilliseconds: getLocalDate(leg.outboundDate).getTime(),
            outboundPlace: locationDetails.find((location) => {
              return leg.departureAirportCode
                ? location.airportCode === leg.departureAirportCode
                : location.cityCode === leg.departureCityCode;
            }),
            inboundPlace: locationDetails.find((location) => {
              return leg.arrivalAirportCode
                ? location.airportCode === leg.arrivalAirportCode
                : location.cityCode === leg.arrivalCityCode;
            }),
          }));
        }

        setIsPlaceAndDateDetailsParsedFromUrl(true);
        setLegSearches(initialLegSearches);
      },
    },
  );

  useEffect(() => {
    if (fetchStatus === 'fetching') {
      setIsPlaceAndDateDetailsParsedFromUrl(false);
    }
  }, [fetchStatus]);

  // Get Cabin types, passenger counts from URL
  useEffect(() => {
    if (params.adultCount) {
      setAdultCount(+params.adultCount[0]);
    }

    if (params.childCount) {
      setChildCount(+params.childCount[0]);
    }

    if (params.infantCount) {
      setInfantCount(+params.infantCount[0]);
    }

    if (
      params.cabinClass &&
      ['economy', 'premium_economy', 'business', 'first'].includes(params.cabinClass)
    ) {
      setCabinClass(params.cabinClass);
    } else {
      setCabinClass('economy');
    }
  }, [params.adultCount, params.cabinClass, params.childCount, params.infantCount]);

  // Get flight comparison providers
  useGetFlightComparisonProviders(
    {
      departureAirportCode: (legSearches[0]?.outboundPlace as Place)?.airportCode,
      departureCityCode: legSearches[0]?.outboundPlace?.cityCode ?? '',
      departureCountryCode: legSearches[0]?.outboundPlace?.countryCode ?? '',
      departureDate: legSearches[0]?.outboundDateMilliseconds
        ? dateApiFormat(new Date(legSearches[0]?.outboundDateMilliseconds))
        : '',
      arrivalAirportCode: (legSearches[0]?.inboundPlace as Place)?.airportCode,
      arrivalCityCode:
        (legSearches[0]?.inboundPlace as Place | PlacesFlightsPopularCity)?.cityCode ?? '',
      arrivalCountryCode:
        (legSearches[0]?.inboundPlace as Place | PlacesFlightsPopularCity)?.countryCode ?? '',
      returnDate: legSearches[0]?.inboundDateMilliseconds
        ? dateApiFormat(new Date(legSearches[0]?.inboundDateMilliseconds))
        : undefined,
      placementType: 'search',
    },
    {
      enabled:
        !isMultiCitySearch &&
        !!legSearches[0] &&
        !!legSearches[0].outboundPlace &&
        !!legSearches[0].inboundPlace &&
        !!legSearches[0].outboundDateMilliseconds &&
        !bowOnlyParam,
      onSuccessCallback: (flightComparisonProviders) => {
        getPreCheckedStatusForFlightComparisonProviders(flightComparisonProviders);

        setFlightComparisonProviders(flightComparisonProviders);
      },
      onErrorCallback: () => {
        setFlightComparisonProviders([]);
      },
    },
  );

  useEffect(() => {
    if (searchType === 'multiCity' && flightComparisonProviders.length) {
      setFlightComparisonProviders([]);
    }
  }, [searchType, flightComparisonProviders.length]);

  useEffect(() => {
    setPreferredPaymentMethods(paymentMethodIdsFromURL);
  }, [paymentMethodIdsFromURL]);

  return (
    <>
      {isStickySearchLayout && <div style={{ height: `${flightSearchFormHeight}px` }}></div>}

      <div
        ref={searchFormRef}
        className={clsx(
          styles.container,
          isRtl && styles.rtl,
          isStickySearchLayout && styles.fixed,
          isFlightSearch2AndNotMulticity && styles.flightSearch2,
        )}
      >
        {isInCollapseView ? (
          <SearchFormCollapseView
            adultCount={adultCount}
            cabinClass={cabinClass}
            childCount={childCount}
            infantCount={infantCount}
            isMultiCitySearch={isMultiCitySearch}
            isPlaceAndDateDetailsParsedFromUrl={isPlaceAndDateDetailsParsedFromUrl}
            searchDetails={mapLegSearchesToSearchDetails(legSearches)}
            onEditClick={handleChangeViewMode}
          />
        ) : (
          <form
            className={clsx(styles.flightSearchForm)}
            onSubmit={handleFormSubmit}
            noValidate={true}
          >
            <div className={styles.searchTypeAndFlightInformation}>
              <FlightSearchTypePicker
                searchType={searchType}
                onSearchTypeChange={handleSearchTypeChange}
              />

              {!isTablet && (
                <FlightInformationPickers
                  adultCount={adultCount}
                  cabinClass={cabinClass}
                  childCount={childCount}
                  infantCount={infantCount}
                  availablePaymentMethods={availablePaymentMethods}
                  preferredPaymentMethods={preferredPaymentMethods}
                  onPassengerCountDecrease={handlePassengerCountDecrease}
                  onPassengerCountIncrease={handlePassengerCountIncrease}
                  onCabinClassChange={handleCabinClassChange}
                  onPaymentMethodToggle={(id) => togglePreferredPaymentMethod(id, true)}
                />
              )}
            </div>

            <div
              className={clsx(styles.legSearches, isMultiCitySearch && styles.multiCityLegSearches)}
            >
              {legSearches.map((legSearch, index) => (
                <LegSearch
                  clientId={wegoAnalyticsClientId}
                  clientSessionId={wegoAnalyticsClientSessionId}
                  geolocationCoordinates={geolocationCoordinates}
                  isRemoveLegSearchDisabled={legSearches.length < 3}
                  key={index}
                  legSearch={legSearch}
                  legSearchIndex={index}
                  nextLegSearchOutboundDateMilliseconds={
                    isMultiCitySearch
                      ? getNearestMulticityLegSearchOutboundDateMilliseconds(
                          'next',
                          index,
                          legSearches,
                        )
                      : undefined
                  }
                  onLegSearchFieldChange={handleLegSearchFieldChange}
                  onDateReset={() => handleDateReset(index)}
                  onRemoveLegSearchClick={handleRemoveLegSearch}
                  onRemoveReturnDateClick={handleRemoveReturnDate}
                  pageViewId={pageViewId}
                  previousLegSearchOutboundDateMilliseconds={
                    isMultiCitySearch
                      ? getNearestMulticityLegSearchOutboundDateMilliseconds(
                          'previous',
                          index,
                          legSearches,
                        )
                      : undefined
                  }
                  ref={(flightSearchHandles) =>
                    (flightSearchRefs.current[index] = flightSearchHandles!)
                  }
                  searchType={searchType}
                  passengerCount={adultCount + childCount + infantCount}
                />
              ))}
              {!isMultiCitySearch && !isTablet && (
                <button
                  id='flightSearchFormButton'
                  className={styles.searchButton}
                  type='submit'
                  tabIndex={2}
                  onKeyDown={handleKeyDown}
                >
                  {isMediumScreen ? (
                    <SearchIcon className={styles.icon} />
                  ) : (
                    <span>{translations.search}</span>
                  )}
                </button>
              )}
            </div>

            {(isTablet || isMultiCitySearch) && (
              <div className={styles.addLegAndFlightInformationAndSearchButton}>
                {isMultiCitySearch && (
                  <div>
                    <button
                      data-pw='multicitySearch_addLegBtn'
                      className={styles.addLegSearch}
                      disabled={legSearches.length === 6}
                      onClick={handleAddLegSearch}
                      type='button'
                    >
                      {translations.add_flight}
                      <div className={clsx(styles.tooltip, styles.bottom)}>
                        {translations.multicity_search_add_flights}
                      </div>
                    </button>
                  </div>
                )}

                <div
                  className={clsx(
                    styles.flightInformationAndSearchButton,
                    isTablet && styles.fullWidth,
                  )}
                >
                  {isTablet && (
                    <FlightInformationPickers
                      adultCount={adultCount}
                      cabinClass={cabinClass}
                      childCount={childCount}
                      infantCount={infantCount}
                      availablePaymentMethods={availablePaymentMethods}
                      preferredPaymentMethods={preferredPaymentMethods}
                      onPassengerCountDecrease={handlePassengerCountDecrease}
                      onPassengerCountIncrease={handlePassengerCountIncrease}
                      onCabinClassChange={handleCabinClassChange}
                      onPaymentMethodToggle={(id) => togglePreferredPaymentMethod(id, true)}
                    />
                  )}

                  <button
                    id='flightSearchFormButton'
                    className={clsx(styles.searchButton, styles.multiCitySearchButton)}
                    type='submit'
                    tabIndex={2}
                    onKeyDown={handleKeyDown}
                  >
                    {translations.search}
                  </button>
                </div>
              </div>
            )}

            {flightComparisonProviders.length > 0 && (
              <div className={styles.flightComparisonProviders}>
                <div className={styles.label}>
                  {getComparisonProviderLabelText(flightComparisonProviders, translations)}
                </div>

                {flightComparisonProviders.map((flightComparisonProvider) => (
                  <div
                    key={flightComparisonProvider.provider.code}
                    className={styles.flightComparisonProvider}
                    onClick={() =>
                      handleFlightComparisonProviderToggle(
                        flightComparisonProvider.provider.code,
                        !flightComparisonProvider.preChecked,
                      )
                    }
                  >
                    <Checkbox
                      className={styles.checkbox}
                      isChecked={flightComparisonProvider.preChecked}
                    />
                    {flightComparisonProvider.provider.name}
                  </div>
                ))}
              </div>
            )}
          </form>
        )}
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <ContentErrorBoundary isErrorReload>
            <Outlet />
          </ContentErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default FlightSearchLayout;

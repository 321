import { useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GlobalContext } from '@context/GlobalContext';

const useSeatSelectionFeatureFlag = () => {
  const [searchParams] = useSearchParams();
  const seatSelection = searchParams.get('seat_selection');
  const { userCentricCoreConfigs } = useContext(GlobalContext);

  return useMemo(
    () =>
      userCentricCoreConfigs?.find((coreConfig) => coreConfig.key === 'WF_5284_BOWF_Seat_Selection')
        ?.value === '1' || seatSelection === 'true',
    [userCentricCoreConfigs, seatSelection],
  );
};

export default useSeatSelectionFeatureFlag;

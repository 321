export function geolocation(): Promise<GeolocationCoordinates> {
  return new Promise<GeolocationCoordinates>((resolve, reject) => {
    if ('geolocation' in navigator) {
      const options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 3600000,
      };

      navigator.geolocation.getCurrentPosition(
        (pos) => {
          resolve(pos.coords);
        },
        (err) => {
          reject(err);
        },
        options,
      );
    } else {
      reject('Geolocation not supported.');
    }
  });
}

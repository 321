import { clsx } from '@alexis/helpers/clsx';
import { dateApiFormat } from '@alexis/helpers/date';
import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { translateText } from '@wego/alexis/helpers/translation';

import Image from '@components/Image';

import {
  cloudflareImageTransformations,
  cloudinaryImageTransformations,
  isCloudinarySrc,
} from '@helpers/imageTransformations';
import { hotelsRoute } from '@helpers/routeTranslation';

import { CurrentSite } from '@wegoTypes/currentSite';
import { HotelRecommendedHotel } from '@wegoTypes/hotels/hotelRecommendedHotel';
import { Translations } from '@wegoTypes/translations';

import styles from './RecommendedHotelCard.module.scss';

interface RecommendedHotelCardProps {
  cityCode: string;
  currentSite: CurrentSite;
  hotel: HotelRecommendedHotel;
  locale: string;
  localeParam: string;
  translations: Translations;
}

const RecommendedHotelCard: React.FC<RecommendedHotelCardProps> = ({
  cityCode,
  currentSite,
  hotel,
  locale,
  localeParam,
  translations,
}): JSX.Element => {
  const { search, state } = useLocation();

  // Construct search with filter params
  const searchWithFilterParams = useMemo<string>(() => {
    const searchParams = new URLSearchParams(search);

    searchParams.append('guests', '2');

    Object.entries(hotel.filter).forEach((filter) => {
      let key = filter[0];

      if (filter[0] === 'accommodationTypes') {
        key = 'accommodation_types';
      }

      if (filter[0] === 'guestScore') {
        key = 'guest_score';
      }

      searchParams.append(key, filter[1].join(','));
    });

    const updatedSearchParams = searchParams.toString();

    return !!updatedSearchParams ? `?${updatedSearchParams}` : '';
  }, [hotel.filter]);

  const checkInDate = new Date();

  const checkOutDate = new Date();
  checkOutDate.setDate(checkInDate.getDate() + 1);

  const noImagePlaceholderSrc = cloudinaryImageTransformations(
    'https://assets.wego.com/image/upload/v1639463186/web/misc/hotel-image-placeholder.png',
    'h_184',
    'c_fill',
    'f_auto',
    'fl_lossy',
    'q_auto:low',
    'g_auto',
  );

  const getImageTransformationsUrl = useCallback<(url: string) => string>((url: string) => {
    if (isCloudinarySrc(url)) {
      return cloudinaryImageTransformations(
        url,
        'h_368',
        'c_fill',
        'f_auto',
        'fl_lossy',
        'q_auto:best',
        'g_auto',
      );
    } else {
      return cloudflareImageTransformations(url, { format: 'auto', height: 368, quality: 90 });
    }
  }, []);

  return (
    <Link
      className={clsx(styles.container)}
      to={{
        pathname: `${hotelsRoute(
          currentSite,
          localeParam,
        )}/searches/${cityCode.toLowerCase()}/${dateApiFormat(checkInDate)}/${dateApiFormat(
          checkOutDate,
        )}`,
        search: searchWithFilterParams,
      }}
      state={state}
    >
      <div className={styles.content}>
        <div className={styles.title}>{hotel.title}</div>
        <div className={styles.propertiesCount}>
          {translateText(translations.properties_count, locale, hotel.hotelCount)}
        </div>
      </div>

      <Image
        alt={hotel.title}
        className={styles.image}
        isLazy={true}
        noImagePlaceholderSrc={noImagePlaceholderSrc}
        placeholderSrc={noImagePlaceholderSrc}
        src={getImageTransformationsUrl(hotel.imageUrl)}
      />
    </Link>
  );
};

export default RecommendedHotelCard;

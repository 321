export function cloudinaryImageTransformations(
  url: string,
  ...transformations: Array<string>
): string {
  return url
    .replace('http://', 'https://')
    .replace(
      /(res.cloudinary.com\/wego\/image\/upload)|(res.cloudinary.com\/wego)/,
      'assets.wego.com/image/upload',
    )
    .replace(
      /^(https:\/\/assets\.wego\.com\/image\/upload|https:\/\/zen\.wego\.com\/image\/upload)\/(.+)$/i,
      `$1/${transformations.join(',')}/$2`,
    );
}

export function cloudflareImageTransformations(
  url: string,
  transformations: CloudflareImageTransformations,
): string {
  const combinedTransformations = Object.entries(transformations).reduce<string>(
    (accumulator, transformation) => {
      return (accumulator += `${!!accumulator ? ',' : ''}${transformation[0]}=${
        transformation[1]
      }`);
    },
    '',
  );

  return url
    .replace('http://', 'https://')
    .replace(
      /^(https:\/\/zen\.(?:wego|wegostaging)\.com)\/(.+)$/i,
      `$1/cdn-cgi/image/${combinedTransformations}/$2`,
    );
}

export function isCloudinarySrc(url: string): boolean {
  return /(?:assets\.wego\.com\/image\/upload|res\.cloudinary\.com\/wego)/i.test(url);
}

export const getImageTransformationsUrl = (url: string) => {
  if (isCloudinarySrc(url)) {
    return cloudinaryImageTransformations(
      url,
      'h_240',
      'c_fill',
      'f_auto',
      'fl_lossy',
      'q_auto:best',
      'g_auto',
    );
  }

  return cloudflareImageTransformations(url, { format: 'auto', height: 240, quality: 90 });
};

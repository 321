import { clsx } from '@alexis/helpers/clsx';
import React from 'react';

import styles from './Skeleton.module.scss';

type SkeletonProps = {
  width: number | string;
  height: number;
  borderRadius?: number;
  className?: string;
};

const rem = parseInt(getComputedStyle(document.documentElement).fontSize.replace('px', ''));

const Skeleton: React.FC<SkeletonProps> = ({
  width: widthProp,
  height: heightProp,
  borderRadius: borderRadiusProp,
  className,
}) => {
  const width = typeof widthProp === 'number' ? widthProp / rem : widthProp;
  const height = heightProp / rem;
  const borderRadius = borderRadiusProp ? borderRadiusProp / rem : 0;

  return (
    <div
      data-testid='skeleton'
      className={clsx(styles.skeleton, className)}
      style={{
        width: typeof widthProp === 'number' ? `${width}rem` : widthProp,
        height: `${height}rem`,
        borderRadius: `${borderRadius}rem`,
      }}
    />
  );
};

export default Skeleton;

import React, { useEffect, PropsWithChildren, useRef } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  rootId?: string;
  zIndex?: number;
}

const Portal: React.FC<PropsWithChildren<PortalProps>> = ({
  children,
  rootId = 'layoutPortalRoot',
  zIndex,
}) => {
  const divElementRef = useRef<HTMLDivElement>(document.createElement('div'));

  useEffect(() => {
    const portalRootElement = document.getElementById(rootId) || document.body;
    const divElement = divElementRef.current;
    if (zIndex) {
      divElement.style.position = 'relative';
      divElement.style.zIndex = zIndex.toString();
    }

    if (!!portalRootElement) {
      portalRootElement.appendChild(divElement);

      return () => {
        portalRootElement.removeChild(divElement);
      };
    }
  }, [rootId, zIndex]);

  return createPortal(children, divElementRef.current);
};

export default Portal;

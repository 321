import { appendSearchParams } from '@alexis/helpers/searchParam';
import axios from 'axios';

import { BlogType } from '@components/Blogs/blog';

export async function getBlogs(locale: string, currentSiteCode: string): Promise<Array<BlogType>> {
  const searchParams = {
    locale,
    site_code: currentSiteCode.toUpperCase(),
  };

  const url = appendSearchParams(`${API_BASE_URL}/story/v1/homepages`, searchParams);

  const response = await axios.get<Array<BlogType>>(url, { withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.statusText);
}

import cuid from 'cuid';
import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { genzoTrackActionEvent } from '@wego/alexis/helpers/genzo';

import FlightSearchForm from '@components/flights/FlightSearchForm';
import HotelSearchForm from '@components/hotels/HotelSearchForm';

import { flightsRoute, hotelsRoute } from '@helpers/routeTranslation';

import TravelStopIcon from '@icons/travelstop_logo.svg';

import useLocaleParam from '@hooks/useLocaleParam';

import { getCurrentSiteState, getLocaleState, getTranslationsState } from '@redux/selectors';

import styles from './SearchForm.module.scss';

export type SearchFormProps = {
  pageType: string;
  availablePaymentMethods: HomepageFlightsPaymentMethod[];
  geolocationCoordinates?: GeolocationCoordinates;
  nearestCityByIP?: Place;
  wegoAnalyticsClientId?: string;
  wegoAnalyticsClientSessionId?: string;
  pageViewId: string;
  onPreferredPaymentMethodsChange: (paymentMethods: number[]) => void;
};

const SearchForm: React.FC<SearchFormProps> = ({
  pageType,
  availablePaymentMethods,
  geolocationCoordinates,
  nearestCityByIP,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
  pageViewId,
  onPreferredPaymentMethodsChange,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const localeParam = useLocaleParam();

  const { search, state } = useLocation();

  const currentSite = useSelector(getCurrentSiteState);
  const translations = useSelector(getTranslationsState);
  const locale = useSelector(getLocaleState);

  const searchWithoutExtraParams = useMemo<string>(() => {
    const searchParams = new URLSearchParams(search);

    searchParams.delete('open_calendar');

    const updatedSearchParams = searchParams.toString();

    return !!updatedSearchParams ? `?${updatedSearchParams}` : '';
  }, [search]);

  const trainsGenzoActionId = cuid();
  const wegoProGenzoActionId = cuid();

  const getTabIndex = (target: EventTarget) => {
    const tabs = tabsRef.current?.getElementsByClassName(styles.tab) ?? [];

    return Array.from(tabs).indexOf(target as Element);
  };

  const handleOnTrainsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        undefined,
        {
          id: pageViewId,
          category: 'products',
          object: 'tab',
          action: (getTabIndex(e.target) + 1).toString(),
          value: 'trains',
        },
        trainsGenzoActionId,
      );
    }
  };

  const handleOnWegoProClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        undefined,
        {
          id: pageViewId,
          category: 'products',
          object: 'tab',
          action: (getTabIndex(e.target) + 1).toString(),
          value: 'wegopro_businesstravel',
        },
        wegoProGenzoActionId,
      );
    }
  };

  return (
    <div className={clsx(styles.searchForm, styles.formMinHeight)}>
      <div ref={tabsRef} className={styles.tabs}>
        <div className={styles.productTabs}>
          <NavLink
            className={({ isActive }) =>
              clsx(styles.tab, (pageType === 'home' || isActive) && styles.active)
            }
            to={{
              pathname: flightsRoute(currentSite, localeParam),
              search: searchWithoutExtraParams,
            }}
            state={state}
          >
            <i
              className={clsx(
                styles.productIcons,
                /^(?:home|flights)$/i.test(pageType) ? styles.flightsGreen : styles.flightsWhite,
              )}
            ></i>
            {translations.flights}
          </NavLink>

          <NavLink
            className={({ isActive }) => clsx(styles.tab, isActive && styles.active)}
            to={{
              pathname: hotelsRoute(currentSite, localeParam),
              search: searchWithoutExtraParams,
            }}
            state={state}
          >
            <i
              className={clsx(
                styles.productIcons,
                pageType === 'hotels' ? styles.hotelsGreen : styles.hotelsWhite,
              )}
            ></i>
            {translations.hotels}
          </NavLink>

          {currentSite.countryCode === 'IN' ? (
            <a
              className={styles.tab}
              href={`https://trains.wego.com?wego_click_id=${trainsGenzoActionId}`}
              target='_blank'
              rel='noreferrer nofollow'
              onClick={handleOnTrainsClick}
            >
              <i className={clsx(styles.productIcons, styles.trainsWhite)}></i>
              {translations.wego_trains}
            </a>
          ) : null}
        </div>
        <a
          className={clsx(styles.tab, styles.wegoProTab)}
          href={`https://www.wegopro.com/?utm_source=wego&utm_medium=web&utm_campaign=homepage&wego_click_id=${wegoProGenzoActionId}`}
          target='_blank'
          rel='noreferrer'
          onClick={handleOnWegoProClick}
        >
          <TravelStopIcon />
          <strong>WegoPro</strong> {translations.business_travel}
          <div className={styles.badgeNew}>{locale === 'ar' ? translations.new : 'New'}</div>
        </a>
      </div>

      <div className={styles.tabContent}>
        {/^(?:home|flights)$/i.test(pageType) ? (
          <FlightSearchForm
            geolocationCoordinates={geolocationCoordinates}
            nearestCityByIP={nearestCityByIP}
            paymentMethods={availablePaymentMethods}
            pageViewId={pageViewId}
            wegoAnalyticsClientId={wegoAnalyticsClientId}
            wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
            setSelectedPaymentTypes={onPreferredPaymentMethodsChange}
          />
        ) : null}

        {pageType === 'hotels' ? (
          <HotelSearchForm
            geolocationCoordinates={geolocationCoordinates}
            localeParam={localeParam}
            nearestCityByIP={nearestCityByIP}
            wegoAnalyticsClientId={wegoAnalyticsClientId}
            wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SearchForm;

import React from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import styles from './Spinner.module.scss';

type ColorScheme = 'green' | 'orange';

export interface SpinnerProps {
  className?: string;
  invert?: boolean;
  colorScheme?: ColorScheme;
}

const Spinner: React.FC<SpinnerProps> = ({ className, invert, colorScheme }) => {
  return (
    <div
      className={clsx(
        styles.container,
        className,
        styles.loading,
        invert && styles.inverted,
        colorScheme && styles[colorScheme],
      )}
    ></div>
  );
};

export default Spinner;

const domain = (hostname: string): string => {
  const parentDomain = hostname.match(/[\w\-]+\.((com?\.?)?(\w+)?)$/i);

  if (!!parentDomain) {
    return parentDomain[0];
  }
  return hostname;
};

export function addCookie(
  name: string,
  value: string,
  expireByMilliseconds?: number,
  samesite?: 'lax' | 'strict' | 'none',
  isSecure?: boolean,
): void {
  let cookie = `${name}=${value}; domain=${domain(window.location.hostname)}; path=/;`;

  if (expireByMilliseconds !== undefined) {
    const expiryDate = new Date(expireByMilliseconds);

    cookie = `${cookie} expires=${expiryDate.toUTCString()};`;
  }

  if (samesite !== undefined) {
    cookie = `${cookie} samesite=${samesite};`;
  }

  if (isSecure) {
    cookie = `${cookie} secure`;
  }

  document.cookie = cookie;
}

export function removeCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}

export function getCookie(name: string): string | undefined {
  const cookie = document.cookie.split('; ').find((cookie) => cookie.startsWith(`${name}=`));

  if (!!cookie) {
    return cookie.split('=')[1];
  }
  return undefined;
}

export function isCookieExist(name: string): boolean {
  return document.cookie.split('; ').some((cookie) => cookie.startsWith(`${name}=`));
}

export const isWinterSeasonCampaignActive = (siteCode: string): boolean => {
  const currentTime = new Date().getTime();
  const campaignStartDate = new Date('2024-12-10');
  campaignStartDate.setHours(0, 0, 0, 0);
  const campaignEndDate = new Date('2025-03-01');
  campaignEndDate.setHours(0, 0, 0, 0);

  return (
    currentTime >= campaignStartDate.getTime() &&
    currentTime < campaignEndDate.getTime() &&
    !['AU', 'NZ'].includes(siteCode)
  );
};

export const isRamadanCampaignActive = (siteCode: string): boolean => {
  const currentTime = new Date().getTime();
  const campaignStartDate = new Date('2025-03-01');
  const campaignEndDate = new Date('2025-04-04');

  campaignStartDate.setHours(0, 0, 0, 0);
  campaignEndDate.setHours(23, 59, 59, 999);

  return (
    ['AE', 'SA', 'KW', 'QA', 'OM', 'BH', 'JO', 'LB', 'DZ', 'MA', 'EG', 'MY', 'ID', 'PK'].includes(
      siteCode,
    ) &&
    currentTime >= campaignStartDate.getTime() &&
    currentTime <= campaignEndDate.getTime()
  );
};

export const isSpringSeasonCampaignActive = (siteCode: string): boolean => {
  if (isRamadanCampaignActive(siteCode)) return false;

  const currentTime = new Date().getTime();
  const campaignStartDate = new Date('2025-03-01');
  const campaignEndDate = new Date('2025-06-05');

  campaignStartDate.setHours(0, 0, 0, 0);
  campaignEndDate.setHours(23, 59, 59, 999);

  return currentTime >= campaignStartDate.getTime() && currentTime <= campaignEndDate.getTime();
};

import { numberOfNights, leadTime } from '@alexis/helpers/date';
import { isEnvironmentProduction } from '@alexis/helpers/environment';
import * as webEngage from '@alexis/helpers/webEngage';

import type { User } from '@wego/alexis/types/helpers/user';

import { CLOUDINARY_DAILY_KEY } from '@constants/cloudinary';

import { filterFlightFareResultFares } from '@helpers/flight';
import { sumAllBrandedFaresPrice } from '@helpers/flightBooking';
import { convertGuestRoomsToSearchParam } from '@helpers/hotel';

import { BrandedFare } from '@wegoTypes/flightBooking';
import { LegViewModel } from '@wegoTypes/flights/legViewModel';
import type { FlightFareResultTrip } from '@wegoTypes/flights/metasearch/flightFareResultTrip';
import { FlightFareResultTripLeg } from '@wegoTypes/flights/metasearch/flightFareResultTripLeg';
import { Hotel } from '@wegoTypes/hotels/hotel';
import { HotelAmenity } from '@wegoTypes/hotels/metasearch/hotelEntity';
import { HotelSearchResultHotel } from '@wegoTypes/hotels/metasearch/hotelSearchResultHotel';
import { HotelSearchResultSearch } from '@wegoTypes/hotels/metasearch/hotelSearchResultSearch';
import type { MetasearchHotelRate } from '@wegoTypes/hotels/metasearch/metasearchHotelRate';
import { RateViewModel } from '@wegoTypes/hotels/rateViewModel';
import type { webEngageTrackGuestContactInfo } from '@wegoTypes/tracking/webEngage/hotel';

import { getReviewRatingType } from './review';

const hostname = window.location.hostname;
const licenseCode: string = isEnvironmentProduction(hostname) ? '~10a5cabbb' : 'd3a4ab3a';

export function getWebEngageAnonymousId(): Promise<string> {
  return new Promise((resolve) => {
    const webEngageScript = webEngage.loadWebEngageScript(licenseCode, false);

    const onReadyCallBack = () => {
      resolve(webEngageScript.user.getAnonymousId());
    };

    webEngageScript.onReady(onReadyCallBack);
  });
}

export const webEngageSetUserAttribute = (attribute: string, value: string): void => {
  webEngage.webEngageSetUserAttribute(licenseCode, false, attribute, value);
};

export const webEngageTrackLogin = (user: User): void => {
  webEngage.webEngageTrackLogin(licenseCode, false, user);
};

export const webEngageTrackLogout = (): void => {
  webEngage.webEngageTrackLogout(licenseCode, false);
};

export function webEngageTrackPurchase(): void {
  webEngage.webEngageTrackEvent(licenseCode, false, 'Purchase', {});
}

const isWeekend = (currentSiteCountryCode: string, fromDate: Date, toDate: Date): boolean => {
  const cloneFromDate = new Date(fromDate.getTime());
  const cloneToDate = new Date(toDate.getTime());

  while (cloneFromDate < cloneToDate) {
    const day = cloneFromDate.getDay();
    let isWeekend = false;

    if (/(?:AE|BH|DZ|EG|IQ|JO|KW|LB|LY|MA|OM|PS|QA|SA|SD|SY|TN|US)/i.test(currentSiteCountryCode)) {
      isWeekend = day === 5 || day === 6;
    } else if (currentSiteCountryCode.toUpperCase() === 'IR') {
      isWeekend = day === 4 || day === 5;
    } else {
      isWeekend = day === 6 || day === 0;
    }

    if (isWeekend) {
      return true; // return immediately if weekend found
    }

    cloneFromDate.setDate(cloneFromDate.getDate() + 1);
  }
  return false;
};

export const webEngageTrackFlightSearch = (
  currentSiteCountryCode: string,
  locale: string,
  adultCount: number,
  childCount: number,
  infantCount: number,
  cabinClass: string,
  legs: Array<FlightSearchResultSearchLeg>,
): void => {
  const routeInfoCityNames: Array<string> = [];
  const routeInfoDestCode: Array<string> = [];
  const routeInfoRoute: Array<string> = [];

  const legsInfo = legs.reduce(
    (accumulator: any, currentValue: FlightSearchResultSearchLeg, index: number) => {
      const legIndex = index + 1;

      accumulator[`leg${legIndex}_origin_code`] = currentValue.departureAirport
        ? currentValue.departureAirport.code
        : currentValue.departureCity.code;
      accumulator[`leg${legIndex}_origin_country`] = currentValue.departureCity.countryName;
      accumulator[`leg${legIndex}_depart_date`] = new Date(currentValue.outboundDate);
      accumulator[`leg${legIndex}_dest_code`] = currentValue.arrivalAirport
        ? currentValue.arrivalAirport.code
        : currentValue.arrivalCity.code;
      accumulator[`leg${legIndex}_dest_country`] = currentValue.arrivalCity.countryName;
      accumulator[`leg${legIndex}_info`] = {
        origin_city_name: currentValue.departureCity.name,
        origin_city_code: currentValue.departureCity.code,
        dest_city_name: currentValue.arrivalCity.name,
        dest_city_code: currentValue.arrivalCity.code,
      };

      if (accumulator.is_domestic) {
        accumulator.is_domestic =
          currentValue.departureCity.countryEnName === currentValue.arrivalCity.countryEnName;
      }

      if (/(?:fa|ar)/i.test(locale)) {
        routeInfoCityNames.splice(
          0,
          0,
          `${currentValue.arrivalCity.name} - ${currentValue.departureCity.name}`,
        );
        routeInfoDestCode.splice(
          0,
          0,
          `${currentValue.arrivalCity.code} - ${currentValue.departureCity.code}`,
        );
        routeInfoRoute.splice(
          0,
          0,
          `${currentValue.arrivalCity.code}-${currentValue.departureCity.code}-${currentValue.outboundDate}`,
        );
      } else {
        routeInfoCityNames.push(
          `${currentValue.departureCity.name} - ${currentValue.arrivalCity.name}`,
        );
        routeInfoDestCode.push(
          `${currentValue.departureCity.code} - ${currentValue.arrivalCity.code}`,
        );
        routeInfoRoute.push(
          `${currentValue.departureCity.code}-${currentValue.arrivalCity.code}-${currentValue.outboundDate}`,
        );
      }

      return accumulator;
    },
    { is_domestic: true },
  );

  const routeInfo = {
    city_name: routeInfoCityNames.join(', '),
    dest_code: routeInfoDestCode.join(', '),
    route: routeInfoRoute.join(':'),
  };

  const legsCount = legs.length;
  const outboundDate = new Date(legs[0].outboundDate);

  const data: any = {
    language: locale,
    adults: adultCount,
    children: childCount,
    infants: infantCount,
    passengers: adultCount + childCount + infantCount,
    class: cabinClass,
    legs: legsCount,
    is_roundtrip:
      legsCount > 1 && legs[0].departureCity.code === legs[legsCount - 1].arrivalCity.code
        ? true
        : false,
    leadtime: leadTime(outboundDate),
    ...legsInfo,
    route_info: routeInfo,
  };

  if (legsCount > 1) {
    const inboundDate = new Date(legs[legsCount - 1].outboundDate);
    const duration = numberOfNights(outboundDate.getTime(), inboundDate.getTime());

    data.duration = duration;
    data.is_weekend =
      duration > 5 ? true : isWeekend(currentSiteCountryCode, outboundDate, inboundDate);
  }

  webEngage.webEngageTrackEvent(licenseCode, false, 'flight_search', data);
};

export const webEngageTrackTripView = (
  currentSiteCountryCode: string,
  locale: string,
  adultCount: number,
  childCount: number,
  infantCount: number,
  cabinClass: string,
  legs: Array<FlightSearchResultSearchLeg>,
  flightFareResultTrip: FlightFareResultTrip,
  selectedPaymentMethodIds: number[],
  providerCodesSearchParam: string,
  providersSearchParam: string,
  isFlightViewRatesTracking?: boolean,
  filteredFaresForFlightViewRatesTracking?: Array<FlightFareResultTripFare>,
): void => {
  const routeInfoCityNames: Array<string> = [];
  const routeInfoDestCode: Array<string> = [];
  const routeInfoRoute: Array<string> = [];

  const legViewModels = flightFareResultTrip.legs;

  const flightNumber = flightFareResultTrip.id.split(':')[1];
  const flightNumbers = flightNumber.split('=');

  const legsInfo = legs.reduce(
    (accumulator: any, currentValue: FlightSearchResultSearchLeg, index: number) => {
      const legIndex = index + 1;

      const legViewModel = legViewModels[index];

      const airlineCode = legViewModel.airlineCodes[0];
      const airlineName = legViewModel.segments.find((segment) => {
        return segment.airlineCode === airlineCode;
      })?.airlineName;

      const legInfo: any = {
        origin_city_name: currentValue.departureCity.name,
        origin_city_code: currentValue.departureCity.code,
        dest_city_name: currentValue.arrivalCity.name,
        dest_city_code: currentValue.arrivalCity.code,
        airline: airlineName,
        air_code: airlineCode,
        flight_number: flightNumbers[index],
      };

      // if (!!flightNumbers) { // Facilitated Booking doesn't have this data. Have to get the backend to fix it
      //   legInfo.flight_number = flightNumbers[index];
      // }

      accumulator[`leg${legIndex}_info`] = legInfo;
      accumulator[`leg${legIndex}_origin_code`] = currentValue.departureAirport
        ? currentValue.departureAirport.code
        : currentValue.departureCity.code;
      accumulator[`leg${legIndex}_origin_country`] = currentValue.departureCity.countryName;
      accumulator[`leg${legIndex}_depart_date`] = new Date(currentValue.outboundDate);
      accumulator[`leg${legIndex}_dest_code`] = currentValue.arrivalAirport
        ? currentValue.arrivalAirport.code
        : currentValue.arrivalCity.code;
      accumulator[`leg${legIndex}_dest_country`] = currentValue.arrivalCity.countryName;

      if (accumulator.is_domestic) {
        accumulator.is_domestic =
          currentValue.departureCity.countryEnName === currentValue.arrivalCity.countryEnName;
      }

      if (/(?:fa|ar)/i.test(locale)) {
        routeInfoCityNames.splice(
          0,
          0,
          `${currentValue.arrivalCity.name} - ${currentValue.departureCity.name}`,
        );
        routeInfoDestCode.splice(
          0,
          0,
          `${currentValue.arrivalCity.code} - ${currentValue.departureCity.code}`,
        );
        routeInfoRoute.splice(
          0,
          0,
          `${currentValue.arrivalCity.code}-${currentValue.departureCity.code}-${currentValue.outboundDate}`,
        );
      } else {
        routeInfoCityNames.push(
          `${currentValue.departureCity.name} - ${currentValue.arrivalCity.name}`,
        );
        routeInfoDestCode.push(
          `${currentValue.departureCity.code} - ${currentValue.arrivalCity.code}`,
        );
        routeInfoRoute.push(
          `${currentValue.departureCity.code}-${currentValue.arrivalCity.code}-${currentValue.outboundDate}`,
        );
      }

      return accumulator;
    },
    { is_domestic: true },
  );

  const routeInfo: any = {
    city_name: routeInfoCityNames.join(', '),
    dest_code: routeInfoDestCode.join(', '),
    route: routeInfoRoute.join(':'),
    flight_number: flightNumber.replace('=', ':'),
  };

  // if (!!flightNumber) { // Facilitated Booking doesn't have this data. Have to get the backend to fix it
  //   routeInfo.flight_number = flightNumber.replace('=', ':');
  // }

  const alliance = legViewModels
    .map((legViewModel) => legViewModel.allianceCodes)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue)) // flatten into a single array
    .reduce((accumulator: Array<string>, currentValue: string) => {
      // distinct value in array
      if (!accumulator.includes(currentValue)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, [])
    .join(',');

  const airlineCode = legViewModels
    .map((leg) => leg.segments.map((segment) => segment.airlineCode).join('-'))
    .join(':');

  const airline = legViewModels
    .map((leg) => leg.segments.map((segment) => segment.airlineName).join('-'))
    .join(':');

  const legsCount = legs.length;
  const outboundDate = new Date(legs[0].outboundDate);

  const { filteredNormalFares, filteredRecommendedFares } = filterFlightFareResultFares(
    flightFareResultTrip,
    selectedPaymentMethodIds,
    providerCodesSearchParam,
    providersSearchParam,
  );

  const firstFare = [...filteredNormalFares, ...filteredRecommendedFares].sort(
    (a, b) => a.price.amountUsd - b.price.amountUsd,
  )[0];

  const data: any = {
    language: locale,
    adults: adultCount,
    children: childCount,
    infants: infantCount,
    passengers: adultCount + childCount + infantCount,
    class: cabinClass,
    legs: legsCount,
    is_roundtrip:
      legsCount > 1 && legs[0].departureCity.code === legs[legsCount - 1].arrivalCity.code
        ? true
        : false,
    leadtime: leadTime(outboundDate),
    ...legsInfo,
    route_info: routeInfo,
    alliance: alliance,
    airline_code: airlineCode,
    airline,
    viewed_rate: firstFare?.price.amount,
    currency: firstFare?.price.currencyCode,
  };

  if (legsCount > 1) {
    const inboundDate = new Date(legs[legsCount - 1].outboundDate);
    const duration = numberOfNights(outboundDate.getTime(), inboundDate.getTime());

    data.duration = duration;
    data.is_weekend =
      duration > 5 ? true : isWeekend(currentSiteCountryCode, outboundDate, inboundDate);
  }

  if (isFlightViewRatesTracking && filteredFaresForFlightViewRatesTracking) {
    data.trip_id = flightFareResultTrip.id;

    data.rates = filteredFaresForFlightViewRatesTracking.slice(0, 4).map((fare) => ({
      price_totalAmount: fare.price.totalAmount,
      price_currencyCode: fare.price.currencyCode,
      provider_code: fare.provider.code,
      provider_name: fare.provider.name,
      provider_type: fare.provider.type,
      provider_logo: `https://res.cloudinary.com/wego/image/upload/c_fit,w_200,h_70/${CLOUDINARY_DAILY_KEY}/providers/rectangular_logos/${fare.provider.code}.png`,
      promos: fare.promos,
    }));
  }

  const eventName = isFlightViewRatesTracking ? 'flight_view_rates' : 'flight_view';

  webEngage.webEngageTrackEvent(licenseCode, false, eventName, data);
};

export const webEngageTrackFareClick = (
  currentSiteCountryCode: string,
  locale: string,
  adultCount: number,
  childCount: number,
  infantCount: number,
  cabinClass: string,
  legs: Array<FlightSearchResultSearchLeg>,
  legViewModels: LegViewModel[],
  tripViewModelTripId: string,
  fares: Array<FlightFareResultTripFare> | Array<FlightSponsoredAdFare>,
  selectedFare: FlightFareResultTripFare | FlightSponsoredAdFare,
): void => {
  const routeInfoCityNames: Array<string> = [];
  const routeInfoDestCode: Array<string> = [];
  const routeInfoRoute: Array<string> = [];

  const flightNumber = tripViewModelTripId.split(':')[1];
  const flightNumbers = flightNumber.split('=');

  const legsInfo = legs.reduce(
    (accumulator: any, currentValue: FlightSearchResultSearchLeg, index: number) => {
      const legIndex = index + 1;

      const legViewModel = legViewModels[index];
      const firstAirline = legViewModel.airlines[0];

      const legInfo: any = {
        origin_city_name: currentValue.departureCity.name,
        origin_city_code: currentValue.departureCity.code,
        dest_city_name: currentValue.arrivalCity.name,
        dest_city_code: currentValue.arrivalCity.code,
        airline: firstAirline.name,
        air_code: firstAirline.code,
        flight_number: flightNumbers[index],
      };

      accumulator[`leg${legIndex}_info`] = legInfo;
      accumulator[`leg${legIndex}_origin_code`] = currentValue.departureAirport
        ? currentValue.departureAirport.code
        : currentValue.departureCity.code;
      accumulator[`leg${legIndex}_origin_country`] = currentValue.departureCity.countryName;
      accumulator[`leg${legIndex}_depart_date`] = new Date(currentValue.outboundDate);
      accumulator[`leg${legIndex}_dest_code`] = currentValue.arrivalAirport
        ? currentValue.arrivalAirport.code
        : currentValue.arrivalCity.code;
      accumulator[`leg${legIndex}_dest_country`] = currentValue.arrivalCity.countryName;

      if (accumulator.is_domestic) {
        accumulator.is_domestic =
          currentValue.departureCity.countryEnName === currentValue.arrivalCity.countryEnName;
      }

      if (/(?:fa|ar)/i.test(locale)) {
        routeInfoCityNames.splice(
          0,
          0,
          `${currentValue.arrivalCity.name} - ${currentValue.departureCity.name}`,
        );
        routeInfoDestCode.splice(
          0,
          0,
          `${currentValue.arrivalCity.code} - ${currentValue.departureCity.code}`,
        );
        routeInfoRoute.splice(
          0,
          0,
          `${currentValue.arrivalCity.code}-${currentValue.departureCity.code}-${currentValue.outboundDate}`,
        );
      } else {
        routeInfoCityNames.push(
          `${currentValue.departureCity.name} - ${currentValue.arrivalCity.name}`,
        );
        routeInfoDestCode.push(
          `${currentValue.departureCity.code} - ${currentValue.arrivalCity.code}`,
        );
        routeInfoRoute.push(
          `${currentValue.departureCity.code}-${currentValue.arrivalCity.code}-${currentValue.outboundDate}`,
        );
      }

      return accumulator;
    },
    { is_domestic: true },
  );

  const routeInfo: any = {
    city_name: routeInfoCityNames.join(', '),
    dest_code: routeInfoDestCode.join(', '),
    route: routeInfoRoute.join(':'),
    flight_number: flightNumber.replace('=', ':'),
  };

  const alliance = legViewModels
    .map((legViewModel) => legViewModel.leg.allianceCodes)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue)) // flatten into a single array
    .reduce((accumulator: Array<string>, currentValue: string) => {
      // distinct value in array
      if (!accumulator.includes(currentValue)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, [])
    .join(',');

  const airlineCode = legViewModels
    .map((leg) => leg.leg.segments.map((segment) => segment.airlineCode).join('-'))
    .join(':');

  const airline = legViewModels
    .map((leg) => leg.leg.segments.map((segment) => segment.airline?.name).join('-'))
    .join(':');

  const legsCount = legs.length;
  const outboundDate = new Date(legs[0].outboundDate);

  const bestRate = fares[0];

  const data: any = {
    language: locale,
    adults: adultCount,
    children: childCount,
    infants: infantCount,
    passengers: adultCount + childCount + infantCount,
    class: cabinClass,
    legs: legsCount,
    is_roundtrip:
      legsCount > 1 && legs[0].departureCity.code === legs[legsCount - 1].arrivalCity.code
        ? true
        : false,
    leadtime: leadTime(outboundDate),
    ...legsInfo,
    route_info: routeInfo,
    alliance: alliance,
    airline_code: airlineCode,
    airline,
    best_rate: bestRate.price.amount,
    currency: bestRate.price.currencyCode,
    partner: selectedFare.provider!.name,
    br: selectedFare.price.amount,
    gbv: selectedFare.price.totalAmount,
    revenue: selectedFare.ecpc,
    has_facilitated_option: fares.some((fare) => fare.provider!.instant),
    is_facilitated: selectedFare.provider!.instant,
  };

  if (legsCount > 1) {
    const inboundDate = new Date(legs[legsCount - 1].outboundDate);
    const duration = numberOfNights(outboundDate.getTime(), inboundDate.getTime());

    data.duration = duration;
    data.is_weekend =
      duration > 5 ? true : isWeekend(currentSiteCountryCode, outboundDate, inboundDate);
  }

  webEngage.webEngageTrackEvent(licenseCode, false, 'flight_click', data);
};

export const webEngageTrackFareClickOnTripDetailPage = (
  currentSiteCountryCode: string,
  locale: string,
  adultCount: number,
  childCount: number,
  infantCount: number,
  cabinClass: string,
  legs: Array<FlightSearchResultSearchLeg>,
  legViewModels: FlightFareResultTripLeg[],
  flightFareResultTripId: string,
  fares: Array<FlightFareResultTripFare> | Array<FlightSponsoredAdFare>,
  selectedFare: FlightFareResultTripFare | FlightSponsoredAdFare,
): void => {
  const routeInfoCityNames: Array<string> = [];
  const routeInfoDestCode: Array<string> = [];
  const routeInfoRoute: Array<string> = [];

  const flightNumber = flightFareResultTripId.split(':')[1];
  const flightNumbers = flightNumber.split('=');

  const legsInfo = legs.reduce(
    (accumulator: any, currentValue: FlightSearchResultSearchLeg, index: number) => {
      const legIndex = index + 1;

      const legViewModel = legViewModels[index];

      const airlineCode = legViewModel.airlineCodes[0];
      const airlineName = legViewModel.segments.find((segment) => {
        return segment.airlineCode === airlineCode;
      })?.airlineName;

      const legInfo: any = {
        origin_city_name: currentValue.departureCity.name,
        origin_city_code: currentValue.departureCity.code,
        dest_city_name: currentValue.arrivalCity.name,
        dest_city_code: currentValue.arrivalCity.code,
        airline: airlineName,
        air_code: airlineCode,
        flight_number: flightNumbers[index],
      };

      accumulator[`leg${legIndex}_info`] = legInfo;
      accumulator[`leg${legIndex}_origin_code`] = currentValue.departureAirport
        ? currentValue.departureAirport.code
        : currentValue.departureCity.code;
      accumulator[`leg${legIndex}_origin_country`] = currentValue.departureCity.countryName;
      accumulator[`leg${legIndex}_depart_date`] = new Date(currentValue.outboundDate);
      accumulator[`leg${legIndex}_dest_code`] = currentValue.arrivalAirport
        ? currentValue.arrivalAirport.code
        : currentValue.arrivalCity.code;
      accumulator[`leg${legIndex}_dest_country`] = currentValue.arrivalCity.countryName;

      if (accumulator.is_domestic) {
        accumulator.is_domestic =
          currentValue.departureCity.countryEnName === currentValue.arrivalCity.countryEnName;
      }

      if (/(?:fa|ar)/i.test(locale)) {
        routeInfoCityNames.splice(
          0,
          0,
          `${currentValue.arrivalCity.name} - ${currentValue.departureCity.name}`,
        );
        routeInfoDestCode.splice(
          0,
          0,
          `${currentValue.arrivalCity.code} - ${currentValue.departureCity.code}`,
        );
        routeInfoRoute.splice(
          0,
          0,
          `${currentValue.arrivalCity.code}-${currentValue.departureCity.code}-${currentValue.outboundDate}`,
        );
      } else {
        routeInfoCityNames.push(
          `${currentValue.departureCity.name} - ${currentValue.arrivalCity.name}`,
        );
        routeInfoDestCode.push(
          `${currentValue.departureCity.code} - ${currentValue.arrivalCity.code}`,
        );
        routeInfoRoute.push(
          `${currentValue.departureCity.code}-${currentValue.arrivalCity.code}-${currentValue.outboundDate}`,
        );
      }

      return accumulator;
    },
    { is_domestic: true },
  );

  const routeInfo: any = {
    city_name: routeInfoCityNames.join(', '),
    dest_code: routeInfoDestCode.join(', '),
    route: routeInfoRoute.join(':'),
    flight_number: flightNumber.replace('=', ':'),
  };

  const alliance = legViewModels
    .map((legViewModel) => legViewModel.allianceCodes)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue)) // flatten into a single array
    .reduce((accumulator: Array<string>, currentValue: string) => {
      // distinct value in array
      if (!accumulator.includes(currentValue)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, [])
    .join(',');

  const airlineCode = legViewModels
    .map((leg) => leg.segments.map((segment) => segment.airlineCode).join('-'))
    .join(':');

  const airline = legViewModels
    .map((leg) => leg.segments.map((segment) => segment.airlineName).join('-'))
    .join(':');

  const legsCount = legs.length;
  const outboundDate = new Date(legs[0].outboundDate);

  const bestRate = fares[0];

  const data: any = {
    language: locale,
    adults: adultCount,
    children: childCount,
    infants: infantCount,
    passengers: adultCount + childCount + infantCount,
    class: cabinClass,
    legs: legsCount,
    is_roundtrip:
      legsCount > 1 && legs[0].departureCity.code === legs[legsCount - 1].arrivalCity.code
        ? true
        : false,
    leadtime: leadTime(outboundDate),
    ...legsInfo,
    route_info: routeInfo,
    alliance: alliance,
    airline_code: airlineCode,
    airline,
    best_rate: bestRate.price.amount,
    currency: bestRate.price.currencyCode,
    partner: selectedFare.provider!.name,
    br: selectedFare.price.amount,
    gbv: selectedFare.price.totalAmount,
    revenue: selectedFare.ecpc,
    has_facilitated_option: fares.some((fare) => fare.provider!.instant),
    is_facilitated: selectedFare.provider!.instant,
  };

  if (legsCount > 1) {
    const inboundDate = new Date(legs[legsCount - 1].outboundDate);
    const duration = numberOfNights(outboundDate.getTime(), inboundDate.getTime());

    data.duration = duration;
    data.is_weekend =
      duration > 5 ? true : isWeekend(currentSiteCountryCode, outboundDate, inboundDate);
  }

  webEngage.webEngageTrackEvent(licenseCode, false, 'flight_click', data);
};

export const webEngageTrackFlightBookingConfirmation = (
  currentSiteCountryCode: string,
  locale: string,
  paxCount: { adultsCount: number; childrenCount: number; infantsCount: number },
  cabinClass: string,
  flightSearchLegDetails: Array<FlightSearchResultSearchLeg>,
  departureLeg: FlightFareResultTripLeg,
  returnLeg: FlightFareResultTripLeg | undefined,
  tripId: string,
  providers: Array<FlightFareResultTripFareProvider>,
  selectedFares: Array<BrandedFare>,
): void => {
  const routeInfoCityNames: Array<string> = [];
  const routeInfoDestCode: Array<string> = [];
  const routeInfoRoute: Array<string> = [];
  const { adultsCount, childrenCount, infantsCount } = paxCount;
  const legs: Array<FlightFareResultTripLeg> = [departureLeg];
  if (!!returnLeg) {
    legs.push(returnLeg);
  }

  const airlinesCodeToNameMap = legs.reduce(
    (accumulator: { [key: string]: string }, leg: FlightFareResultTripLeg) => {
      for (let segment of leg.segments) {
        if (!!segment.airlineName) {
          accumulator[segment.airlineCode.toLowerCase()] = segment.airlineName;
        }
      }
      return accumulator;
    },
    {},
  );

  const flightNumber = tripId.split(':')[1];
  const flightNumbers = flightNumber.split('=');

  const leg1Info = {
    origin_city_name: flightSearchLegDetails[0].departureCity.name,
    origin_city_code: flightSearchLegDetails[0].departureCity.code,
    dest_city_name: flightSearchLegDetails[0].arrivalCity.name,
    dest_city_code: flightSearchLegDetails[0].arrivalCity.code,
    airline: airlinesCodeToNameMap[departureLeg.airlineCodes[0].toLowerCase()],
    air_code: departureLeg.airlineCodes[0],
    flight_number: flightNumbers[0],
  };

  let legsInfo: any = {
    leg1_info: leg1Info,
    leg1_origin_code: departureLeg.departureAirportCode
      ? departureLeg.departureAirportCode
      : flightSearchLegDetails[0].departureCity.code,
    leg1_origin_country: flightSearchLegDetails[0].departureCity.countryName,
    leg1_depart_date: new Date(flightSearchLegDetails[0].outboundDate),
    leg1_dest_code: departureLeg.arrivalAirportCode
      ? departureLeg.arrivalAirportCode
      : flightSearchLegDetails[0].arrivalCity.code,
    leg1_dest_country: flightSearchLegDetails[0].arrivalCity.countryName,
  };

  if (/(?:fa|ar)/i.test(locale)) {
    routeInfoCityNames.splice(
      0,
      0,
      `${flightSearchLegDetails[0].arrivalCity.name} - ${flightSearchLegDetails[0].departureCity.name}`,
    );
    routeInfoDestCode.splice(
      0,
      0,
      `${flightSearchLegDetails[0].arrivalCity.code} - ${flightSearchLegDetails[0].departureCity.code}`,
    );
    routeInfoRoute.splice(
      0,
      0,
      `${flightSearchLegDetails[0].arrivalCity.code}-${flightSearchLegDetails[0].departureCity.code}-${flightSearchLegDetails[0].outboundDate}`,
    );
  } else {
    routeInfoCityNames.push(
      `${flightSearchLegDetails[0].departureCity.name} - ${flightSearchLegDetails[0].arrivalCity.name}`,
    );
    routeInfoDestCode.push(
      `${flightSearchLegDetails[0].departureCity.code} - ${flightSearchLegDetails[0].arrivalCity.code}`,
    );
    routeInfoRoute.push(
      `${flightSearchLegDetails[0].departureCity.code}-${flightSearchLegDetails[0].arrivalCity.code}-${flightSearchLegDetails[0].outboundDate}`,
    );
  }

  if (!!returnLeg && !!flightSearchLegDetails[1]) {
    const leg2Info = {
      origin_city_name: flightSearchLegDetails[1].departureCity.name,
      origin_city_code: flightSearchLegDetails[1].departureCity.code,
      dest_city_name: flightSearchLegDetails[1].arrivalCity.name,
      dest_city_code: flightSearchLegDetails[1].arrivalCity.code,
      airline: airlinesCodeToNameMap[returnLeg.airlineCodes[0].toLowerCase()],
      air_code: returnLeg.airlineCodes[0],
      flight_number: flightNumbers[1],
    };

    legsInfo = {
      ...legsInfo,
      leg2_info: leg2Info,
      leg2_origin_code: returnLeg.departureAirportCode
        ? returnLeg.departureAirportCode
        : flightSearchLegDetails[1].departureCity.code,
      leg2_origin_country: flightSearchLegDetails[1].departureCity.countryName,
      leg2_depart_date: new Date(flightSearchLegDetails[1].outboundDate),
      leg2_dest_code: returnLeg.arrivalAirportCode
        ? returnLeg.arrivalAirportCode
        : flightSearchLegDetails[1].arrivalCity.code,
      leg2_dest_country: flightSearchLegDetails[1].arrivalCity.countryName,
    };

    if (/(?:fa|ar)/i.test(locale)) {
      routeInfoCityNames.splice(
        0,
        0,
        `${flightSearchLegDetails[1].arrivalCity.name} - ${flightSearchLegDetails[1].departureCity.name}`,
      );
      routeInfoDestCode.splice(
        0,
        0,
        `${flightSearchLegDetails[1].arrivalCity.code} - ${flightSearchLegDetails[1].departureCity.code}`,
      );
      routeInfoRoute.splice(
        0,
        0,
        `${flightSearchLegDetails[1].arrivalCity.code}-${flightSearchLegDetails[1].departureCity.code}-${flightSearchLegDetails[1].outboundDate}`,
      );
    } else {
      routeInfoCityNames.push(
        `${flightSearchLegDetails[1].departureCity.name} - ${flightSearchLegDetails[1].arrivalCity.name}`,
      );
      routeInfoDestCode.push(
        `${flightSearchLegDetails[1].departureCity.code} - ${flightSearchLegDetails[1].arrivalCity.code}`,
      );
      routeInfoRoute.push(
        `${flightSearchLegDetails[1].departureCity.code}-${flightSearchLegDetails[1].arrivalCity.code}-${flightSearchLegDetails[1].outboundDate}`,
      );
    }
  }

  const routeInfo = {
    city_name: routeInfoCityNames.join(', '),
    dest_code: routeInfoDestCode.join(', '),
    route: routeInfoRoute.join(':'),
    flight_number: flightNumber.replace('=', ':'),
  };

  const alliance = legs
    .map((leg) => leg.allianceCodes)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue)) // flatten into a single array
    .reduce((accumulator: Array<string>, currentValue: string) => {
      // distinct value in array
      if (!accumulator.includes(currentValue)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    }, [])
    .join(',');

  const airlineCodes = legs
    .map((leg) => leg.airlineCodes)
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue)) // flatten into a single array
    .join(':');

  const airlineNames = airlineCodes
    .split(':')
    .map((airlineCode) => airlinesCodeToNameMap[airlineCode.toLowerCase()])
    .join(':');

  const legsCount = legs.length;
  const outboundDate = new Date(flightSearchLegDetails[0].outboundDate);

  const data = {
    language: locale,
    is_roundtrip: !!departureLeg && !!returnLeg,
    ...legsInfo,
    legs: legsCount,
    route_info: routeInfo,
    passengers: adultsCount + childrenCount + infantsCount,
    adults: adultsCount,
    children: childrenCount,
    infants: infantsCount,
    class: cabinClass,
    is_domestic:
      flightSearchLegDetails[0].departureCity.countryName ===
        flightSearchLegDetails[0].arrivalCity!.countryName &&
      (!!returnLeg
        ? flightSearchLegDetails[1].departureCity.countryName ===
          flightSearchLegDetails[1].arrivalCity!.countryName
        : true),
    leadtime: leadTime(outboundDate),
    alliance: alliance,
    airline: airlineNames,
    airline_code: airlineCodes,
    partner: 'wego.com',
    br:
      sumAllBrandedFaresPrice(selectedFares.map((selectedFare) => selectedFare.price)).amount /
      (adultsCount + childrenCount + infantsCount),
    gbv: sumAllBrandedFaresPrice(selectedFares.map((selectedFare) => selectedFare.price)).amount,
    currency: selectedFares[0].price.currencyCode,
    has_facilitated_option: providers.some((provider) => provider.instant),
    is_facilitated: false,
  };

  if (!!returnLeg && !!flightSearchLegDetails[1]) {
    const inboundDate = new Date(flightSearchLegDetails[1].outboundDate);
    const duration = numberOfNights(outboundDate.getTime(), inboundDate.getTime());

    data.duration = duration;
    data.is_weekend =
      duration > 5 ? true : isWeekend(currentSiteCountryCode, outboundDate, inboundDate);
  }

  webEngage.webEngageTrackEvent(licenseCode, false, 'flight_payment', data);
};

export const webEngageTrackHotelSearch = (
  currentSiteCountryCode: string,
  locale: string,
  userCity: Place,
  hotelSearchResultSearch: HotelSearchResultSearch,
  url: string,
): void => {
  const checkInDate = new Date(hotelSearchResultSearch.checkIn);
  const checkOutDate = new Date(hotelSearchResultSearch.checkOut);

  const data = {
    dest_code: hotelSearchResultSearch.region
      ? `q${hotelSearchResultSearch.region.id}`
      : hotelSearchResultSearch.city?.code,
    dest_country: hotelSearchResultSearch.country?.name,
    dest: hotelSearchResultSearch.region
      ? hotelSearchResultSearch.region?.name
      : hotelSearchResultSearch.city?.name,
    guests: hotelSearchResultSearch.guestsCount,
    in: checkInDate,
    is_staycation:
      hotelSearchResultSearch.searchType === 'CITY'
        ? userCity.cityCode!.toUpperCase() === hotelSearchResultSearch.cityCode!.toUpperCase()
        : false,
    is_weekend: isWeekend(currentSiteCountryCode, checkInDate, checkOutDate),
    language: locale,
    leadtime: leadTime(checkInDate),
    link: {
      arrangement: convertGuestRoomsToSearchParam(hotelSearchResultSearch.rooms),
      deeplink: url,
    },
    nights: numberOfNights(checkInDate.getTime(), checkOutDate.getTime()),
    out: checkOutDate,
    rooms: hotelSearchResultSearch.roomsCount,
  };

  webEngage.webEngageTrackEvent(licenseCode, false, 'hotel_search', data);
};

export const webEngageTrackHotelView = (
  currentSiteCountryCode: string,
  locale: string,
  userCity: Place,
  hotelSearchResultSearch: HotelSearchResultSearch,
  url: string,
  hotel: Hotel,
  rates: Array<MetasearchHotelRate>,
): void => {
  const checkInDate = new Date(hotelSearchResultSearch.checkIn);
  const checkOutDate = new Date(hotelSearchResultSearch.checkOut);

  const data: any = {
    // Same information as webEngageTrackHotelSearch
    dest_code: hotelSearchResultSearch.region
      ? `q${hotelSearchResultSearch.region.id}`
      : hotelSearchResultSearch.city!.code,
    dest_country: hotelSearchResultSearch.country.name,
    dest: hotelSearchResultSearch.region
      ? hotelSearchResultSearch.region.name
      : hotelSearchResultSearch.city!.name,
    guests: hotelSearchResultSearch.guestsCount,
    in: checkInDate,
    is_staycation:
      hotelSearchResultSearch.searchType === 'CITY'
        ? userCity.cityCode!.toUpperCase() === hotelSearchResultSearch.cityCode!.toUpperCase()
        : false,
    is_weekend: isWeekend(currentSiteCountryCode, checkInDate, checkOutDate),
    language: locale,
    leadtime: leadTime(checkInDate),
    link: {
      arrangement: convertGuestRoomsToSearchParam(hotelSearchResultSearch.rooms),
      deeplink: url,
    },
    nights: numberOfNights(checkInDate.getTime(), checkOutDate.getTime()),
    out: checkOutDate,
    rooms: hotelSearchResultSearch.roomsCount,
    // ==============================================

    accom_type: hotel.propertyType.id,
    hotel_id: hotel.id,
    hotel_name: hotel.name,
  };

  const amenityIds = hotel.amenities?.map((amenity) => amenity.id);
  if (!!amenityIds) {
    data.amenity = amenityIds;
  }

  if (!!hotel.brand) {
    data.brand = hotel.brand.name;
  }

  if (!!hotel.district) {
    data.district = hotel.district.id;
  }

  const rate = rates[0];
  if (!!rate) {
    data.viewed_rate = rate.price.taxInclusive
      ? rate.price.amount - rate.price.taxAmount
      : rate.price.amount;
  }

  const allReview = !!hotel.reviews ? hotel.reviews[0] : undefined;
  if (!!allReview) {
    data.rating = getReviewRatingType(allReview.score);
  }

  if (!!hotel.star) {
    data.star = hotel.star;
  }

  webEngage.webEngageTrackEvent(licenseCode, false, 'hotel_view', data);
};

export const webEngageTrackRateClick = (
  currentSiteCountryCode: string,
  locale: string,
  userCity: Place,
  hotelSearchResultSearch: HotelSearchResultSearch,
  url: string,
  hotel: Hotel,
  selectedRate: MetasearchHotelRate,
): void => {
  const checkInDate = new Date(hotelSearchResultSearch.checkIn);
  const checkOutDate = new Date(hotelSearchResultSearch.checkOut);

  const data: any = {
    // Same information as webEngageTrackHotelSearch
    dest_code: hotelSearchResultSearch.region
      ? `q${hotelSearchResultSearch.region.id}`
      : hotelSearchResultSearch.city!.code,
    dest_country: hotelSearchResultSearch.country.name,
    dest: hotelSearchResultSearch.region
      ? hotelSearchResultSearch.region.name
      : hotelSearchResultSearch.city!.name,
    guests: hotelSearchResultSearch.guestsCount,
    in: checkInDate,
    is_staycation:
      hotelSearchResultSearch.searchType === 'CITY'
        ? userCity.cityCode!.toUpperCase() === hotelSearchResultSearch.cityCode!.toUpperCase()
        : false,
    is_weekend: isWeekend(currentSiteCountryCode, checkInDate, checkOutDate),
    language: locale,
    leadtime: leadTime(checkInDate),
    link: {
      arrangement: convertGuestRoomsToSearchParam(hotelSearchResultSearch.rooms),
      deeplink: url,
    },
    nights: numberOfNights(checkInDate.getTime(), checkOutDate.getTime()),
    out: checkOutDate,
    rooms: hotelSearchResultSearch.roomsCount,
    // ==============================================

    accom_type: hotel.propertyType.id,
    hotel_id: hotel.id,
    hotel_name: hotel.name,
  };

  const amenityIds = hotel.amenities?.map((amenity) => amenity.id);
  if (!!amenityIds) {
    data.amenity = amenityIds;
  }

  if (!!hotel.brand) {
    data.brand = hotel.brand.name;
  }

  if (!!hotel.district) {
    data.district = hotel.district.id;
  }

  const allReview = !!hotel.reviews ? hotel.reviews[0] : undefined;
  if (!!allReview) {
    data.rating = getReviewRatingType(allReview.score);
  }

  if (!!hotel.star) {
    data.star = hotel.star;
  }

  data.viewed_rate = selectedRate.price.taxInclusive
    ? selectedRate.price.amount - selectedRate.price.taxAmount
    : selectedRate.price.amount;
  data.currency = selectedRate.price.currencyCode;
  data.br = selectedRate.price.taxInclusive
    ? selectedRate.price.amount - selectedRate.price.taxAmount
    : selectedRate.price.amount;
  data.gbv = selectedRate.price.taxInclusive
    ? selectedRate.price.totalAmount - selectedRate.price.totalTaxAmount
    : selectedRate.price.totalAmount;
  data.revenue = selectedRate.price.ecpc;
  data.partner = selectedRate.provider.name;

  webEngage.webEngageTrackEvent(licenseCode, false, 'hotel_click', data);
};

export function webEngageTrackRateSearchResult(
  currentSiteCountryCode: string,
  hotel: HotelSearchResultHotel,
  hotelAmenities: Array<HotelAmenity>,
  hotelSearchResultSearch: HotelSearchResultSearch,
  locale: string,
  selectedRate: RateViewModel,
  url: string,
  userCity: Place,
): void {
  const checkInDate = new Date(hotelSearchResultSearch.checkIn);
  const checkOutDate = new Date(hotelSearchResultSearch.checkOut);

  const data: any = {
    // Same information as webEngageTrackHotelSearch
    dest_code: hotelSearchResultSearch.region
      ? `q${hotelSearchResultSearch.region.id}`
      : hotelSearchResultSearch.city!.code,
    dest_country: hotelSearchResultSearch.country.name,
    dest: hotelSearchResultSearch.region
      ? hotelSearchResultSearch.region.name
      : hotelSearchResultSearch.city!.name,
    guests: hotelSearchResultSearch.guestsCount,
    in: checkInDate,
    is_staycation:
      hotelSearchResultSearch.searchType === 'CITY'
        ? userCity.cityCode!.toUpperCase() === hotelSearchResultSearch.cityCode!.toUpperCase()
        : false,
    is_weekend: isWeekend(currentSiteCountryCode, checkInDate, checkOutDate),
    language: locale,
    leadtime: leadTime(checkInDate),
    link: {
      arrangement: convertGuestRoomsToSearchParam(hotelSearchResultSearch.rooms),
      deeplink: url,
    },
    nights: numberOfNights(checkInDate.getTime(), checkOutDate.getTime()),
    out: checkOutDate,
    rooms: hotelSearchResultSearch.roomsCount,
    // ==============================================

    accom_type: hotel.propertyTypeId,
    hotel_id: hotel.id,
    hotel_name: hotel.name,
  };

  const amenityIds = hotelAmenities?.map((amenity) => amenity.id);
  if (!!amenityIds) {
    data.amenity = amenityIds;
  }

  if (!!hotel.brandId) {
    data.brand = hotel.brandId;
  }

  if (!!hotel.districtId) {
    data.district = hotel.districtId;
  }

  const allReview = !!hotel.reviews ? hotel.reviews[0] : undefined;
  if (!!allReview) {
    data.rating = getReviewRatingType(allReview.score);
  }

  if (!!hotel.star) {
    data.star = hotel.star;
  }

  data.viewed_rate = selectedRate.price.taxInclusive
    ? selectedRate.price.amount - selectedRate.price.taxAmount
    : selectedRate.price.amount;
  data.currency = selectedRate.price.currencyCode;
  data.br = selectedRate.price.taxInclusive
    ? selectedRate.price.amount - selectedRate.price.taxAmount
    : selectedRate.price.amount;
  data.gbv = selectedRate.price.taxInclusive
    ? selectedRate.price.totalAmount - selectedRate.price.totalTaxAmount
    : selectedRate.price.totalAmount;
  data.revenue = selectedRate.price.ecpc;
  data.partner = selectedRate.provider.name;

  webEngage.webEngageTrackEvent(licenseCode, false, 'hotel_click', data);
}

export function webEngageTrackGuestContactInfo(data: webEngageTrackGuestContactInfo): void {
  webEngage.webEngageTrackEvent(licenseCode, false, 'hotel_contact_info', data);
}

export function webEngageTrackHotelPayment(): void {
  webEngage.webEngageTrackEvent(licenseCode, false, 'hotel_payment', {});
}

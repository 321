declare global {
  interface Window {
    clarity(method: 'set', key: string, value: string): void; // create custom tag
  }
}

export async function loadClarityScript(): Promise<Window['clarity']> {
  return new Promise((resolve) => {
    const intervalClarity = setInterval(() => {
      if (!!window.clarity) {
        clearInterval(intervalClarity);
        resolve(window.clarity);
      } else {
        // Note: We load clarity script GTM now, so we don't need to load it manually
        // If we decide to remove GTM, we can use this function to load clarity script
        //   const elementId: string = 'clarity_script_tag';
        //   const document$: Document = document;
        //   const clarityScriptElement = document$.getElementById(elementId);
        //   if (!clarityScriptElement) {
        //     const scriptElement = document$.createElement('script');
        //     scriptElement.id = elementId;
        //     scriptElement.src = 'https://www.clarity.ms/tag/h9a0xura19?ref=gtm2';
        //     scriptElement.defer = true;
        //     document$.head.appendChild(scriptElement);
        //   }
      }
    }, 100);
  });
}

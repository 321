import { useSelector } from 'react-redux';

import { getPlace } from '@apis/places';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getLocaleState } from '@redux/selectors';

export const getPlaceKey = 'place';

export const useGetPlace = (code: string | null, options?: UseAxiosQueryOptions<Place>) => {
  const locale = useSelector(getLocaleState);

  return useAxiosQuery({
    queryKey: [getPlaceKey, { locale, code }],
    queryFn: () => {
      if (code === undefined || code === null) {
        throw new Error('Code is required');
      }

      return getPlace(locale, code);
    },
    staleTime: 1000 * 60, // 1 minute
    ...options,
  });
};

import { PageViewIdActionTypes } from '@redux/actions/types';

const initialState: PageViewIdState = {
  home: '',
};

export default function (
  state = initialState,
  action: { type: PageViewIdActionTypes; payload: string },
) {
  switch (action.type) {
    case PageViewIdActionTypes.ChangeHomePageViewId: {
      return { ...state, home: action.payload };
    }

    default:
      return state;
  }
}

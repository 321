import axios from 'axios';

import type { CurrencyFormat } from '@wegoTypes/currency';

export async function getCurrencyDisplayFormat(): Promise<CurrencyFormat> {
  const url = `${API_BASE_URL}/v3/metasearch/flights/cms/currencies/format`;

  const response = await axios.get(url);

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.statusText);
}

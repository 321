import { HotelMetaSearchActionTypes } from '@redux/actions/types';

import { HotelLandmark } from '@wegoTypes/hotels/hotelLandmarks';
import { HotelMetaSearch } from '@wegoTypes/hotels/hotelMetaSearch';
import { HotelMetaSearchFilter } from '@wegoTypes/hotels/hotelMetaSearchFilter';
import { HotelViewModel } from '@wegoTypes/hotels/hotelViewModel';
import { HotelSearchResultCity } from '@wegoTypes/hotels/metasearch/hotelEntity';
import { HotelSearchResultSearch } from '@wegoTypes/hotels/metasearch/hotelSearchResultSearch';
import { MetasearchHotelRateProvider } from '@wegoTypes/hotels/metasearch/metasearchHotelRateProvider';

const initialState: HotelMetaSearch = {
  search: undefined,
  rateProviders: [],
  cities: [],
  landmarks: undefined,

  amenitiesFilter: [],
  bookingOptionsFilter: [],
  brandsFilter: [],
  chainsFilter: [],
  districtsFilter: [],
  propertyTypesFilter: [],
  rateAmenitiesFilter: [],
  reviewerGroupsFilter: [],
  starsFilter: [],

  hotelViewModels: [],
  sponsoredAdHotelViewModels: [],
};

export default function (
  state: HotelMetaSearch = initialState,
  action: {
    type: HotelMetaSearchActionTypes;
    payload:
      | HotelSearchResultSearch
      | Array<MetasearchHotelRateProvider>
      | Array<HotelSearchResultCity>
      | Array<HotelMetaSearchFilter>
      | Array<HotelViewModel>
      | Array<HotelLandmark>;
  },
): HotelMetaSearch {
  switch (action.type) {
    case HotelMetaSearchActionTypes.ChangeHotelMetaSearch:
      return {
        ...state,
        search: action.payload as HotelSearchResultSearch,
      };
    case HotelMetaSearchActionTypes.ChangeHotelRateProviders:
      return {
        ...state,
        rateProviders: action.payload as Array<MetasearchHotelRateProvider>,
      };
    case HotelMetaSearchActionTypes.ChangeHotelCities:
      return {
        ...state,
        cities: action.payload as Array<HotelSearchResultCity>,
      };
    //#region Filters
    case HotelMetaSearchActionTypes.ChangeAmenitiesFilter:
      return {
        ...state,
        amenitiesFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangeBookingOptionsFilter:
      return {
        ...state,
        bookingOptionsFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangeBrandsFilter:
      return {
        ...state,
        brandsFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangeChainsFilter:
      return {
        ...state,
        chainsFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangeDistrictsFilter:
      return {
        ...state,
        districtsFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangePropertyTypesFilter:
      return {
        ...state,
        propertyTypesFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangeRateAmenitiesFilter:
      return {
        ...state,
        rateAmenitiesFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangeReviewerGroupsFilter:
      return {
        ...state,
        reviewerGroupsFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    case HotelMetaSearchActionTypes.ChangeStarsFilter:
      return {
        ...state,
        starsFilter: action.payload as Array<HotelMetaSearchFilter>,
      };
    //#endregion
    case HotelMetaSearchActionTypes.ChangeHotelViewModels:
      return {
        ...state,
        hotelViewModels: action.payload as Array<HotelViewModel>,
      };
    case HotelMetaSearchActionTypes.ChangeSponsoredAdHotelViewModels:
      return {
        ...state,
        sponsoredAdHotelViewModels: action.payload as Array<HotelViewModel>,
      };
    case HotelMetaSearchActionTypes.ChangeHotelLandmarks:
      return {
        ...state,
        landmarks: action.payload as Array<HotelLandmark>,
      };
    case HotelMetaSearchActionTypes.ResetHotelMetaSearch:
      return { ...initialState };
    default:
      return state;
  }
}

/**
 * Enum representing the possible booking status texts.
 */
export enum BookingStatusText {
  /**
   * The booking has been cancelled.
   */
  cancelled = 'CANCELLED',

  /**
   * The booking has been completed.
   */
  completed = 'COMPLETED',

  /**
   * The booking has been confirmed.
   */
  confirmed = 'CONFIRMED',

  /**
   * The booking has failed.
   */
  failed = 'FAILED',

  /**
   * The booking is on hold.
   */
  onHold = 'ON_HOLD',

  /**
   * The refund for the booking is being processed.
   */
  processingRefund = 'PROCESSING_REFUND',

  /**
   * The refund for the booking has been processed.
   */
  refundIsProcessed = 'REFUND_IS_PROCESSED',

  /**
   * The booking is under review.
   */
  reviewInProcess = 'REVIEW_IN_PROCESS',

  /**
   * The booking has been ticketed.
   */
  ticketed = 'TICKETED',

  /**
   * The booking has been validated.
   */
  validated = 'VALIDATED',
  /**
   * The booking is offline.
   */
  offline = 'OFFLINE',
}

import Checkbox from '@alexis/components/Checkbox';
import { isEqual } from 'lodash-es';
import { useSelector } from 'react-redux';

import Button from '@components/Button';

import useAvailablePaymentMethods from '@hooks/useAvailablePaymentMethods';
import usePreferredPaymentMethods from '@hooks/usePreferredPaymentMethods';

import { getTranslationsState } from '@redux/selectors';

import styles from './PreferredPaymentMethods.module.scss';

const PreferencesPreferredPaymentMethods = () => {
  const { availablePaymentMethods } = useAvailablePaymentMethods();

  const {
    preferredPaymentMethods,
    togglePreferredPaymentMethod,
    defaultPreferredPaymentMethods,
    savePreferredPaymentMethods,
  } = usePreferredPaymentMethods(availablePaymentMethods);

  const translations = useSelector(getTranslationsState);

  return (
    <div className={styles.cardContainer}>
      <h4 className={styles.cardTitle}>{translations.payment_methods}</h4>
      <p className={styles.cardDescription}>{translations.payment_methods_description}</p>
      <div className={styles.paymentMethodsContainer}>
        {availablePaymentMethods.map((paymentMethod) => {
          const isChecked = preferredPaymentMethods?.includes(paymentMethod.id) ?? false;

          return (
            <label
              key={paymentMethod.id}
              data-testid={paymentMethod.name}
              data-checked={isChecked}
              className={styles.paymentMethod}
              onClick={() => togglePreferredPaymentMethod(paymentMethod.id)}
            >
              <Checkbox className={styles.checkbox} isChecked={isChecked} />
              <span>{paymentMethod.name}</span>
              <img src={paymentMethod.imageUrl} />
            </label>
          );
        })}
      </div>
      <Button
        onClick={savePreferredPaymentMethods}
        disabled={isEqual(defaultPreferredPaymentMethods, preferredPaymentMethods)}
      >
        {translations.save}
      </Button>
    </div>
  );
};

export default PreferencesPreferredPaymentMethods;

import React, { useEffect, useState } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import styles from '../styles/components/Image.module.scss';

interface ImageProps {
  alt?: string;
  className?: string;
  isLazy?: boolean;
  noImagePlaceholderSrc?: string;
  onClick?: () => void;
  placeholderSrc?: string;
  src: string;
  style?: React.CSSProperties;
}

const Image: React.FC<ImageProps> = ({
  alt,
  className,
  isLazy,
  noImagePlaceholderSrc,
  onClick,
  placeholderSrc,
  src,
  style,
}) => {
  const [startLoading, setStartLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [srcHasError, setSrcHasError] = useState<boolean>(false);
  const [placeholderSrcHasError, setPlaceholderSrcHasError] = useState<boolean>(false);

  const [containerDivRef, isContainerDivVisible] = useIntersectionObserver<HTMLDivElement>();

  useEffect(() => {
    // Reset startLoading, isLoaded and srcHasError if image src is changed
    setStartLoading(false);
    setIsLoaded(false);
    setSrcHasError(false);
    setPlaceholderSrcHasError(false);
  }, [src]);

  useEffect(() => {
    if (!!isLazy) {
      if (isContainerDivVisible) {
        const startLoadingTimeout = setTimeout(() => {
          setStartLoading(true);
        }, 250);

        return () => {
          clearTimeout(startLoadingTimeout);
        };
      }
    } else {
      setStartLoading(true);
    }
  }, [isContainerDivVisible, src]);

  return (
    <div
      ref={containerDivRef}
      className={clsx(styles.container, className)}
      style={style}
      onClick={onClick}
    >
      {startLoading ? (
        <img
          alt={alt}
          loading={!!isLazy ? 'lazy' : undefined}
          onLoad={() => setIsLoaded(true)}
          onError={() => setSrcHasError(true)}
          src={!!noImagePlaceholderSrc && srcHasError ? noImagePlaceholderSrc : src}
        />
      ) : null}

      {isContainerDivVisible ? (
        <img
          alt={alt}
          className={clsx(!placeholderSrc && styles.greyBackground, isLoaded && styles.hide)}
          loading={!!isLazy ? 'lazy' : undefined}
          onError={() => setPlaceholderSrcHasError(true)}
          src={
            !!noImagePlaceholderSrc && placeholderSrcHasError
              ? noImagePlaceholderSrc
              : placeholderSrc
          }
        />
      ) : null}
    </div>
  );
};

export default Image;

import { clsx } from '@alexis/helpers/clsx';
import type { TripIdeasProps } from 'props/home/tripIdeasProps';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Image from '@components/Image';
import Price from '@components/Price';

import { flightsRoute } from '@helpers/routeTranslation';

import { useGetTravelThemeCities } from '@hooks/api/places/useGetTravelThemeCities';

import styles from './TripIdeas.module.scss';

const TripIdeas: React.FC<TripIdeasProps> = ({
  className,
  currency,
  currentSite,
  departureCityCode,
  exchangeRate,
  locale,
  localeParam,
  translations,
  travelThemes,
}): JSX.Element => {
  const searchParams = new URLSearchParams(window.location.search);
  const hasBowOnlyParamAsTrue = searchParams.get('bow_only') === 'true';

  const [selectedTravelThemeId, setSelectedTravelThemeId] = useState<number>(travelThemes[0].id);

  const { search } = useLocation();

  const { data: travelThemeCities } = useGetTravelThemeCities({
    travelThemeId: selectedTravelThemeId,
    searchParams: {
      departureCityCode,
      locale,
      currencyCode: currency.code,
      siteCode: currentSite.countryCode,
      ...(hasBowOnlyParamAsTrue && { bowOnly: hasBowOnlyParamAsTrue }),
    },
  });

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.travelThemes}>
        {travelThemes.map((travelTheme) => (
          <div
            key={travelTheme.id}
            className={clsx(
              styles.travelTheme,
              selectedTravelThemeId === travelTheme.id && styles.active,
            )}
            onClick={() => setSelectedTravelThemeId(travelTheme.id)}
          >
            {travelTheme.name}
          </div>
        ))}
      </div>

      <div className={styles.travelThemeCities}>
        {travelThemeCities?.map((travelThemeCity) => (
          <a
            className={styles.travelThemeCity}
            href={`${flightsRoute(
              currentSite,
              localeParam,
            )}/${departureCityCode.toLowerCase()}/${travelThemeCity.code.toLowerCase()}${search}`}
            key={travelThemeCity.code}
            target='_blank'
            rel='noreferrer'
          >
            <Image
              className={styles.image}
              isLazy={true}
              src={`https://zen.wego.com/cdn-cgi/image/height=296/destinations/cities/${travelThemeCity.code}.jpg`}
            />

            <div className={styles.content}>
              <div className={styles.cityName}>{travelThemeCity.name}</div>
              <div className={styles.countryName}>{travelThemeCity.countryName}</div>

              {!!travelThemeCity.cheapestPrice ? (
                <>
                  <div className={styles.roundTripLabel}>{translations.lbl_round_trip_from}</div>
                  <Price
                    className={styles.price}
                    currency={currency}
                    exchangeRate={exchangeRate}
                    isInline={true}
                    locale={locale}
                    price={{
                      amount: travelThemeCity.cheapestPrice.amount,
                      amountUsd: travelThemeCity.cheapestPrice.amountUsd,
                      currencyCode: travelThemeCity.cheapestPrice.currencyCode,
                    }}
                  />
                </>
              ) : null}

              {!travelThemeCity.cheapestPrice ? (
                <div className={styles.noFare}>{translations.get_fares}</div>
              ) : null}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default TripIdeas;

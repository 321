import { FlightSearchResultSearchParams } from '@wegoTypes/flights/flightSearchParams';

export const DEFAULT_NUMBER_OF_OPTIONS_SHOWED = 5;

export const filterParamsUniqueForDepartLeg = [
  FlightSearchResultSearchParams.DepartLegDepartureTimeBlocks,
  FlightSearchResultSearchParams.DepartLegArrivalTimeBlocks,
  FlightSearchResultSearchParams.DepartDuration,
];

export const filterParamsUniqueForReturnLeg = [
  FlightSearchResultSearchParams.ReturnLegDepartureTimeBlocks,
  FlightSearchResultSearchParams.ReturnLegArrivalTimeBlocks,
  FlightSearchResultSearchParams.ReturnDuration,
];

export const isBaggageFilterOn = false;

export const SELECTED_LEG_ID_PLS_PARAM = 'selectedLegIdPLS';

export const providerCodesForBaggageFilter = [
  'in.a.wego.com',
  'eg.a.wego.com',
  'qa.a.wego.com',
  'ca.a.wego.com',
  'tr.a.wego.com',
  'ae.a.wego.com',
  'om.a.wego.com',
  'jo.a.wego.com',
  'a.wego.com',
  'kw.a.wego.com',
  'sa.a.wego.com',
  'gb.a.wego.com',
  'my.a.wego.com',
  'pk.a.wego.com',
  'bh.a.wego.com',
  'us.a.wego.com',
  'hk.a.wego.com',
  'almosafer.ae',
  'global.almosafer.com',
  'almosafer.com-kw',
  'bh.almosafer.com',
  'almosafer.com-sa',
  'almosafer.com-en',
  'almosafer.com',
  'qa.almosafer.com',
  'eg.almosafer.com',
  'om.almosafer.com',
  'almusafeer.com',
  'ae.b.wego.com',
  'sa.b.wego.com',
  'b.wego.com',
  'qa.c.wego.com',
  'om.c.wego.com',
  'kw.c.wego.com',
  'eg.c.wego.com',
  'ae.c.wego.com',
  'c.wego.com',
  'd.wego.com',
  'ae.d.wego.com',
  'e.wego.com',
  'enuygun.tr',
  'sa.wingie.com-ar',
  'wingie.de',
  'wingie.tr',
  'jo.wingie.com',
  'wingie.es',
  'ru.wingie.com',
  'wingie.com-asiaen',
  'eg.wingie.com',
  'wingie.com',
  'wingie.ae',
  'enuygun.com',
  'wingie.com-menaen',
  'wingie.ae-ar',
  'wingie.sa',
  'wingie.co.uk',
  'bh.wingie.com',
  'wingie.qa',
  'kw.wingie.com',
  'f.wego.com',
  'flybooking.com',
  'g.wego.com',
  'de.gotogate.com',
  'gotogate.it',
  'gotogate.co.il',
  'gotogate.ng',
  'gotogate.id',
  'gotogate.in',
  'gotogate.cl',
  'tr.gotogate.com',
  'ca.gotogate.com',
  'gotogate.pt',
  'gotogate.eg',
  'gotogate.se',
  'au.gotogate.com',
  'ie.gotogate.com',
  'gotogate.my',
  'gotogate.mx',
  'gotogate.ph',
  'en.mytrip.com',
  'sa.gotogate.com',
  'gotogate.es',
  'gotogate.nl',
  'gotogate.bh',
  'gotogate.jo',
  'gotogate.jp',
  'gotogate.fr',
  'gotogate.kw',
  'gotogate.co.za',
  'gotogate.pl',
  'gotogate.sg',
  'gotogate.com.cn',
  'gotogate.kr',
  'gotogate.br',
  'gotogate.co.uk',
  'gotogate.vn',
  'gotogate.com',
  'gotogate.ae',
  'gotogate.de',
  'gotogate.om',
  'gotogate.qa',
  'gotogate.co.nz',
  'gotogate.tw',
  'gotogate.hk',
  'th.gotogate.com',
  'gotogate.ar',
  'gotogate.co',
  'ch.gotogate.com',
  'h.wego.com',
  'handoff.wego.com',
  'i.wego.com',
  'k.wego.com',
  'l.wego.com',
  'm.wego.com',
  'makemytrip.com-ae',
  'makemytrip.com-sa',
  'makemytrip.com-hiq',
  'makemytrip.com',
  'mytrip.com.fr',
  'trip.ru',
  'mytrip.com.ie',
  'mytrip.com.ar',
  'mytrip.com.mx',
  'mytrip.com.se',
  'trip.ae',
  'mytrip.com.it',
  'qa.mytrip.com',
  'om.mytrip.com',
  'mytrip.com.us',
  'mytrip.com',
  'mytrip.com.pt',
  'mytrip.com.ps',
  'jo.mytrip.com',
  'mytrip.com.nl',
  'mytrip.com.ca',
  'mytrip.com.au',
  'mytrip.com.de',
  'mytrip.com.es',
  'n.wego.com',
  'o.wego.com',
  'offer.wego.com',
  'p.wego.com',
  'q.wego.com',
  'r.wego.com',
  's.wego.com',
  't.wego.com',
  'u.wego.com',
  'v.wego.com',
  'w.wego.com',
  'x.wego.com',
  'y.wego.com',
  'z.wego.com',
  'za.wego.com',
  'zb.wego.com',
  'zc.wego.com',
  'zd.wego.com',
  'ze.wego.com',
  'zf.wego.com',
  'zg.wego.com',
  'zh.wego.com',
  'zj.wego.com',
];

import type { Translations } from '@wegoTypes/translations';

/**
 * @description Get review rating type based on score.
 * @param score
 * @returns FAIR 0 - 67
 * @returns GOOD 68 - 74
 * @returns VERYGOOD 75 - 79
 * @returns SUPERB 80 - 85
 * @returns EXCELLENT 86 - 100
 */
export function getReviewRatingType(
  score: number,
): 'EXCELLENT' | 'SUPERB' | 'VERYGOOD' | 'GOOD' | 'FAIR' {
  if (score > 85) {
    return 'EXCELLENT';
  }

  if (score > 79) {
    return 'SUPERB';
  }

  if (score > 74) {
    return 'VERYGOOD';
  }

  if (score > 67) {
    return 'GOOD';
  }

  return 'FAIR';
}

/**
 * @description Get review rating type based on score.
 * @param score
 * @returns POOR < 50
 * @returns FAIR 50 - 60
 * @return PLEASANT 60 - 70
 * @returns GOOD 70 - 80
 * @returns VERYGOOD 80 - 90
 * @returns EXCELLENT > 90
 */
export function getNewReviewRatingType(
  score: number,
): 'EXCELLENT' | 'VERYGOOD' | 'GOOD' | 'PLEASANT' | 'FAIR' | 'POOR' {
  if (score > 90) {
    return 'EXCELLENT';
  }

  if (score > 80) {
    return 'VERYGOOD';
  }

  if (score > 70) {
    return 'GOOD';
  }

  if (score > 60) {
    return 'PLEASANT';
  }

  if (score > 50) {
    return 'FAIR';
  }

  return 'POOR';
}

export const getReviewRatingTypeColorClassName = (
  score: number,
  styles: { readonly [key: string]: string },
) => {
  switch (getReviewRatingType(score)) {
    case 'EXCELLENT':
    case 'SUPERB':
    case 'VERYGOOD':
      return styles.excellent;
    case 'GOOD':
      return styles.fair;
    case 'FAIR':
      return styles.poor;
  }
};

export const getNewReviewRatingTypeColorClassName = (
  score: number,
  styles: { readonly [key: string]: string },
) => {
  switch (getNewReviewRatingType(score)) {
    case 'EXCELLENT':
    case 'VERYGOOD':
    case 'GOOD':
      return styles.excellent;
    case 'PLEASANT':
    case 'FAIR':
      return styles.fair;
    case 'POOR':
      return styles.poor;
  }
};

export function getReviewScoreDisplay(score: number): string {
  const decimalScore = score / 10;

  if (decimalScore < 10) {
    return decimalScore.toFixed(1);
  }
  return decimalScore.toFixed(0);
}

export function getReviewerGroupFilterTranslation(
  translations: Translations,
  reviewerGroupName: string,
): string {
  switch (reviewerGroupName) {
    case 'ALL':
      return translations.all_travellers as string;
    case 'BUSINESS':
      return translations.business_travellers as string;
    case 'COUPLE':
      return translations.couple_travellers as string;
    case 'FAMILY':
      return translations.family_travellers as string;
    case 'SOLO':
      return translations.solo_travellers as string;
    default:
      return '';
  }
}

export function getReviewRatingTypeDisplay(score: number, translations: Translations): string {
  switch (getReviewRatingType(score)) {
    case 'EXCELLENT':
      return translations.review_score_excellent as string;
    case 'SUPERB':
      return translations.review_score_superb as string;
    case 'VERYGOOD':
      return translations.review_score_verygood as string;
    case 'GOOD':
      return translations.review_score_good as string;
    case 'FAIR':
      return translations.review_score_fair as string;
  }
}

export function getNewReviewRatingTypeDisplay(score: number, translations: Translations) {
  switch (getNewReviewRatingType(score)) {
    case 'EXCELLENT':
      return translations.review_score_excellent as string;
    case 'VERYGOOD':
      return translations.review_score_verygood as string;
    case 'GOOD':
      return translations.review_score_good as string;
    case 'PLEASANT':
      return translations.review_score_pleasant as string;
    case 'FAIR':
      return translations.review_score_fair as string;
    case 'POOR':
      return translations.review_score_poor as string;
  }
}

import { useSelector } from 'react-redux';

import { getFlightComparisonProviders } from '@apis/compare';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

const flightComparisonProvidersKey = 'flight-comparison-providers';

type GetFlightComparisonProvidersParams = {
  departureAirportCode: string | undefined;
  departureCityCode: string;
  departureCountryCode: string;
  departureDate: string;
  arrivalAirportCode: string | undefined;
  arrivalCityCode: string;
  arrivalCountryCode: string;
  returnDate: string | undefined;
  placementType: 'home' | 'search' | 'leaderboard';
};

export const useGetFlightComparisonProviders = (
  getFlightComparisonProvidersParams: GetFlightComparisonProvidersParams,
  options?: UseAxiosQueryOptions<Array<FlightComparisonProvider>>,
) => {
  const currentSiteCode = useSelector(getCurrentSiteState);
  const locale = useSelector(getLocaleState);

  const {
    departureAirportCode,
    departureCityCode,
    departureCountryCode,
    departureDate,
    arrivalAirportCode,
    arrivalCityCode,
    arrivalCountryCode,
    returnDate,
    placementType,
  } = getFlightComparisonProvidersParams;

  return useAxiosQuery({
    queryKey: [
      flightComparisonProvidersKey,
      {
        locale,
        code: currentSiteCode.countryCode,
        ...getFlightComparisonProvidersParams,
      },
    ],
    queryFn: () =>
      getFlightComparisonProviders(
        currentSiteCode.countryCode,
        locale,
        departureAirportCode,
        departureCityCode,
        departureCountryCode,
        departureDate,
        arrivalAirportCode,
        arrivalCityCode,
        arrivalCountryCode,
        returnDate,
        placementType,
      ),
    ...options,
  });
};

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import {
  APP_LOCALE_TO_SPRINKLR_CHAT_LOCALE_MAP,
  SPRINKLR_CHAT_SUPPORTED_LOCALE,
} from '@constants/sprinklrChat';

import { getUserState, getLocaleState } from '@redux/selectors';

import { SprinklrChatSupportedLocale } from '@wegoTypes/sprinklrChat';

declare global {
  interface Window {
    sprChat: (...args: any[]) => void;
    sprChatSettings: {
      appId: string;
      skin: string;
      locale: string;
      theme: {
        styles: {
          trigger: {
            align: string;
          };
        };
      };
      user: {
        id: string;
        firstName: string;
        lastName: string;
        profileImageUrl: string;
        phoneNo: string;
        email: string;
        hash: string;
      };
    };
  }
}

type SprinklrChatProviderValue = {
  sprinklrChat: (action: string, params?: any) => void;
};

const SprinklrChatContext = createContext<SprinklrChatProviderValue>({
  sprinklrChat: () => {},
});

type SprinklrChatProviderProps = {
  children: React.ReactNode;
};

export const SprinklrChatProvider: React.FC<SprinklrChatProviderProps> = ({ children }) => {
  const [sprinklrChat, setSprinklrChat] = useState<any>(undefined);
  const user = useSelector(getUserState);
  const locale = useSelector(getLocaleState);

  const initOptions = useMemo(() => {
    // Sprinklr configuration currently only supports Arabic (ar) and English (en) locale.
    // Setting other locales will fall back to English (en).
    const sprinklrChatLocale =
      APP_LOCALE_TO_SPRINKLR_CHAT_LOCALE_MAP[
        locale as keyof typeof APP_LOCALE_TO_SPRINKLR_CHAT_LOCALE_MAP
      ] ??
      ((SPRINKLR_CHAT_SUPPORTED_LOCALE as unknown as string[]).includes(locale)
        ? (locale as SprinklrChatSupportedLocale)
        : 'en');

    return {
      appId: '6744787c835a2949b24f0979_app_1111004429',
      skin: 'MODERN',
      locale: sprinklrChatLocale,
      theme: {
        styles: {
          trigger: {
            align: sprinklrChatLocale === 'ar' ? 'left' : 'right',
          },
        },
      },
      user: {
        id: user?.idHash ?? 'guest',
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        profileImageUrl: user?.photoUrl ?? '',
        phoneNo: `${user?.accountPhoneCountryCode ?? ''}${user?.accountPhoneNumber ?? ''}`,
        email: user?.email ?? '',
        hash: user?.sprinklrUserHash ?? '',
      },
    };
  }, [locale, user]);

  useEffect(() => {
    window.sprChatSettings = initOptions;

    const initializeSprinklrChat = () => {
      const sprinklrChat = window.sprChat;
      if (!sprinklrChat) return;
      setSprinklrChat(() => sprinklrChat);
    };

    if (document.readyState === 'complete') {
      initializeSprinklrChat();
    } else {
      window.addEventListener('load', initializeSprinklrChat);
      return () => window.removeEventListener('load', initializeSprinklrChat);
    }
  }, [initOptions]);

  useEffect(() => {
    if (sprinklrChat && user) {
      window.sprChat('updateUserSettings', {
        user: initOptions.user,
      });
    }
  }, [sprinklrChat, user]);

  useEffect(() => {
    if (sprinklrChat && locale) {
      window.sprChat('updateLocale', locale);
    }
  }, [sprinklrChat, locale]);

  const providerValue: SprinklrChatProviderValue = useMemo(
    () => ({
      sprinklrChat: (action: string, params?: any) => {
        if (sprinklrChat) {
          sprinklrChat(action, params);
        }
      },
    }),
    [sprinklrChat],
  );

  return (
    <SprinklrChatContext.Provider value={providerValue}>
      <Helmet>
        <script
          defer
          id='sprinklrChatScript'
          src='https://prod15-live-chat.sprinklr.com/api/livechat/handshake/widget/6744787c835a2949b24f0979_app_1111004429'
        />
      </Helmet>
      {children}
    </SprinklrChatContext.Provider>
  );
};

export const useSprinklrChat = () => {
  const ctx = useContext(SprinklrChatContext);
  if (!ctx) {
    throw new Error('useSprinklrChat was used outside of SprinklrChatProvider');
  }
  return ctx;
};

import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import { isSiteCodePartOfMENA } from '@alexis/helpers/site';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateText } from '@wego/alexis/helpers/translation';
import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { getPageNameByPathName } from '@helpers/genzo';
import {
  airlinesRoute,
  airportsRoute,
  hotelsRoute,
  schedulesRoute,
} from '@helpers/routeTranslation';

import useLocaleParam from '@hooks/useLocaleParam';

import type { Translations } from '@wegoTypes/translations';

import { getPageViewIdState } from '../redux/selectors';
import styles from './Footer.module.scss';

interface FooterProps {
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
  currentSite: CurrentSite;
  locale: string;
  translations: Translations;
  className?: string;
}

const Footer: React.FC<FooterProps> = ({
  className,
  currentSite,
  locale,
  translations,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
}) => {
  const { pathname } = useLocation();
  const pageViewIdState = useSelector(getPageViewIdState);
  const localeParam = useLocaleParam();

  const playStoreUrl = useMemo<string>(() => {
    if (/^MY|ID$/i.test(currentSite.countryCode)) {
      return 'http://app.mi.com/detail/82456';
    }

    if (currentSite.countryCode === 'CN') {
      return 'https://id.9apps.com/android-apps/Wego-Flights-and-Hotels';
    }

    return 'https://wegotravel.onelink.me/pGV9/6e3f482e';
  }, [currentSite]);

  const facebookId = useMemo<string>(() => {
    if (isSiteCodePartOfMENA(currentSite.countryCode)) {
      return 'wego.arabia';
    }

    if (currentSite.countryCode === 'IN') {
      return 'wego.india';
    }

    if (currentSite.countryCode === 'ID') {
      return 'wego.indonesia';
    }

    if (currentSite.countryCode === 'MY') {
      return 'wego.commalaysia';
    }

    if (currentSite.countryCode === 'TW') {
      return 'wego.chinese';
    }

    return 'wego';
  }, [currentSite]);

  const twitterId = useMemo<string>(() => {
    if (isSiteCodePartOfMENA(currentSite.countryCode)) {
      return 'wegoarabia';
    }

    if (currentSite.countryCode === 'IN') {
      return 'wegoindia';
    }

    if (currentSite.countryCode === 'ID') {
      return 'wegoid';
    }

    return 'wego';
  }, [currentSite]);

  const instagramId = useMemo<string>(() => {
    if (isSiteCodePartOfMENA(currentSite.countryCode)) {
      return 'wegoarabia';
    }

    if (currentSite.countryCode === 'IN') {
      return 'wegoindia';
    }

    if (currentSite.countryCode === 'ID') {
      return 'wegoindonesia';
    }

    if (currentSite.countryCode === 'MY') {
      return 'wegomalaysia';
    }

    if (currentSite.countryCode === 'TW') {
      return 'wegotaiwan';
    }

    return 'wego_travel';
  }, [currentSite]);

  const handleFooterLinksClicked = (object: string) => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      const pageName = getPageNameByPathName(locale, pathname);
      const eventData = {
        id: pageViewIdState.home,
        page: pageName,
        category: 'Footer',
        object,
        action: 'clicked',
        value: '',
      };
      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        undefined,
        eventData,
      );
    }
  };

  const handleOnWegoProClick = () => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      const eventData = {
        id: pageViewIdState.home,
        category: 'footer',
        object: 'wegopro_businesstravel',
        action: 'clicked',
        value: '',
      };
      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        undefined,
        eventData,
      );
    }
  };

  return (
    <footer className={clsx(styles.container, className)}>
      <div className={styles.links}>
        <div className={styles.category}>
          <div className={styles.categoryHeader}>{translations.company}</div>
          <nav>
            <a
              href={`https://company.wego.com${locale === 'ar' ? '/ar' : ''}`}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('about_wego')}
            >
              {translations.about_wego}
            </a>
            <a
              href='https://company.wego.com/newsroom'
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('press')}
            >
              {translations.press}
            </a>
            <a
              href={`https://company.wego.com/careers${locale === 'ar' ? '/ar' : ''}`}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('careers')}
            >
              {translations.careers}
            </a>
            <a
              href={`https://company.wego.com/support${
                currentSite.countryCode === 'ID' ? '-id' : locale === 'ar' ? '/ar' : ''
              }`}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('contact_us')}
            >
              {translations.contact_us}
            </a>
          </nav>
        </div>
        <div className={styles.category}>
          <div className={styles.categoryHeader}>{translations.learn_more}</div>
          <nav>
            <a
              href='https://company.wego.com/book-on-wego'
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('book_on_wego')}
            >
              {translations.book_on_wego}
            </a>
            <a
              href='https://www.wegopro.com/?utm_source=wego&utm_medium=web&utm_campaign=bottom-nav-bar'
              target='_blank'
              rel='noreferrer'
              className={styles.wegoPro}
              onClick={() => handleOnWegoProClick()}
            >
              <strong>WegoPro</strong> {translations.business_travel}
              <div className={styles.badgeNew}>{locale === 'ar' ? translations.new : 'New'}</div>
            </a>
            <a
              href='https://company.wego.com/affiliate-program'
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('affiliates')}
            >
              {translations.affiliates}
            </a>
            <a
              href='https://www.wego.com/advertise'
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('advertise')}
            >
              {translations.advertise}
            </a>
            <a
              href={`https://company.wego.com/hoteliers${
                /^id|zh\-(?:cn|tw)$/i.test(locale) ? `-${locale}` : ''
              }`}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('hoteliers')}
            >
              {translations.hoteliers}
            </a>
            <a
              href='https://company.wego.com/data-privacy-policy'
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('data_privacy_policy')}
            >
              {translations.lbl_data_privacy_policy}
            </a>
            <a
              href='https://www.wego.com/terms'
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('terms')}
            >
              {translations.terms}
            </a>
            <div id='cookie-script-footer-link'></div>
          </nav>
        </div>
        <div className={styles.category}>
          <div className={styles.categoryHeader}>{translations.explore}</div>
          <nav>
            <a
              href={airportsRoute(currentSite, localeParam)}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('airport_directory')}
            >
              {translations.airport_directory}
            </a>
            <a
              href={airlinesRoute(currentSite, localeParam)}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('airlines_directory')}
            >
              {translations.airlines_directory}
            </a>
            <a
              href={schedulesRoute(currentSite, localeParam)}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('flight_schedules')}
            >
              {translations.flight_schedules}
            </a>
            <a
              href={`${hotelsRoute(currentSite, localeParam)}/chains`}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('hotel_chains')}
            >
              {translations.hotel_chains}
            </a>
          </nav>
        </div>
        <div className={styles.category}>
          <div className={styles.categoryHeader}>{translations.lbl_download}</div>
          <nav>
            <a
              href='https://wegotravel.onelink.me/pGV9/6e3f482e'
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('wego_appios')}
            >
              {translations.wego_app_ios}
            </a>
            <a
              href={playStoreUrl}
              target='_blank'
              rel='noreferrer'
              onClick={() => handleFooterLinksClicked('wego_appandroid')}
            >
              {translations.wego_app_android}
            </a>
          </nav>
        </div>
      </div>

      <div className={styles.brandAndSocialMediaLinks}>
        <span>
          Copyright{' '}
          {translateText(translations.all_rights_footer, locale, new Date().getFullYear())} <br />
          Site Operator: Wego Pte. Ltd. Travel License No. 03513
        </span>
        <div className={styles.socialMediaLinks}>
          <a
            aria-label='Wego Facebook'
            className={clsx(styles.socialMedia, styles.facebook)}
            href={`https://www.facebook.com/${facebookId}`}
            target='_blank'
            rel='noreferrer'
          ></a>
          <a
            aria-label='Wego Twitter'
            className={clsx(styles.socialMedia, styles.twitter)}
            href={`https://twitter.com/${twitterId}`}
            target='_blank'
            rel='noreferrer'
          ></a>
          <a
            aria-label='Wego Instagram'
            className={clsx(styles.socialMedia, styles.instagram)}
            href={`https://www.instagram.com/${instagramId}`}
            target='_blank'
            rel='noreferrer'
          ></a>
          <a
            aria-label='Wego LinkedIn'
            className={clsx(styles.socialMedia, styles.linkedin)}
            href={`https://www.linkedin.com/company/wego-com`}
            target='_blank'
            rel='noreferrer'
          ></a>
          {isSiteCodePartOfMENA(currentSite.countryCode) ? (
            <a
              aria-label='Wego Youtube'
              className={clsx(styles.socialMedia, styles.youtube)}
              href={`https://www.youtube.com/user/wegoarabia`}
              target='_blank'
              rel='noreferrer'
            ></a>
          ) : null}
          {isSiteCodePartOfMENA(currentSite.countryCode) ? (
            <a
              aria-label='Wego Snapchat'
              className={clsx(styles.socialMedia, styles.snapchat)}
              href={`https://www.snapchat.com/add/wegoarabia`}
              target='_blank'
              rel='noreferrer'
            ></a>
          ) : null}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { TravelThemesSearchParams, getTravelThemes } from '@apis/places';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

type GetTravelThemesParams = {
  searchParams: TravelThemesSearchParams;
};

const getTravelThemesKey = 'travel-themes';

export const useGetTravelThemes = <TResult = PlacesTravelTheme[]>(
  params: GetTravelThemesParams,
  options?: UseAxiosQueryOptions<PlacesTravelTheme[], TResult>,
) => {
  const apiBaseUrl = API_BASE_URL;

  return useAxiosQuery({
    queryKey: [
      getTravelThemesKey,
      {
        ...params,
      },
    ],
    queryFn: () => getTravelThemes(apiBaseUrl, params.searchParams),
    ...options,
  });
};

import useFlightSearchPageParams from '@pages/FlightSearchResult/hooks/useFlightSearchPageParams';
import { createContext, useContext, useMemo, useState } from 'react';
import React from 'react';

import {
  AIRLINE_BACKFILL_CORE_CONFIG_KEY,
  CACHE_FLIGHT_SEARCH_CORE_CONFIG_KEY,
  FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY,
  JWP_AD_DWEB_CORE_CONFIG_KEY,
  LAZYLOADING_ADS_WEB_CORE_CONFIG_KEY,
  NATIVE_AD_CORE_CONFIG_KEY,
} from '@constants/flight';

import { GlobalContext } from '@context/GlobalContext';

import { useClarityCustomTag } from '@hooks/useClarity';

interface FlightContextValue {
  flightSearchFormHeight: number;
  setFlightSearchFormHeight: React.Dispatch<React.SetStateAction<number>>;

  isApplyBaggageFilter: boolean;
  setIsApplyBaggageFilter: React.Dispatch<React.SetStateAction<boolean>>;

  isFlightSearch2AndNotMulticity: boolean;

  //region a/b test values
  isFlightSearch2: boolean;
  isIncludeAirlineBackfill: boolean;
  isShowNativeAd: boolean;
  isLazyloadAdsVariantA: boolean;
  isShowingJWPAds: boolean;
  isCacheFlightSearch: boolean;
}

export const FlightContext = createContext<FlightContextValue | undefined>(undefined);

interface FlightContextProviderProps {
  children: React.ReactNode;
}

const FlightContextProvider: React.FC<FlightContextProviderProps> = ({ children }) => {
  const { userCentricCoreConfigs } = useContext(GlobalContext);
  const { isMultiCity } = useFlightSearchPageParams();

  const [flightSearchFormHeight, setFlightSearchFormHeight] = useState<number>(0);
  const [isApplyBaggageFilter, setIsApplyBaggageFilter] = useState<boolean>(false);

  const flightSearch2CoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY,
  );
  const isFlightSearch2 = flightSearch2CoreConfig?.value === '1';

  const airlineBackfillCoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === AIRLINE_BACKFILL_CORE_CONFIG_KEY,
  );
  const isIncludeAirlineBackfill = airlineBackfillCoreConfig?.value === '1';
  useClarityCustomTag(AIRLINE_BACKFILL_CORE_CONFIG_KEY, airlineBackfillCoreConfig?.value);

  const nativeAdCoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === NATIVE_AD_CORE_CONFIG_KEY,
  );
  const isShowNativeAd = nativeAdCoreConfig?.value === '1';
  useClarityCustomTag(NATIVE_AD_CORE_CONFIG_KEY, nativeAdCoreConfig?.value);

  const lazyloadAdsCoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === LAZYLOADING_ADS_WEB_CORE_CONFIG_KEY,
  );
  const isLazyloadAdsVariantA = lazyloadAdsCoreConfig?.value === '1';
  useClarityCustomTag(LAZYLOADING_ADS_WEB_CORE_CONFIG_KEY, lazyloadAdsCoreConfig?.value);

  const showingJWPAdsCoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === JWP_AD_DWEB_CORE_CONFIG_KEY,
  );
  const isShowingJWPAds = showingJWPAdsCoreConfig?.value === '1';
  useClarityCustomTag(JWP_AD_DWEB_CORE_CONFIG_KEY, showingJWPAdsCoreConfig?.value);

  const cacheFlightSearchCoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === CACHE_FLIGHT_SEARCH_CORE_CONFIG_KEY,
  );
  const isCacheFlightSearch = cacheFlightSearchCoreConfig?.value === '1';
  useClarityCustomTag(CACHE_FLIGHT_SEARCH_CORE_CONFIG_KEY, cacheFlightSearchCoreConfig?.value);

  const isFlightSearch2AndNotMulticity = isFlightSearch2 && !isMultiCity;

  const providerValue: FlightContextValue = useMemo(
    () => ({
      flightSearchFormHeight,
      setFlightSearchFormHeight,
      isFlightSearch2,
      isIncludeAirlineBackfill,
      isShowNativeAd,
      isLazyloadAdsVariantA,
      isShowingJWPAds,
      isCacheFlightSearch,
      isApplyBaggageFilter,
      setIsApplyBaggageFilter,
      isFlightSearch2AndNotMulticity,
    }),
    [
      flightSearchFormHeight,
      isIncludeAirlineBackfill,
      isCacheFlightSearch,
      isFlightSearch2,
      isLazyloadAdsVariantA,
      isShowNativeAd,
      isShowingJWPAds,
      isApplyBaggageFilter,
      setIsApplyBaggageFilter,
      isFlightSearch2AndNotMulticity,
    ],
  );

  return <FlightContext.Provider value={providerValue}>{children}</FlightContext.Provider>;
};

export default FlightContextProvider;

export const useFlightContext = () => {
  const flightContext = useContext(FlightContext);

  if (flightContext === undefined) {
    throw new Error('useFlightContext was used outside of FlightContextProvider');
  }

  return flightContext;
};

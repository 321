import { localStorageGet } from '@alexis/helpers/localStorage';
import { datadogRum } from '@datadog/browser-rum';
import { noop } from 'lodash-es';
import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';

type DatadogRecord = {
  startTime: number;
  endTime?: number;
  observer?: MutationObserver;
  trackedValue?: string;
  isAborted?: boolean;
};

type StartRecordingActionParams = {
  recordKey: string;
  observer?: MutationObserver;
  trackedValue?: string;
};

type StopRecordingActionParams = {
  actionName: string;
  recordKey: string;
  customData?: Record<string, any>;
  onStop?: () => void;
  suppressAction?: boolean;
};

type RecordChangeActionParams = {
  dd: string;
  type: 'filter' | 'sort';
};

export type RecordMountActionParams = Pick<
  StopRecordingActionParams,
  'actionName' | 'onStop' | 'suppressAction' | 'customData'
> & {
  dd: string;
  // Map custom data to the node attribute
  customDataFromAttributeMap?: Record<string, string>;
};

type DatadogProviderValue = {
  startRecordingAction: (prams: StartRecordingActionParams) => void;
  stopRecordingAction: (params: StopRecordingActionParams) => void;
  recordMountAction: (params: RecordMountActionParams) => void;
  recordChangeAction: (params: RecordChangeActionParams) => void;
  abortRecording: (recordKey: string) => void;
};

const DatadogContext = createContext<DatadogProviderValue>({
  startRecordingAction: noop,
  stopRecordingAction: noop,
  recordMountAction: noop,
  recordChangeAction: noop,
  abortRecording: noop,
});

const DatadogProvider = ({ children }: { children?: React.ReactNode }) => {
  const records = useRef<Record<string, DatadogRecord | undefined>>({});

  const startRecordingAction = useCallback(
    ({ recordKey, observer, trackedValue }: StartRecordingActionParams) => {
      // disconnect previous observer
      records.current[recordKey]?.observer?.disconnect();

      records.current[recordKey] = {
        startTime: Date.now(),
        observer,
        trackedValue,
      };
    },
    [],
  );

  const stopRecordingAction = useCallback(
    ({ actionName, recordKey, customData, onStop, suppressAction }: StopRecordingActionParams) => {
      const record = records.current[recordKey];

      if (!record || record.endTime || record.isAborted) return;

      record.endTime = Date.now();

      const value = record.endTime - record.startTime;

      if (localStorageGet('debug-dd')) {
        console.log('Recorded action:', actionName, value, customData);
      }

      if (!suppressAction) {
        datadogRum.addAction(actionName, { value, ...customData });
      }

      onStop?.();

      record.observer?.disconnect();
    },
    [],
  );

  const recordMountAction = useCallback<DatadogProviderValue['recordMountAction']>(
    ({ dd, actionName, onStop, suppressAction, customDataFromAttributeMap, customData }) => {
      const recordKey = `${dd}:${actionName}`;

      const mutationCallback: MutationCallback = (mutationsList) => {
        const record = records.current[recordKey];

        if (!record) return;

        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const expectedNode = document.querySelector(`[data-dd~="${dd}"]`);

            if (expectedNode && !records.current[recordKey]?.endTime) {
              const customDataFromAttributes = customDataFromAttributeMap
                ? Object.fromEntries(
                    Object.entries(customDataFromAttributeMap).map(([customDataKey, ddKey]) => {
                      const attributeValue = expectedNode.getAttribute(ddKey);

                      // Parse value to number or boolean if possible
                      let value;
                      try {
                        value = JSON.parse(attributeValue || '');
                      } catch (e) {
                        value = attributeValue;
                      }

                      return [customDataKey, value];
                    }),
                  )
                : undefined;

              stopRecordingAction({
                recordKey,
                actionName,
                onStop,
                suppressAction,
                customData: {
                  ...customData,
                  ...customDataFromAttributes,
                },
              });
            }
          }
        }
      };

      const observer = new MutationObserver(mutationCallback);

      startRecordingAction({ recordKey, observer });

      const containerNode = document.body;

      observer.observe(containerNode, { childList: true, subtree: true });
    },
    [startRecordingAction, stopRecordingAction],
  );

  const recordChangeAction = useCallback(({ dd, type }: RecordChangeActionParams) => {
    const actionName = `change ${type}`;
    const recordKey = `${dd}:${actionName}`;
    records.current[recordKey]?.observer?.disconnect();
    records.current[recordKey] = undefined;

    const containerNode = document.body;

    const mutationCallback: MutationCallback = (mutationsList) => {
      const record = records.current[recordKey];

      if (!record) return;

      if (record.endTime) {
        record.observer?.disconnect();
        return;
      }

      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const expectedNode = document.querySelector(`[data-dd="${dd}"]`);

          if (!expectedNode) {
            return;
          }

          const typeValue = expectedNode.getAttribute(`data-dd-${type}`);

          if (typeValue === record.trackedValue) {
            return;
          }

          stopRecordingAction({
            recordKey,
            actionName,
          });
        }
      }
    };

    const observer = new MutationObserver(mutationCallback);

    startRecordingAction({
      recordKey,
      observer,
      trackedValue:
        document.querySelector(`[data-dd="${dd}"]`)?.getAttribute(`data-dd-${type}`) || undefined,
    });

    observer.observe(containerNode, {
      childList: true,
      subtree: true,
    });
  }, []);

  const abortRecording = useCallback((recordKey: string) => {
    const record = records.current[recordKey];

    if (!record || record.endTime) return;

    record.observer?.disconnect();
    record.endTime = Date.now();
    record.isAborted = true;
  }, []);

  const value = useMemo(
    () => ({
      startRecordingAction,
      stopRecordingAction,
      recordMountAction,
      recordChangeAction,
      abortRecording,
    }),
    [
      recordChangeAction,
      recordMountAction,
      startRecordingAction,
      stopRecordingAction,
      abortRecording,
    ],
  );

  return <DatadogContext.Provider value={value}>{children}</DatadogContext.Provider>;
};

export default DatadogProvider;

export const useDatadog = () => useContext(DatadogContext);

import { clsx } from '@alexis/helpers/clsx';
import { dateDisplayFormat } from '@alexis/helpers/date';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import DatePicker from '@components/DatePicker';
import FlightDateSelector from '@components/flights/FlightDateSelector';
import InputSelector from '@components/InputSelector';

import {
  INSPIRATIONAL_SHOPPING_CORE_CONFIGS_KEY,
  AUTOCOMPLETE_CORE_CONFIG_KEY,
  FARE_CALENDAR_CORE_CONFIG_KEY,
  DIRECT_ONLY_PARAM,
  TEST_FEATURES_PARAM,
  SHOW_FARE_CALENDAR_PARAM,
  SHOW_FARE_CALENDAR_6_MONTHS_PARAM,
} from '@constants/flight';

import { GlobalContext } from '@context/GlobalContext';

import ClearIcon from '@icons/close.svg';
import SwapIcon from '@icons/swap.svg';
import WorldIcon from '@icons/world.svg';

import { useGetFlightFareCalendar } from '@hooks/api/flights/useGetFlightFareCalendar';
import { useGetFlightsAutocomplete } from '@hooks/api/places/useGetFlightsAutocomplete';
import { useGetFlightsPopularCities } from '@hooks/api/places/useGetFlightsPopularCities';
import { useGetNearestPlaces } from '@hooks/api/places/useGetNearestPlaces';
import { useGetPlaces } from '@hooks/api/places/useGetPlaces';
import { useClarityCustomTag } from '@hooks/useClarity';

import {
  getCurrentSiteState,
  getIsRtlState,
  getLocaleState,
  getTranslationsState,
  getUserState,
  getCurrencyState,
} from '@redux/selectors';

import type { FlightSearchType } from '@wegoTypes/flights/flightSearchType';

import { getSubsequentMonthDateFromCurrentDate } from './helper';
import styles from './LegSearch.module.scss';

export interface LegSearchProps {
  clientId: string | undefined;
  clientSessionId: string | undefined;
  geolocationCoordinates: GeolocationCoordinates | undefined;
  isFromHomepage?: boolean;
  isRemoveLegSearchDisabled: boolean;
  legSearch: LegSearch;
  legSearchIndex: number;
  nextLegSearchOutboundDateMilliseconds: number | undefined;
  onLegSearchFieldChange(
    legSearchIndex: number,
    field: FlightSearchFields,
    value: InboundPlace | number,
  ): void;
  onRemoveLegSearchClick(legSearchIndex: number): void;
  onRemoveReturnDateClick(): void;
  onDateReset(): void;
  pageViewId: string;
  previousLegSearchOutboundDateMilliseconds: number | undefined;
  searchType: FlightSearchType;
  passengerCount: number;
}

type OutboundInbound = 'outbound' | 'inbound';

type ArrowKey = 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight';

const LegSearch = forwardRef<LegSearchHandles, LegSearchProps>(
  (
    {
      clientId,
      clientSessionId,
      geolocationCoordinates,
      isFromHomepage,
      isRemoveLegSearchDisabled,
      legSearch,
      legSearchIndex,
      nextLegSearchOutboundDateMilliseconds,
      onLegSearchFieldChange,
      onDateReset,
      onRemoveLegSearchClick,
      onRemoveReturnDateClick,
      pageViewId,
      previousLegSearchOutboundDateMilliseconds,
      searchType,
      passengerCount,
    },
    ref,
  ) => {
    const user = useSelector(getUserState);
    const translations = useSelector(getTranslationsState);
    const locale = useSelector(getLocaleState);
    const currentSite = useSelector(getCurrentSiteState);
    const isRtl = useSelector(getIsRtlState);

    const { userCentricCoreConfigs } = useContext(GlobalContext);

    //#region search params
    const [searchParams] = useSearchParams();
    const openCalendarSearchParam = searchParams.get('open_calendar');

    const testFeatures = searchParams.get(TEST_FEATURES_PARAM)?.split(',') || [];

    const isShowFareCalendarParam = testFeatures.includes(SHOW_FARE_CALENDAR_PARAM);

    const isFareCalendar6MonthsParam = testFeatures.includes(SHOW_FARE_CALENDAR_6_MONTHS_PARAM);

    //#endregion

    const outboundPlacePickerRef = useRef<HTMLInputElement>(null);
    const inboundPlacePickerRef = useRef<HTMLInputElement>(null);
    const legSearchContainerRef = useRef<HTMLInputElement>(null);

    const [outboundSearchQuery, setOutboundSearchQuery] = useState<string>('');
    const [outboundSearchQueryPlaces, setOutboundSearchQueryPlaces] = useState<Array<Place>>([]);
    const [outboundSearchActiveItemIndex, setOutboundSearchActiveItemIndex] = useState<number>(0);
    const [inboundSearchQuery, setInboundSearchQuery] = useState<string>('');
    const [inboundSearchQueryPlaces, setInboundSearchQueryPlaces] = useState<Array<Place>>([]);
    const [inboundSearchActiveItemIndex, setInboundSearchActiveItemIndex] = useState<number>(0);

    const [isSwapped, setIsSwapped] = useState<boolean>(false);

    const [selectedDate, setSelectedDate] = useState<Date>(); // State used by date picker component
    const [activeOutboundDateMilliseconds, setActiveOutboundDateMilliseconds] = useState<number>(0);

    const limitFromMilliseconds$ = new Date().setHours(0, 0, 0, 0);

    //#region Pickers focus state
    const [isOutboundPlacePickerFocus, setIsOutboundPlacePickerFocus] = useState<boolean>(false);
    const [isInboundPlacePickerFocus, setIsInboundPlacePickerFocus] = useState<boolean>(false);

    const [isDatePickerOutboundFocus, setIsDatePickerOutboundFocus] = useState<boolean>(false);
    const [isDatePickerOutboundChanged, setIsDatePickerOutboundChanged] = useState<boolean>(false);
    const [isDatePickerInboundFocus, setIsDatePickerInboundFocus] = useState<boolean>(false);
    const [isDatePickerInboundChanged, setIsDatePickerInboundChanged] = useState<boolean>(false);
    const [isInboundDateInputClicked, setIsInboundDateInputClicked] = useState<boolean>(false);
    //#endregion

    //#region Pickers validated and has error state
    const [isOutboundSearchQueryValidated, setOutboundSearchQueryValidated] =
      useState<boolean>(false);
    const [outboundSearchQueryHasError, setOutboundSearchQueryHasError] = useState<boolean>(false);

    const [isInboundSearchQueryValidated, setInboundSearchQueryValidated] =
      useState<boolean>(false);
    const [inboundSearchQueryHasError, setInboundSearchQueryHasError] = useState<boolean>(false);

    const [isOutboundDateMillisecondsValidated, setIsOutboundDateMillisecondsValidated] =
      useState<boolean>(false);
    const [outboundDateMillisecondsHasError, setOutboundDateMillisecondsHasError] =
      useState<boolean>(false);

    const [isInboundDateMillisecondsValidated, setIsInboundDateMillisecondsValidated] =
      useState<boolean>(false);
    const [inboundDateMillisecondsHasError, setInboundDateMillisecondsHasError] =
      useState<boolean>(false);
    //#endregion

    const [isShowFareCalendarNote, setIsShowFareCalendarNote] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({
      validate,
    }));

    //#region A/B tests
    const autosuggestCoreConfig = userCentricCoreConfigs.find(
      (coreConfig) => coreConfig.key === AUTOCOMPLETE_CORE_CONFIG_KEY,
    );
    const autosuggestVariantA = autosuggestCoreConfig?.value === '1';

    useClarityCustomTag(AUTOCOMPLETE_CORE_CONFIG_KEY, autosuggestCoreConfig?.value);

    const isShowFareCalendarCoreConfigValue = userCentricCoreConfigs.find(
      (coreConfig) => coreConfig.key === FARE_CALENDAR_CORE_CONFIG_KEY,
    )?.value;

    const isShowFareCalendar =
      isShowFareCalendarCoreConfigValue === '1' ||
      isShowFareCalendarCoreConfigValue === '2' ||
      isShowFareCalendarParam;

    useClarityCustomTag(FARE_CALENDAR_CORE_CONFIG_KEY, isShowFareCalendarCoreConfigValue);

    const currency = useSelector(getCurrencyState);

    const isDirectFlightOnly = searchParams.has(DIRECT_ONLY_PARAM);

    const departureCode = legSearch.outboundPlace?.code || '';
    const departureCodeType = legSearch.outboundPlace?.type === 'city' ? 'City' : 'Airport';
    const arrivalCode = (legSearch.inboundPlace as OutboundPlace)?.code || '';
    const arrivalCodeType =
      (legSearch.inboundPlace as OutboundPlace)?.type === 'city' ? 'City' : 'Airport';

    const fareCalendarDateRanges = useMemo(() => {
      const today = new Date();
      if (isShowFareCalendar) {
        const monthOffsets = isFareCalendar6MonthsParam ? [0] : [0, 1, 2, 3, 4, 5];
        const range = isFareCalendar6MonthsParam ? 7 : 2;

        return monthOffsets.map((offset) => ({
          startDate: getSubsequentMonthDateFromCurrentDate(today, offset, true),
          endDate: getSubsequentMonthDateFromCurrentDate(today, offset + range, false),
        }));
      }

      return [];
    }, [isFareCalendar6MonthsParam, isShowFareCalendar]);

    const {
      data: fareCalendar,
      isLoading: isLoadingFareCalendar,
      isError: isFareCalendarError,
    } = useGetFlightFareCalendar(
      {
        departureLocations: [
          {
            code: departureCode,
            type: departureCodeType,
          },
        ],
        arrivalLocations: [
          {
            code: arrivalCode,
            type: arrivalCodeType,
          },
        ],
        currencyCode: currency.code,
        dateRanges: fareCalendarDateRanges,
        tripType: searchType === 'oneWay' ? 'ONEWAY' : 'ROUNDTRIP',
        directOnly: isDirectFlightOnly,
      },
      {
        enabled: searchType === 'oneWay' && !!departureCode && !!arrivalCode && isShowFareCalendar,
      },
    );

    useEffect(() => {
      if (
        !departureCode ||
        !arrivalCode ||
        isFareCalendarError ||
        !isShowFareCalendar ||
        searchType !== 'oneWay'
      ) {
        setIsShowFareCalendarNote(false);
      } else {
        setIsShowFareCalendarNote(true);
      }
    }, [isFareCalendarError, departureCode, arrivalCode, isShowFareCalendar, searchType]);

    // Get place based on outboundSearchQuery
    const { isLoading: isLoadingOutboundFlightsAutocomplete } = useGetFlightsAutocomplete(
      {
        searchFor: 'origin',
        query: outboundSearchQuery,
        minimumAirportCount: 1,
      },
      {
        enabled: Boolean(
          !!outboundSearchQuery && isOutboundPlacePickerFocus && autosuggestVariantA,
        ),
        onSuccessCallback: (data) => setOutboundSearchQueryPlaces(data),
        onErrorCallback: () => setOutboundSearchQueryPlaces([]),
      },
    );

    const { isLoading: isLoadingOutboundPlaces } = useGetPlaces(
      {
        query: outboundSearchQuery,
        isCity: false,
        isDistrict: false,
        isHotel: false,
        isRegion: false,
        isActivity: false,
        isAttraction: false,
        minimumAirportCount: 1,
      },
      {
        enabled: Boolean(
          !!outboundSearchQuery && isOutboundPlacePickerFocus && !autosuggestVariantA,
        ),
        onSuccessCallback: (data) => setOutboundSearchQueryPlaces(data),
        onErrorCallback: () => setOutboundSearchQueryPlaces([]),
      },
    );

    const isLoadingPlacesBasedOnOutbound = useMemo(() => {
      return isLoadingOutboundFlightsAutocomplete || isLoadingOutboundPlaces;
    }, [isLoadingOutboundFlightsAutocomplete, isLoadingOutboundPlaces]);

    // Get place based on inboundSearchQuery
    const { isLoading: isLoadingInboundFlightsAutocomplete } = useGetFlightsAutocomplete(
      {
        searchFor: 'destination',
        query: inboundSearchQuery,
        minimumAirportCount: 1,
      },
      {
        enabled: Boolean(!!inboundSearchQuery && isInboundPlacePickerFocus && autosuggestVariantA),
        onSuccessCallback: (data) => setInboundSearchQueryPlaces(data),
        onErrorCallback: () => setInboundSearchQueryPlaces([]),
      },
    );

    const { isLoading: isLoadingInboundPlaces } = useGetPlaces(
      {
        query: inboundSearchQuery,
        isCity: false,
        isDistrict: false,
        isHotel: false,
        isRegion: false,
        isActivity: false,
        isAttraction: false,
        minimumAirportCount: 1,
      },
      {
        enabled: Boolean(!!inboundSearchQuery && isInboundPlacePickerFocus && !autosuggestVariantA),
        onSuccessCallback: (data) => setInboundSearchQueryPlaces(data),
        onErrorCallback: () => setInboundSearchQueryPlaces([]),
      },
    );

    const isLoadingPlacesBasedOnInbound = useMemo(() => {
      return isLoadingInboundFlightsAutocomplete || isLoadingInboundPlaces;
    }, [isLoadingInboundFlightsAutocomplete, isLoadingInboundPlaces]);

    // Get outbound popular cities
    const { data: outboundPopularCities, isLoading: isLoadingOutboundPopularCities } =
      useGetFlightsPopularCities({ isDeparture: true, perPageCount: 16 });

    // Get inbound popular cities
    const { data: inboundPopularCities, isLoading: isLoadingInboundPopularCities } =
      useGetFlightsPopularCities({ isDeparture: false, perPageCount: 16 });

    // Get nearest airport based on geolocation
    const { data: nearestAirportByGeolocation, isLoading: isLoadingNearestAirportByGeolocation } =
      useGetNearestPlaces(
        {
          isCity: false,
          latitude: geolocationCoordinates?.latitude,
          longitude: geolocationCoordinates?.longitude,
        },
        {
          enabled: Boolean(geolocationCoordinates),
          select: (data) => data[0],
        },
      );

    const isVariantShowingInspirationalShopping = useMemo(() => {
      const inspirationalShoppingCoreConfig = userCentricCoreConfigs.find(
        (coreConfig) => coreConfig.key === INSPIRATIONAL_SHOPPING_CORE_CONFIGS_KEY,
      );

      return (
        inspirationalShoppingCoreConfig?.value === '1' ||
        inspirationalShoppingCoreConfig?.value === '2'
      );
    }, [userCentricCoreConfigs]);

    const showInspirationalShopping = useMemo(
      () =>
        locale === 'en' &&
        searchType !== 'multiCity' &&
        !inboundSearchQuery &&
        isFromHomepage &&
        isVariantShowingInspirationalShopping,
      [
        inboundSearchQuery,
        isFromHomepage,
        isVariantShowingInspirationalShopping,
        locale,
        searchType,
      ],
    );
    //#endregion

    const limitFromMilliseconds = useMemo(() => {
      if (previousLegSearchOutboundDateMilliseconds) {
        return previousLegSearchOutboundDateMilliseconds;
      }

      if (!!legSearch.outboundDateMilliseconds && isDatePickerInboundFocus) {
        return legSearch.outboundDateMilliseconds;
      }
      return limitFromMilliseconds$;
    }, [
      isDatePickerInboundFocus,
      legSearch.outboundDateMilliseconds,
      limitFromMilliseconds$,
      previousLegSearchOutboundDateMilliseconds,
    ]);

    const limitToMilliseconds = useMemo(() => {
      const limitTillDate = new Date();
      limitTillDate.setHours(0, 0, 0, 0);
      limitTillDate.setDate(limitTillDate.getDate() - 1);

      return limitTillDate.setFullYear(limitTillDate.getFullYear() + 1);
    }, []);

    const limitToMillisecondsMulticity = useMemo(() => {
      return nextLegSearchOutboundDateMilliseconds || limitToMilliseconds;
    }, [limitToMilliseconds, nextLegSearchOutboundDateMilliseconds]);

    useEffect(() => {
      if (legSearch.outboundDateMilliseconds) {
        setActiveOutboundDateMilliseconds(legSearch.outboundDateMilliseconds);
      }
    }, [legSearch.outboundDateMilliseconds]);

    //#region validate functions
    const validateOutboundPlace = useCallback(() => {
      let outboundPlaceHasError = false;

      if (!legSearch.outboundPlace) {
        outboundPlaceHasError = true;
      }

      setOutboundSearchQueryHasError(outboundPlaceHasError);
      setOutboundSearchQueryValidated(true);

      return outboundPlaceHasError;
    }, [legSearch.outboundPlace]);

    const validateInboundPlace = useCallback(() => {
      let inboundPlaceHasError = false;

      if (
        !legSearch.inboundPlace ||
        (legSearch.outboundPlace &&
          legSearch.inboundPlace !== 'Anywhere' &&
          legSearch.outboundPlace.cityCode === legSearch.inboundPlace.cityCode)
      ) {
        inboundPlaceHasError = true;
      }

      setInboundSearchQueryHasError(inboundPlaceHasError);
      setInboundSearchQueryValidated(true);

      return inboundPlaceHasError;
    }, [legSearch.inboundPlace, legSearch.outboundPlace]);

    const validateOutboundDateMilliseconds = useCallback(() => {
      let outboundDateMillisecondsHasError = false;

      if (
        !legSearch.outboundDateMilliseconds ||
        (!!previousLegSearchOutboundDateMilliseconds &&
          legSearch.outboundDateMilliseconds < previousLegSearchOutboundDateMilliseconds)
      ) {
        outboundDateMillisecondsHasError = true;
      }

      setOutboundDateMillisecondsHasError(outboundDateMillisecondsHasError);
      setIsOutboundDateMillisecondsValidated(true);

      return outboundDateMillisecondsHasError;
    }, [legSearch.outboundDateMilliseconds, previousLegSearchOutboundDateMilliseconds]);

    const validateInboundDateMilliseconds = useCallback(() => {
      let inboundDateMillisecondsHasError = false;

      if (!legSearch.inboundDateMilliseconds && searchType === 'roundTrip') {
        inboundDateMillisecondsHasError = true;
      }

      setInboundDateMillisecondsHasError(inboundDateMillisecondsHasError);
      setIsInboundDateMillisecondsValidated(true);

      return inboundDateMillisecondsHasError;
    }, [legSearch.inboundDateMilliseconds, searchType]);

    const validate = useCallback(() => {
      const outboundPlaceHasError = validateOutboundPlace();
      const inboundPlaceHasError = validateInboundPlace();
      const outboundDateMillisecondsHasError = validateOutboundDateMilliseconds();
      const inboundDateMillisecondsHasError = validateInboundDateMilliseconds();

      return (
        outboundPlaceHasError ||
        inboundPlaceHasError ||
        outboundDateMillisecondsHasError ||
        (searchType === 'roundTrip' && inboundDateMillisecondsHasError)
      );
    }, [
      validateOutboundPlace,
      validateInboundPlace,
      validateOutboundDateMilliseconds,
      validateInboundDateMilliseconds,
      searchType,
    ]);
    //#endregion

    //#region date picker events
    const handleDatePickerOutboundFocus = useCallback(() => {
      setIsOutboundPlacePickerFocus(false);
      setIsInboundPlacePickerFocus(false);

      setIsDatePickerOutboundFocus(true);
      setIsDatePickerInboundFocus(false);

      setIsInboundDateInputClicked(false);

      document.getElementById('from-input-container')?.focus();
    }, []);

    const handleDatePickerInboundFocus = useCallback(() => {
      setIsOutboundPlacePickerFocus(false);
      setIsInboundPlacePickerFocus(false);

      setIsInboundDateInputClicked(true);

      if (!legSearch.outboundDateMilliseconds) {
        setIsDatePickerOutboundFocus(true);
        setIsDatePickerInboundFocus(false);
      } else {
        setIsDatePickerInboundFocus(true);
        setIsDatePickerOutboundFocus(false);
      }
      document.getElementById('to-input-container')?.focus();
    }, [legSearch.outboundDateMilliseconds]);

    const handleDateChanged = useCallback((value: Date, field: 'inbound' | 'outbound') => {
      // setActiveOutboundDateMilliseconds()
      if (field === 'outbound') {
        setIsDatePickerOutboundChanged(true);
        setSelectedDate(value);
      } else if (field === 'inbound') {
        setIsDatePickerInboundChanged(true);
        setSelectedDate(value);
      }
    }, []);
    //#endregion

    //#region place picker events
    const handleOutboundPlacePickerChange = useCallback((value: string) => {
      setIsOutboundPlacePickerFocus(true);
      setOutboundSearchQueryValidated(false);
      setOutboundSearchQueryHasError(false);
      setOutboundSearchQuery(value);
      setOutboundSearchActiveItemIndex(0);
    }, []);

    const handleOutboundPlacePickerClear = useCallback(() => {
      setOutboundSearchQuery('');
      setOutboundSearchQueryPlaces([]);
      onLegSearchFieldChange(legSearchIndex, 'outboundPlace', undefined);

      outboundPlacePickerRef.current!.select();
    }, [onLegSearchFieldChange, legSearchIndex, outboundPlacePickerRef]);

    const handleOutboundPlacePickerFocus = useCallback(() => {
      setIsDatePickerOutboundFocus(false);
      setIsDatePickerInboundFocus(false);

      setIsOutboundPlacePickerFocus(true);
      setIsInboundPlacePickerFocus(false);
      outboundPlacePickerRef.current!.select();
    }, [outboundPlacePickerRef]);

    const handlePrevious = useCallback(
      (field: OutboundInbound, step?: number) => {
        if (field === 'outbound' && outboundSearchActiveItemIndex >= 1) {
          setOutboundSearchActiveItemIndex((prev) => Math.max(prev - (step || 1), 0));

          const itemElement = document.getElementById(
            `outboundItem-${outboundSearchActiveItemIndex}`,
          );
          const searchResultContainer = document.getElementById('outboundSearchQueryList');

          if (searchResultContainer && itemElement) {
            const itemElementTop = itemElement.clientHeight * (outboundSearchActiveItemIndex - 1);

            const differences = searchResultContainer.scrollTop - itemElementTop;

            if (differences > 0) {
              searchResultContainer.scrollTop = searchResultContainer.scrollTop - differences;
            }
          }
        }

        if (field === 'inbound' && inboundSearchActiveItemIndex >= 1) {
          setInboundSearchActiveItemIndex((prev) => Math.max(prev - (step || 1), 0));

          const itemElement = document.getElementById(
            `inboundItem-${inboundSearchActiveItemIndex}`,
          );
          const searchResultContainer = document.getElementById('inboundSearchQueryList');

          if (searchResultContainer && itemElement) {
            const itemElementTop = itemElement.clientHeight * (inboundSearchActiveItemIndex - 1);

            const differences = searchResultContainer.scrollTop - itemElementTop;

            if (differences > 0) {
              searchResultContainer.scrollTop = searchResultContainer.scrollTop - differences;
            }
          }
        }
      },
      [inboundSearchActiveItemIndex, outboundSearchActiveItemIndex],
    );

    const handleNext = useCallback(
      (field: OutboundInbound, step?: number) => {
        const outboundVisibleItemsCount = outboundSearchQuery
          ? outboundSearchQueryPlaces.length
          : (outboundPopularCities?.length || 0) + (nearestAirportByGeolocation ? 1 : 0);

        if (
          field === 'outbound' &&
          outboundSearchActiveItemIndex < outboundVisibleItemsCount - (step || 1)
        ) {
          setOutboundSearchActiveItemIndex((prev) => prev + (step || 1));

          const itemElement = document.getElementById(
            `outboundItem-${outboundSearchActiveItemIndex}`,
          );
          const searchResultContainer = document.getElementById('outboundSearchQueryList');
          searchResultContainer;

          if (itemElement && searchResultContainer) {
            const itemElementBottom =
              itemElement.clientHeight * (outboundSearchActiveItemIndex + 2);
            const differences = itemElementBottom - searchResultContainer.clientHeight;

            if (differences > 0 && searchResultContainer.scrollTop <= differences) {
              searchResultContainer.scrollTop = differences;
            }
          }
        }

        const inboundVisibleItemsCount = inboundSearchQuery
          ? inboundSearchQueryPlaces.length
          : (inboundPopularCities?.length || 0) + (showInspirationalShopping ? 1 : 0);
        if (
          field === 'inbound' &&
          inboundSearchActiveItemIndex < inboundVisibleItemsCount - (step || 1)
        ) {
          setInboundSearchActiveItemIndex((prev) => prev + (step || 1));

          const itemElement = document.getElementById(
            `inboundItem-${inboundSearchActiveItemIndex}`,
          );
          const searchResultContainer = document.getElementById('inboundSearchQueryList');
          searchResultContainer;

          if (itemElement && searchResultContainer) {
            const itemElementBottom = itemElement.clientHeight * (inboundSearchActiveItemIndex + 2);
            const differences = itemElementBottom - searchResultContainer.clientHeight;

            if (differences > 0 && searchResultContainer.scrollTop <= differences) {
              searchResultContainer.scrollTop = differences;
            }
          }
        }
      },
      [
        inboundPopularCities?.length,
        inboundSearchActiveItemIndex,
        inboundSearchQuery,
        inboundSearchQueryPlaces.length,
        nearestAirportByGeolocation,
        outboundPopularCities?.length,
        outboundSearchActiveItemIndex,
        outboundSearchQuery,
        outboundSearchQueryPlaces.length,
        showInspirationalShopping,
      ],
    );

    const handleOutboundPlaceSelected = useCallback(
      (place: Place | PlacesFlightsPopularCity, index?: number) => {
        onLegSearchFieldChange(legSearchIndex, 'outboundPlace', place);

        setIsOutboundPlacePickerFocus(false);

        if (!legSearch.inboundPlace) {
          setIsInboundPlacePickerFocus(true);
        }

        if (clientId && clientSessionId && index !== undefined) {
          const eventData = {
            category: 'autocomplete',
            object: 'autocomplete_results',
            action: place.type,
            id: pageViewId,
            value: JSON.stringify({
              index_id: index,
              query: outboundSearchQuery,
              name: place.name,
              countryName: place.countryName,
              id: place.id,
              permalink: place.permalink,
              cityId: place.cityId,
            }),
          };
          genzoTrackActionEvent(API_BASE_URL, clientId, clientSessionId, user?.userHash, eventData);
        }
      },
      [
        onLegSearchFieldChange,
        legSearchIndex,
        legSearch.inboundPlace,
        pageViewId,
        outboundSearchQuery,
        clientId,
        clientSessionId,
        user?.userHash,
      ],
    );

    const handleInboundPlacePickerChange = useCallback((value: string) => {
      setIsInboundPlacePickerFocus(true);
      setInboundSearchQueryValidated(false);
      setInboundSearchQueryHasError(false);
      setInboundSearchQuery(value);
      setInboundSearchActiveItemIndex(0);
    }, []);

    const handleInboundPlacePickerClear = useCallback(() => {
      setInboundSearchQuery('');
      setInboundSearchQueryPlaces([]);
      onLegSearchFieldChange(legSearchIndex, 'inboundPlace', undefined);

      inboundPlacePickerRef.current!.select();
    }, [onLegSearchFieldChange, legSearchIndex, inboundPlacePickerRef]);

    const handleInboundPlacePickerFocus = useCallback(() => {
      setIsDatePickerOutboundFocus(false);
      setIsDatePickerInboundFocus(false);
      setIsOutboundPlacePickerFocus(false);
      setIsInboundPlacePickerFocus(true);

      inboundPlacePickerRef.current!.select();
    }, [inboundPlacePickerRef]);

    const handleOutboundPlacePickerKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
          event.preventDefault();
          legSearchContainerRef.current?.focus();
          setIsOutboundPlacePickerFocus(false);
        } else if (['Enter', 'Tab'].includes(event.key) && !event.shiftKey) {
          event.preventDefault();

          if (outboundSearchQueryPlaces.length > 0) {
            onLegSearchFieldChange(
              legSearchIndex,
              'outboundPlace',
              outboundSearchQueryPlaces[outboundSearchActiveItemIndex],
            );
          }

          if (!event.currentTarget.value) {
            if (nearestAirportByGeolocation && outboundSearchActiveItemIndex === 0) {
              onLegSearchFieldChange(legSearchIndex, 'outboundPlace', nearestAirportByGeolocation);
            } else if (outboundPopularCities) {
              onLegSearchFieldChange(
                legSearchIndex,
                'outboundPlace',
                outboundPopularCities[
                  nearestAirportByGeolocation
                    ? outboundSearchActiveItemIndex - 1
                    : outboundSearchActiveItemIndex
                ],
              );
            }
          }

          inboundPlacePickerRef.current!.select();
          setIsOutboundPlacePickerFocus(false);

          setOutboundSearchActiveItemIndex(0);
        }

        if (event.key === 'ArrowUp') {
          event.preventDefault();

          handlePrevious('outbound', outboundSearchQuery ? 1 : 4);
        }

        if (event.key === 'ArrowDown') {
          event.preventDefault();

          handleNext(
            'outbound',
            outboundSearchQuery ||
              (nearestAirportByGeolocation && outboundSearchActiveItemIndex === 0)
              ? 1
              : 4,
          );
        }

        if (event.key === 'ArrowRight' && !outboundSearchQuery) {
          event.preventDefault();
          handleNext('outbound', 1);
        }
        if (event.key === 'ArrowLeft' && !outboundSearchQuery) {
          event.preventDefault();
          handlePrevious('outbound', 1);
        }
      },
      [
        handleNext,
        handlePrevious,
        legSearchIndex,
        nearestAirportByGeolocation,
        onLegSearchFieldChange,
        outboundPopularCities,
        outboundSearchActiveItemIndex,
        outboundSearchQuery,
        outboundSearchQueryPlaces,
      ],
    );

    const handleInboundPlacePickerKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab' && event.shiftKey) {
          event.preventDefault();
          outboundPlacePickerRef.current!.select();
          setIsInboundPlacePickerFocus(false);
        } else if (['Enter', 'Tab'].includes(event.key)) {
          event.preventDefault();

          if (inboundSearchQueryPlaces.length > 0) {
            onLegSearchFieldChange(
              legSearchIndex,
              'inboundPlace',
              inboundSearchQueryPlaces[inboundSearchActiveItemIndex],
            );
          }

          if (!event.currentTarget.value) {
            if (showInspirationalShopping && inboundSearchActiveItemIndex === 0) {
              onLegSearchFieldChange(legSearchIndex, 'inboundPlace', 'Anywhere');
            } else if (inboundPopularCities) {
              onLegSearchFieldChange(
                legSearchIndex,
                'inboundPlace',
                inboundPopularCities[
                  showInspirationalShopping
                    ? inboundSearchActiveItemIndex - 1
                    : inboundSearchActiveItemIndex
                ],
              );
            }
          }

          setInboundSearchActiveItemIndex(0);
          handleDatePickerOutboundFocus();
        }

        if (event.key === 'ArrowUp') {
          event.preventDefault();

          handlePrevious('inbound', inboundSearchQuery ? 1 : 4);
        }

        if (event.key === 'ArrowDown') {
          event.preventDefault();

          handleNext(
            'inbound',
            inboundSearchQuery || (showInspirationalShopping && inboundSearchActiveItemIndex === 0)
              ? 1
              : 4,
          );
        }

        if (event.key === 'ArrowRight' && !inboundSearchQuery) {
          event.preventDefault();
          handleNext('inbound', 1);
        }
        if (event.key === 'ArrowLeft' && !inboundSearchQuery) {
          event.preventDefault();
          handlePrevious('inbound', 1);
        }
      },
      [
        handleDatePickerOutboundFocus,
        handleNext,
        handlePrevious,
        inboundPopularCities,
        inboundSearchActiveItemIndex,
        inboundSearchQuery,
        inboundSearchQueryPlaces,
        legSearchIndex,
        onLegSearchFieldChange,
        showInspirationalShopping,
      ],
    );

    const handleInboundPlaceSelected = useCallback(
      (place: Place | PlacesFlightsPopularCity | 'Anywhere', index?: number) => {
        onLegSearchFieldChange(legSearchIndex, 'inboundPlace', place);

        setIsInboundPlacePickerFocus(false);

        if (!legSearch.outboundDateMilliseconds && legSearch.outboundPlace) {
          setIsDatePickerOutboundFocus(true);
        }

        if (!!clientId && !!clientSessionId) {
          if (place === 'Anywhere') {
            const eventData = {
              id: pageViewId,
              category: 'autocomplete',
              object: 'autocomplete_results',
              action: 'explore_anywhere',
              value: 'explore_anywhere',
            };

            genzoTrackActionEvent(
              API_BASE_URL,
              clientId,
              clientSessionId,
              user?.userHash,
              eventData,
            );
          } else if (index !== undefined) {
            const eventData = {
              category: 'autocomplete',
              object: 'autocomplete_results',
              action: place.type,
              id: pageViewId,
              value: JSON.stringify({
                index_id: index,
                query: inboundSearchQuery,
                name: place.name,
                countryName: place.countryName,
                id: place.id,
                permalink: place.permalink,
                cityId: place.cityId,
              }),
            };

            genzoTrackActionEvent(
              API_BASE_URL,
              clientId,
              clientSessionId,
              user?.userHash,
              eventData,
            );
          }
        }
      },
      [
        onLegSearchFieldChange,
        legSearchIndex,
        legSearch.outboundDateMilliseconds,
        legSearch.outboundPlace,
        clientId,
        clientSessionId,
        pageViewId,
        inboundSearchQuery,
        user?.userHash,
      ],
    );

    const handleMoveDateWithKeyboard = (key: ArrowKey) => {
      switch (key) {
        case 'ArrowRight':
          if (activeOutboundDateMilliseconds >= limitToMilliseconds) {
            return;
          }
          setActiveOutboundDateMilliseconds((prev) => {
            const newDate = new Date(prev);
            newDate.setDate(newDate.getDate() + 1);
            return newDate.getTime();
          });
          break;

        case 'ArrowLeft':
          if (activeOutboundDateMilliseconds <= limitFromMilliseconds) {
            return;
          }
          setActiveOutboundDateMilliseconds((prev) => {
            const newDate = new Date(prev);
            newDate.setDate(newDate.getDate() - 1);
            return newDate.getTime();
          });
          break;
        case 'ArrowUp':
          if (activeOutboundDateMilliseconds <= limitFromMilliseconds + 6 * 24 * 60 * 60 * 1000) {
            return;
          }
          setActiveOutboundDateMilliseconds((prev) => {
            const newDate = new Date(prev);
            newDate.setDate(newDate.getDate() - 7);
            return newDate.getTime();
          });
          break;
        case 'ArrowDown':
          if (activeOutboundDateMilliseconds >= limitToMilliseconds + 6 * 24 * 60 * 60 * 1000) {
            return;
          }
          setActiveOutboundDateMilliseconds((prev) => {
            const newDate = new Date(prev);
            newDate.setDate(newDate.getDate() + 7);
            return newDate.getTime();
          });
          break;

        default:
          break;
      }
    };

    const searchButton = document.getElementById('flightSearchFormButton');

    const handleFromDateKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
      if (event.key === 'Enter' && !isDatePickerOutboundFocus) {
        setIsDatePickerOutboundFocus(true);
      } else if (event.key === 'Tab' && event.shiftKey) {
        event.preventDefault();
        inboundPlacePickerRef.current!.select();
        setIsDatePickerOutboundFocus(false);
      } else if (['Enter', 'Tab'].includes(event.key) && !event.shiftKey) {
        event.preventDefault();
        setIsDatePickerOutboundChanged(true);
        setSelectedDate(new Date(activeOutboundDateMilliseconds));

        if (searchType === 'roundTrip') {
          handleDatePickerInboundFocus();
        } else {
          searchButton?.focus();
        }
      }

      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.preventDefault();
        handleMoveDateWithKeyboard(event.key as ArrowKey);
      }
    };

    const handleToDateKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
      if (event.key === 'Enter' && !isDatePickerInboundFocus) {
        setIsDatePickerInboundFocus(true);
      } else if (event.key === 'Tab' && event.shiftKey) {
        event.preventDefault();
        handleDatePickerOutboundFocus();
      } else if (['Enter', 'Tab'].includes(event.key) && !event.shiftKey) {
        event.preventDefault();
        setIsDatePickerInboundChanged(true);
        setSelectedDate(new Date(activeOutboundDateMilliseconds));

        searchButton?.focus();
      }

      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.preventDefault();
        handleMoveDateWithKeyboard(event.key as ArrowKey);
      }
    };

    const handleSwapOutboundInboundPlace = useCallback(() => {
      setIsSwapped((prev) => !prev);
      onLegSearchFieldChange(legSearchIndex, 'swapOutboundInboundPlace', undefined);
    }, [onLegSearchFieldChange, legSearchIndex]);
    //#endregion

    const placeLabel = useCallback((place: Place | PlacesFlightsPopularCity) => {
      const placeLabel: Array<string> = [place.name];

      if (place.name !== place.countryName) {
        placeLabel.push(place.countryName);
      }

      return placeLabel.join(', ');
    }, []);

    const getPlaceDisplay = useCallback(
      (place: Place) => {
        return (
          <>
            {place.type === 'city' ? <i className={clsx(styles.icon, styles.cityGrey)}></i> : null}
            {place.type === 'airport' ? (
              <i className={clsx(styles.icon, styles.airportGrey)}></i>
            ) : null}

            <div className={styles.labelAndCode}>
              <div className={styles.label}>{placeLabel(place)}</div>

              <div className={styles.code}>
                {place.type === 'city' && place.airportCount! > 1
                  ? translations.all_airports
                  : place.code}
              </div>
            </div>
          </>
        );
      },
      [placeLabel, translations.all_airports],
    );

    const closeAllInputPickers = useCallback((): void => {
      legSearchContainerRef.current?.focus();

      if (isOutboundPlacePickerFocus) {
        setIsOutboundPlacePickerFocus(false);
        setOutboundSearchActiveItemIndex(0);
      }

      if (isInboundPlacePickerFocus) {
        setIsInboundPlacePickerFocus(false);
        setInboundSearchActiveItemIndex(0);
      }

      if (isDatePickerOutboundFocus) {
        setIsDatePickerOutboundFocus(false);
      }

      if (isDatePickerInboundFocus) {
        setIsDatePickerInboundFocus(false);
      }
    }, [
      isDatePickerInboundFocus,
      isDatePickerOutboundFocus,
      isInboundPlacePickerFocus,
      isOutboundPlacePickerFocus,
    ]);

    const handleRemoveReturnDateClick = useCallback(() => {
      closeAllInputPickers();
      onRemoveReturnDateClick();
    }, [onRemoveReturnDateClick, closeAllInputPickers]);

    // Open calendar if open_calendar search param exist
    useEffect(() => {
      if (openCalendarSearchParam !== null) {
        const setDatePickerFocusTimeout: NodeJS.Timeout = setTimeout(() => {
          setIsDatePickerOutboundFocus(true);
          setIsDatePickerInboundFocus(false);
        }, 1500);

        return () => {
          clearTimeout(setDatePickerFocusTimeout);
        };
      }
    }, [openCalendarSearchParam]);

    useEffect(() => {
      if (!isInboundPlacePickerFocus && !isDatePickerInboundFocus) {
        setIsInboundDateInputClicked(false);
      }
    }, [isInboundPlacePickerFocus, isDatePickerInboundFocus]);

    useEffect(() => {
      setOutboundSearchQueryValidated(false);
      setOutboundSearchQueryHasError(false);

      setInboundSearchQueryValidated(false);
      setInboundSearchQueryHasError(false);

      setIsOutboundDateMillisecondsValidated(false);
      setOutboundDateMillisecondsHasError(false);

      setIsInboundDateMillisecondsValidated(false);
      setInboundDateMillisecondsHasError(false);
    }, [searchType]);

    useEffect(() => {
      if (
        (isDatePickerOutboundFocus ||
          isDatePickerInboundFocus ||
          isDatePickerOutboundChanged ||
          isDatePickerInboundChanged) &&
        selectedDate
      ) {
        const selectedDateMilliseconds = selectedDate.getTime();

        if (isDatePickerOutboundFocus || isDatePickerOutboundChanged) {
          onLegSearchFieldChange(
            legSearchIndex,
            'outboundDateMilliseconds',
            selectedDateMilliseconds,
          );

          setIsOutboundDateMillisecondsValidated(false);
          setOutboundDateMillisecondsHasError(false);
          setIsDatePickerOutboundFocus(false);

          if (searchType === 'roundTrip' && !isDatePickerOutboundChanged) {
            setIsDatePickerInboundFocus(true);
          }
        } else if (isDatePickerInboundFocus || isDatePickerInboundChanged) {
          onLegSearchFieldChange(
            legSearchIndex,
            'inboundDateMilliseconds',
            selectedDateMilliseconds,
          );
          setIsInboundDateMillisecondsValidated(false);
          setInboundDateMillisecondsHasError(false);
          setIsDatePickerOutboundFocus(false);
          setIsDatePickerInboundFocus(false);
        }

        setIsDatePickerOutboundChanged(false);
        setIsDatePickerInboundChanged(false);
        setSelectedDate(undefined);
      }
    }, [
      isDatePickerInboundChanged,
      isDatePickerInboundFocus,
      isDatePickerOutboundChanged,
      isDatePickerOutboundFocus,
      legSearchIndex,
      onLegSearchFieldChange,
      searchType,
      selectedDate,
    ]);

    // Set all picker focus to false if body is clicked
    useEffect(() => {
      if (
        isOutboundPlacePickerFocus ||
        isInboundPlacePickerFocus ||
        isDatePickerOutboundFocus ||
        isDatePickerInboundFocus
      ) {
        document.body.addEventListener('click', closeAllInputPickers);

        return () => {
          document.body.removeEventListener('click', closeAllInputPickers);
        };
      }
    }, [
      closeAllInputPickers,
      isOutboundPlacePickerFocus,
      isInboundPlacePickerFocus,
      isDatePickerOutboundFocus,
      isDatePickerInboundFocus,
    ]);

    useEffect(() => {
      document.body.tabIndex = 1;
      legSearchContainerRef.current?.focus();
    }, [legSearchContainerRef.current]);

    useEffect(() => {
      if (!isOutboundPlacePickerFocus) {
        if (legSearch.outboundPlace) {
          setOutboundSearchQuery(
            `${placeLabel(legSearch.outboundPlace)} (${legSearch.outboundPlace.cityCode})`,
          );
        } else {
          setOutboundSearchQuery('');
        }
      }
      setOutboundSearchQueryValidated(false);
      setOutboundSearchQueryHasError(false);
    }, [isOutboundPlacePickerFocus, legSearch.outboundPlace, placeLabel]);

    useEffect(() => {
      if (!isInboundPlacePickerFocus) {
        if (legSearch.inboundPlace) {
          setInboundSearchQuery(
            legSearch.inboundPlace === 'Anywhere'
              ? legSearch.inboundPlace
              : `${placeLabel(legSearch.inboundPlace)} (${legSearch.inboundPlace.cityCode})`,
          );
        } else {
          setInboundSearchQuery('');
        }
      }
      setInboundSearchQueryValidated(false);
      setInboundSearchQueryHasError(false);
    }, [isInboundPlacePickerFocus, legSearch.inboundPlace, placeLabel]);

    return (
      <>
        <div
          ref={legSearchContainerRef}
          tabIndex={1}
          className={styles.mockInitialFocusPoint}
        ></div>

        <div
          className={clsx(
            styles.container,
            searchType === 'roundTrip' && styles.roundTrip,
            searchType === 'oneWay' && styles.oneWay,
          )}
          data-testid='legSearchContainer'
          data-is-loading={`${
            isLoadingInboundPopularCities ||
            isLoadingOutboundPopularCities ||
            isLoadingPlacesBasedOnInbound ||
            isLoadingPlacesBasedOnOutbound ||
            isLoadingNearestAirportByGeolocation
          }`}
        >
          <div className={styles.placePickersContainer}>
            <div className={styles.placePickerContainer} data-testid='outboundPlacePickerContainer'>
              <InputSelector
                dataPw={`${
                  outboundSearchQueryPlaces.length === 0 &&
                  outboundPopularCities &&
                  outboundPopularCities.length > 0
                    ? 'locationPicker_popularCities'
                    : !!outboundSearchQuery && outboundSearchQueryPlaces.length > 0
                    ? 'locationPicker_placeSuggestions'
                    : ''
                }`}
                data-testid='outbound-search-input'
                className={styles.inputSelector}
                hasError={outboundSearchQueryHasError}
                id='outboundSearchQuery'
                isFlightProduct={true}
                isFocus={isOutboundPlacePickerFocus}
                listContainerId='outboundSearchQueryList'
                isValidated={isOutboundSearchQueryValidated}
                onChange={handleOutboundPlacePickerChange}
                onClear={handleOutboundPlacePickerClear}
                onFocus={handleOutboundPlacePickerFocus}
                onKeyDown={handleOutboundPlacePickerKeyDown}
                placeholder={translations.lbl_from as string}
                ref={outboundPlacePickerRef}
                value={outboundSearchQuery}
              >
                {/* Nearby and Popular Cities */}
                {!outboundSearchQuery ? (
                  <>
                    {nearestAirportByGeolocation ? (
                      <>
                        <div className={clsx(styles.category, styles.with8TopMargin)}>
                          {translations.nearby}
                        </div>

                        <div
                          className={clsx(
                            styles.place,
                            styles.with8TopMargin,
                            outboundSearchActiveItemIndex === 0 && styles.active,
                          )}
                          onClick={() =>
                            handleOutboundPlaceSelected(nearestAirportByGeolocation, 0)
                          }
                        >
                          {getPlaceDisplay(nearestAirportByGeolocation)}
                        </div>
                      </>
                    ) : null}

                    {outboundPopularCities && outboundPopularCities.length > 0 ? (
                      <>
                        <div className={clsx(styles.category, styles.with16TopMargin)}>
                          {translations.popular_cities}
                        </div>

                        <div className={styles.popularCities}>
                          {outboundPopularCities &&
                            outboundPopularCities.map((popularCity, index) => {
                              const realIndex = nearestAirportByGeolocation ? index + 1 : index;

                              return (
                                <div
                                  key={popularCity.code}
                                  data-testid='popular-city'
                                  className={clsx(
                                    styles.popularCity,
                                    outboundSearchActiveItemIndex === realIndex && styles.active,
                                  )}
                                  onClick={() =>
                                    handleOutboundPlaceSelected(popularCity, realIndex)
                                  }
                                >
                                  <div>{popularCity.cityName}</div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}

                {/* Place */}
                {!!outboundSearchQuery && outboundSearchQueryPlaces.length > 0 ? (
                  <>
                    {outboundSearchQueryPlaces.map((outboundSearchQueryPlace, index) => (
                      <div
                        data-pw='locationPicker_placeSuggestion'
                        key={index}
                        id={`outboundItem-${index}`}
                        className={clsx(
                          styles.place,
                          outboundSearchActiveItemIndex === index && styles.active,
                        )}
                        onClick={() => handleOutboundPlaceSelected(outboundSearchQueryPlace, index)}
                      >
                        {getPlaceDisplay(outboundSearchQueryPlace)}
                      </div>
                    ))}
                  </>
                ) : null}
              </InputSelector>
            </div>

            <button
              aria-label='Swap'
              className={clsx(isSwapped && styles.swap)}
              disabled={
                !legSearch.outboundPlace ||
                !legSearch.inboundPlace ||
                legSearch.inboundPlace === 'Anywhere'
              }
              type='button'
              onClick={handleSwapOutboundInboundPlace}
            >
              <SwapIcon />
            </button>

            <div className={styles.placePickerContainer} data-testid='inboundPlacePickerContainer'>
              <InputSelector
                dataPw={`${
                  inboundSearchQueryPlaces.length === 0 &&
                  inboundPopularCities &&
                  inboundPopularCities.length > 0
                    ? 'locationPicker_popularCities'
                    : !!inboundSearchQuery && inboundSearchQueryPlaces.length > 0
                    ? 'locationPicker_placeSuggestions'
                    : ''
                }`}
                className={clsx(styles.inputSelector, styles.inbound)}
                hasError={inboundSearchQueryHasError}
                id='inboundSearchQuery'
                isFlightInbound={true}
                isFlightProduct={true}
                isFocus={isInboundPlacePickerFocus}
                listContainerId='inboundSearchQueryList'
                isValidated={isInboundSearchQueryValidated}
                onChange={handleInboundPlacePickerChange}
                onClear={handleInboundPlacePickerClear}
                onFocus={handleInboundPlacePickerFocus}
                onKeyDown={handleInboundPlacePickerKeyDown}
                placeholder={translations.lbl_to as string}
                ref={inboundPlacePickerRef}
                value={inboundSearchQuery}
              >
                {/* Inspirational Shopping */}
                {showInspirationalShopping && (
                  <div
                    className={clsx(
                      styles.inspirationalShoppingWrapper,
                      inboundSearchActiveItemIndex === 0 && styles.active,
                    )}
                    onClick={() => handleInboundPlaceSelected('Anywhere')}
                  >
                    <WorldIcon />
                    <div className={styles.text}>
                      <div className={styles.header}>
                        Anywhere <span>New</span>
                      </div>
                      <div className={styles.subHeader}>Search for trips anywhere in the world</div>
                    </div>
                  </div>
                )}

                {/* Popular Cities */}
                {!inboundSearchQuery ? (
                  <>
                    {inboundPopularCities && inboundPopularCities.length > 0 ? (
                      <>
                        <div className={clsx(styles.category, styles.with16TopMargin)}>
                          {translations.popular_cities}
                        </div>

                        <div className={styles.popularCities}>
                          {inboundPopularCities &&
                            inboundPopularCities.map((popularCity, index) => {
                              const realIndex = showInspirationalShopping ? index + 1 : index;

                              return (
                                <div
                                  key={popularCity.code}
                                  data-testid='popular-city'
                                  className={clsx(
                                    styles.popularCity,
                                    inboundSearchActiveItemIndex === realIndex && styles.active,
                                  )}
                                  onClick={() => handleInboundPlaceSelected(popularCity, realIndex)}
                                >
                                  <div>{popularCity.cityName}</div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}

                {/* Place */}
                {!!inboundSearchQuery && inboundSearchQueryPlaces.length > 0 ? (
                  <>
                    {inboundSearchQueryPlaces.map((inboundSearchQueryPlace, index) => (
                      <div
                        data-pw='locationPicker_placeSuggestion'
                        key={index}
                        id={`inboundItem-${index}`}
                        className={clsx(
                          styles.place,
                          inboundSearchActiveItemIndex === index && styles.active,
                        )}
                        onClick={() => handleInboundPlaceSelected(inboundSearchQueryPlace, index)}
                      >
                        {getPlaceDisplay(inboundSearchQueryPlace)}
                      </div>
                    ))}
                  </>
                ) : null}
              </InputSelector>
            </div>
          </div>

          <div className={styles.datePickerContainer} data-testid='datePickerContainer'>
            <FlightDateSelector
              clientId={clientId}
              clientSessionId={clientSessionId}
              className={styles.flightDateSelector}
              fromHasError={outboundDateMillisecondsHasError}
              fromMillisecond={legSearch.outboundDateMilliseconds}
              fromValue={
                legSearch.outboundDateMilliseconds
                  ? dateDisplayFormat(
                      new Date(legSearch.outboundDateMilliseconds),
                      translations.short_months as Array<string>,
                      translations.short_weekdays as Array<string>,
                      locale === 'fa',
                      5,
                    )
                  : ''
              }
              isFromFocus={isDatePickerOutboundFocus}
              isFromValidated={isOutboundDateMillisecondsValidated}
              searchType={searchType}
              isToFocus={isDatePickerInboundFocus}
              isToValidated={isInboundDateMillisecondsValidated}
              limitFromMilliseconds={limitFromMilliseconds}
              limitTillMilliseconds={limitToMillisecondsMulticity}
              onDateChanged={handleDateChanged}
              onDateReset={onDateReset}
              onFromFocus={handleDatePickerOutboundFocus}
              onToFocus={handleDatePickerInboundFocus}
              onFromDateKeyDown={handleFromDateKeyDown}
              onToDateKeyDown={handleToDateKeyDown}
              onRemoveReturnDateClick={handleRemoveReturnDateClick}
              pageViewId={pageViewId}
              toHasError={inboundDateMillisecondsHasError}
              toMillisecond={legSearch.inboundDateMilliseconds}
              toValue={
                legSearch.inboundDateMilliseconds
                  ? dateDisplayFormat(
                      new Date(legSearch.inboundDateMilliseconds),
                      translations.short_months as Array<string>,
                      translations.short_weekdays as Array<string>,
                      locale === 'fa',
                      5,
                    )
                  : ''
              }
              isShowFareCalendar={isShowFareCalendar}
              isShowFareCalendarNote={isShowFareCalendarNote}
            >
              <DatePicker
                firstDayOfWeek={
                  /^(?:BD|BH|DZ|EG|JO|KW|OM|QA|SA|TN)$/i.test(currentSite.countryCode) ? 7 : 1
                }
                isRtl={isRtl}
                isToInputClicked={isInboundDateInputClicked}
                limitFromMilliseconds={limitFromMilliseconds}
                limitTillMilliseconds={limitToMilliseconds}
                locale={locale}
                onDateSelected={setSelectedDate}
                selectedFromDateMilliseconds={legSearch.outboundDateMilliseconds}
                selectedToDateMilliseconds={legSearch.inboundDateMilliseconds}
                translations={translations}
                isFlightDateSelector
                isLoadingFareCalendar={isLoadingFareCalendar}
                calendarFares={fareCalendar}
                passengerCount={passengerCount}
                activeOutboundDateMilliseconds={activeOutboundDateMilliseconds}
              />
            </FlightDateSelector>
          </div>

          {searchType === 'multiCity' && !!onRemoveLegSearchClick ? (
            <button
              aria-label='Remove'
              data-pw='multicitySearch_removeLegBtn'
              type='button'
              disabled={isRemoveLegSearchDisabled}
              onClick={() => onRemoveLegSearchClick(legSearchIndex)}
            >
              <ClearIcon className={styles.icon} />
            </button>
          ) : null}
        </div>
      </>
    );
  },
);

LegSearch.displayName = 'LegSearch';

export default LegSearch;

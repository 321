export function notUndefinedAndNull<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function convertListToHashMap<T>(list: Array<T>, key: string): { [key: string]: T } {
  return list.reduce((previousMap: any, item: any) => {
    previousMap[item[key]] = item;
    return previousMap;
  }, {});
}

export function uniqBy<T>(list: Array<T>, key: string): Array<T> {
  return list.reduce((accumulator: Array<any>, currentValue: any) => {
    if (!accumulator.some((accumulatorValue: any) => currentValue[key] === accumulatorValue[key])) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);
}

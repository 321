import React, { useState, useEffect } from 'react';

export function useBodyScrollable(): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [isBodyScrollable, setIsBodyScrollable] = useState<boolean>(true);

  useEffect(() => {
    // const handleTouchmove = (ev: Event): void => {
    //   if (ev.cancelable) {
    //     ev.preventDefault();
    //   }
    // };

    if (!isBodyScrollable) {
      document.body.style.overflow = 'hidden';
      // window.addEventListener('touchmove', handleTouchmove, { passive: false });
    } else {
      document.body.style.overflow = '';
      // window.removeEventListener('touchmove', handleTouchmove);
    }

    return () => {
      document.body.style.overflow = '';
      // window.removeEventListener('touchmove', handleTouchmove);
    };
  }, [isBodyScrollable]);

  return [isBodyScrollable, setIsBodyScrollable];
}

import React from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import styles from '../styles/components/Switch.module.scss';

interface SwitchProps {
  checked: boolean;
  className?: string;
  isRtl: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Switch: React.FC<SwitchProps> = ({ checked, className, style, isRtl, onClick }) => {
  return (
    <div
      data-checked={checked}
      role='checkbox'
      className={clsx(
        styles.container,
        checked && styles.checked,
        isRtl && styles.isRtl,
        className,
      )}
      style={style}
      onClick={onClick}
    >
      <div className={styles.inner} />
    </div>
  );
};

export default Switch;

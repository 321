import React, { ReactElement, ReactNode } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import Button from '@components/Button';
import {
  ButtonBorderWidth,
  ButtonColorScheme,
  ButtonSize,
  ButtonVariant,
} from '@components/Button/type';

import styles from './ActionPopup.module.scss';

type ButtonProps = {
  label: string | ReactNode;
  onClick: () => void;
  variant?: ButtonVariant;
  colorScheme?: ButtonColorScheme;
  isDisabled?: boolean;
  borderWidth?: ButtonBorderWidth;
  size?: ButtonSize;
};

type ActionPopup = {
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactNode;
  description: ReactElement;
  primaryButton: ButtonProps;
  secondaryButton: Omit<ButtonProps, 'isLoading'>;
  isSubmitting?: boolean;
};

const ActionPopup: React.FC<ActionPopup> = ({
  isOpen,
  onClose,
  title,
  description,
  primaryButton,
  secondaryButton,
  isSubmitting,
}) => {
  return (
    <div data-testid='action-popup' className={clsx(styles.container, isOpen && styles.open)}>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={clsx(styles.wrapper)}>
        <div>
          <h4 className={styles.title}>{title}</h4>
          <div className={styles.description}>{description}</div>

          <div className={styles.buttonContainer}>
            <Button
              size={secondaryButton.size || 'lg'}
              variant={secondaryButton.variant || 'outline'}
              colorScheme={secondaryButton.colorScheme || 'grey'}
              borderWidth={secondaryButton.borderWidth || 'thin'}
              onClick={secondaryButton.onClick}
              disabled={isSubmitting || secondaryButton.isDisabled}
            >
              {secondaryButton.label}
            </Button>
            <Button
              size={primaryButton.size || 'lg'}
              variant={primaryButton.variant || 'solid'}
              colorScheme={primaryButton.colorScheme || 'green'}
              borderWidth={secondaryButton.borderWidth || 'thin'}
              onClick={primaryButton.onClick}
              disabled={isSubmitting || primaryButton.isDisabled}
              isLoading={isSubmitting}
            >
              {primaryButton.label}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPopup;

import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  convertLegParamToLegs,
  isMulticity as isMulticityFunc,
  isRoundTrip as isRoundTripFunc,
} from '@helpers/flight';

import { CabinClass } from '@wegoTypes/flights/cabinClass';

const useFlightSearchPageParams = () => {
  const [searchParams] = useSearchParams();

  const params = useParams<{
    leg?: string;
    cabinClass?: string;
    adultCount?: string;
    childCount?: string;
    infantCount?: string;
  }>();

  const paymentMethodsSearchParam = searchParams.get('payment_methods');

  const paymentMethods = useMemo(
    () => paymentMethodsSearchParam?.split(',').map((v) => parseInt(v)) ?? [],
    [paymentMethodsSearchParam],
  );

  const legs = useMemo<Array<Leg>>(() => {
    if (params.leg) {
      try {
        return convertLegParamToLegs(params.leg);
      } catch (_) {
        return [];
      }
    }
    return [];
  }, [params.leg]);

  const cabinClass =
    !!params.cabinClass && /^(?:economy|premium_economy|business|first)$/i.test(params.cabinClass)
      ? (params.cabinClass.toLowerCase() as CabinClass)
      : undefined;

  const adultCount = params.adultCount ? parseInt(params.adultCount.slice(0, -1)) : 1;

  const childCount = params.childCount ? parseInt(params.childCount.slice(0, -1)) : 0;

  const infantCount = params.infantCount ? parseInt(params.infantCount.slice(0, -1)) : 0;

  const passengerCount = adultCount + childCount + infantCount;

  const isMultiCity = isMulticityFunc(legs);

  const isRoundTrip = isRoundTripFunc(legs);

  return {
    legs,
    cabinClass,
    adultCount,
    childCount,
    infantCount,
    passengerCount,
    paymentMethods,
    isMultiCity,
    isRoundTrip,
  };
};

export default useFlightSearchPageParams;

export enum HotelSortType {
  Recommended = 'RECOMMENDED',
  Price = 'PRICE',
  Discount = 'DISCOUNT',
  Saving = 'SAVING',
  AllReviewScore = 'ALL_REVIEW_SCORE',
  SoloReviewScore = 'SOLO_REVIEW_SCORE',
  BusinessReviewScore = 'BUSINESS_REVIEW_SCORE',
  FamilyReviewScore = 'FAMILY_REVIEW_SCORE',
  CoupleReviewScore = 'COUPLE_REVIEW_SCORE',
  NearestToCityCentre = 'NEAREST_TO_CITY_CENTRE',
  NearestToAirport = 'NEAREST_TO_AIRPORT',
  NearestToYou = 'NEAREST_TO_YOU',
  Star = 'STAR',
  NearestToTargetLocation = 'NEAREST_TO_TARGET_LOCATION',
}

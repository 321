import { addCookie } from '@alexis/helpers/cookie';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { translateText } from '@wego/alexis/helpers/translation';

import FlightContextProvider from '@context/FlightContext';

import type { Translations } from '@wegoTypes/translations';

import { flightsRouteTranslation } from '../helpers/routeTranslation';

interface FlightsPathRouteProps {
  flightsParam: string;
  locale: string;
  localeParam: string | undefined;
  translations: Translations;
}

const FlightsPathRoute: React.FC<FlightsPathRouteProps> = ({
  flightsParam,
  locale,
  localeParam,
  translations,
}): JSX.Element => {
  const { pathname, search, state } = useLocation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const wegoCampaign = searchParams.get('wg_internal_campaign');

  // set wego flight campaign value in cookie
  useEffect(() => {
    if (!!wegoCampaign) {
      // expires after 30 mins
      const T30_MINS = 30 * 60 * 1000;
      addCookie('wg_internal_campaign', wegoCampaign, new Date().getTime() + T30_MINS);
    }
  }, [wegoCampaign]);

  // Navigate to the corrent flights route translation
  useEffect(() => {
    if (!!localeParam) {
      const flightsRouteTranslation$ = flightsRouteTranslation(localeParam);

      if (flightsRouteTranslation$ !== flightsParam) {
        navigate(
          { pathname: pathname.replace(flightsParam, flightsRouteTranslation$), search },
          { replace: true, state },
        );
      }
    }
  }, [localeParam]);

  const getHostNameWithoutWWW = useCallback<(hostname: string) => string>((hostname: string) => {
    const hostNameWithoutWWW = hostname.replace('www.', '');

    return hostNameWithoutWWW.replace(/^./, hostNameWithoutWWW[0].toUpperCase());
  }, []);

  const title = useMemo<string>(() => {
    return translateText(
      translations.flight_title,
      locale,
      getHostNameWithoutWWW(window.location.hostname),
    );
  }, [locale, translations, getHostNameWithoutWWW]);

  const metaDescription = useMemo<string>(() => {
    return translateText(
      translations.flight_des,
      locale,
      getHostNameWithoutWWW(window.location.hostname),
    );
  }, [locale, translations, getHostNameWithoutWWW]);

  return (
    <FlightContextProvider>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={metaDescription} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname}`} />
      </Helmet>

      <Outlet />
    </FlightContextProvider>
  );
};

export default FlightsPathRoute;

import { useSelector } from 'react-redux';

import { getLocationDetails } from '@apis/places';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getLocaleState } from '@redux/selectors';

export const getLocationDetailsKey = 'location-details';

type GetLocationDetailsParams = {
  locationCodes: string[];
};

export const useGetLocationDetails = <TData = Place[]>(
  params: GetLocationDetailsParams,
  options?: UseAxiosQueryOptions<Place[], TData>,
) => {
  const locale = useSelector(getLocaleState);

  const query = useAxiosQuery({
    queryKey: [getLocationDetailsKey, locale, params],
    queryFn: () => getLocationDetails(locale, params.locationCodes),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    ...options,
  });

  return query;
};

import { useSelector } from 'react-redux';

import { getWegoIdToken } from '@apis/authentication';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getDomainBasedApiBaseUrlState } from '@redux/selectors';

const getWegoIdTokenKey = 'wego-id-token';

export const useGetWegoIdToken = <TResult = string>(
  options?: UseAxiosQueryOptions<string, TResult>,
) => {
  const domainBasedApiBaseUrl = useSelector(getDomainBasedApiBaseUrlState);

  return useAxiosQuery({
    queryKey: [getWegoIdTokenKey],
    queryFn: () => getWegoIdToken(domainBasedApiBaseUrl),
    cacheTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
  });
};

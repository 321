import { dateApiFormat } from '@alexis/helpers/date';
import type { DatePickerProps } from 'props/datePickerProps';
import React, { useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateNumber } from '@wego/alexis/helpers/translation';

import Calendar from '@components/Calendar';
import FareCalendar from '@components/FareCalendar';

import {
  FARE_CALENDAR_CORE_CONFIG_KEY,
  SHOW_FARE_CALENDAR_6_MONTHS_PARAM,
  SHOW_FARE_CALENDAR_PARAM,
  TEST_FEATURES_PARAM,
} from '@constants/flight';

import { GlobalContext } from '@context/GlobalContext';

import { useGetPublicHolidays } from '@hooks/api/places/useGetPublicHolidays';

import { FareCalendarResponseDateRange } from '@wegoTypes/flights/metasearch/calendar';

import styles from './DatePicker.module.scss';

const DatePicker: React.FC<DatePickerProps> = ({
  className,
  firstDayOfWeek,
  isRtl,
  isToInputClicked,
  limitFromMilliseconds,
  limitTillMilliseconds,
  locale,
  onDateSelected,
  selectedFromDateMilliseconds,
  selectedToDateMilliseconds,
  translations,
  calendarFares,
  isFlightDateSelector,
  isLoadingFareCalendar,
  passengerCount,
  activeOutboundDateMilliseconds,
}): JSX.Element => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const currentMonthCalendar: { month: number; year: number } = {
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  };

  const [searchParams] = useSearchParams();

  const testFeatures = searchParams.get(TEST_FEATURES_PARAM)?.split(',') || [];

  const isShowFareCalendarParam = testFeatures.includes(SHOW_FARE_CALENDAR_PARAM);

  const isFareCalendar6MonthsParam = testFeatures.includes(SHOW_FARE_CALENDAR_6_MONTHS_PARAM);

  const [selectedCalendarIndex, setSelectedCalenderIndex] = useState(0);

  const { userCentricCoreConfigs } = useContext(GlobalContext);

  const isShowFareCalendarCoreConfigValue = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === FARE_CALENDAR_CORE_CONFIG_KEY,
  )?.value;

  const isShowFareCalendar =
    isFlightDateSelector &&
    (isShowFareCalendarCoreConfigValue === '1' ||
      isShowFareCalendarCoreConfigValue === '2' ||
      isShowFareCalendarParam);

  const isShowFareCalendarVariantA =
    isShowFareCalendarCoreConfigValue === '1' || isShowFareCalendarParam;

  // Add this function to calculate startingCalendarMonth
  function calculateStartingCalendarMonth(): { month: number; year: number } {
    if (activeOutboundDateMilliseconds) {
      const availableFromDate = new Date(activeOutboundDateMilliseconds);
      return {
        month: availableFromDate.getMonth() + 1,
        year: availableFromDate.getFullYear(),
      };
    } else if (isToInputClicked && selectedToDateMilliseconds) {
      const availableFromDate = new Date(selectedToDateMilliseconds);
      return {
        month: availableFromDate.getMonth() + 1,
        year: availableFromDate.getFullYear(),
      };
    } else if (selectedFromDateMilliseconds) {
      const availableFromDate = new Date(selectedFromDateMilliseconds);
      return {
        month: availableFromDate.getMonth() + 1,
        year: availableFromDate.getFullYear(),
      };
    } else if (limitFromMilliseconds) {
      const availableFromDate = new Date(limitFromMilliseconds);
      return {
        month: availableFromDate.getMonth() + 1,
        year: availableFromDate.getFullYear(),
      };
    }
    return currentMonthCalendar;
  }

  // Use the calculated value
  const startingCalendarMonth = calculateStartingCalendarMonth();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  // Add these calculated values
  const firstCalendarDisplayMonth = calculateDisplayMonth(0);
  const secondCalendarDisplayMonth = calculateDisplayMonth(1);

  // Add this function to calculate display months
  function calculateDisplayMonth(offset: number): { month: number; year: number } {
    const displayDate = new Date(startingCalendarMonth.year, startingCalendarMonth.month - 1);
    displayDate.setMonth(displayDate.getMonth() + selectedIndex + offset - selectedCalendarIndex);

    return {
      month: displayDate.getMonth() + 1,
      year: displayDate.getFullYear(),
    };
  }

  const fromDate = new Date(firstCalendarDisplayMonth.year, firstCalendarDisplayMonth.month - 1);
  const toDate = new Date(secondCalendarDisplayMonth.year, secondCalendarDisplayMonth.month - 1);
  toDate.setMonth(toDate.getMonth() + 1);
  toDate.setDate(0);

  const nextFromDate = new Date(firstCalendarDisplayMonth.year, firstCalendarDisplayMonth.month);
  const nextToDate = new Date(secondCalendarDisplayMonth.year, secondCalendarDisplayMonth.month);
  nextToDate.setMonth(nextToDate.getMonth() + 1);
  nextToDate.setDate(0);

  // Get public holidays
  const { data: publicHolidays } = useGetPublicHolidays(
    {
      fromDate,
      toDate,
    },
    {
      staleTime: 30 * 60 * 1000, // 30 minutes
      cacheTime: 30 * 60 * 1000,
      select: (data) => data.publicHolidays,
    },
  );

  // Get public holidays for next 2 months from current month
  useGetPublicHolidays(
    { fromDate: nextFromDate, toDate: nextToDate },
    {
      staleTime: 30 * 60 * 1000, // 30 minutes
      cacheTime: 30 * 60 * 1000,
      notifyOnChangeProps: [],
      select: (data) => data.publicHolidays,
    },
  );

  const publicHolidayMap = useMemo(
    () =>
      publicHolidays
        ?.map((publicHoliday) => {
          const publicHolidayMap = { [publicHoliday.startDate]: publicHoliday.name };

          if (publicHoliday.endDate !== publicHoliday.startDate) {
            const [fromYear, fromMonth, fromDay] = publicHoliday.startDate
              .split('-')
              .map((value) => parseInt(value));
            const [toYear, toMonth, toDay] = publicHoliday.endDate
              .split('-')
              .map((value) => parseInt(value));

            const to = new Date(toYear, toMonth - 1, toDay);

            let date = new Date(fromYear, fromMonth - 1, fromDay + 1);
            do {
              publicHolidayMap[dateApiFormat(date)] = publicHoliday.name;
              date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
            } while (date < to);
            publicHolidayMap[publicHoliday.endDate] = publicHoliday.name;
          }
          return publicHolidayMap;
        })
        .reduce<PublicHolidayMap>(
          (accumulator, currentValue) => Object.assign({}, accumulator, currentValue),
          {},
        ),
    [publicHolidays],
  );

  const { cheapThreshold, expensiveThreshold } = useMemo(() => {
    let dateRange: FareCalendarResponseDateRange | undefined;

    if (isFareCalendar6MonthsParam) {
      dateRange = calendarFares?.dateRanges ? calendarFares?.dateRanges[0] : undefined;
    } else {
      dateRange = (calendarFares?.dateRanges || []).find((dateRange) => {
        const startDateMonth = new Date(dateRange.startDate).getMonth() + 1;

        return startDateMonth === firstCalendarDisplayMonth.month;
      });

      if (!dateRange) {
        dateRange = (calendarFares?.dateRanges || []).find((dateRange) => {
          const endDateMonth = new Date(dateRange.endDate).getMonth() + 1;

          return endDateMonth === firstCalendarDisplayMonth.month;
        });
      }
    }

    return {
      cheapThreshold: dateRange?.cheapThreshold,
      expensiveThreshold: dateRange?.expensiveThreshold,
    };
  }, [calendarFares, firstCalendarDisplayMonth]);

  function handleChevronClick(isNext: boolean): void {
    if (isNext) {
      if (!isDisabled(isNext, secondCalendarDisplayMonth)) {
        setSelectedIndex(selectedIndex + 1);
      }
    } else {
      if (!isDisabled(isNext, firstCalendarDisplayMonth)) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
  }

  function isDisabled(isNext: boolean, selectedCalendar: { month: number; year: number }): boolean {
    if (isNext) {
      if (limitTillMilliseconds !== undefined) {
        const lastDayOfLimitTillMonth = new Date(limitTillMilliseconds);
        lastDayOfLimitTillMonth.setHours(0, 0, 0, 0);
        lastDayOfLimitTillMonth.setMonth(lastDayOfLimitTillMonth.getMonth() + 1);
        lastDayOfLimitTillMonth.setDate(0);

        const lastDayOfSelectedCalendar = new Date(
          selectedCalendar.year,
          selectedCalendar.month - 1,
        );
        lastDayOfSelectedCalendar.setMonth(lastDayOfSelectedCalendar.getMonth() + 1);
        lastDayOfSelectedCalendar.setDate(0);

        return lastDayOfLimitTillMonth.getTime() === lastDayOfSelectedCalendar.getTime();
      }
      return false;
    } else {
      const firstDayOfSelectedCalendar = new Date(
        selectedCalendar.year,
        selectedCalendar.month - 1,
      );

      if (limitFromMilliseconds !== undefined) {
        const firstDayOfLimitFromMonth = new Date(limitFromMilliseconds);
        firstDayOfLimitFromMonth.setHours(0, 0, 0, 0);
        firstDayOfLimitFromMonth.setDate(1);

        return firstDayOfLimitFromMonth.getTime() === firstDayOfSelectedCalendar.getTime();
      }

      const firstDayOfTodayMonth = new Date(
        currentMonthCalendar.year,
        currentMonthCalendar.month - 1,
      );

      return firstDayOfTodayMonth.getTime() === firstDayOfSelectedCalendar.getTime();
    }
  }

  function publicHolidayDateLabel(
    publicHoliday: PlacesHoliday,
    locale: string,
    shortMonthLabels: Array<string> | undefined,
  ): string {
    if (!shortMonthLabels) return '';

    const publicHolidayDateLabels = [];
    const startDate = new Date(publicHoliday.startDate);

    if (/zh\-(cn|tw)|ja/.test(locale)) {
      publicHolidayDateLabels.push(
        `${shortMonthLabels[startDate.getMonth()]} ${translateNumber(startDate.getDate())}日`,
      );
    } else {
      publicHolidayDateLabels.push(
        `${translateNumber(startDate.getDate(), locale === 'fa')} ${
          shortMonthLabels[startDate.getMonth()]
        }`,
      );
    }

    if (publicHoliday.endDate !== publicHoliday.startDate) {
      const endDate = new Date(publicHoliday.endDate);

      if (/zh\-(cn|tw)|ja/.test(locale)) {
        publicHolidayDateLabels.push(
          `${shortMonthLabels[endDate.getMonth()]} ${translateNumber(endDate.getDate())}日`,
        );
      } else {
        publicHolidayDateLabels.push(
          `${translateNumber(endDate.getDate(), locale === 'fa')} ${
            shortMonthLabels[endDate.getMonth()]
          }`,
        );
      }
    }

    return publicHolidayDateLabels.join(' - ');
  }

  const handleDateSelected = (date: Date, calenderIndex: number): void => {
    setSelectedCalenderIndex(calenderIndex);
    setSelectedIndex(0);
    onDateSelected(date);
  };

  return (
    <div
      className={clsx(
        styles.container,
        isShowFareCalendar && styles.fareCalendarContainer,
        className,
        isRtl && styles.rtl,
      )}
    >
      <div
        data-pw={`${isRtl ? 'datePicker_nextMonthBtn' : 'datePicker_previousMonthBtn'}`}
        data-testid={`${isRtl ? 'next-month-button' : 'previous-month-button'}`}
        className={clsx(styles.chevronContainer, styles.left)}
        onClick={() => handleChevronClick(isRtl)}
      >
        <div
          className={clsx(
            styles.chevron,
            styles.left,
            isDisabled(isRtl, isRtl ? secondCalendarDisplayMonth : firstCalendarDisplayMonth) &&
              styles.disabled,
          )}
        ></div>
      </div>

      <div
        data-pw={`${isRtl ? 'datePicker_previousMonthBtn' : 'datePicker_nextMonthBtn'}`}
        data-testid={`${isRtl ? 'previous-month-button' : 'next-month-button'}`}
        className={clsx(styles.chevronContainer, styles.right)}
        onClick={() => handleChevronClick(!isRtl)}
      >
        <div
          className={clsx(
            styles.chevron,
            styles.right,
            isDisabled(!isRtl, isRtl ? firstCalendarDisplayMonth : secondCalendarDisplayMonth) &&
              styles.disabled,
          )}
        ></div>
      </div>

      <div className={styles.calendars}>
        {isShowFareCalendar ? (
          <>
            <FareCalendar
              className={styles.fareCalendar}
              firstDayOfWeek={firstDayOfWeek}
              limitFromMilliseconds={limitFromMilliseconds}
              limitTillMilliseconds={limitTillMilliseconds}
              month={firstCalendarDisplayMonth.month}
              onDateSelected={(date) => handleDateSelected(date, 0)}
              publicHolidayMap={publicHolidayMap}
              selectedFromDateMilliseconds={selectedFromDateMilliseconds}
              selectedToDateMilliseconds={selectedToDateMilliseconds}
              year={firstCalendarDisplayMonth.year}
              calendarFares={calendarFares?.prices}
              isLoadingFares={!!isLoadingFareCalendar}
              isShowFareCalendarVariantA={isShowFareCalendarVariantA}
              cheapThreshold={cheapThreshold}
              expensiveThreshold={expensiveThreshold}
              passengerCount={passengerCount || 1}
              activeOutboundDateMilliseconds={activeOutboundDateMilliseconds}
            />

            <FareCalendar
              className={styles.fareCalendar}
              firstDayOfWeek={firstDayOfWeek}
              limitFromMilliseconds={limitFromMilliseconds}
              limitTillMilliseconds={limitTillMilliseconds}
              month={secondCalendarDisplayMonth.month}
              onDateSelected={(date) => handleDateSelected(date, 1)}
              publicHolidayMap={publicHolidayMap}
              selectedFromDateMilliseconds={selectedFromDateMilliseconds}
              selectedToDateMilliseconds={selectedToDateMilliseconds}
              year={secondCalendarDisplayMonth.year}
              calendarFares={calendarFares?.prices}
              isLoadingFares={!!isLoadingFareCalendar}
              isShowFareCalendarVariantA={isShowFareCalendarVariantA}
              cheapThreshold={cheapThreshold}
              expensiveThreshold={expensiveThreshold}
              passengerCount={passengerCount || 1}
              activeOutboundDateMilliseconds={activeOutboundDateMilliseconds}
            />
          </>
        ) : (
          <>
            <Calendar
              className={styles.calendar}
              dayLabels={translations.mini_weekdays as Array<string>}
              firstDayOfWeek={firstDayOfWeek}
              limitFromMilliseconds={limitFromMilliseconds}
              limitTillMilliseconds={limitTillMilliseconds}
              locale={locale}
              month={firstCalendarDisplayMonth.month}
              monthLabels={translations.long_months as Array<string>}
              onDateSelected={(date) => handleDateSelected(date, 0)}
              publicHolidayMap={publicHolidayMap}
              selectedFromDateMilliseconds={selectedFromDateMilliseconds}
              selectedToDateMilliseconds={selectedToDateMilliseconds}
              year={firstCalendarDisplayMonth.year}
              activeOutboundDateMilliseconds={activeOutboundDateMilliseconds}
            />

            <Calendar
              className={styles.calendar}
              dayLabels={translations.mini_weekdays as Array<string>}
              firstDayOfWeek={firstDayOfWeek}
              limitFromMilliseconds={limitFromMilliseconds}
              limitTillMilliseconds={limitTillMilliseconds}
              locale={locale}
              month={secondCalendarDisplayMonth.month}
              monthLabels={translations.long_months as Array<string>}
              onDateSelected={(date) => handleDateSelected(date, 1)}
              publicHolidayMap={publicHolidayMap}
              selectedFromDateMilliseconds={selectedFromDateMilliseconds}
              selectedToDateMilliseconds={selectedToDateMilliseconds}
              year={secondCalendarDisplayMonth.year}
              activeOutboundDateMilliseconds={activeOutboundDateMilliseconds}
            />
          </>
        )}
      </div>

      {publicHolidays && publicHolidays.length > 0 ? (
        <>
          <div className={styles.label}>
            <span className={styles.asterisk}>*</span>
            {translations.public_holidays}
          </div>

          <div data-pw='datePicker_publicHolidaysNote' className={clsx(styles.publicHolidays)}>
            {publicHolidays.map((publicHoliday) => (
              <div key={publicHoliday.key} className={styles.publicHoliday}>
                <div className={styles.date}>
                  {publicHolidayDateLabel(
                    publicHoliday,
                    locale,
                    translations.short_months as Array<string>,
                  )}
                </div>
                {publicHoliday.name}
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DatePicker;

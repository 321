import { useCallback, useState, useEffect } from 'react';

const eventListenerOptions = {
  passive: true,
};

export function useViewportSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  const setSize = useCallback(() => {
    setWindowSize({ width: window.innerWidth || 0, height: window.innerHeight || 0 });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setSize, eventListenerOptions);

    return () => window.removeEventListener('resize', setSize);
  }, [setSize]);

  useEffect(() => {
    window.addEventListener('orientationchange', setSize, eventListenerOptions);

    return () => window.removeEventListener('orientationchange', setSize);
  }, [setSize]);

  useEffect(setSize, [setSize]);

  return windowSize;
}

export const STAGING_TS_CODES: Record<string, string> = {
  '40de3': 'Google',
  '584b0': 'Skyscanner',
  c1ac9: 'Kayak',
  '23548': 'Tripadvisor',
  '2cd1b': 'Vio.com',
  '6c508': 'Trivago',
};
export const PROD_TS_CODES: Record<string, string> = {
  '2f2fc': 'Google',
  '6be92': 'Skyscanner',
  '9b77c': 'Kayak',
  c7c57: 'Tripadvisor',
  '27a46': 'Vio.com',
  '6a97e': 'Trivago',
};

import { useSelector } from 'react-redux';

import { getFlightsAutocomplete } from '@apis/places';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

export const getFlightsAutocompleteKey = 'get-flights-autocomplete';

type GetFlightsAutocompleteParams = {
  searchFor: 'origin' | 'destination';
  query: string;
  minimumAirportCount: number;
};

export const useGetFlightsAutocomplete = (
  params: GetFlightsAutocompleteParams,
  options?: UseAxiosQueryOptions<Array<Place>>,
) => {
  const locale = useSelector(getLocaleState);
  const currentSite = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [getFlightsAutocompleteKey, { locale, code: currentSite.countryCode, ...params }],
    queryFn: () =>
      getFlightsAutocomplete(
        locale,
        currentSite.countryCode,
        params.searchFor,
        params.query,
        params.minimumAirportCount,
      ),
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    ...options,
  });
};

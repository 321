import { clsx } from '@alexis/helpers/clsx';
import { getCookie } from '@alexis/helpers/cookie';
import { toISOStringWithTimezone } from '@alexis/helpers/date';
import {
  genzoTrack,
  genzoTrackActionEvent,
  genzoTrackCarouselClick,
  genzoTrackPageView,
} from '@alexis/helpers/genzo';
import { gtmTrack } from '@alexis/helpers/gtm';
import { generateCUID } from '@alexis/helpers/identity';
import { localStorageGet } from '@alexis/helpers/localStorage';
import { isSiteCodePartOfMENA } from '@alexis/helpers/site';
import sha256 from 'crypto-js/sha256';
import cuid from 'cuid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { translateText } from '@wego/alexis/helpers/translation';
import type { User } from '@wego/alexis/types/helpers/user';

import BlogsSection from '@components/Blogs/BlogsSection';
import Carousel from '@components/Carousel';
import PopularAirlines from '@components/home/PopularAirlines';
import PopularBrands from '@components/home/PopularBrands';
import TravelPartners from '@components/home/TravelPartners';

import { INSPIRATIONAL_SHOPPING_CORE_CONFIGS_KEY } from '@constants/flight';
import { GTM_ID } from '@constants/general';
import { HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY } from '@constants/localStorage';

import { GlobalContext } from '@context/GlobalContext';

import { uniqBy } from '@helpers/array';
import { isGoogleBot } from '@helpers/bots';
import {
  isWinterSeasonCampaignActive,
  isSpringSeasonCampaignActive,
  isRamadanCampaignActive,
} from '@helpers/campaign';
import { getPrependedCountryName } from '@helpers/country';
import { constructGreatEscapeLink, isFlightProduct } from '@helpers/flight';
import { isHotelProduct } from '@helpers/hotel';
import {
  cheapFlightsToRouteTranslation,
  flightsToRoute,
  hotelsRoute,
} from '@helpers/routeTranslation';

import { useGetTravelThemes } from '@hooks/api/places/useGetTravelThemes';
import { useGetMarketingPartnerPromo } from '@hooks/api/users/useGetMarketingPartnerPromo';
import useLocaleParam from '@hooks/useLocaleParam';
import { useViewportSize } from '@hooks/useViewportSize';

import { changeHomePageViewId } from '@redux/actions/pageViewIdActions';
import {
  getCurrencyState,
  getCurrentSiteState,
  getExchangeRateState,
  getIsRtlState,
  getLocaleState,
} from '@redux/selectors';

import { HotelSearch } from '@wegoTypes/hotels/hotelSearch';
import { MarketingPartnerPromoGenzoData } from '@wegoTypes/marketing/marketingPatnerPromo';
import type { Translations } from '@wegoTypes/translations';

import styles from './Home.module.scss';
import HotelTopCities from './HotelTopCities';
import InstallBanner from './InstallBanner';
import NtoBannerAd from './NtoBannerAd';
import PopularHotels from './PopularHotels';
import Propositions from './Propositions';
import RecommendedHotels from './RecommendedHotels';
import SearchForm from './SearchForm/SearchForm';
import TripIdeas from './TripIdeas';

interface HomeProps {
  availablePaymentMethods: Array<HomepageFlightsPaymentMethod>;
  campaignLogoImageUrl: string | undefined;
  geolocationCoordinates: GeolocationCoordinates | undefined;
  gtmAuth: string;
  gtmPreview: string;
  heroImageUrl: string;
  homepage: Homepage | undefined;
  nearestCityByIP: Place | undefined;
  translations: Translations;
  user: User | null;
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
}

const Home: React.FC<HomeProps> = ({
  availablePaymentMethods,
  campaignLogoImageUrl,
  geolocationCoordinates,
  gtmAuth,
  gtmPreview,
  heroImageUrl,
  homepage,
  nearestCityByIP,
  translations,
  user,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
}) => {
  const locale = useSelector(getLocaleState);
  const currentSite = useSelector(getCurrentSiteState);
  const currency = useSelector(getCurrencyState);
  const exchangeRate = useSelector(getExchangeRateState);
  const isRtl = useSelector(getIsRtlState);
  const { width } = useViewportSize();

  const { webEngageAnonymousId, userCentricCoreConfigs } = useContext(GlobalContext);
  const countryName = getPrependedCountryName({
    code: currentSite.countryCode,
    locale,
    name: translations[`pos_${currentSite.countryCode}`.toLowerCase()] as string,
  });

  const [selectedCampaignCarouselIndex, setSelectedCampaignCarouselIndex] = useState<number>(1);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<number[]>([]);

  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const wegoSourceSearchParam = searchParams.get('wg_source');
  const wegoMediumSearchParam = searchParams.get('wg_medium');
  const wgCampaign = getCookie('wg_campaign') || searchParams.get('wg_campaign');
  const tsCodeSearchParam = searchParams.get('ts_code');
  const wgInternalCampaign = searchParams.get('wg_internal_campaign');
  const bowOnlyParam = searchParams.get('bow_only');
  const hasBowOnlyParamAsTrue = bowOnlyParam === 'true';

  const inspirationalShoppingCoreConfig = useMemo(
    () =>
      userCentricCoreConfigs.find(
        (coreConfig) => coreConfig.key === INSPIRATIONAL_SHOPPING_CORE_CONFIGS_KEY,
      ),
    [userCentricCoreConfigs],
  );

  const isVariantShowingInspirationalShopping =
    inspirationalShoppingCoreConfig?.value === '1' ||
    inspirationalShoppingCoreConfig?.value === '2';

  const isInspirationalShoppingVariantA = inspirationalShoppingCoreConfig?.value === '1';

  const inspirationalShoppingLogoName = isInspirationalShoppingVariantA
    ? 'click-to-find'
    : 'do-you-know';

  // Get locale param
  const localeParam = useLocaleParam();

  // Get product param
  const pageType = useMemo<'home' | 'flights' | 'hotels' | 'trains'>(() => {
    const productParam = pathname.split('/')[!!localeParam ? 2 : 1];

    if (isFlightProduct(productParam)) {
      return 'flights';
    } else if (isHotelProduct(productParam)) {
      return 'hotels';
    }
    return 'home';
  }, [pathname, localeParam]);

  const pageViewId = useMemo<string>(() => {
    return generateCUID();
  }, [pageType]);

  const hotelRecentSearch = useMemo<HotelSearch | null>(() => {
    return localStorageGet<HotelSearch>(HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY);
  }, []);

  const hotelsInTopCities = useMemo<Array<HomepageCity>>(() => {
    if (!!homepage) {
      return [...homepage.hotels.domesticCities, ...homepage.hotels.cities].slice(0, 10);
    }
    return [];
  }, [homepage]);

  const flightsToTopCities = useMemo<Array<HomepageCity>>(() => {
    if (!!homepage) {
      return [...homepage.flights.domesticCities, ...homepage.flights.cities].slice(0, 10);
    }
    return [];
  }, [homepage]);

  const topInternationalDestinations = useMemo<Array<HomepageCity>>(() => {
    if (!!homepage) {
      if (pageType === 'home') {
        return uniqBy(
          [...homepage.hotels.domesticCities.slice(0, 10), ...homepage.hotels.cities.slice(0, 10)],
          'id',
        );
      } else if (pageType === 'flights') {
        if (homepage.flights.domesticCities.length > 0) {
          const numberOfDomesticCityInTopCities = 10 - homepage.flights.domesticCities.length;

          const startFrom = Math.max(0, numberOfDomesticCityInTopCities);
          return homepage.flights.cities.slice(startFrom, startFrom + 10);
        }
      } else if (pageType === 'hotels') {
        if (homepage.hotels.domesticCities.length > 0) {
          const numberOfDomesticCityInTopCities = 10 - homepage.hotels.domesticCities.length;

          const startFrom = Math.max(0, numberOfDomesticCityInTopCities);
          return homepage.hotels.cities.slice(startFrom, startFrom + 10);
        }
      }
    }
    return [];
  }, [homepage, pageType]);

  const hotelsInTopCountries = useMemo<Array<HomepageCountry>>(() => {
    if (!!homepage) {
      return homepage.hotels.countries.slice(0, 15);
    }
    return [];
  }, [homepage]);

  const flightsToTopCountries = useMemo<Array<HomepageCountry>>(() => {
    if (!!homepage) {
      return homepage.flights.countries.slice(0, 10);
    }
    return [];
  }, [homepage]);

  const blogUrl = useMemo<string>(() => {
    if (isSiteCodePartOfMENA(currentSite.countryCode)) {
      return 'https://rahhal.wego.com';
    }

    if (/^(?:ID)$/i.test(currentSite.countryCode)) {
      return 'https://www.wego.co.id/berita';
    }

    if (/^(?:CN|HK|MO|TW)$/i.test(currentSite.countryCode)) {
      return 'https://travel.wego.com/zh-tw';
    }

    return 'https://blog.wego.com';
  }, [currentSite]);

  const isShowInpirationalShoppingBanner = useMemo<boolean>(() => {
    return (
      locale === 'en' &&
      isVariantShowingInspirationalShopping &&
      (pageType === 'home' || pageType === 'flights')
    );
  }, [locale, isVariantShowingInspirationalShopping, pageType]);

  // Genzo visit tracking
  useEffect(() => {
    if (
      !isGoogleBot &&
      !!wegoAnalyticsClientId &&
      !!wegoAnalyticsClientSessionId &&
      !!webEngageAnonymousId
    ) {
      const visitData: any = {
        id: pageViewId,
        client: {
          app_type: 'WEB_APP',
          resolution: window.screen.availHeight + 'x' + window.screen.availWidth,
          id: wegoAnalyticsClientId,
          session_id: wegoAnalyticsClientSessionId,
        },
        campaign: {
          source: wegoSourceSearchParam,
          ts_code: getCookie('wego_ts_code') ?? null,
        },
        page: {
          locale: locale,
          site_code: currentSite.countryCode,
          url: window.location.href,
          referrer_url: document.referrer,
          name: 'Homepage',
          base_type: 'homepage',
        },
        created_at: new Date().toISOString(),
      };

      if (!!user) {
        visitData.user = { email: user.email };
        visitData.client.user_hash = user.userHash;
        visitData.external_service = {
          crm_id: user.userHash,
        };
      } else {
        visitData.external_service = {
          crm_id: webEngageAnonymousId,
        };
      }

      genzoTrack(`${API_BASE_URL}/genzo/v2/visits`, visitData);
    }
  }, [user, wegoAnalyticsClientId, wegoAnalyticsClientSessionId, webEngageAnonymousId]);

  // Genzo Page View Tracking
  useEffect(() => {
    if (
      !isGoogleBot &&
      !!wegoAnalyticsClientId &&
      !!wegoAnalyticsClientSessionId &&
      !!webEngageAnonymousId
    ) {
      const clientData: any = {
        id: wegoAnalyticsClientId,
        session_id: wegoAnalyticsClientSessionId,
      };

      const pageData: any = {
        locale: locale,
        site_code: currentSite.countryCode,
        url: window.location.href,
        referrer_url: document.referrer,
      };

      switch (pageType) {
        case 'home':
          pageData.name = 'Main Homepage';
          pageData.product = 'homepage';
          pageData.base_type = 'homepage';
          pageData.sub_type = 'homepage';
          break;
        case 'flights':
          pageData.name = 'Flights Homepage';
          pageData.product = 'flights';
          pageData.base_type = 'flights_homepage';
          pageData.sub_type = 'flights_homepage';
          break;
        case 'hotels':
          pageData.name = 'Hotels Homepage';
          pageData.product = 'hotels';
          pageData.base_type = 'hotels_homepage';
          pageData.sub_type = 'hotels_homepage';
          break;
      }

      const data: any = {
        id: pageViewId,
        client: clientData,
        page: pageData,
        created_at: toISOStringWithTimezone(new Date()),
        external_services: {
          crm_id: user ? user.userHash : webEngageAnonymousId,
        },
      };

      if (!!user) {
        data.client.user_hash = user.userHash;
      }

      genzoTrackPageView(API_BASE_URL, data);
    }
  }, [
    locale,
    pageViewId,
    user,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
    webEngageAnonymousId,
  ]);

  // Gtm Tracking
  useEffect(() => {
    if (
      !isGoogleBot &&
      !!nearestCityByIP &&
      !!wegoAnalyticsClientId &&
      !!wegoAnalyticsClientSessionId
    ) {
      const pageData: HomeGtmPageData = {
        client_type: 'WEB_APP',
        domain: window.location.hostname,
        event: 'pageChanged',
        locale: locale,
        session_id: wegoAnalyticsClientSessionId,
        site_code: currentSite.countryCode,
        ts_code: getCookie('wego_ts_code') ?? null,
        url: window.location.href,
        user_country_code: nearestCityByIP.countryCode,
        user_id: getCookie('wego_user_id') ?? null,
        ...(!!user?.email && { e_id: sha256(user?.email).toString() }),
      };

      switch (pageType) {
        case 'home':
          pageData.page_name = 'Main Homepage';
          pageData.page_type = 'homepage';
          pageData.product = 'home';
          break;
        case 'flights':
          pageData.page_name = 'Flights Homepage';
          pageData.page_type = 'flights_homepage';
          pageData.product = 'flights';
          break;
        case 'hotels':
          pageData.page_name = 'Hotels Homepage';
          pageData.page_type = 'hotels_homepage';
          pageData.product = 'hotels';
          break;
      }
      gtmTrack(GTM_ID, gtmAuth, gtmPreview, pageData);
    }
  }, [
    locale,
    pageType,
    nearestCityByIP,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
    user,
  ]);

  // Get marketing partner promo
  const { data: marketingPartnerPromo } = useGetMarketingPartnerPromo();

  // Get travel themes based on nearest city by IP
  const { data: travelThemes } = useGetTravelThemes(
    {
      searchParams: {
        departureCityCode: nearestCityByIP?.code ?? '',
        locale,
        currencyCode: currency.code,
        siteCode: currentSite.countryCode.toUpperCase(),
        ...(hasBowOnlyParamAsTrue && { bowOnly: hasBowOnlyParamAsTrue }),
      },
    },
    {
      enabled: !!nearestCityByIP,
    },
  );

  // Update the selected campaign carousel index for every 5 sec
  useEffect(() => {
    let intervalRef: NodeJS.Timeout;

    if (isWinterSeasonCampaignActive(currentSite.countryCode)) {
      intervalRef = setInterval(() => {
        setSelectedCampaignCarouselIndex((prevIndex) => (prevIndex === 5 ? 1 : prevIndex + 1));
      }, 5000);

      return () => clearInterval(intervalRef);
    }

    if (isRamadanCampaignActive(currentSite.countryCode)) {
      intervalRef = setInterval(() => {
        setSelectedCampaignCarouselIndex((prevIndex) => (prevIndex === 3 ? 1 : prevIndex + 1));
      }, 5000);

      return () => clearInterval(intervalRef);
    }

    if (isSpringSeasonCampaignActive(currentSite.countryCode)) {
      intervalRef = setInterval(() => {
        setSelectedCampaignCarouselIndex((prevIndex) => (prevIndex === 3 ? 1 : prevIndex + 1));
      }, 5000);

      return () => clearInterval(intervalRef);
    }
  }, [currentSite.countryCode, selectedCampaignCarouselIndex]);

  useEffect(() => {
    if (!pageViewId) return;

    dispatch(changeHomePageViewId(pageViewId));
  }, [pageViewId]);

  // const pointOfSaleWithSearchDealsTagline = ['AU', 'BD', 'CA', 'CN', 'DE', 'FR', 'GB', 'HK', 'ID', 'IN', 'JP', 'KR', 'LK', 'MO', 'MY', 'NZ', 'PH', 'PK', 'SG', 'TH', 'TW', 'VN'];

  const genzoTrackInspirationalShoppingBannerClickedEvent = (): void => {
    if (!!wegoAnalyticsClientSessionId && !!wegoAnalyticsClientId) {
      const eventData: any = {
        id: pageViewId,
        category: 'banner',
        object: 'explore_anywhere',
        action: 'click',
        value: 'explore_anywhere',
      };

      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId!,
        wegoAnalyticsClientSessionId!,
        user?.userHash,
        eventData,
      );
    }
  };

  const openGreatEscapeInNewTab = (): void => {
    if (!!wegoAnalyticsClientSessionId) {
      const paymentMethodParams =
        selectedPaymentMethods.length > 0
          ? selectedPaymentMethods.join(',')
          : availablePaymentMethods
              .filter((paymentMethod) => paymentMethod.isPopular)
              .map((paymentMethod) => paymentMethod.id)
              .join(',');

      const greatEscapeLink = constructGreatEscapeLink(
        currentSite.host,
        wegoAnalyticsClientSessionId,
        {
          ...(wegoSourceSearchParam && { wg_source: wegoSourceSearchParam }),
          ...(wegoMediumSearchParam && { wg_medium: wegoMediumSearchParam }),
          ...(wgCampaign && { wg_campaign: wgCampaign }),
          ...(tsCodeSearchParam && { ts_code: tsCodeSearchParam }),
          ...(wgInternalCampaign && { wg_internal_campaign: wgInternalCampaign }),
          ...(bowOnlyParam && { bow_only: bowOnlyParam }),
          cc: currency.code,
          payment_methods: paymentMethodParams,
        },
      );

      window.open(greatEscapeLink, '_blank');
    }
  };

  const handleInspirationalShoppingBannerClicked = (): void => {
    genzoTrackInspirationalShoppingBannerClickedEvent();
    openGreatEscapeInNewTab();
  };

  const popularProviders = !!homepage
    ? homepage[pageType === 'hotels' ? 'hotels' : 'flights'].popularProviders
    : [];

  const popularAirlines = !!homepage ? homepage.flights.popularAirlines : [];
  const popularBrands = !!homepage ? homepage.hotels.popularBrands : [];

  useEffect(() => {
    import(/* webpackChunkName: "FlightSearchResult" */ '@pages/FlightSearchResult');
  }, []);

  const handleOnPartnerPromoClick = (
    carouselData: MarketingPartnerPromoGenzoData,
    actionId: string,
  ): void => {
    genzoTrackCarouselClick({
      apiBaseUrl: API_BASE_URL,
      clientId: wegoAnalyticsClientId!,
      clientSessionId: wegoAnalyticsClientSessionId!,
      userHash: user?.userHash,
      carouselData,
      id: actionId,
      pageViewId,
    });
  };

  // Show marketing partner promo only if there are more than 2 items and viewport width is greater or equal 1024.
  // If viewport width is less than 1024, show marketing partner promo only if there are more than 1 item.
  // Jira ticket reference: https://wegomushi.atlassian.net/browse/WG-486
  // Jira ticket reference: https://wegomushi.atlassian.net/browse/WG-506
  const showMarketingPartnerPromo =
    !!marketingPartnerPromo &&
    ((width < 1024 && marketingPartnerPromo.data.list.length > 1) ||
      (width >= 1024 && marketingPartnerPromo.data.list.length > 2));

  return (
    <div className={clsx(styles.container)}>
      <div className={clsx(styles.heroImageContainer, styles.heroImageContainerNew)}>
        {/* Loading the images for the carousel */}
        {isWinterSeasonCampaignActive(currentSite.countryCode) ? (
          [1, 2, 3, 4, 5].map((id) => (
            <div
              key={id}
              className={clsx(
                styles.heroImageCampaign,
                selectedCampaignCarouselIndex === id && styles.activeHeroImageCampaign,
              )}
              style={{
                backgroundImage: `url("https://assets.wego.com/image/upload/c_fill,fl_lossy,q_auto:best,f_auto,w_2560/v1678790461/web/campaigns/winter-season/hero-image_${id}.png")`,
              }}
            ></div>
          ))
        ) : isRamadanCampaignActive(currentSite.countryCode) ? (
          [1, 2, 3].map((id) => (
            <div
              key={id}
              className={clsx(
                styles.heroImageCampaign,
                selectedCampaignCarouselIndex === id && styles.activeHeroImageCampaign,
              )}
              style={{
                backgroundImage: `url("https://assets.wego.com/image/upload/c_fill,fl_lossy,q_auto:best,f_auto,w_2560/v1740653013/web/campaigns/ramadan/hero-image_${id}.jpg")`,
              }}
            ></div>
          ))
        ) : isSpringSeasonCampaignActive(currentSite.countryCode) ? (
          [1, 2, 3].map((id) => (
            <div
              key={id}
              className={clsx(
                styles.heroImageCampaign,
                selectedCampaignCarouselIndex === id && styles.activeHeroImageCampaign,
              )}
              style={{
                backgroundImage: `url("https://assets.wego.com/image/upload/c_fill,fl_lossy,q_auto:best,f_auto,w_2560/v1740659145/web/campaigns/spring-season/hero-image_${id}.jpg")`,
              }}
            ></div>
          ))
        ) : (
          <div
            className={clsx(styles.heroImageCampaign, styles.activeHeroImageCampaign)}
            style={{ backgroundImage: `url("${heroImageUrl}")` }}
          ></div>
        )}

        <div className={styles.heroImage}>
          {/* <div className={styles.tagline}>{pointOfSaleWithSearchDealsTagline.includes(currentSite.countryCode) ? translations.search_deals : translations.landing_tagline}</div> */}

          {!!campaignLogoImageUrl ? (
            <div className={styles.campaignLogoImageContainer}>
              <img src={campaignLogoImageUrl} />
            </div>
          ) : null}
        </div>
      </div>

      <div className={clsx(styles.contentContainer, styles.marginTop)}>
        {/* Search Form */}
        <SearchForm
          availablePaymentMethods={availablePaymentMethods}
          onPreferredPaymentMethodsChange={setSelectedPaymentMethods}
          pageType={pageType}
          pageViewId={pageViewId}
          geolocationCoordinates={geolocationCoordinates}
          nearestCityByIP={nearestCityByIP}
          wegoAnalyticsClientId={wegoAnalyticsClientId}
          wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
        />

        {/* Egpyt Central Bank Notice */}
        {currentSite.countryCode === 'EG' ? (
          <div className={styles.egyptCentralBankNotice}>
            {translations.eg_central_bank_fee_text}
          </div>
        ) : null}

        <NtoBannerAd
          countryCode={currentSite.countryCode}
          locale={locale}
          isUserLoggedIn={!!user}
        />

        {/* Marketing Partner Promo */}
        {showMarketingPartnerPromo ? (
          <div className={styles.section}>
            <Carousel
              className={styles.marketingPartnerPromos}
              isRtl={isRtl}
              itemLength={marketingPartnerPromo.data.list.length}
              itemWidth={384}
              scrollWidth={384}
            >
              {marketingPartnerPromo.data.list.map((partnerPromo, index) => {
                const genzoActionId = cuid();
                const deeplinkUrl = partnerPromo.deeplinkUrl.startsWith('http')
                  ? partnerPromo.deeplinkUrl
                  : `https://${partnerPromo.deeplinkUrl}`;
                const href = new URL(deeplinkUrl);
                href.searchParams.set('wego_click_id', genzoActionId);

                const carouselData: MarketingPartnerPromoGenzoData = {
                  id: partnerPromo.id,
                  position: index + 1,
                  agreement_term_id: partnerPromo.agreementTermId,
                  url: href.toString(),
                  site_code: currentSite.countryCode,
                  locale: locale,
                };

                return (
                  <a
                    className={styles.marketingPartnerPromo}
                    href={href.toString()}
                    key={partnerPromo.id}
                    target='_blank'
                    rel='noreferrer nofollow'
                    onClick={() => handleOnPartnerPromoClick(carouselData, genzoActionId)}
                  >
                    <img src={partnerPromo.imageUrl} />
                  </a>
                );
              })}
            </Carousel>
          </div>
        ) : null}

        {/* Inspirational Shopping Banner */}
        {isShowInpirationalShoppingBanner ? (
          <div
            onClick={handleInspirationalShoppingBannerClicked}
            className={styles.inspirationalShoppingBanner}
            style={{
              backgroundImage: `url("https://assets.wego.com/image/upload/c_fill,fl_lossy,q_auto:low,f_auto/v1690286620/web/inspirational_shopping/${inspirationalShoppingLogoName}.png")`,
            }}
          ></div>
        ) : null}

        {/* Propositions */}
        <Propositions
          countryName={countryName}
          translations={translations}
          locale={locale}
          bowOnly={bowOnlyParam}
          pageType={pageType}
        />

        {/* Stories */}
        <BlogsSection blogUrl={blogUrl} translations={translations} isRtl={isRtl} />

        <PopularAirlines
          translations={translations}
          locale={locale}
          countryName={countryName}
          popularAirlines={popularAirlines}
          pageType={pageType}
          currentSite={currentSite}
          localeParam={localeParam}
        />

        <PopularBrands
          translations={translations}
          pageType={pageType}
          popularBrands={popularBrands}
        />

        {/* Popular Hotels */}
        <PopularHotels />

        {/* Recommended Hotels */}
        {pageType === 'hotels' ? (
          <RecommendedHotels
            hotelRecentSearch={hotelRecentSearch}
            nearestCityByIP={nearestCityByIP}
            localeParam={localeParam}
          />
        ) : null}

        <TravelPartners
          translations={translations}
          locale={locale}
          countryName={countryName}
          popularProviders={popularProviders}
        />

        {/* Top Cities */}
        {pageType === 'hotels' && hotelsInTopCities.length > 0 ? (
          <HotelTopCities hotelsInTopCities={hotelsInTopCities} localeParam={localeParam} />
        ) : null}

        {/* Trip Ideas */}
        {travelThemes &&
        travelThemes.length > 0 &&
        (pageType === 'hotels' ? bowOnlyParam !== 'true' : true) ? (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              {translateText(translations.trip_ideas_from, locale, countryName)}
            </div>

            <TripIdeas
              className={styles.tripIdeas}
              currency={currency}
              currentSite={currentSite}
              departureCityCode={nearestCityByIP!.code!}
              exchangeRate={exchangeRate}
              locale={locale}
              localeParam={localeParam}
              translations={translations}
              travelThemes={travelThemes}
            />
          </div>
        ) : null}

        <InstallBanner />

        {/* SEO Flights To Top Cities */}
        {(pageType === 'home' || pageType === 'flights') && flightsToTopCities.length > 0 ? (
          <div className={styles.section}>
            <div className={styles.seoTitle}>
              {translateText(translations.flights_top_cities_from, locale, countryName)}
            </div>

            <nav>
              {flightsToTopCities.map((flightsToTopCity) => (
                <a
                  key={flightsToTopCity.code}
                  href={`${flightsToRoute(
                    currentSite,
                    localeParam,
                  )}/${flightsToTopCity.code.toLowerCase()}/${cheapFlightsToRouteTranslation(
                    locale,
                    flightsToTopCity.permalink,
                  )}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {translateText(translations.flights_to, locale, flightsToTopCity.name)}
                </a>
              ))}
            </nav>
          </div>
        ) : null}

        {/* SEO Flights To Top Countries */}
        {pageType === 'home' && flightsToTopCountries.length > 0 ? (
          <div className={styles.section}>
            <div className={styles.seoTitle}>
              {translateText(translations.flights_top_countries_from, locale, countryName)}
            </div>

            <nav>
              {flightsToTopCountries.map((flightsToTopCountry) => (
                <a
                  key={flightsToTopCountry.code}
                  href={`${flightsToRoute(
                    currentSite,
                    localeParam,
                  )}/${flightsToTopCountry.code.toLowerCase()}/${cheapFlightsToRouteTranslation(
                    locale,
                    flightsToTopCountry.countryPermalink,
                  )}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {translateText(translations.flights_to, locale, flightsToTopCountry.name)}
                </a>
              ))}
            </nav>
          </div>
        ) : null}

        {/* SEO Hotels In Top Cities / Top International Destinations / Hotels In Top International Destinations */}
        {topInternationalDestinations.length > 0 &&
        !(pageType === 'flights' && currentSite.countryCode === 'AE') ? (
          <div className={styles.section}>
            <div className={styles.seoTitle}>
              {pageType === 'home' ? translations.hotels_top_cities : null}
              {pageType === 'flights' ? translations.top_international_destinations : null}
              {pageType === 'hotels' ? translations.hotels_international_destinations : null}
            </div>

            <nav>
              {topInternationalDestinations.map((hotelsInTopInternationalDestination) => (
                <a
                  key={hotelsInTopInternationalDestination.code}
                  href={
                    pageType === 'flights'
                      ? `${flightsToRoute(
                          currentSite,
                          localeParam,
                        )}/${hotelsInTopInternationalDestination.code.toLowerCase()}/${cheapFlightsToRouteTranslation(
                          locale,
                          hotelsInTopInternationalDestination.permalink,
                        )}`
                      : `${hotelsRoute(currentSite, localeParam)}/${
                          hotelsInTopInternationalDestination.countryPermalink
                        }/${hotelsInTopInternationalDestination.permalink}${search}`
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  {translateText(
                    pageType === 'flights' ? translations.flights_to : translations.hotels_in,
                    locale,
                    hotelsInTopInternationalDestination.name,
                  )}
                </a>
              ))}
            </nav>
          </div>
        ) : null}

        {/* SEO Flights To Top Countries / Hotels In Top Countries */}
        {((pageType === 'home' || pageType === 'hotels') && hotelsInTopCountries.length > 0) ||
        (pageType === 'flights' && flightsToTopCountries.length > 0) ? (
          <div className={styles.section}>
            <div className={styles.seoTitle}>
              {pageType === 'home' || pageType === 'hotels'
                ? translations.hotels_top_countries
                : null}
              {pageType === 'flights' ? translations.top_countries : null}
            </div>

            <nav>
              {pageType === 'home' || pageType === 'hotels'
                ? hotelsInTopCountries.map((hotelsInTopCountry) => (
                    <a
                      key={hotelsInTopCountry.code}
                      href={`${hotelsRoute(currentSite, localeParam)}/${
                        hotelsInTopCountry.countryPermalink
                      }${search}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {translateText(translations.hotels_in, locale, hotelsInTopCountry.name)}
                    </a>
                  ))
                : null}

              {pageType === 'flights'
                ? flightsToTopCountries.map((flightsToTopCountry) => (
                    <a
                      key={flightsToTopCountry.code}
                      href={`${flightsToRoute(
                        currentSite,
                        localeParam,
                      )}/${flightsToTopCountry.code.toLowerCase()}/${cheapFlightsToRouteTranslation(
                        locale,
                        flightsToTopCountry.countryPermalink,
                      )}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {translateText(translations.flights_to, locale, flightsToTopCountry.name)}
                    </a>
                  ))
                : null}
            </nav>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Home;

import { useSelector } from 'react-redux';

import { getPublicHolidays } from '@apis/places';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

type GetPublicHolidaysParams = {
  fromDate: Date;
  toDate: Date;
};

const getPublicHolidaysKey = 'public-holidays';

export const useGetPublicHolidays = <TResult = Array<PlacesHoliday>>(
  params: GetPublicHolidaysParams,
  options?: UseAxiosQueryOptions<
    {
      publicHolidays: Array<PlacesHoliday>;
    },
    TResult
  >,
) => {
  const apiBaseUrl = API_BASE_URL;
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [
      getPublicHolidaysKey,
      {
        locale,
        currentSiteCode: currentSiteCode.countryCode,
        fromDate: params.fromDate,
        toDate: params.toDate,
      },
    ],
    queryFn: () =>
      getPublicHolidays(
        apiBaseUrl,
        locale,
        currentSiteCode.countryCode,
        params.fromDate,
        params.toDate,
      ),
    ...options,
  });
};

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useHotelMetaData } from '@hooks/useHotelMetaData';

import { hotelsRouteTranslation } from '../helpers/routeTranslation';

interface HotelsPathRouteProps {
  hotelsParam: string;
  localeParam: string | undefined;
}

const HotelsPathRoute: React.FC<HotelsPathRouteProps> = ({
  hotelsParam,
  localeParam,
}): JSX.Element => {
  const { pathname, search, state } = useLocation();
  const { title, description: metaDescription } = useHotelMetaData();

  const navigate = useNavigate();

  // Navigate to the corrent hotels route translation
  useEffect(() => {
    if (!!localeParam) {
      const hotelsRouteTranslation$ = hotelsRouteTranslation(localeParam);

      if (hotelsRouteTranslation$ !== hotelsParam) {
        navigate(
          { pathname: pathname.replace(hotelsParam, hotelsRouteTranslation$), search },
          { replace: true, state },
        );
      }
    }
  }, [localeParam]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={metaDescription} />
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname}`} />
      </Helmet>

      <Outlet />
    </>
  );
};

export default HotelsPathRoute;

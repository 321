import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Carousel from '@components/Carousel';

import { hotelsRoute } from '@helpers/routeTranslation';

import { getCurrentSiteState, getIsRtlState, getTranslationsState } from '@redux/selectors';

import styles from './HotelTopCities.module.scss';
import TopCityCard from './TopCityCard';

type HotelTopCitiesProps = {
  hotelsInTopCities: HomepageCity[];
  localeParam: string;
};

const HotelTopCities: React.FC<HotelTopCitiesProps> = ({ hotelsInTopCities, localeParam }) => {
  const currentSite = useSelector(getCurrentSiteState);
  const translations = useSelector(getTranslationsState);
  const { search } = useLocation();
  const isRtl = useSelector(getIsRtlState);

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>{translations.hotels_top_cities}</div>

      <Carousel
        className={styles.topCities}
        isRtl={isRtl}
        itemLength={hotelsInTopCities.length}
        itemWidth={192}
        scrollWidth={192}
      >
        {hotelsInTopCities.map((hotelsInTopCity) => (
          <TopCityCard
            city={hotelsInTopCity}
            href={`${hotelsRoute(currentSite, localeParam)}/${hotelsInTopCity.countryPermalink}/${
              hotelsInTopCity.permalink
            }${search}`}
            key={hotelsInTopCity.code}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default HotelTopCities;

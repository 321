import { dateApiFormat } from '@alexis/helpers/date';
import { localStorageGet } from '@alexis/helpers/localStorage';
import { appendSearchParams } from '@alexis/helpers/searchParam';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { translateText } from '@wego/alexis/helpers/translation';

import Carousel from '@components/Carousel';

import { HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY } from '@constants/localStorage';

import { isFlightProduct } from '@helpers/flight';
import { isHotelProduct } from '@helpers/hotel';
import { hotelsRoute } from '@helpers/routeTranslation';

import { useGetCityPopularHotels } from '@hooks/api/hotels/useGetCityPopularHotels';
import { useGetNearestPlaces } from '@hooks/api/places/useGetNearestPlaces';

import {
  getCurrencyState,
  getCurrentSiteState,
  getExchangeRateState,
  getIsRtlState,
  getLocaleState,
  getTranslationsState,
} from '@redux/selectors';

import { HotelPopularHotel } from '@wegoTypes/hotels/hotelPopularHotel';
import { HotelSearch } from '@wegoTypes/hotels/hotelSearch';

import PopularHotelCard from '../PopularHotelCard';
import styles from './PopularHotels.module.scss';

const getLocaleParams = ({ pathname }: { pathname: string }) => {
  const localeParam = pathname.split('/')[1];

  if (/^(?:[a-zA-Z]{2}|[a-zA-Z]{2}\-[a-zA-Z]{2})$/i.test(localeParam)) {
    return localeParam;
  }

  return '';
};

const getPageType = ({ pathname, localeParam }: { pathname: string; localeParam: string }) => {
  const productParam = pathname.split('/')[!!localeParam ? 2 : 1];

  if (isFlightProduct(productParam)) {
    return 'flights';
  } else if (isHotelProduct(productParam)) {
    return 'hotels';
  }
  return 'home';
};

const PopularHotels = () => {
  const currentSite = useSelector(getCurrentSiteState);
  const locale = useSelector(getLocaleState);
  const currency = useSelector(getCurrencyState);
  const isRtl = useSelector(getIsRtlState);
  const translations = useSelector(getTranslationsState);
  const exchangeRate = useSelector(getExchangeRateState);

  const { pathname, search } = useLocation();

  const localeParam = getLocaleParams({ pathname });
  const pageType = getPageType({ pathname, localeParam });

  const hotelRecentSearch: HotelSearch | null = useMemo(
    () => localStorageGet(HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY),
    [],
  );

  const { data: nearestCityByIP } = useGetNearestPlaces({ isCity: true });

  const { data: popularHotels } = useGetCityPopularHotels(
    {
      cityCode: hotelRecentSearch?.place?.code || nearestCityByIP?.[0]?.code || '',
      perPageCount: 10,
    },
    {
      enabled: !!hotelRecentSearch?.place?.code || !!nearestCityByIP?.[0]?.code,
    },
  );

  const popularHotelHref = (popularHotel: HotelPopularHotel) => {
    const checkInDate = new Date();
    const checkOutDate = new Date();

    checkOutDate.setDate(checkInDate.getDate() + 1);
    const encodedPlaceCode = encodeURIComponent(
      hotelRecentSearch?.place?.code || nearestCityByIP?.[0]?.code || '',
    );
    const encodedCheckInDate = encodeURIComponent(dateApiFormat(checkInDate));
    const encodedCheckOutDate = encodeURIComponent(dateApiFormat(checkOutDate));
    const encodedSearch = encodeURIComponent(search);

    return appendSearchParams(
      `${window.location.origin}${hotelsRoute(
        currentSite,
        localeParam,
      )}/searches/${encodedPlaceCode}/${encodedCheckInDate}/${encodedCheckOutDate}/${
        popularHotel.id
      }${encodedSearch}`,
      { guests: 2, open_calendar: true },
    );
  };

  if (pageType !== 'hotels' || !popularHotels || popularHotels.length < 1) return null;

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        {translateText(
          translations.popular_hotels,
          locale,
          hotelRecentSearch?.place?.cityName || nearestCityByIP?.[0]?.cityName || '',
        )}
      </div>

      <Carousel
        className={styles.popularHotels}
        isRtl={isRtl}
        itemLength={popularHotels.length}
        itemWidth={288}
        scrollWidth={288}
      >
        {popularHotels.map((popularHotel) => (
          <PopularHotelCard
            currency={currency}
            exchangeRate={exchangeRate}
            hotel={popularHotel}
            href={popularHotelHref(popularHotel)}
            key={popularHotel.id}
            locale={locale}
            translations={translations}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default PopularHotels;

import { dateDisplayFormat, dateApiFormat, getLocalDate } from '@alexis/helpers/date';
import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Subscription } from 'rxjs';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateText } from '@wego/alexis/helpers/translation';
import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { getHotelsPopularCities, getNearestPlaces, getPlaces } from '@apis/places';

import DatePicker from '@components/DatePicker';
import InputSelector from '@components/InputSelector';

import { HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY } from '@constants/localStorage';

import {
  convertGuestRoomsToSearchParam,
  convertGuestSearchParamToGuestRooms,
  getGuestsCount,
  getHotelSortTypeSearchParamValue,
  getMetaSearchPlace,
  getPlaceLabel,
} from '@helpers/hotel';
import { handleNextItem, handlePreviousItem } from '@helpers/hotels';

import SearchIcon from '@icons/search.svg';

import { useViewportSize } from '@hooks/useViewportSize';

import { triggerService } from '@observables/changeDatesObservables';
import { setHotelHovered } from '@observables/mapObservables';

import { updateTriggerSearchCounter } from '@redux/actions/triggerSearchCounterActions';
import {
  getCurrentSiteState,
  getHotelMetaSearchState,
  getIsRtlState,
  getLocaleState,
  getTranslationsState,
  getTriggerSearchCounterState,
} from '@redux/selectors';

import { GuestRoom } from '@wegoTypes/hotels/guestRoom';
import { HotelMetaSearch } from '@wegoTypes/hotels/hotelMetaSearch';
import { HotelSearch } from '@wegoTypes/hotels/hotelSearch';
import { HotelSortType } from '@wegoTypes/hotels/hotelSortType';
import { PlacesHotelsPopularCity } from '@wegoTypes/hotels/placesHotelsPopularCity';
import { Translations } from '@wegoTypes/translations';

import GuestRoomPicker from '../GuestRoomPicker';
import GuestRoomSelector from '../GuestRoomSelector';
import HotelDateSelector from '../HotelDateSelector';
import styles from './HotelSearchLayout.module.scss';

const HEADER_HEIGHT = 71;

const HotelSearchLayout: React.FC<{
  geolocationCoordinates: GeolocationCoordinates | undefined;
}> = ({ geolocationCoordinates }): JSX.Element => {
  const apiBaseUrl: string = API_BASE_URL;
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const hotelMetaSearch: HotelMetaSearch = useSelector(getHotelMetaSearchState);
  const isRtl: boolean = useSelector(getIsRtlState);
  const locale: string = useSelector(getLocaleState);
  const translations: Translations = useSelector(getTranslationsState);
  const triggerSearchCounter: number = useSelector(getTriggerSearchCounterState);

  const placePickerRef = useRef<HTMLInputElement>(null);
  const keyPressRef = useRef(false);
  const hotelRecentSearch = localStorageGet<HotelSearch>(HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY);

  const [isEditingDestinationSearchQuery, setIsEditingDestinationSearchQuery] =
    useState<boolean>(false);

  const [destinationSearchQuery, setDestinationSearchQuery] = useState<string>('');
  const [destinationSearchQueryPlaces, setDestinationSearchQueryPlaces] = useState<Array<Place>>(
    [],
  );
  const [destinationSearchActiveItemIndex, setDestinationSearchActiveItemIndex] =
    useState<number>(0);

  const [placePickerPlaceholder, setPlacePickerPlaceholder] = useState<string>('');

  const [nearestCityByGeolocation, setNearestCityByGeolocation] = useState<Place>();
  const [popularCities, setPopularCities] = useState<Array<PlacesHotelsPopularCity>>([]);

  const [selectedDate, setSelectedDate] = useState<Date>(); // State used by date picker component

  const [selectedPlace, setSelectedPlace] = useState<Place | PlacesHotelsPopularCity>();
  const [checkInDateMilliseconds, setCheckInDateMilliseconds] = useState<number>();
  const [checkOutDateMilliseconds, setCheckOutDateMilliseconds] = useState<number>();
  const [guestRooms, setGuestRooms] = useState<Array<GuestRoom>>([]);
  const [isStickySearchLayout, setIsStickySearchLayout] = useState<boolean>(false);
  const [searchFormHeight, setSearchFormHeight] = useState<number>();
  const searchFormWrapper = useRef<HTMLDivElement>(null);

  const { width } = useViewportSize();
  const isHalfScreenMap = width > 1024;

  const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const limitFromMilliseconds = yesterdayDate.setHours(0, 0, 0, 0);
  const limitTillDate = new Date();
  limitTillDate.setHours(0, 0, 0, 0);
  const limitToMilliseconds = limitTillDate.setMonth(limitTillDate.getMonth() + 11);

  const dispatch = useDispatch();

  const { pathname, search, state } = useLocation();
  const isDetailPage = useMemo<boolean>(() => {
    return /[?&]search_id=/.test(search);
  }, [search]);

  const navigate = useNavigate();

  const params = useParams<{ checkInDate: string; checkOutDate: string }>();

  const [searchParams] = useSearchParams();
  const guestsSearchParam = searchParams.get('guests');
  const openCalendarSearchParam = searchParams.get('open_calendar');
  const radiusLatParam = searchParams.get('lat');
  const radiusLongParam = searchParams.get('long');

  const [isPlacePickerFocus, setIsPlacePickerFocus] = useState<boolean>(false);

  const [isDatePickerFromFocus, setIsDatePickerFromFocus] = useState<boolean>(false);
  const [isDatePickerToFocus, setIsDatePickerToFocus] = useState<boolean>(false);

  const [isGuestRoomFocus, setIsGuestRoomFocus] = useState<boolean>(false);

  const [isDestinationSearchQueryValidated, setIsDestinationSearchQueryValidated] =
    useState<boolean>(false);
  const [destinationSearchQueryHasError, setDestinationSearchQueryHasError] =
    useState<boolean>(false);

  const [isCheckInDateValidated, setIsCheckInDateValidated] = useState<boolean>(false);
  const [checkInDateHasError, setCheckInDateHasError] = useState<boolean>(false);

  const [isCheckOutDateValidated, setIsCheckOutDateValidated] = useState<boolean>(false);
  const [checkOutDateHasError, setCheckOutDateHasError] = useState<boolean>(false);

  // Set all picker focus to false if body is clicked
  useEffect(() => {
    if (isPlacePickerFocus || isDatePickerFromFocus || isDatePickerToFocus || isGuestRoomFocus) {
      const handleBodyOnClick = (): void => {
        if (isPlacePickerFocus) {
          setIsPlacePickerFocus(false);
        }

        if (isDatePickerFromFocus) {
          setIsDatePickerFromFocus(false);
        }

        if (isDatePickerToFocus) {
          setIsDatePickerToFocus(false);
        }

        if (isGuestRoomFocus) {
          setIsGuestRoomFocus(false);
        }
      };

      document.body.addEventListener('click', handleBodyOnClick);

      return () => {
        document.body.removeEventListener('click', handleBodyOnClick);
      };
    }
  }, [isPlacePickerFocus, isDatePickerFromFocus, isDatePickerToFocus, isGuestRoomFocus]);

  // Set selected place based on metasearch search if selected place is undefined.
  useEffect(() => {
    if (
      !!hotelMetaSearch.search &&
      (hotelMetaSearch.search.searchType !== 'GEO_LOCATION' || (radiusLatParam && radiusLongParam))
    ) {
      const place = getMetaSearchPlace(hotelMetaSearch, hotelRecentSearch);

      if (!selectedPlace && !isEditingDestinationSearchQuery) {
        setSelectedPlace(place);
      }
    }
  }, [hotelMetaSearch.search, isEditingDestinationSearchQuery, hotelRecentSearch]);

  // Set check in date, check out date and guests search param based on params and search param.
  useEffect(() => {
    if (/^(?:[0-9]{4}\-[0-9]{2}\-[0-9]{2})$/.test(params.checkInDate!)) {
      const checkInDate = getLocalDate(params.checkInDate!);
      setCheckInDateMilliseconds(checkInDate.getTime());
    } else {
      setIsCheckInDateValidated(true);
      setCheckInDateHasError(true);
    }

    if (/^(?:[0-9]{4}\-[0-9]{2}\-[0-9]{2})$/.test(params.checkOutDate!)) {
      const checkOutDate = getLocalDate(params.checkOutDate!);
      setCheckOutDateMilliseconds(checkOutDate.getTime());
    } else {
      setIsCheckOutDateValidated(true);
      setCheckOutDateHasError(true);
    }

    if (!!guestsSearchParam) {
      setGuestRooms(convertGuestSearchParamToGuestRooms(guestsSearchParam));
    }
  }, [params.checkInDate, params.checkOutDate, guestsSearchParam]);

  // Get place based on destinationSearchQuery
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    let getPlaceTimeout: NodeJS.Timeout | undefined;

    if (!!destinationSearchQuery && isPlacePickerFocus && !selectedPlace) {
      getPlaceTimeout = setTimeout(async (): Promise<void> => {
        try {
          const places = await getPlaces(
            locale,
            currentSite.countryCode,
            destinationSearchQuery,
            true,
            true,
            true,
            true,
            false,
            false,
            cancelTokenSource.token,
            1,
          );
          setDestinationSearchQueryPlaces(places);
        } catch (error) {
          setDestinationSearchQueryPlaces([]);
        }
      }, 150);
    } else {
      cancelTokenSource.cancel('Empty search query.');
      setDestinationSearchQueryPlaces([]);
    }

    return () => {
      cancelTokenSource.cancel('Component unmount.');

      if (!!getPlaceTimeout) {
        clearTimeout(getPlaceTimeout);
      }
    };
  }, [destinationSearchQuery, locale, isPlacePickerFocus, selectedPlace]);

  // Get popular cities
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const initPopularCities = async (
      apiBaseUrl: string,
      locale: string,
      currentSite: CurrentSite,
      cancelToken: CancelToken,
    ): Promise<void> => {
      try {
        const popularCities = await getHotelsPopularCities(
          apiBaseUrl,
          locale,
          currentSite.countryCode,
          cancelToken,
        );

        setPopularCities(popularCities);
      } catch (error) {
        setPopularCities([]);
      }
    };

    initPopularCities(apiBaseUrl, locale, currentSite, cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [locale]);

  // Get nearest city based on geolocation
  useEffect(() => {
    if (!!geolocationCoordinates) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const initNearestCity = async (
        apiBaseUrl: string,
        geolocationCoordinates: GeolocationCoordinates,
        locale: string,
        cancelToken: CancelToken,
      ): Promise<void> => {
        try {
          const nearestCities = await getNearestPlaces(
            locale,
            true,
            cancelToken,
            geolocationCoordinates.latitude,
            geolocationCoordinates.longitude,
          );

          setNearestCityByGeolocation(nearestCities[0]);
        } catch (error) {
          setNearestCityByGeolocation(undefined);
        }
      };

      initNearestCity(apiBaseUrl, geolocationCoordinates, locale, cancelTokenSource.token);

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    }
  }, [geolocationCoordinates, locale]);

  //#region Place picker events and helper functions
  const handleOnPlacePickerChange = useCallback<(value: string) => void>((value: string) => {
    setIsDestinationSearchQueryValidated(false);
    setDestinationSearchQueryHasError(false);
    setDestinationSearchQuery(value);
    setIsEditingDestinationSearchQuery(true);
    setSelectedPlace(undefined);
  }, []);

  const handleOnPlacePickerClear = useCallback(() => {
    setDestinationSearchQuery('');
    setIsEditingDestinationSearchQuery(true);
    setSelectedPlace(undefined);

    placePickerRef.current!.focus();
  }, [placePickerRef]);

  const handleOnPlacePickerFocus = useCallback(() => {
    setIsDatePickerFromFocus(false);
    setIsDatePickerToFocus(false);
    setIsGuestRoomFocus(false);

    setIsPlacePickerFocus(true);

    placePickerRef.current!.select();
  }, [placePickerRef]);

  const handleOnPlacePickerKeyDown = useCallback<
    (event: React.KeyboardEvent<HTMLInputElement>) => void
  >(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (keyPressRef.current) return;
      keyPressRef.current = true;

      if (event.key === 'Enter') {
        event.preventDefault();

        if (
          !!event.currentTarget.value &&
          (destinationSearchQueryPlaces.length > 0 || !!nearestCityByGeolocation)
        ) {
          setSelectedPlace(
            destinationSearchQueryPlaces[destinationSearchActiveItemIndex] ||
              nearestCityByGeolocation,
          );

          setIsPlacePickerFocus(false);
          placePickerRef.current!.blur();
        }

        if (!event.currentTarget.value && !!nearestCityByGeolocation) {
          setSelectedPlace(nearestCityByGeolocation);

          setIsPlacePickerFocus(false);
          placePickerRef.current!.blur();
        }

        setDestinationSearchActiveItemIndex(0);
      }

      if (event.key === 'Tab') {
        setIsPlacePickerFocus(false);

        setIsDatePickerFromFocus(true);
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();

        handlePreviousItem(
          destinationSearchActiveItemIndex,
          setDestinationSearchActiveItemIndex,
          'destinationSearchQueryList',
          'destinationItem',
        );
      }
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        handleNextItem(
          destinationSearchActiveItemIndex,
          setDestinationSearchActiveItemIndex,
          destinationSearchQueryPlaces.length,
          'destinationSearchQueryList',
          'destinationItem',
        );
      }

      requestAnimationFrame(() => (keyPressRef.current = false));
    },
    [
      placePickerRef,
      destinationSearchQueryPlaces,
      nearestCityByGeolocation,
      handlePreviousItem,
      handleNextItem,
      destinationSearchActiveItemIndex,
    ],
  );

  const handleOnPlaceSelected = useCallback<(place: Place | PlacesHotelsPopularCity) => void>(
    (place: Place | PlacesHotelsPopularCity) => {
      setSelectedPlace(place);

      setIsPlacePickerFocus(false);
    },
    [],
  );

  const getPlaceDisplay = useCallback<(place: Place) => JSX.Element>(
    (place: Place) => {
      return (
        <>
          {place.type === 'city' ? <i className={clsx(styles.icon, styles.cityGrey)}></i> : null}
          {place.type === 'region' ? (
            <i className={clsx(styles.icon, styles.regionGrey)}></i>
          ) : null}
          {place.type === 'district' ? (
            <i className={clsx(styles.icon, styles.districtGrey)}></i>
          ) : null}
          {place.type === 'hotel' ? <i className={clsx(styles.icon, styles.hotelGrey)}></i> : null}

          <div className={styles.labelAndHotelCount}>
            <div className={styles.label}>{getPlaceLabel(place)}</div>

            {place.type !== 'hotel' ? (
              <div className={styles.hotelCount}>
                {translateText(translations.hotels_count, locale, place.hotelCount!)}
              </div>
            ) : null}
          </div>
        </>
      );
    },
    [translations],
  );

  useEffect(() => {
    if (isPlacePickerFocus) {
      setPlacePickerPlaceholder(translations.search_by as string);
    } else {
      setPlacePickerPlaceholder(translations.destination as string);
    }
  }, [isPlacePickerFocus, translations]);

  useEffect(() => {
    if (!!selectedPlace) {
      setDestinationSearchQuery(getPlaceLabel(selectedPlace));
    }
    setIsDestinationSearchQueryValidated(false);
    setDestinationSearchQueryHasError(false);
  }, [selectedPlace]);
  //#endregion

  //#region Date picker events and helper functions
  const handleOnDatePickerFromFocus = useCallback(() => {
    setIsPlacePickerFocus(false);
    setIsGuestRoomFocus(false);

    setIsDatePickerFromFocus(true);
    setIsDatePickerToFocus(false);

    triggerService.resetTrigger();
  }, []);

  const handleOnDatePickerToFocus = useCallback(() => {
    setIsPlacePickerFocus(false);
    setIsGuestRoomFocus(false);

    if (!checkInDateMilliseconds) {
      setIsDatePickerFromFocus(true);
      setIsDatePickerToFocus(false);
    } else {
      setIsDatePickerToFocus(true);
      setIsDatePickerFromFocus(false);
    }
  }, [checkInDateMilliseconds]);

  // Open calendar if open_calendar search param exist
  useEffect(() => {
    if (openCalendarSearchParam !== null) {
      const setDatePickerFocusTimeout: NodeJS.Timeout = setTimeout(() => {
        setIsDatePickerFromFocus(true);
        setIsDatePickerToFocus(false);
      }, 600);

      return () => {
        clearTimeout(setDatePickerFocusTimeout);
      };
    }
  }, [openCalendarSearchParam]);

  useEffect(() => {
    if ((isDatePickerFromFocus || isDatePickerToFocus) && selectedDate) {
      const selectedDateMilliseconds = selectedDate.getTime();

      if (isDatePickerFromFocus) {
        setCheckInDateMilliseconds(selectedDateMilliseconds);

        if (!!checkOutDateMilliseconds && selectedDateMilliseconds > checkOutDateMilliseconds) {
          setCheckOutDateMilliseconds(undefined);
        }
        setIsCheckInDateValidated(false);
        setCheckInDateHasError(false);
        setIsDatePickerFromFocus(false);
        setIsDatePickerToFocus(true);
      } else if (isDatePickerToFocus) {
        if (!!checkInDateMilliseconds && checkInDateMilliseconds !== selectedDateMilliseconds) {
          setCheckOutDateMilliseconds(selectedDateMilliseconds);
          setIsCheckOutDateValidated(false);
          setCheckOutDateHasError(false);
          setIsDatePickerFromFocus(false);
          setIsDatePickerToFocus(false);
        }
      }

      setSelectedDate(undefined);
    }
  }, [isDatePickerFromFocus, isDatePickerToFocus, selectedDate]);

  useEffect(() => {
    const searchFormCurrent = searchFormWrapper.current;

    const handleWindowScroll = (ev: Event) => {
      const window = ev.currentTarget as Window;
      setSearchFormHeight(searchFormCurrent?.clientHeight ?? 0);

      if (
        window.scrollY > HEADER_HEIGHT + (searchFormCurrent?.offsetTop ?? 0) &&
        (!isHalfScreenMap || isDetailPage)
      ) {
        setIsStickySearchLayout(true);
      } else {
        setIsStickySearchLayout(false);
      }
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, [isHalfScreenMap, isDetailPage]);

  useEffect(() => {
    // Subscribe to the observable
    const subscription: Subscription = triggerService.trigger$.subscribe((triggered) => {
      if (triggered) {
        // Execute function when triggered
        handleOnDatePickerFromFocus();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [handleOnDatePickerFromFocus]);

  //#endregion

  //#region Guest room picker events and helper functions
  const handleOnGuestRoomPickerFocus = useCallback(() => {
    setIsPlacePickerFocus(false);
    setIsDatePickerFromFocus(false);
    setIsDatePickerToFocus(false);

    setIsGuestRoomFocus(true);
  }, []);

  const guestRoomLabel = useCallback<(guestRooms: Array<GuestRoom>) => string | undefined>(
    (guestRooms: Array<GuestRoom>) => {
      const roomCount = guestRooms.length;
      if (roomCount > 0) {
        const guestCount = getGuestsCount(guestRooms);
        const childrenCount = guestRooms.reduce(
          (accumulator: number, currentValue: GuestRoom) =>
            accumulator + currentValue.childrenCount,
          0,
        );
        let guestLabel = translateText(translations.adults_count, locale, guestCount);

        if (childrenCount > 0) {
          guestLabel = translateText(translations.guest_count, locale, guestCount);
        }
        return translateText(
          translations.adult_in_room,
          locale,
          guestLabel,
          translateText(translations.room_count, locale, roomCount),
        );
      }
      return undefined;
    },
    [translations],
  );

  const handleOnAddRoom = useCallback(() => {
    setGuestRooms([...guestRooms, { adultsCount: 1, childrenCount: 0, childrenAges: [] }]);
  }, [guestRooms]);

  const handleOnRemoveRoom = useCallback<(roomIndex: number) => void>(
    (roomIndex: number) => {
      const cloneGuestRooms = [...guestRooms];

      cloneGuestRooms.splice(roomIndex, 1);

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );

  const handleOnAddGuest = useCallback<(type: 'adult' | 'child', roomIndex: number) => void>(
    (type: 'adult' | 'child', roomIndex: number) => {
      const cloneGuestRooms = [...guestRooms];

      const toBeUpdatedGuestRoom = cloneGuestRooms[roomIndex];

      if (type === 'adult') {
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          adultsCount: toBeUpdatedGuestRoom.adultsCount + 1,
        });
      } else {
        toBeUpdatedGuestRoom.childrenAges.push(12);
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          childrenCount: toBeUpdatedGuestRoom.childrenCount + 1,
        });
      }

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );

  const handleOnMinusGuest = useCallback<(type: 'adult' | 'child', roomIndex: number) => void>(
    (type: 'adult' | 'child', roomIndex: number) => {
      const cloneGuestRooms = [...guestRooms];

      const toBeUpdatedGuestRoom = cloneGuestRooms[roomIndex];

      if (type === 'adult') {
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          adultsCount: toBeUpdatedGuestRoom.adultsCount - 1,
        });
      } else {
        toBeUpdatedGuestRoom.childrenAges.pop();
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          childrenCount: toBeUpdatedGuestRoom.childrenCount - 1,
        });
      }

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );

  const handleOnChildAgeChange = useCallback<
    (roomIndex: number, childAgeIndex: number, age: number) => void
  >(
    (roomIndex: number, childAgeIndex: number, age: number) => {
      const cloneGuestRooms = [...guestRooms];

      const toBeUpdatedGuestRoom = cloneGuestRooms[roomIndex];

      toBeUpdatedGuestRoom.childrenAges[childAgeIndex] = age;

      cloneGuestRooms.splice(roomIndex, 1, { ...toBeUpdatedGuestRoom });

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );
  //#endregion

  const handleFormSubmit = useCallback<(event: React.FormEvent<HTMLFormElement>) => void>(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setHotelHovered(undefined);
      setIsDestinationSearchQueryValidated(true);
      setIsCheckInDateValidated(true);
      setIsCheckOutDateValidated(true);

      let hasError = false;

      if (!selectedPlace) {
        hasError = true;
        setDestinationSearchQueryHasError(true);
      }

      const yesterdayMilliseconds = yesterdayDate.setHours(0, 0, 0, 0);

      if (!checkInDateMilliseconds || checkInDateMilliseconds < yesterdayMilliseconds) {
        hasError = true;
        setCheckInDateHasError(true);
      }

      if (
        !checkOutDateMilliseconds ||
        (!!checkInDateMilliseconds && checkOutDateMilliseconds <= checkInDateMilliseconds)
      ) {
        hasError = true;
        setCheckOutDateHasError(true);
      }

      if (!hasError && !!selectedPlace && !!checkInDateMilliseconds && !!checkOutDateMilliseconds) {
        const checkInDate: string = dateApiFormat(new Date(checkInDateMilliseconds));
        const checkOutDate: string = dateApiFormat(new Date(checkOutDateMilliseconds));

        const searchParams = new URLSearchParams(search);

        searchParams.delete('guests');
        searchParams.delete('districts');
        searchParams.delete('search_id');
        searchParams.delete('open_calendar');
        searchParams.delete('wego_price');
        searchParams.delete('lat');
        searchParams.delete('long');
        searchParams.delete('radius');

        if (selectedPlace.type === 'hotel') {
          searchParams.delete('sort');
          searchParams.delete('order');
        } else {
          if (!searchParams.has('sort')) {
            searchParams.append(
              'sort',
              getHotelSortTypeSearchParamValue(HotelSortType.Recommended),
            );
          }

          if (!searchParams.has('order')) {
            searchParams.append('order', 'desc');
          }
        }

        const tempLocaleParam = pathname.split('/')[1];
        const localeParam = /^(?:[a-zA-Z]{2}|[a-zA-Z]{2}\-[a-zA-Z]{2})$/i.test(tempLocaleParam)
          ? tempLocaleParam
          : '';
        const productParam = pathname.split('/')[!!localeParam ? 2 : 1];

        let searchResultPathname = '';

        switch (selectedPlace.type) {
          case 'city':
            searchResultPathname = `/${localeParam}${
              !!localeParam ? `/${productParam}` : productParam
            }/searches/${selectedPlace.cityCode}/${checkInDate}/${checkOutDate}`;
            break;
          case 'district':
            searchParams.append('districts', selectedPlace.districtId!.toString());
            searchResultPathname = `/${localeParam}${
              !!localeParam ? `/${productParam}` : productParam
            }/searches/${selectedPlace.cityCode}/${checkInDate}/${checkOutDate}/d${
              selectedPlace.districtId
            }`;
            break;
          case 'region':
            searchResultPathname = `/${localeParam}${
              !!localeParam ? `/${productParam}` : productParam
            }/searches/q${selectedPlace.regionId}/${checkInDate}/${checkOutDate}`;
            break;
          case 'hotel':
            searchResultPathname = `/${localeParam}${
              !!localeParam ? `/${productParam}` : productParam
            }/searches/${selectedPlace.cityCode}/${checkInDate}/${checkOutDate}/${
              selectedPlace.id
            }`;
            break;
        }

        const newGuestsSearchParam = convertGuestRoomsToSearchParam(guestRooms);

        if (
          searchResultPathname.toLowerCase() !== pathname ||
          newGuestsSearchParam !== guestsSearchParam ||
          selectedPlace.type === 'hotel'
        ) {
          searchParams.append('guests', newGuestsSearchParam);

          setIsEditingDestinationSearchQuery(false);

          localStorageSave<HotelSearch>(HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY, {
            place: selectedPlace,
            checkInDateMilliseconds,
            checkOutDateMilliseconds,
            guestRooms,
          });

          navigate(
            { pathname: searchResultPathname.toLowerCase(), search: searchParams.toString() },
            { state },
          );
        } else {
          dispatch(updateTriggerSearchCounter(triggerSearchCounter + 1));
        }
      }
    },
    [
      selectedPlace,
      checkInDateMilliseconds,
      checkOutDateMilliseconds,
      guestRooms,
      guestsSearchParam,
      pathname,
      search,
      state,
      triggerSearchCounter,
    ],
  );

  return (
    <>
      {isStickySearchLayout ? <div style={{ height: searchFormHeight }}></div> : null}
      <div
        ref={searchFormWrapper}
        id='hotelSearchLayout'
        className={clsx(styles.container, isStickySearchLayout && styles.fixed)}
      >
        <form className={styles.hotelSearchForm} onSubmit={handleFormSubmit} noValidate={true}>
          <div className={styles.placePickerContainer}>
            <InputSelector
              className={styles.inputSelector}
              hasError={destinationSearchQueryHasError}
              id='destinationSearchQuery'
              isFocus={isPlacePickerFocus}
              listContainerId='destinationSearchQueryList'
              isValidated={isDestinationSearchQueryValidated}
              onChange={handleOnPlacePickerChange}
              onClear={handleOnPlacePickerClear}
              onFocus={handleOnPlacePickerFocus}
              onKeyDown={handleOnPlacePickerKeyDown}
              placeholder={placePickerPlaceholder}
              ref={placePickerRef}
              value={destinationSearchQuery}
            >
              {/* Nearby and Popular Cities */}
              {destinationSearchQueryPlaces.length === 0 ? (
                <>
                  {!!nearestCityByGeolocation ? (
                    <>
                      <div className={clsx(styles.category, styles.with8TopMargin)}>
                        {translations.nearby}
                      </div>

                      <div
                        className={clsx(styles.place, styles.with8TopMargin)}
                        onClick={() => handleOnPlaceSelected(nearestCityByGeolocation)}
                      >
                        {getPlaceDisplay(nearestCityByGeolocation)}
                      </div>
                    </>
                  ) : null}

                  {popularCities.length > 0 ? (
                    <>
                      <div className={clsx(styles.category, styles.with16TopMargin)}>
                        {translations.popular_cities}
                      </div>

                      <div className={styles.popularCities}>
                        {popularCities.map((popularCity) => (
                          <div
                            key={popularCity.code}
                            className={styles.popularCity}
                            onClick={() => handleOnPlaceSelected(popularCity)}
                          >
                            <div>{popularCity.cityName}</div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}

              {/* Place */}
              {!!destinationSearchQuery && destinationSearchQueryPlaces.length > 0 ? (
                <>
                  {destinationSearchQueryPlaces.map((destinationSearchQueryPlace, index) => (
                    <div
                      key={index}
                      id={`destinationItem-${index}`}
                      className={clsx(
                        styles.place,
                        destinationSearchActiveItemIndex === index && styles.active,
                      )}
                      onClick={() => handleOnPlaceSelected(destinationSearchQueryPlace)}
                    >
                      {getPlaceDisplay(destinationSearchQueryPlace)}
                    </div>
                  ))}
                </>
              ) : null}
            </InputSelector>
          </div>

          <div className={styles.datePickerContainer}>
            <HotelDateSelector
              className={styles.hotelDateSelector}
              fromHasError={checkInDateHasError}
              fromPlaceholder={translations.check_in as string}
              fromValue={
                !!checkInDateMilliseconds
                  ? dateDisplayFormat(
                      new Date(checkInDateMilliseconds),
                      translations.short_months as Array<string>,
                      translations.short_weekdays as Array<string>,
                      locale === 'fa',
                      5,
                    )
                  : ''
              }
              isFromFocus={isDatePickerFromFocus}
              isFromValidated={isCheckInDateValidated}
              isRange={true}
              isToFocus={isDatePickerToFocus}
              isToValidated={isCheckOutDateValidated}
              onFromFocus={handleOnDatePickerFromFocus}
              onToFocus={handleOnDatePickerToFocus}
              toHasError={checkOutDateHasError}
              toPlaceholder={translations.check_out as string}
              toValue={
                !!checkOutDateMilliseconds
                  ? dateDisplayFormat(
                      new Date(checkOutDateMilliseconds),
                      translations.short_months as Array<string>,
                      translations.short_weekdays as Array<string>,
                      locale === 'fa',
                      5,
                    )
                  : ''
              }
            >
              <DatePicker
                firstDayOfWeek={
                  /^(?:BD|BH|DZ|EG|JO|KW|OM|QA|SA|TN)$/i.test(currentSite.countryCode) ? 7 : 1
                }
                isRtl={isRtl}
                limitFromMilliseconds={
                  !!checkInDateMilliseconds && isDatePickerToFocus
                    ? checkInDateMilliseconds
                    : limitFromMilliseconds
                }
                limitTillMilliseconds={limitToMilliseconds}
                locale={locale}
                onDateSelected={setSelectedDate}
                selectedFromDateMilliseconds={checkInDateMilliseconds}
                selectedToDateMilliseconds={checkOutDateMilliseconds}
                translations={translations}
              />
            </HotelDateSelector>
          </div>

          <div className={styles.guestRoomContainer}>
            <GuestRoomSelector
              isFocus={isGuestRoomFocus}
              onFocus={handleOnGuestRoomPickerFocus}
              placeholder={translations.guest_rooms as string}
              value={guestRoomLabel(guestRooms)}
            >
              <GuestRoomPicker
                guestRooms={guestRooms}
                maximumGuestLimit={40}
                maximumRoomLimit={10}
                onAddRoom={handleOnAddRoom}
                onApply={() => setIsGuestRoomFocus(false)}
                onChildAgeChange={handleOnChildAgeChange}
                onMinus={handleOnMinusGuest}
                onPlus={handleOnAddGuest}
                onRemoveRoom={handleOnRemoveRoom}
              />
            </GuestRoomSelector>
          </div>

          <button type='submit' className={styles.searchButton}>
            <SearchIcon className={styles.icon} />
          </button>
        </form>
      </div>

      <Outlet />
    </>
  );
};

export default HotelSearchLayout;

import type { TriggerSearchCounterActionType } from 'redux/triggerSearchCounterActionType';

const initialState: number = 0;

export default function (
  state: number = initialState,
  action: { type: TriggerSearchCounterActionType; payload: number },
): number {
  switch (action.type) {
    case 'UPDATE_TRIGGER_SEARCH_COUNTER':
      return action.payload;
    default:
      return state;
  }
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateNumber, translateText } from '@wego/alexis/helpers/translation';

import { getGuestsCount } from '@helpers/hotel';

import { getLocaleState, getTranslationsState } from '@redux/selectors';

import { GuestRoom } from '@wegoTypes/hotels/guestRoom';
import { Translations } from '@wegoTypes/translations';

import styles from './GuestRoomPicker.module.scss';

interface GuestRoomPickerProps {
  className?: string;
  guestRooms: Array<GuestRoom>;
  maximumGuestLimit: number;
  maximumRoomLimit: number;
  onAddRoom(): void;
  onApply(): void;
  onChildAgeChange(roomIndex: number, childAgeIndex: number, age: number): void;
  onMinus(type: 'adult' | 'child', roomIndex: number): void;
  onPlus(type: 'adult' | 'child', roomIndex: number): void;
  onRemoveRoom(roomIndex: number): void;
}

const GuestRoomPicker: React.FC<GuestRoomPickerProps> = ({
  className,
  guestRooms,
  maximumGuestLimit,
  maximumRoomLimit,
  onAddRoom,
  onApply,
  onChildAgeChange,
  onMinus,
  onPlus,
  onRemoveRoom,
}): JSX.Element => {
  const locale: string = useSelector(getLocaleState);
  const translations: Translations = useSelector(getTranslationsState);

  const [isAddGuestDisabled, setIsAddGuestDisabled] = useState<boolean>(false);
  const [isAddRoomDisabled, setIsAddRoomDisabled] = useState<boolean>(false);

  useEffect(() => {
    const guestCount = getGuestsCount(guestRooms);

    setIsAddGuestDisabled(guestCount >= maximumGuestLimit);
    setIsAddRoomDisabled(guestRooms.length >= maximumRoomLimit);
  }, [guestRooms]);

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.rooms}>
        {guestRooms.map((guestRoom, guestRoomIndex) => (
          <div key={guestRoomIndex} className={styles.room}>
            <div className={styles.roomNumberAndRemoveButton}>
              <div className={styles.roomNumber}>
                {translateText(translations.room_number, locale, guestRoomIndex + 1)}
              </div>

              {guestRoomIndex > 0 && guestRooms.length > 1 ? (
                <button type='button' onClick={() => onRemoveRoom(guestRoomIndex)}>
                  {translations.remove}
                </button>
              ) : null}
            </div>

            <div className={styles.guests}>
              {/* Adult */}
              <div className={styles.type}>
                <i className={clsx(styles.icon, styles.adultGrey)}></i>

                <div>
                  <div className={styles.label}>{translations.Adults}</div>
                  <div className={styles.requirement}>
                    {translateText(translations.years, locale, '>17')}
                  </div>
                </div>
              </div>

              <div className={clsx(styles.stepperContainer, styles.withRightMarging)}>
                <button
                  className={styles.minusSign}
                  type='button'
                  disabled={guestRoom.adultsCount < 2}
                  onClick={() => onMinus('adult', guestRoomIndex)}
                ></button>
                <div className={styles.counter}>
                  {translateNumber(guestRoom.adultsCount, locale === 'fa')}
                </div>
                <button
                  className={styles.plusSign}
                  type='button'
                  disabled={isAddGuestDisabled}
                  onClick={() => onPlus('adult', guestRoomIndex)}
                ></button>
              </div>

              {/* Child */}
              <div className={styles.type}>
                <i className={clsx(styles.icon, styles.childGrey)}></i>

                <div>
                  <div className={styles.label}>{translations.children}</div>
                  <div className={styles.requirement}>
                    {translateText(translations.years, locale, '≤17')}
                  </div>
                </div>
              </div>

              <div className={styles.stepperContainer}>
                <button
                  className={styles.minusSign}
                  type='button'
                  disabled={guestRoom.childrenCount < 1}
                  onClick={() => onMinus('child', guestRoomIndex)}
                ></button>
                <div className={styles.counter}>
                  {translateNumber(guestRoom.childrenCount, locale === 'fa')}
                </div>
                <button
                  className={styles.plusSign}
                  type='button'
                  disabled={isAddGuestDisabled}
                  onClick={() => onPlus('child', guestRoomIndex)}
                ></button>
              </div>
            </div>

            {guestRoom.childrenCount > 0 ? (
              <>
                <div className={styles.ageOfChildrenLabel}>{translations.age_of_children}</div>

                <div className={styles.childrenAges}>
                  {guestRoom.childrenAges.map((childAge, childAgeIndex) => (
                    <div key={childAgeIndex} className={styles.childrenAge}>
                      <select
                        defaultValue={childAge}
                        onChange={(e) => {
                          onChildAgeChange(
                            guestRoomIndex,
                            childAgeIndex,
                            parseInt(e.currentTarget.value),
                          );
                        }}
                      >
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map(
                          (age) => (
                            <option key={age} value={age}>
                              {age === 0
                                ? `<${translateNumber(1, locale === 'fa')}`
                                : translateNumber(age, locale === 'fa')}
                            </option>
                          ),
                        )}
                      </select>

                      <div className={styles.customSelect}>
                        {childAge === 0
                          ? `<${translateNumber(1, locale === 'fa')}`
                          : translateNumber(childAge, locale === 'fa')}

                        <i className={clsx(styles.triangle, styles.down)}></i>
                      </div>
                    </div>
                  ))}
                </div>

                <div className={styles.childAgePresetDescription}>
                  {translations.preset_children_age}
                </div>
              </>
            ) : null}
          </div>
        ))}
      </div>
      <div className={styles.addRoomAndApply}>
        <button
          type='button'
          disabled={isAddRoomDisabled || isAddGuestDisabled}
          className={styles.addRoom}
          onClick={onAddRoom}
        >
          <div className={styles.plusSign}></div>
          {((translations.add_room as string) || '').toLowerCase()}
        </button>

        <button type='button' className={styles.apply} onClick={onApply}>
          {((translations.apply as string) || '').toLowerCase()}
        </button>
      </div>
    </div>
  );
};

export default GuestRoomPicker;

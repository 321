import { useSelector } from 'react-redux';

import { getMarketingPartnerPromo } from '@apis/marketing';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

import { MarketingPartnerPromo } from '@wegoTypes/marketing/marketingPatnerPromo';

const getMarketingPartnerPromoKey = 'marketing-partner-promo';

export const useGetMarketingPartnerPromo = <TResult = MarketingPartnerPromo>(
  options?: UseAxiosQueryOptions<MarketingPartnerPromo, TResult>,
) => {
  const apiBaseUrl = API_BASE_URL;
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [
      getMarketingPartnerPromoKey,
      {
        locale,
        currentSiteCode: currentSiteCode.countryCode,
      },
    ],
    queryFn: () =>
      getMarketingPartnerPromo(
        apiBaseUrl,
        locale,
        currentSiteCode.countryCode,
        'WEB_APP',
        'DESKTOP',
      ),
    ...options,
  });
};

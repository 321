import React, { type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import styles from './HotelDateSelector.module.scss';

interface HotelDateSelectorProps {
  className?: string;
  fromHasError?: boolean;
  fromPlaceholder: string;
  fromValue?: string;
  isFromFocus: boolean;
  isFromValidated: boolean;
  isRange: boolean;
  isToFocus?: boolean;
  isToValidated?: boolean;
  onFromFocus(): void;
  onToFocus?(): void;
  style?: React.CSSProperties;
  toHasError?: boolean;
  toPlaceholder?: string;
  toValue?: string;
}

const HotelDateSelector: React.FC<PropsWithChildren<HotelDateSelectorProps>> = ({
  children,
  className,
  fromHasError,
  fromPlaceholder,
  fromValue,
  isFromFocus,
  isFromValidated,
  isRange,
  isToFocus,
  isToValidated,
  onFromFocus,
  onToFocus,
  style,
  toHasError,
  toPlaceholder,
  toValue,
}): JSX.Element => {
  const containerDivRef = useRef<HTMLDivElement>(null);

  const [inputContainersMaxWidth, setInputContainersMaxWidth] = useState<string>();

  useEffect(() => {
    const initializeInputContainersMaxWidth = (): void => {
      if (!!containerDivRef) {
        setInputContainersMaxWidth(`${containerDivRef.current!.clientWidth}px`);
      }
    };

    window.addEventListener('resize', initializeInputContainersMaxWidth);

    const initializeInputContainersMaxWidthTimeout: NodeJS.Timeout = setTimeout(() => {
      initializeInputContainersMaxWidth();
    }, 500);

    return () => {
      window.removeEventListener('resize', initializeInputContainersMaxWidth);

      clearTimeout(initializeInputContainersMaxWidthTimeout);
    };
  }, [containerDivRef.current?.clientWidth, isRange]);

  const handleFromInputContainerClick = useCallback<
    (event: React.MouseEvent<HTMLDivElement>) => void
  >(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isFromFocus) {
        event.stopPropagation();
      }
      onFromFocus();
    },
    [isFromFocus, onFromFocus],
  );

  const handleToInputContainerClick = useCallback<
    (event: React.MouseEvent<HTMLDivElement>) => void
  >(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isFromFocus || isToFocus) {
        event.stopPropagation();
      }

      if (!!onToFocus) {
        onToFocus();
      }
    },
    [isFromFocus, isToFocus, onToFocus],
  );

  return (
    <div
      ref={containerDivRef}
      className={clsx(
        styles.container,
        className,
        (isFromFocus || isToFocus) && styles.focus,
        (isFromValidated || isToValidated) && styles.validated,
        !!(fromHasError || toHasError) && styles.error,
      )}
      style={style}
    >
      <div
        className={clsx(styles.inputContainers, isRange && styles.isRange)}
        style={{ maxWidth: inputContainersMaxWidth }}
      >
        <div
          className={clsx(
            styles.fromInputContainer,
            isFromFocus && styles.focus,
            isFromValidated && styles.validated,
            !!fromHasError && styles.error,
          )}
          onClick={handleFromInputContainerClick}
        >
          <div className={styles.input} data-testid='check-in-input-value'>
            {fromValue}
          </div>
          <label>{fromPlaceholder}</label>
        </div>

        {isRange ? (
          <>
            <div className={styles.verticalLine}></div>

            <div
              className={clsx(
                styles.toInputContainer,
                isToFocus && styles.focus,
                isToValidated && styles.validated,
                !!toHasError && styles.error,
              )}
              onClick={handleToInputContainerClick}
            >
              <div className={styles.input} data-testid='check-out-input-value'>
                {toValue}
              </div>
              <label>{toPlaceholder}</label>
            </div>
          </>
        ) : null}
      </div>

      <div
        className={clsx(styles.children, (isFromFocus || isToFocus) && styles.show)}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default HotelDateSelector;

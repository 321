import { clsx } from '@alexis/helpers/clsx';
import React, { type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { genzoTrackActionEvent } from '@wego/alexis/helpers/genzo';

import Switch from '@components/Switch';

import { DIRECT_ONLY_PARAM } from '@constants/flight';

import { getIsRtlState, getTranslationsState, getUserState } from '@redux/selectors';

import type { FlightSearchType } from '@wegoTypes/flights/flightSearchType';

import styles from './FlightDateSelector.module.scss';

export interface FlightDateSelectorProps {
  clientId: string | undefined;
  clientSessionId: string | undefined;
  className?: string;
  fromHasError?: boolean;
  fromMillisecond?: number;
  fromValue?: string;
  isFromFocus: boolean;
  isFromValidated: boolean;
  isToFocus?: boolean;
  isToValidated?: boolean;
  limitFromMilliseconds?: number;
  limitTillMilliseconds: number;
  onDateChanged(value: Date, field: 'inbound' | 'outbound'): void;
  onDateReset(): void;
  onFromFocus(): void;
  onToFocus?(): void;
  onFromDateKeyDown(event: React.KeyboardEvent<HTMLDivElement>): void;
  onToDateKeyDown?(event: React.KeyboardEvent<HTMLDivElement>): void;
  onRemoveReturnDateClick(): void;
  pageViewId: string;
  searchType: FlightSearchType;
  style?: React.CSSProperties;
  toHasError?: boolean;
  toMillisecond?: number;
  toValue?: string;
  isShowFareCalendar: boolean;
  isShowFareCalendarNote: boolean;
}

const FlightDateSelector: React.FC<PropsWithChildren<FlightDateSelectorProps>> = ({
  clientId,
  clientSessionId,
  children,
  className,
  fromHasError,
  fromMillisecond,
  fromValue,
  isFromFocus,
  isFromValidated,
  searchType,
  isToFocus,
  isToValidated,
  limitFromMilliseconds,
  limitTillMilliseconds,
  onDateChanged,
  onDateReset,
  onFromFocus,
  onToFocus,
  onFromDateKeyDown,
  onToDateKeyDown,
  pageViewId,
  style,
  toHasError,
  toMillisecond,
  toValue,
  isShowFareCalendar,
  isShowFareCalendarNote,
}) => {
  const user = useSelector(getUserState);
  const translations = useSelector(getTranslationsState);
  const isRtl = useSelector(getIsRtlState);

  const containerDivRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const [inputContainersMaxWidth, setInputContainersMaxWidth] = useState<string>();

  const isOneWay = searchType === 'oneWay';
  const isRange = searchType === 'roundTrip';

  const [searchParams] = useSearchParams();
  const { state, pathname } = useLocation();

  const isDirectFlightOnly = searchParams.has(DIRECT_ONLY_PARAM);

  useEffect(() => {
    if (!isShowFareCalendar) {
      const initializeInputContainersMaxWidth = (): void => {
        if (containerDivRef.current?.clientWidth) {
          setInputContainersMaxWidth(`${containerDivRef.current.clientWidth}px`);
        }
      };

      window.addEventListener('resize', initializeInputContainersMaxWidth);

      const initializeInputContainersMaxWidthTimeout: NodeJS.Timeout = setTimeout(() => {
        initializeInputContainersMaxWidth();
      });

      return () => {
        window.removeEventListener('resize', initializeInputContainersMaxWidth);

        clearTimeout(initializeInputContainersMaxWidthTimeout);
      };
    }
  }, [containerDivRef.current?.clientWidth, isRange, isOneWay, isShowFareCalendar]);

  const handleFromInputContainerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      if (isFromFocus) {
        event.stopPropagation();
      }
      onFromFocus();
    },
    [isFromFocus, onFromFocus],
  );

  const handleToInputContainerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>): void => {
      if (isFromFocus || isToFocus) {
        event.stopPropagation();
      }

      onToFocus?.();
    },
    [isFromFocus, isToFocus, onToFocus],
  );

  const isValid = useCallback(
    (isNext: boolean, isInboundDate?: boolean): boolean => {
      const fromMillisecondToCompare = fromMillisecond || new Date().setHours(0, 0, 0, 0);

      // Inbound date valid check
      if (isInboundDate) {
        if (toMillisecond) {
          if (isNext) {
            return toMillisecond < limitTillMilliseconds;
          }

          return fromMillisecondToCompare < toMillisecond;
        }

        return false;
      }

      // Outbound date valid check
      if (isNext) {
        if (toMillisecond) {
          return fromMillisecondToCompare < toMillisecond;
        }

        return fromMillisecondToCompare < limitTillMilliseconds;
      }

      return fromMillisecondToCompare > (limitFromMilliseconds || new Date().setHours(0, 0, 0, 0));
    },
    [fromMillisecond, limitFromMilliseconds, limitTillMilliseconds, toMillisecond],
  );

  const handleChevronClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isNext: boolean, isInboundDate?: boolean) => {
      e.stopPropagation();

      if (!isValid(isNext, isInboundDate)) {
        return;
      }

      if (isNext) {
        const nextDate = new Date(isInboundDate ? toMillisecond! : fromMillisecond!);
        nextDate.setDate(nextDate.getDate() + 1);

        onDateChanged(nextDate, isInboundDate ? 'inbound' : 'outbound');
      } else {
        const previousDate = new Date(isInboundDate ? toMillisecond! : fromMillisecond!);
        previousDate.setDate(previousDate.getDate() - 1);

        onDateChanged(previousDate, isInboundDate ? 'inbound' : 'outbound');
      }
    },
    [fromMillisecond, isValid, onDateChanged, toMillisecond],
  );

  const handleDateReset: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      if (clientId && clientSessionId) {
        const eventData = {
          id: pageViewId,
          category: 'search_form',
          object: isOneWay ? 'oneway' : isRange ? 'roundtrip' : 'multicity',
          action: 'clear_calendar',
          value: '',
        };

        genzoTrackActionEvent(API_BASE_URL, clientId, clientSessionId, user?.userHash, eventData);
      }

      onDateReset();
      onFromFocus();
    },
    [clientId, clientSessionId, onDateReset, onFromFocus, pageViewId, user?.userHash],
  );

  const handleDirectFlightToggle = useCallback(() => {
    if (isDirectFlightOnly) {
      searchParams.delete(DIRECT_ONLY_PARAM);
    } else {
      searchParams.set(DIRECT_ONLY_PARAM, 'true');
    }

    if (clientId && clientSessionId) {
      const eventData = {
        id: pageViewId,
        category: 'search_form',
        object: 'fare_calendar',
        action: 'direct_flights_prices',
        value: !isDirectFlightOnly ? 'TRUE' : 'FALSE',
      };

      genzoTrackActionEvent(API_BASE_URL, clientId, clientSessionId, user?.userHash, eventData);
    }

    navigate({ pathname, search: searchParams.toString() }, { replace: true, state });
  }, [
    clientId,
    clientSessionId,
    isDirectFlightOnly,
    pageViewId,
    pathname,
    searchParams,
    state,
    user?.userHash,
  ]);

  //Align the fare calendar to the bottom of the page when it is not visible
  useEffect(() => {
    if (!(isFromFocus || isToFocus) && containerDivRef.current) {
      containerDivRef.current.style.removeProperty('top');
    } else {
      const timeout = setTimeout(() => {
        if (containerDivRef.current) {
          const rect = containerDivRef.current.getBoundingClientRect();

          if (rect.bottom > window.innerHeight && containerDivRef.current) {
            const heightOfBottomBar = window.innerHeight - rect.bottom - 16;
            containerDivRef.current.style.top = `${heightOfBottomBar}px`;
          } else {
            containerDivRef.current.style.top = '-16px';
          }
        }
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isFromFocus, isToFocus, isShowFareCalendarNote]);

  return (
    <div
      ref={containerDivRef}
      className={clsx(
        styles.container,
        isShowFareCalendar && styles.fareCalendar,
        className,
        (isFromFocus || isToFocus) && styles.focus,
        (isFromValidated || isToValidated) && styles.validated,
        !!(fromHasError || toHasError) && styles.error,
      )}
      style={style}
    >
      <div
        className={clsx(styles.inputContainers, isRange && styles.isRange)}
        style={{ maxWidth: isShowFareCalendar ? 1016 : inputContainersMaxWidth }}
      >
        {(isFromFocus || isToFocus) && (
          <button
            disabled={!fromValue && !toValue}
            type='button'
            onClick={handleDateReset}
            className={styles.clear}
          >
            {translations.clear}
          </button>
        )}

        <div className={styles.dateInputsContainer}>
          <div
            className={clsx(
              styles.fromInputContainer,
              isFromFocus && styles.focus,
              isFromValidated && styles.validated,
              !!fromHasError && styles.error,
            )}
            id='from-input-container'
            data-testid='from-input-container'
            onClick={handleFromInputContainerClick}
            tabIndex={2}
            onKeyDown={onFromDateKeyDown}
          >
            <div data-testid='from-input-value' className={styles.input}>
              {fromValue}
            </div>
            <label>{translations.depart}</label>

            <div className={styles.leftAndRightChevronContainer}>
              <div
                className={clsx(
                  styles.chevronContainer,
                  styles.left,
                  (isFromFocus || isToFocus || !fromMillisecond) && styles.hide,
                )}
                data-testid='from-chevron-left'
                onClick={(e) => handleChevronClick(e, isRtl)}
              >
                <div
                  className={clsx(styles.chevron, styles.left, !isValid(isRtl) && styles.disabled)}
                />
              </div>

              <div
                className={clsx(
                  styles.chevronContainer,
                  styles.right,
                  (isFromFocus || isToFocus || !fromMillisecond) && styles.hide,
                )}
                data-testid='from-chevron-right'
                onClick={(e) => handleChevronClick(e, !isRtl)}
              >
                <div
                  className={clsx(
                    styles.chevron,
                    styles.right,
                    !isValid(!isRtl) && styles.disabled,
                  )}
                />
              </div>
            </div>
          </div>

          {isRange ? (
            <>
              <div className={styles.verticalLine} />

              <div
                className={clsx(
                  styles.toInputContainer,
                  isToFocus && styles.focus,
                  isToValidated && styles.validated,
                  !!toHasError && styles.error,
                )}
                id='to-input-container'
                data-testid='to-input-container'
                onClick={handleToInputContainerClick}
                tabIndex={2}
                onKeyDown={onToDateKeyDown}
              >
                <div data-testid='to-input-value' className={styles.input}>
                  {toValue}
                </div>
                <label>{translations.return}</label>

                <div className={styles.chevronAndRemoveReturnContainer}>
                  <div className={styles.leftAndRightChevronContainer}>
                    <div
                      className={clsx(
                        styles.chevronContainer,
                        styles.left,
                        (isFromFocus || isToFocus || !toMillisecond) && styles.hide,
                      )}
                      data-testid='to-chevron-left'
                      onClick={(e) => handleChevronClick(e, isRtl, true)}
                    >
                      <div
                        className={clsx(
                          styles.chevron,
                          styles.left,
                          !isValid(isRtl, true) && styles.disabled,
                        )}
                      />
                    </div>

                    <div
                      className={clsx(
                        styles.chevronContainer,
                        styles.right,
                        (isFromFocus || isToFocus || !toMillisecond) && styles.hide,
                      )}
                      data-testid='to-chevron-right'
                      onClick={(e) => handleChevronClick(e, !isRtl, true)}
                    >
                      <div
                        className={clsx(
                          styles.chevron,
                          styles.right,
                          !isValid(!isRtl, true) && styles.disabled,
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>

      {isShowFareCalendarNote && (isFromFocus || isToFocus) && (
        <div className={styles.directFlightToggleContainer} onClick={(e) => e.stopPropagation()}>
          <Switch isRtl={isRtl} checked={isDirectFlightOnly} onClick={handleDirectFlightToggle} />
          {translations.fare_calendar_direct_flights}
        </div>
      )}

      <div
        className={clsx(styles.children, (isFromFocus || isToFocus) && styles.show)}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default FlightDateSelector;

import Spinner from '@alexis/components/Spinner';
import { facebookSignOut, googleSignOut, wegoSignOut } from '@alexis/helpers/authentications';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import { translateText } from '@alexis/helpers/translation';
import { SELECTED_LEG_ID_PLS_PARAM } from '@pages/FlightSearchResult/constants';
import cuid from 'cuid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { LoggedInType } from '@wego/alexis/src/types/helpers/loggedInType';
import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';
import type { User } from '@wego/alexis/types/helpers/user';

import DropdownProfile from '@components/DropdownProfile';
import Login from '@components/Login';
import Portal from '@components/Portal';
import PreferencesSideNavigationLink from '@components/preferences/PreferenceSideNavigationLink';
import Price from '@components/Price';
import ResendConfirmation from '@components/ResendConfirmation';
import ResetPassword from '@components/ResetPassword';
import ShopCashInfo from '@components/shopcash/ShopCashInfo';
import SignUp from '@components/SignUp';
import SiteLocaleAndCurrencyPicker from '@components/SiteLocaleAndCurrencyPicker';

import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, WEGO_CLIENT_ID } from '@constants/env';
import { DIRECT_ONLY_PARAM, TEST_FEATURES_PARAM } from '@constants/flight';
import { PORTAL_MODAL_Z_INDEX } from '@constants/layout';

import { useSprinklrChat } from '@context/Sprinklrchat';

import {
  deleteFilterStatesFromSearchParam,
  getFlightRecentSearchesForComparing,
} from '@helpers/flight';
import { checkIfFlightSearchResultPage } from '@helpers/flight';
import { flightsRoute, hotelsNavUrl } from '@helpers/routeTranslation';
import { isWegoShopcashEnabled } from '@helpers/shopcash';
import { webEngageTrackLogin, webEngageTrackLogout } from '@helpers/webEngage';

import AccountIcon from '@icons/account.svg';
import BusinessIcon from '@icons/businessOutline.svg';
import HeadsetIcon from '@icons/headset.svg';
import WalletIcon from '@icons/wallet.svg';

import { useBodyScrollable } from '@hooks/useBodyScrollable';
import { useHandleLoginEffect } from '@hooks/useHandleLoginEffect';
import { useIsAuthNew } from '@hooks/useIsAuthNew';
import useLocaleParam from '@hooks/useLocaleParam';
import { useViewportSize } from '@hooks/useViewportSize';

import { changeUser, resetUser } from '@redux/actions/userActions';
import { getPageViewIdState } from '@redux/selectors';

import { Currency } from '@wegoTypes/currency';
import { Translations } from '@wegoTypes/translations';

import styles from './Header.module.scss';

interface HeaderProps {
  className?: string;
  currency: Currency;
  currentSite: CurrentSite;
  domainBasedApiBaseUrl: string;
  exchangeRate: ExchangeRate;
  authButtonLoading: boolean;
  isBookingComparisonAndCheckoutPage: boolean;
  locale: string;
  productParam: string;
  setAuthButtonLoading: React.Dispatch<React.SetStateAction<boolean>>;
  shopCashUser: ShopCashUser | null;
  showProductLinks: boolean;
  theme: Theme;
  translations: Translations;
  user: User | null;
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
}

const Header: React.FC<HeaderProps> = ({
  className,
  currency,
  currentSite,
  domainBasedApiBaseUrl,
  exchangeRate,
  isBookingComparisonAndCheckoutPage,
  authButtonLoading,
  locale,
  productParam,
  setAuthButtonLoading,
  shopCashUser,
  showProductLinks,
  theme,
  translations,
  user,
  wegoAnalyticsClientSessionId,
  wegoAnalyticsClientId,
}): JSX.Element => {
  const localeParam = useLocaleParam();

  const [isAuthenticationExpanded, setIsAuthenticationExpanded] = useState<boolean>(false);
  const [isAdditionalLinksSectionExpanded, setIsAdditionalLinksSectionExpanded] =
    useState<boolean>(false);

  let flightSearchesInStore = getFlightRecentSearchesForComparing();

  const { width } = useViewportSize();

  const dispatch = useDispatch();

  const { pathname, search, state } = useLocation();

  const navigate = useNavigate();
  const { isAuthNew, login, logout, usedNewLogin, coreConfigsLoaded } = useIsAuthNew();

  const pageViewIdState = useSelector(getPageViewIdState);

  const trainsCUID = cuid();

  const [searchParams] = useSearchParams();
  const modalSearchParam = searchParams.get('modal');

  const [, setIsBodyScrollable] = useBodyScrollable();

  const { sprinklrChat } = useSprinklrChat();

  // Remove all filter params from search
  const searchWithoutExtraParams = useMemo<string>(() => {
    const searchParams = new URLSearchParams(search);

    searchParams.delete('modal');
    searchParams.delete('search_id');
    searchParams.delete('guests');
    searchParams.delete('sort');
    searchParams.delete('order');
    searchParams.delete('name');
    searchParams.delete('deals');
    searchParams.delete('amenities');
    searchParams.delete('providers');
    searchParams.delete('brands');
    searchParams.delete('chains');
    searchParams.delete('districts');
    searchParams.delete('price');
    searchParams.delete('accommodation_types');
    searchParams.delete('free_amenities');
    searchParams.delete('guest_rating');
    searchParams.delete('guest_score');
    searchParams.delete('stars');
    searchParams.delete('provider_codes');
    searchParams.delete('open_calendar');
    searchParams.delete('outbound_date');
    searchParams.delete('inbound_date');
    searchParams.delete('dep_code');
    searchParams.delete('arr_code');
    searchParams.delete('trip_type');
    searchParams.delete('reference_id');
    searchParams.delete('search_id');
    searchParams.delete('sponsored_ad_id');
    searchParams.delete('booking_id');
    searchParams.delete('booking_page_view_id');
    searchParams.delete('selected_hotel_ids');
    searchParams.delete('payment_methods');
    searchParams.delete('view');
    searchParams.delete(TEST_FEATURES_PARAM);
    searchParams.delete('session_id');
    searchParams.delete('selected_trip_id');
    searchParams.delete('search_id');
    searchParams.delete('orderId');
    searchParams.delete('branded_fare');
    searchParams.delete(SELECTED_LEG_ID_PLS_PARAM);
    searchParams.delete(DIRECT_ONLY_PARAM);

    const updatedSearchParams = deleteFilterStatesFromSearchParam(searchParams).toString();

    return !!updatedSearchParams ? `?${updatedSearchParams}` : '';
  }, [search]);

  const isPagePaymentVerification = /(?:verify)/i.test(pathname);

  const showShopcash: boolean = useMemo(
    () => isWegoShopcashEnabled(currentSite.countryCode),
    [currentSite.countryCode],
  );

  const shopcashLink: string = useMemo(() => {
    const siteCode = currentSite.countryCode;
    const domain = siteCode == 'SA' ? 'shopcash.sa' : 'shopcash.ae';
    const normalizedLocale = ['ar', 'en'].includes(locale) ? locale : 'en';
    const defaultLocale = siteCode == 'SA' ? 'ar' : 'en';

    return `https://${domain}${normalizedLocale === defaultLocale ? '' : `/${locale}`}`;
  }, [currentSite.countryCode, locale]);

  const showBlog: boolean = useMemo(
    () => ['en', 'ar', 'id', 'th', 'zh-hk', 'zh-tw'].includes(locale),
    [locale],
  );

  const blogLink: string = useMemo(() => {
    switch (locale) {
      case 'ar':
        return 'https://rahhal.wego.com';
      case 'id':
        return 'https://www.wego.co.id/berita';
      case 'th':
        return 'https://travel.wego.com/th';
      case 'zh-hk':
      case 'zh-tw':
        return 'https://travel.wego.com/zh-tw';

      default:
        return 'https://blog.wego.com';
    }
  }, [locale]);

  const showTrain: boolean = useMemo(
    () => currentSite.countryCode === 'IN',
    [currentSite.countryCode],
  );

  const trainLink: string = useMemo(
    () => `https://trains.wego.com?wego_click_id=${trainsCUID}`,
    [trainsCUID],
  );

  const showWegoProNav = useMemo(() => width >= 1280, [width]);

  const additionalLinks = useMemo(() => {
    return [
      {
        id: 'wegoPro',
        href: 'https://www.wegopro.com/?utm_source=wego&utm_medium=web&utm_campaign=top-nav-bar',
        isVisible: !showWegoProNav,
        label: translations.business_travel,
      },
      {
        id: 'shopCash',
        href: shopcashLink,
        isVisible: showShopcash,
        label: translations.cashback,
      },
      {
        id: 'blog',
        href: blogLink,
        isVisible: showBlog,
        label: translations.travel_guides,
      },
      {
        id: 'train',
        href: trainLink,
        isVisible: showTrain,
        label: translations.wego_trains,
      },
    ];
  }, [
    blogLink,
    shopcashLink,
    showBlog,
    showShopcash,
    showTrain,
    showWegoProNav,
    trainLink,
    translations.business_travel,
    translations.cashback,
    translations.travel_guides,
    translations.wego_trains,
  ]);

  const visibleAdditionalLinks = useMemo(
    () => additionalLinks.filter((link) => link.isVisible),
    [additionalLinks],
  );

  const isFlightSearchResultPage = useMemo<boolean>(() => {
    return checkIfFlightSearchResultPage(pathname, localeParam);
  }, [localeParam, pathname]);

  const handleOnWegoProClick = useCallback(() => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      const eventData = {
        id: pageViewIdState.home,
        category: 'header',
        object: 'wegopro_businesstravel',
        action: 'clicked',
        value: '',
      };
      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        undefined,
        eventData,
      );
    }
  }, [pageViewIdState.home, wegoAnalyticsClientId, wegoAnalyticsClientSessionId]);

  const renderAdditionalLinks = useCallback(() => {
    return (
      <>
        {showWegoProNav ? (
          <a
            className={styles.productLink}
            href='https://www.wegopro.com/?utm_source=wego&utm_medium=web&utm_campaign=top-nav-bar'
            target='_blank'
            rel='noreferrer'
            onClick={() => handleOnWegoProClick()}
          >
            WegoPro
          </a>
        ) : null}

        {showBlog && width > 1279 && (
          <a className={styles.productLink} href={blogLink} target='_blank' rel='noreferrer'>
            {translations.travel_guides}
          </a>
        )}

        {visibleAdditionalLinks.length > 0 && width < 1280 ? (
          <div
            className={clsx(styles.productLink, styles.additionalLinks)}
            onClick={() => setIsAdditionalLinksSectionExpanded((prev) => !prev)}
          >
            {translations.more}
            <i
              className={clsx(
                styles.triangle,
                isAdditionalLinksSectionExpanded ? styles.up : styles.down,
              )}
            ></i>

            {isAdditionalLinksSectionExpanded && (
              <div className={styles.additionalLinksPopover}>
                {visibleAdditionalLinks.map((link) => {
                  return (
                    <a
                      key={link.id}
                      className={clsx(styles.productLink, styles.dropdownItem)}
                      rel={link.id === 'train' ? 'noreferrer nofollow' : 'noreferrer'}
                      href={link.href}
                      target='_blank'
                      onClick={() => {
                        if (link.id === 'wegoPro') handleOnWegoProClick();
                      }}
                    >
                      {link.id === 'wegoPro' ? <strong>WegoPro&nbsp;</strong> : null}
                      {link.label}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        ) : null}
      </>
    );
  }, [
    handleOnWegoProClick,
    isAdditionalLinksSectionExpanded,
    showWegoProNav,
    translations.business_travel,
    translations.more,
    visibleAdditionalLinks,
  ]);

  const showModal = useCallback<
    (type: 'login' | 'signup' | 'resetpassword' | 'resendconfirmation' | 'shopcash' | null) => void
  >(
    (type: 'login' | 'signup' | 'resetpassword' | 'resendconfirmation' | 'shopcash' | null) => {
      const searchParams = new URLSearchParams(search);
      searchParams.delete('modal');

      if (!!type) {
        searchParams.append('modal', type);
      }

      navigate({ pathname, search: searchParams.toString() }, { replace: true, state });
    },
    [navigate, pathname, search, state],
  );

  const handleOnDropdownButtonClick = useCallback<
    (dropdownType: 'currentSite' | 'locale' | 'currency' | 'authentication' | 'shopcash') => void
  >(
    (dropdownType: 'currentSite' | 'locale' | 'currency' | 'authentication' | 'shopcash') => {
      switch (dropdownType) {
        case 'authentication': {
          if (!user) {
            const trackLoginEvent = () => {
              if (!wegoAnalyticsClientId || !wegoAnalyticsClientSessionId) return;

              const eventData = {
                id: pageViewIdState.home,
                category: 'signin',
                object: 'button_signin',
                action: 'clicked',
                value: '',
              };

              genzoTrackActionEvent(
                API_BASE_URL,
                wegoAnalyticsClientId,
                wegoAnalyticsClientSessionId,
                undefined,
                eventData,
              );
            };
            trackLoginEvent();

            if (isAuthNew) {
              login();
              return;
            }

            showModal('login');
          } else {
            setIsAuthenticationExpanded(!isAuthenticationExpanded);
          }
          break;
        }
        case 'shopcash': {
          showModal('shopcash');
          break;
        }
      }
    },
    [
      isAuthenticationExpanded,
      pageViewIdState.home,
      showModal,
      user,
      wegoAnalyticsClientId,
      wegoAnalyticsClientSessionId,
      isAuthNew,
    ],
  );

  const handleOnOverlayClick = useCallback<() => void>(() => {
    showModal(null);
  }, [showModal]);

  const handleOnLogin = useCallback<(user: User) => void>(
    (user: User) => {
      const previousPath = (state as { previousPath?: string })?.previousPath || '';

      dispatch(changeUser(user));
      webEngageTrackLogin(user);
      showModal(null);

      if (user && previousPath) {
        navigate({ pathname: previousPath }, { replace: true });
      }
    },
    [dispatch, navigate, showModal, state],
  );

  const handleOnSignUp = useCallback<(user: User) => void>(
    (user: User) => {
      dispatch(changeUser(user));
      webEngageTrackLogin(user);
      showModal(null);
    },
    [dispatch, showModal],
  );

  const handleLogOut = useCallback<() => void>(() => {
    const resetUserData = () => {
      dispatch(resetUser());
      webEngageTrackLogout();
    };

    setIsAuthenticationExpanded(false);
    if (window?.sprChat) {
      window.sprChat('updateUserSettings');
      window.sprChat('close');
    }

    if (usedNewLogin) {
      logout();
      resetUserData();
      setAuthButtonLoading(true);
      return;
    }
    const searchParams = new URLSearchParams(search);
    switch (user!.loggedInType) {
      case LoggedInType.GoogleOneTap:
        googleSignOut(domainBasedApiBaseUrl);
        break;
      case LoggedInType.GoogleSignIn:
        googleSignOut(domainBasedApiBaseUrl);
        break;
      case LoggedInType.Facebook:
        facebookSignOut(domainBasedApiBaseUrl);
        break;
      case LoggedInType.Wego:
        wegoSignOut(domainBasedApiBaseUrl);
        break;
    }
    resetUserData();

    // Redirect to home if user is on preferences page, otherwise stay on the same page
    if (pathname.includes('preferences')) {
      navigate(
        { pathname: `/${localeParam ? localeParam : ''}`, search: searchParams.toString() },
        { replace: true, state: {} },
      );
    }
  }, [
    dispatch,
    domainBasedApiBaseUrl,
    localeParam,
    navigate,
    pathname,
    search,
    user,
    usedNewLogin,
  ]);

  const isEmail = useCallback<(value: string) => boolean>((value: string) => {
    return /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  }, []);

  // Set all picker focus to false if body is clicked
  useEffect(() => {
    if (isAuthenticationExpanded || isAdditionalLinksSectionExpanded) {
      const handleBodyOnClick = (): void => {
        if (isAuthenticationExpanded) {
          setIsAuthenticationExpanded(false);
        }

        if (isAdditionalLinksSectionExpanded) {
          setIsAdditionalLinksSectionExpanded(false);
        }
      };

      document.body.addEventListener('click', handleBodyOnClick);

      return () => {
        document.body.removeEventListener('click', handleBodyOnClick);
      };
    }
  }, [isAdditionalLinksSectionExpanded, isAuthenticationExpanded]);

  // Disable body scroll if dialog is open
  useEffect(() => {
    if (!!modalSearchParam) {
      setIsBodyScrollable(false);
    } else {
      setIsBodyScrollable(true);
    }
  }, [modalSearchParam, setIsBodyScrollable]);

  const { loginEffectLoading, setUserIsLoggingIn } = useHandleLoginEffect(coreConfigsLoaded, () =>
    handleOnDropdownButtonClick('authentication'),
  );

  const handleSupportClick = useCallback(() => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      const eventData = {
        id: pageViewIdState.home,
        category: 'header',
        object: 'support',
        action: 'clicked',
        value: '',
      };

      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        undefined,
        eventData,
      );
    }

    sprinklrChat('open');
  }, [pageViewIdState.home, wegoAnalyticsClientId, wegoAnalyticsClientSessionId, sprinklrChat]);

  const handleMyTripsClick = useCallback(() => {
    if (!!wegoAnalyticsClientId && !!wegoAnalyticsClientSessionId) {
      const eventData = {
        id: pageViewIdState.home,
        category: 'header',
        object: 'my_trips',
        action: 'clicked',
        value: '',
      };

      genzoTrackActionEvent(
        API_BASE_URL,
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        undefined,
        eventData,
      );
    }
    if (!user) {
      setUserIsLoggingIn(true);
    }
  }, [pageViewIdState.home, wegoAnalyticsClientId, wegoAnalyticsClientSessionId]);

  return (
    <div className={clsx(styles.container, theme === 'white' && styles.whiteBackground, className)}>
      <div className={styles.content}>
        <div className={styles.logoAndNavLinks}>
          <Link
            aria-label='Wego'
            className={clsx(styles.logoLink, styles[`${theme === 'white' ? 'dark' : 'white'}`])}
            state={state}
            to={{ pathname: `/${localeParam}`, search: searchWithoutExtraParams }}
          />

          {showProductLinks && !isBookingComparisonAndCheckoutPage ? (
            <div className={styles.productLinks}>
              <NavLink
                className={({ isActive }) => clsx(styles.productLink, isActive && styles.active)}
                state={state}
                to={{
                  pathname: flightsRoute(currentSite, localeParam),
                  search: searchWithoutExtraParams,
                }}
              >
                {translations.flights}
              </NavLink>

              <NavLink
                className={({ isActive }) => clsx(styles.productLink, isActive && styles.active)}
                state={state}
                to={{
                  pathname: hotelsNavUrl(
                    isFlightSearchResultPage,
                    flightSearchesInStore,
                    currentSite,
                    localeParam,
                  ),
                  search: searchWithoutExtraParams,
                }}
              >
                {translations.hotels}
              </NavLink>

              {renderAdditionalLinks()}
            </div>
          ) : null}
        </div>

        <div className={styles.siteConfigurationAndAuthentication}>
          {/* Current site, Locale and Currency */}
          {!isBookingComparisonAndCheckoutPage ? (
            <SiteLocaleAndCurrencyPicker
              currency={currency}
              currentSite={currentSite}
              locale={locale}
              productParam={productParam}
              theme={theme}
              translations={translations}
            />
          ) : null}

          {!isBookingComparisonAndCheckoutPage && (
            <div className={styles.userNavLinks}>
              <Link
                className={styles.userNavLink}
                to=''
                onClick={(e) => {
                  e.preventDefault();
                  handleSupportClick();
                }}
              >
                <span className={styles.linkText}>{translations.support}</span>
                <HeadsetIcon className={styles.linkIcon} />
              </Link>
              <PreferencesSideNavigationLink
                className={styles.userNavLink}
                to={user ? `/preferences/trips/flights${searchWithoutExtraParams}` : ''}
                localeParam={localeParam}
                onClick={(e) => {
                  if (!user) {
                    e.preventDefault();
                    handleMyTripsClick();
                  } else {
                    handleMyTripsClick();
                  }
                }}
              >
                <span className={styles.linkText}>{translations.my_trips}</span>
                <BusinessIcon className={styles.linkIcon} />
              </PreferencesSideNavigationLink>
            </div>
          )}

          {/* Authentication */}
          {user &&
            (isBookingComparisonAndCheckoutPage ? (
              <div className={styles.bowLoggedIn}>
                <div className={styles.bowLoggedInEmail}>
                  {translateText(translations.logged_in_as, locale, ' ')}
                  <span>{user.email}</span>
                </div>
                <div
                  className={styles.switchAccount}
                  onClick={() => {
                    handleLogOut();
                    setTimeout(() => {
                      setUserIsLoggingIn(true);
                    });
                  }}
                >
                  {translations.not_you} <span>{translations.switch_account}</span>
                </div>
              </div>
            ) : (
              <button
                aria-label='user'
                className={clsx(
                  styles.authentication,
                  isAuthenticationExpanded && styles.expanded,
                  isPagePaymentVerification && styles.hide,
                )}
                onClick={() => handleOnDropdownButtonClick('authentication')}
              >
                <div
                  className={clsx(
                    styles.name,
                    !shopCashUser && isEmail(user.displayName) && styles.isEmail,
                  )}
                >
                  {!!shopCashUser && shopCashUser.ledgerUsd ? (
                    <div className={styles.shopCash}>
                      <WalletIcon className={styles.icon} />
                      <Price
                        locale={locale}
                        price={{
                          amount: shopCashUser.ledgerUsd.displayBalance,
                          amountUsd: shopCashUser.ledgerUsd.displayBalance,
                          currencyCode: 'USD',
                        }}
                        isInline={true}
                        showDecimal={true}
                        currency={currency}
                        exchangeRate={exchangeRate}
                        className={styles.displayBalance}
                      />
                    </div>
                  ) : width < 1024 ? (
                    <AccountIcon className={styles.icon} />
                  ) : (
                    user.displayName
                  )}
                </div>
                <i className={clsx(styles.triangle, styles.down)}></i>
              </button>
            ))}

          {!user && (
            <>
              <button
                aria-label={translations.login as string}
                className={clsx(
                  styles.authentication,
                  isAuthenticationExpanded && styles.expanded,
                  isPagePaymentVerification && styles.hide,
                )}
                disabled={loginEffectLoading || authButtonLoading}
                onClick={() => setUserIsLoggingIn(true)}
              >
                {loginEffectLoading || authButtonLoading ? <Spinner /> : translations.login}
              </button>
            </>
          )}

          {/* Authentication dropdown */}
          {isAuthenticationExpanded ? <DropdownProfile onLogout={handleLogOut} /> : null}
        </div>
      </div>

      <Portal zIndex={PORTAL_MODAL_Z_INDEX}>
        <>
          <div
            className={clsx(styles.overlay, !!modalSearchParam && styles.open)}
            onClick={handleOnOverlayClick}
          ></div>

          {!!modalSearchParam && !isAuthNew ? (
            <>
              {modalSearchParam === 'login' ? (
                <Login
                  apiBaseUrl={API_BASE_URL}
                  className={styles.login}
                  domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                  facebookAppId={FACEBOOK_APP_ID}
                  googleClientId={GOOGLE_CLIENT_ID}
                  locale={locale}
                  onClose={() => showModal(null)}
                  onForgetPassword={() => showModal('resetpassword')}
                  onLogin={handleOnLogin}
                  onSendConfirmationEmail={() => showModal('resendconfirmation')}
                  onSignUp={() => showModal('signup')}
                  translations={translations}
                  wegoClientId={WEGO_CLIENT_ID}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              ) : null}

              {modalSearchParam === 'signup' ? (
                <SignUp
                  apiBaseUrl={API_BASE_URL}
                  className={styles.signUp}
                  countryCode={currentSite.countryCode}
                  domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                  facebookAppId={FACEBOOK_APP_ID}
                  googleClientId={GOOGLE_CLIENT_ID}
                  locale={locale}
                  onClose={() => showModal(null)}
                  onContinue={() => showModal(null)}
                  onLogin={() => showModal('login')}
                  onSendConfirmationEmail={() => showModal('resendconfirmation')}
                  onSignUp={handleOnSignUp}
                  translations={translations}
                  wegoClientId={WEGO_CLIENT_ID}
                  wegoAnalyticsClientId={wegoAnalyticsClientId}
                  wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
                />
              ) : null}

              {modalSearchParam === 'resetpassword' ? (
                <ResetPassword
                  className={styles.resetPassword}
                  domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                  locale={locale}
                  onBackToLogin={() => showModal('login')}
                  onClose={() => showModal(null)}
                  translations={translations}
                />
              ) : null}

              {modalSearchParam === 'resendconfirmation' ? (
                <ResendConfirmation
                  className={styles.resetPassword}
                  domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                  locale={locale}
                  onBackToLogin={() => showModal('login')}
                  onClose={() => showModal(null)}
                  translations={translations}
                />
              ) : null}

              {modalSearchParam === 'shopcash' ? (
                <ShopCashInfo
                  className={styles.shopCashInfoModal}
                  onClose={() => showModal(null)}
                  translations={translations}
                />
              ) : null}
            </>
          ) : null}
        </>
      </Portal>
    </div>
  );
};

export default Header;

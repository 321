/**
 *
 * @param total
 * @returns { hours, minutes, seconds}
 */
export function getTimeRemaining(total: number): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  return { hours, minutes, seconds };
}

/**
 * @description Convert date string from DD/MM/YYYY to YYYY-MM-DD
 * @param date DD/MM/YYYY
 */
export function convertDateStringToDateApiFormat(date: string): string {
  const [DD, MM, YYYY] = date.split('/');
  return `${YYYY}-${MM}-${DD}`;
}

/**
 *
 * @param dateStr Convert date string from DD/MM/YYYY to Date
 * @returns Date
 */
export function convertStringToDate(dateStr: string): Date {
  const [day, month, year] = dateStr.split('/');
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  date.setHours(0, 0, 0, 0);
  return date;
}

/**
 *
 * @param date DD/MM/YYYY
 * @returns boolean
 */
export function isDateValid(date: string): boolean {
  const isDateFormatValid = /(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}/.test(date);

  if (!isDateFormatValid) return false;

  const [day, month, year] = date.split('/');
  const d = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  if (
    d.getFullYear() === parseInt(year) &&
    d.getMonth() === parseInt(month) - 1 &&
    d.getDate() === parseInt(day)
  )
    return true;

  return false;
}

/**
 *
 * @param date
 * @param maxAge
 * @returns boolean
 */
export function isWithinMaxAgeLimit(date: string, maxAge: number): boolean {
  const [day, month, year] = date.split('/');
  const givenDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  givenDate.setUTCFullYear(givenDate.getUTCFullYear() + maxAge);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.getTime() < givenDate.getTime();
}

// Convert date to MM/DD/YYYY (11/28/2019) format.
export function dateGreatEscapeFormat(date: Date): string {
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
    .getDate()
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
}

export function getDifferenceBetweenDatesInMonths(startDate: Date, endDate: Date): number {
  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  return months <= 0 ? 0 : months;
}

export function getDaysDifference(
  date1InMilliseconds: number,
  date2InMilliseconds: number,
  isAbsolute = true,
): number {
  const differenceInTime = isAbsolute
    ? Math.abs(date2InMilliseconds - date1InMilliseconds)
    : date2InMilliseconds - date1InMilliseconds;

  const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

  return differenceInDays;
}

export const getDateWithUTCtimeZone = (dateString: string, locale: string, withTime?: boolean) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  };
  if (withTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }
  try {
    return date.toLocaleDateString(locale, options);
  } catch (error) {
    // Fallback to a default locale
    return date.toLocaleDateString('en-US', options);
  }
};

export function getTomorrowDateInMilliSeconds(): number {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow.getTime();
}

export function getDateAfterNDaysFromTheDate(
  dateInMilliseconds: number,
  numberOfDays: number,
): number {
  const dateAfterNDays = new Date(dateInMilliseconds);
  dateAfterNDays.setDate(dateAfterNDays.getDate() + numberOfDays);
  dateAfterNDays.setHours(0, 0, 0, 0);
  return dateAfterNDays.getTime();
}

// Use this file to create and manage experiments on frontend side
// When you don't want to wait for the user-centric core config API response,
// you can define your experiments here
import md5 from 'crypto-js/md5';

import { HOMEPAGE_SEARCH_FORM_DESIGN_UPDATE_KEY } from '@constants/general';

const HEX_ARRAY = '0123456789ABCDEF'.split('');

// Converts bytes to a hexadecimal string
export const bytesToHex = (bytes: Uint8Array): string => {
  return Array.from(bytes)
    .map((byte) => {
      const v = byte & 0xff;
      return HEX_ARRAY[v >>> 4] + HEX_ARRAY[v & 0x0f];
    })
    .join('');
};

// Converts a hash to a percentage (1 - 100)
export const getExperimentPercentageValue = (clientId: string, salt?: string): number => {
  // Add optional salt to client ID
  const valueToHash = salt ? `${clientId}${salt}` : clientId;

  // Get MD5 hash as bytes
  const hashBytes = new Uint8Array(md5(valueToHash).words);

  // Convert bytes to hex string
  const hexString = bytesToHex(hashBytes);

  // Use first 8 chars of hex string to get percentage
  const hashNum = parseInt(hexString.substring(0, 8), 16);
  return (hashNum % 100) + 1;
};

// Homepage design update experiment definition
export const getHomepageDesignUpdateExperiment = (
  clientId: string,
  countryCode: string,
): CoreConfig => {
  // Exclude countries from the experiment
  // Not removed from the experiment yet may be needed in the future
  const excludedCountries: string[] = [];

  const experimentStartDate = new Date('2024-12-16');
  const experimentEndDate = new Date('2025-01-31');

  experimentStartDate.setHours(0, 0, 0, 0);
  experimentEndDate.setHours(23, 59, 59, 999);

  const currentDate = new Date().getTime();

  const isExcluded =
    excludedCountries.includes(countryCode) ||
    currentDate < experimentStartDate.getTime() ||
    currentDate > experimentEndDate.getTime();

  if (isExcluded) {
    return {
      key: HOMEPAGE_SEARCH_FORM_DESIGN_UPDATE_KEY,
      value: '0',
      experimentName: '',
      variantName: '',
    };
  }

  const percentageValue = getExperimentPercentageValue(clientId, 'WG-381-homepage-design-update');
  const isVariantA = percentageValue > 50;

  return {
    key: HOMEPAGE_SEARCH_FORM_DESIGN_UPDATE_KEY,
    value: isVariantA ? '1' : '0',
    experimentName: 'WG-381 Homepage hero image search form design update',
    variantName: isVariantA ? 'Variant A' : 'Baseline',
  };
};

// Get all client side experiment core configs
export const getClientSideUserCentricConnect = (
  clientId: string,
  countryCode: string,
): CoreConfig[] => {
  return [
    getHomepageDesignUpdateExperiment(clientId, countryCode),
    // Add other experiments here as needed
  ];
};

import { createContext } from 'react';

export const GlobalContext = createContext<{
  userCentricCoreConfigs: Array<CoreConfig>;
  webEngageAnonymousId: string | undefined;
  clientSideUserCentricCoreConfigs: Array<CoreConfig>;
}>({
  userCentricCoreConfigs: [],
  webEngageAnonymousId: undefined,
  clientSideUserCentricCoreConfigs: [],
});

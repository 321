import cuid from 'cuid';
import { v4 as uuidv4 } from 'uuid';

import { addCookie, getCookie } from '@wego/alexis/helpers/cookie';

let wegoAnalyticsClientIdCookieExpireByDate: Date = new Date();
let wegoAnalyticsClientSessionIdCookieExpireByDate: Date = new Date();
let wegoVisitsCountCookieExpireByDate: Date = new Date();

const handlePageActivityEvent = (): void => {
  const nowMilliseconds = Date.now();

  const wegoAnalyticsClientIdCookieExpireByTimeDiff =
    (wegoAnalyticsClientIdCookieExpireByDate.getTime() - nowMilliseconds) / 1000; // Convert milliseconds to seconds

  wegoAnalyticsClientIdCookieExpireByDate = new Date(nowMilliseconds + 17520 * 60 * 60 * 1000); // 17520 hours === 2 years

  const wegoAnalyticsClientIdCookie = getCookie('wego_analytics_client_id');

  if (wegoAnalyticsClientIdCookieExpireByTimeDiff > 1 && !!wegoAnalyticsClientIdCookie) {
    // Extend cookie expiry time
    addCookie(
      'wego_analytics_client_id',
      wegoAnalyticsClientIdCookie!,
      wegoAnalyticsClientIdCookieExpireByDate.getTime(),
      'lax',
      true,
    );
  } else {
    const newClientId = uuidv4();
    addCookie(
      'wego_analytics_client_id',
      newClientId,
      wegoAnalyticsClientIdCookieExpireByDate.getTime(),
      'lax',
      true,
    );
    document.dispatchEvent(
      new CustomEvent('newWegoClient', { detail: { wegoAnalyticsClientId: newClientId } }),
    );
  }

  const wegoAnalyticsClientSessionIdCookieExpireByTimeDiff =
    (wegoAnalyticsClientSessionIdCookieExpireByDate.getTime() - nowMilliseconds) / 1000; // Convert milliseconds to seconds

  wegoAnalyticsClientSessionIdCookieExpireByDate = new Date(nowMilliseconds + 0.5 * 60 * 60 * 1000); // 0.5 hours

  const wegoAnalyticsClientSessionIdCookie = getCookie('wego_analytics_client_session_id');

  if (
    wegoAnalyticsClientSessionIdCookieExpireByTimeDiff > 1 &&
    !!wegoAnalyticsClientSessionIdCookie
  ) {
    // Extend cookie expiry time
    addCookie(
      'wego_analytics_client_session_id',
      wegoAnalyticsClientSessionIdCookie!,
      wegoAnalyticsClientSessionIdCookieExpireByDate.getTime(),
      'lax',
      true,
    );
  } else {
    const newSessionId = uuidv4();
    addCookie(
      'wego_analytics_client_session_id',
      newSessionId,
      wegoAnalyticsClientSessionIdCookieExpireByDate.getTime(),
      'lax',
      true,
    );
    document.dispatchEvent(
      new CustomEvent('newWegoSession', { detail: { wegoAnalyticsClientSessionId: newSessionId } }),
    );
  }
};

export const initializeIdentity = (): void => {
  const nowMilliseconds = Date.now();

  const wegoAnalyticsClientIdCookie = getCookie('wego_analytics_client_id');
  wegoAnalyticsClientIdCookieExpireByDate = new Date(nowMilliseconds + 17520 * 60 * 60 * 1000); // 17520 hours === 2 years
  const newClientId = uuidv4();
  addCookie(
    'wego_analytics_client_id',
    wegoAnalyticsClientIdCookie ?? newClientId,
    wegoAnalyticsClientIdCookieExpireByDate.getTime(),
    'lax',
    true,
  );

  if (wegoAnalyticsClientIdCookie === undefined) {
    document.dispatchEvent(
      new CustomEvent('newWegoClient', { detail: { wegoAnalyticsClientId: newClientId } }),
    );
  }

  const wegoAnalyticsClientSessionIdCookie = getCookie('wego_analytics_client_session_id');
  wegoAnalyticsClientSessionIdCookieExpireByDate = new Date(nowMilliseconds + 0.5 * 60 * 60 * 1000); // 0.5 hours
  const newSessionId = uuidv4();
  addCookie(
    'wego_analytics_client_session_id',
    wegoAnalyticsClientSessionIdCookie ?? newSessionId,
    wegoAnalyticsClientSessionIdCookieExpireByDate.getTime(),
    'lax',
    true,
  );

  if (wegoAnalyticsClientSessionIdCookie === undefined) {
    document.dispatchEvent(
      new CustomEvent('newWegoSession', { detail: { wegoAnalyticsClientSessionId: newSessionId } }),
    );
  }

  const wegoVisitsCountCookie = getCookie('wego_visits_cnt');
  wegoVisitsCountCookieExpireByDate = new Date(nowMilliseconds + 17520 * 60 * 60 * 1000); // 17520 hours === 2 years
  addCookie(
    'wego_visits_cnt',
    !!wegoVisitsCountCookie ? (parseInt(wegoVisitsCountCookie, 10) + 1).toString() : '1',
    wegoVisitsCountCookieExpireByDate.getTime(),
    'lax',
    true,
  );

  const wegoTsCodeCookie = getCookie('wego_ts_code');
  const wegoTsCodeCookieExpireByDate = new Date(nowMilliseconds + 720 * 60 * 60 * 1000); // 720 hours === 30 days
  const wegoTsCodeSearchParam = new URLSearchParams(window.location.search).get('ts_code');

  if (!!wegoTsCodeCookie || !!wegoTsCodeSearchParam) {
    // Assumption that wego ts code in search param will override wego ts code in cookie
    addCookie(
      'wego_ts_code',
      wegoTsCodeSearchParam ?? wegoTsCodeCookie!,
      wegoTsCodeCookieExpireByDate.getTime(),
      'lax',
      true,
    );
  }

  document.addEventListener('click', handlePageActivityEvent);
  document.addEventListener('scroll', handlePageActivityEvent);
};

export const generateUUIDv4 = (): string => {
  return uuidv4();
};

export const generateCUID = (): string => {
  return cuid();
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ShopCashLedger } from '@apis/shopCash';

import { useGetWegoIdToken } from '@hooks/api/authentications/useGetWegoIdToken';
import { useGetShopCashAccount } from '@hooks/api/shopCash/useGetShopCashAccount';
import { useShopCashSignIn } from '@hooks/api/shopCash/useShopCashSignIn';

import { getCurrentSiteState, getUserState } from '@redux/selectors';

import { isWegoShopcashEnabled } from '../helpers/shopcash';
import { changeShopCashUser, resetShopCashUser } from '../redux/actions/shopCashActions';

const ShopCashUser = (): null => {
  const currentSite = useSelector(getCurrentSiteState);
  const user = useSelector(getUserState);

  const { data: idToken } = useGetWegoIdToken({
    enabled: isWegoShopcashEnabled(currentSite.countryCode) && !!user,
  });

  const { data: shopCashTokens } = useShopCashSignIn(
    {
      idToken: idToken || '',
    },
    {
      enabled: !!idToken,
    },
  );

  const { data: shopCashProfile } = useGetShopCashAccount(
    {
      accessToken: shopCashTokens?.data.accessToken || '',
    },
    {
      enabled: !!shopCashTokens,
    },
  );

  const dispatch = useDispatch();

  const hasLedgerBalances = (ledger: ShopCashLedger) => {
    return ledger?.availableBalance || ledger?.pendingBalance;
  };

  useEffect(() => {
    if (shopCashProfile && hasLedgerBalances(shopCashProfile.data.ledger)) {
      dispatch(
        changeShopCashUser({
          accessToken: shopCashTokens?.data.accessToken || '',
          ledger: shopCashProfile.data.ledger,
        }),
      );
    }

    if (!user) {
      dispatch(resetShopCashUser());
    }
  }, [shopCashProfile, user]);

  return null;
};

export default ShopCashUser;

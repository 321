import { useSelector } from 'react-redux';

import { getNearestPlaces } from '@apis/places';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getLocaleState } from '@redux/selectors';

type GetNearestPlacesParams = {
  isCity: boolean;
  latitude?: number;
  longitude?: number;
};

const getNearestPlacesKey = 'nearest-city-by-ip';

export const useGetNearestPlaces = <TData = Place[]>(
  params: GetNearestPlacesParams,
  options?: UseAxiosQueryOptions<Place[], TData>,
) => {
  const locale = useSelector(getLocaleState);

  return useAxiosQuery<Place[], TData>({
    queryKey: [
      getNearestPlacesKey,
      {
        locale,
        ...params,
      },
    ],
    queryFn: () =>
      getNearestPlaces(locale, params.isCity, undefined, params.latitude, params.longitude),
    ...options,
  });
};

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useIsAuthNew } from '@hooks/useIsAuthNew';

const AuthMagicLink = () => {
  const { isAuthNew, login } = useIsAuthNew();
  const [search, setSearchParams] = useSearchParams();
  const searchParams = new URLSearchParams(search);

  const reAuthenticate = searchParams.get('re-authenticate');

  useEffect(() => {
    if (!reAuthenticate || !isAuthNew) {
      return;
    }

    searchParams.delete('re-authenticate');
    login('redirect');
    setSearchParams(searchParams, { replace: true });
  }, [reAuthenticate, isAuthNew, login]);

  return null;
};

export default AuthMagicLink;

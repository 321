import { useSelector } from 'react-redux';

import { shopCashSignIn, ShopCashWegoAuthResponse } from '@apis/shopCash';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

type ShopCashSignInParams = { idToken: string };

const shopCashSignInKey = 'shopCash-signIn';

export const useShopCashSignIn = <TResult = ShopCashWegoAuthResponse>(
  params: ShopCashSignInParams,
  options?: UseAxiosQueryOptions<ShopCashWegoAuthResponse, TResult>,
) => {
  const currentSite = useSelector(getCurrentSiteState);
  const locale = useSelector(getLocaleState);

  return useAxiosQuery({
    queryKey: [
      shopCashSignInKey,
      {
        idToken: params.idToken,
        siteCode: currentSite.countryCode,
        locale,
      },
    ],
    queryFn: () => shopCashSignIn(params.idToken, currentSite.countryCode, locale),
    cacheTime: 1000 * 60 * 60 * 24, // 1 day
    staleTime: 1000 * 60 * 60 * 24, // 1 day
    ...options,
  });
};

import { isDevelopmentEnvironment, isEnvironmentProduction } from '@alexis/helpers/environment';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import React from 'react';
import { render } from 'react-dom';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/index.module.scss';

console.log(
  '%c\n██╗    ██╗███████╗ ██████╗  ██████╗ \n██║    ██║██╔════╝██╔════╝ ██╔═══██╗\n██║ █╗ ██║█████╗  ██║  ███╗██║   ██║\n██║███╗██║██╔══╝  ██║   ██║██║   ██║\n╚███╔███╔╝███████╗╚██████╔╝╚██████╔╝\n ╚══╝╚══╝ ╚══════╝ ╚═════╝  ╚═════╝ \n\n',
  'color:#44b50c;',
);

const sentryConfigs = {
  staging: {
    // sending 100% of events to Sentry in staging
    ...(DEPLOYMENT_VERSION ? { release: DEPLOYMENT_VERSION } : {}),
    dsn: 'https://5c93dc54bb003f50e34c65db1cf0f064@o16074.ingest.sentry.io/6721721',
    integrations: [Sentry.browserTracingIntegration()],
    environment: 'staging',
  },
  production: {
    ...(DEPLOYMENT_VERSION ? { release: DEPLOYMENT_VERSION } : {}),
    dsn: 'https://5c93dc54bb003f50e34c65db1cf0f064@o16074.ingest.sentry.io/6721721',
    integrations: [Sentry.browserTracingIntegration()],
    environment: 'production',
    sampleRate: 0.1,
  },
};

const shouldInitSentry = !isDevelopmentEnvironment(window.location.hostname);
if (shouldInitSentry) {
  Sentry.init(
    isEnvironmentProduction(window.location.hostname)
      ? sentryConfigs['production']
      : sentryConfigs['staging'],
  );
}

const SAMPLE_RATE = 10;
const SESSION_SAMPLE_RATE = 20;

datadogRum.init({
  applicationId: 'b59466d6-b51c-476a-b55c-fb53f278f137',
  clientToken: 'pub5e53adc01d8b59846cfe232653d2cee4',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'desktop-web',
  env: isEnvironmentProduction(window.location.hostname) ? 'production' : 'staging',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: DEPLOYMENT_VERSION,
  // `sessionSampleRate` is the percentage of sessions to track: 100 for all, 0 for none.
  //  Set to 10 to collect only 10% of all sessions.
  sessionSampleRate: SAMPLE_RATE,
  sessionReplaySampleRate: SESSION_SAMPLE_RATE,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask',
  beforeSend(event) {
    if (event.type === 'error' && event.error.message.includes('Component unmount.')) {
      return false;
    }

    return true;
  },
});

const container = document.getElementById('app');
render(<App />, container);

serviceWorkerRegistration.register();

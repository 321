import { getShopCashAccount, ShopCashAccountResponse } from '@apis/shopCash';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

type GetShopCashAccountParams = {
  accessToken: string;
};

const getShopCashAccountKey = 'shopCash-account';

export const useGetShopCashAccount = <TResult = ShopCashAccountResponse>(
  params: GetShopCashAccountParams,
  options?: UseAxiosQueryOptions<ShopCashAccountResponse, TResult>,
) => {
  return useAxiosQuery({
    queryKey: [getShopCashAccountKey, params],
    queryFn: () => getShopCashAccount(params.accessToken),
    cacheTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  });
};

import React from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import Image from '@components/Image';

import { cloudinaryImageTransformations } from '@helpers/imageTransformations';

import type { Translations } from '@wegoTypes/translations';

import { BlogType } from './blog';
import styles from './BlogCard.module.scss';

interface BlogCardProps {
  blog: BlogType;
  translations: Translations;
}

const BlogCard: React.FC<BlogCardProps> = ({ blog, translations }) => {
  return (
    <a className={clsx(styles.container)} href={blog.url} target='_blank' rel='noreferrer'>
      <Image className={styles.image} isLazy={true} src={blog.featured_media_url} />

      <div className={styles.content}>
        <div className={styles.title}>{blog.title}</div>

        <div className={styles.wegoIconContainer}>
          <img
            alt='wego'
            src={cloudinaryImageTransformations(
              `https://assets.wego.com/image/upload/v1456382855/wego_logos/wego-icon.png`,
              'c_fit',
              'w_48',
              'h_48',
              'q_auto',
              'f_auto',
              'fl_lossy',
            )}
          />

          {translations.wego_travel_blog}
        </div>
      </div>
    </a>
  );
};

export default BlogCard;

import { localStorageGet } from '@alexis/helpers/localStorage';
import { LoggedInType } from '@alexis/types/loggedInType';
import axios from 'axios';

import {
  Facebook,
  LoginStatus,
  UpdateUser,
  User,
  WegoSignUpResponse,
} from '@wegoTypes/authentication';
import { Google } from '@wegoTypes/googleOneTap/google';
import { IdConfiguration } from '@wegoTypes/googleOneTap/idConfiguration';
import { WegoTokenResponse } from '@wegoTypes/wegoTokenResponse';
import { WegoUserInformation } from '@wegoTypes/wegoUserInformation';

declare global {
  interface Window {
    onGoogleLibraryLoad: any;
    google: Google;
    gapi: any;

    fbAsyncInit: any;
    FB: Facebook;
  }
}

const loadGoogleOneTapClientLibrary = (): Promise<Google> => {
  return new Promise((resolve) => {
    const elementId: string = 'googleOneTapClient';
    const document$: Document = document;
    const existingScriptElement = document$.getElementById(elementId);

    if (!existingScriptElement) {
      const scriptElement = document$.createElement('script');
      scriptElement.id = elementId;
      scriptElement.defer = true;
      scriptElement.src = 'https://accounts.google.com/gsi/client';
      scriptElement.onload = () => {
        resolve(window.google);
      };
      document$.head.appendChild(scriptElement);
    } else {
      resolve(window.google);
    }
  });
};

const wegoSignInWithPassword = async (
  apiBaseUrl: string,
  clientId: string,
  email: string,
  password: string,
): Promise<WegoTokenResponse> => {
  const data = {
    grant_type: 'password',
    scope: 'users',
    client_id: clientId,
    email,
    password,
  };

  const response = await axios.post<WegoTokenResponse>(`${apiBaseUrl}/users/oauth/token`, data, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

const wegoSignInWithToken = async (
  apiBaseUrl: string,
  clientId: string,
  provider: 'google' | 'facebook',
  token: string,
): Promise<WegoTokenResponse> => {
  const data = {
    grant_type: 'password',
    scope: 'users',
    client_id: clientId,
    provider: provider,
    provider_access_token: token,
  };

  // With credentials is set to true here is for server to set credential to cookie.
  const response = await axios.post<WegoTokenResponse>(`${apiBaseUrl}/users/oauth/token`, data, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const retrieveWegoUserInformation = async (
  apiBaseUrl: string,
): Promise<WegoUserInformation> => {
  // With credentials is set to true here is for server to read credential from cookie.
  const response = await axios.get<WegoUserInformation>(`${apiBaseUrl}/users/info`, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

//#region Google One Tap

const initializeGoogleOneTapClient = async (
  apiBaseUrl: string,
  googleClientId: string,
  wegoClientId: string,
  context: 'signin' | 'signup' | 'use',
): Promise<User> => {
  const google = await loadGoogleOneTapClientLibrary();

  return new Promise<User>((resolve, reject) => {
    const locale: string = localStorageGet('locale') ?? 'en';
    const parentElement = document.querySelector<HTMLElement>(
      context === 'signin' ? '#googleSignIn' : '#googleSignUp',
    );
    const buttonWidth = parentElement?.offsetWidth;

    const idConfiguration: IdConfiguration = {
      client_id: googleClientId,
      auto_select: false,
      itp_support: true,
      use_fedcm_for_prompt: true,
      callback: async (credentialResponse) => {
        try {
          const wegoTokenResponse: WegoTokenResponse = await wegoSignInWithToken(
            apiBaseUrl,
            wegoClientId,
            'google',
            credentialResponse.credential,
          );

          const tokenCreationDate = new Date(wegoTokenResponse.created_at * 1000);
          const expireBy = tokenCreationDate.setSeconds(wegoTokenResponse.expires_in);

          const wegoUserInformation: WegoUserInformation = await retrieveWegoUserInformation(
            apiBaseUrl,
          );

          const user: User = mapWegoUserInformationToUser(
            wegoUserInformation,
            LoggedInType.GoogleOneTap,
            expireBy,
          );
          resolve(user);
        } catch (error) {
          reject((error as any).message);
        }
      },
      native_callback: async (credential) => {
        if (!!credential) {
          try {
            const wegoTokenResponse: WegoTokenResponse = await wegoSignInWithPassword(
              apiBaseUrl,
              wegoClientId,
              credential.id,
              credential.password,
            );

            const tokenCreationDate = new Date(wegoTokenResponse.created_at * 1000);
            const expireBy = tokenCreationDate.setSeconds(wegoTokenResponse.expires_in);

            const wegoUserInformation: WegoUserInformation = await retrieveWegoUserInformation(
              apiBaseUrl,
            );

            const user: User = mapWegoUserInformationToUser(
              wegoUserInformation,
              LoggedInType.GoogleOneTap,
              expireBy,
            );
            resolve(user);
          } catch (error) {
            reject((error as any).message);
          }
        }
      },
      context: context,
    };

    google.accounts.id.initialize(idConfiguration);
    google.accounts.id.prompt();
    google.accounts.id.renderButton(parentElement, {
      theme: 'outline',
      locale,
      text: context === 'signin' ? 'signin_with' : 'signup_with',
      shape: 'pill',
      width: buttonWidth,
    });
  });
};

export const googleOneTapSignIn = (
  apiBaseUrl: string,
  googleClientId: string,
  wegoClientId: string,
): Promise<User> => {
  return initializeGoogleOneTapClient(apiBaseUrl, googleClientId, wegoClientId, 'signin');
};

export const googleOneTapSignUp = (
  apiBaseUrl: string,
  googleClientId: string,
  wegoClientId: string,
): Promise<User> => {
  return initializeGoogleOneTapClient(apiBaseUrl, googleClientId, wegoClientId, 'signup');
};

export const googleSignOut = async (apiBaseUrl: string): Promise<void> => {
  const google = await loadGoogleOneTapClientLibrary();

  google.accounts.id.disableAutoSelect();

  await wegoSignOut(apiBaseUrl);
};

//#region Facebook Sign-In
export const loadFacebookClientLibrary = async (appId: string): Promise<Facebook> => {
  return new Promise((resolve) => {
    const elementId: string = 'facebook-jssdk';
    const document$: Document = document;
    const existingScriptElement = document$.getElementById(elementId);

    if (!existingScriptElement) {
      const scriptElement = document$.createElement('script');
      scriptElement.id = elementId;
      scriptElement.async = true;
      scriptElement.defer = true;
      scriptElement.src = 'https://connect.facebook.net/en_US/sdk.js';
      document$.head.appendChild(scriptElement);

      scriptElement.onload = () => {
        resolve(window.FB);
        window.FB.init({ appId: appId, version: 'v9.0', cookie: true, xfbml: true });
      };
    } else {
      resolve(window.FB);
      window.FB.init({ appId: appId, version: 'v9.0', cookie: true, xfbml: true });
    }
  });
};

const retrieveFacebookLoginStatus = (FB: Facebook): Promise<LoginStatus> => {
  return new Promise<LoginStatus>((resolve) => {
    FB.getLoginStatus((response) => {
      resolve(response);
    }, false);
  });
};

const facebookLogin = (FB: Facebook): Promise<LoginStatus> => {
  return new Promise<LoginStatus>((resolve) => {
    FB.login(
      (response) => {
        resolve(response);
      },
      { scope: 'public_profile,email' },
    );
  });
};

const facebookLogout = (FB: Facebook): Promise<any> => {
  return new Promise<any>((resolve) => {
    FB.logout((response) => {
      resolve(response);
    });
  });
};

export const facebookSignIn = async (apiBaseUrl: string, wegoClientId: string): Promise<User> => {
  const { FB } = window;
  const loginStatusResponse = await retrieveFacebookLoginStatus(FB);

  let token: string | undefined =
    loginStatusResponse.status === 'connected'
      ? loginStatusResponse.authResponse!.accessToken
      : undefined;

  if (loginStatusResponse.status !== 'connected') {
    const loginResponse = await facebookLogin(FB);

    if (loginResponse.status === 'connected') {
      token = loginResponse.authResponse!.accessToken;
    }
  }

  if (!!token) {
    const wegoTokenResponse: WegoTokenResponse = await wegoSignInWithToken(
      apiBaseUrl,
      wegoClientId,
      'facebook',
      token,
    );

    const tokenCreationDate = new Date(wegoTokenResponse.created_at * 1000);
    const expireBy = tokenCreationDate.setSeconds(wegoTokenResponse.expires_in);

    const wegoUserInformation: WegoUserInformation = await retrieveWegoUserInformation(apiBaseUrl);

    const user: User = mapWegoUserInformationToUser(
      wegoUserInformation,
      LoggedInType.Facebook,
      expireBy,
    );
    return user;
  }
  throw new Error('Unable to login to Facebook.');
};

export const facebookSignOut = async (apiBaseUrl: string): Promise<void> => {
  const { FB } = window;

  const loginStatusResponse = await retrieveFacebookLoginStatus(FB);

  if (loginStatusResponse.status === 'connected') {
    await facebookLogout(FB);

    await wegoSignOut(apiBaseUrl);
  } else {
    return Promise.resolve();
  }
};

const wegoRefreshToken = async (apiBaseUrl: string): Promise<WegoTokenResponse> => {
  const response = await axios.get<WegoTokenResponse>(`${apiBaseUrl}/users/refresh_access_token`, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

const wegoSignUp$ = async (
  apiBaseUrl: string,
  email: string,
  password: string,
  optInReceivePromotionsFromWego: boolean,
  countryCode: string,
  locale: string,
  source: 'desktop_web' | 'mobile_web',
  deviceType: 'desktop' | 'ios' | 'android',
): Promise<WegoSignUpResponse> => {
  const data = {
    user: {
      email: email,
      password: password,
      newsletter_opt_in: optInReceivePromotionsFromWego ? '1' : '0',
      geo_country_code: countryCode,
      locale: locale,
      cctld: window.location.hostname,
      source: source,
    },
    device: {
      device_type: deviceType,
    },
  };

  const response = await axios.post<WegoSignUpResponse>(`${apiBaseUrl}/users/sign_up`, data, {
    withCredentials: true,
  });

  if (response.status === 200 || response.status === 201) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const wegoSignIn = async (
  apiBaseUrl: string,
  wegoClientId: string,
  email: string,
  password: string,
): Promise<User> => {
  const wegoTokenResponse: WegoTokenResponse = await wegoSignInWithPassword(
    apiBaseUrl,
    wegoClientId,
    email,
    password,
  );

  const tokenCreationDate = new Date(wegoTokenResponse.created_at * 1000);
  const expireBy = tokenCreationDate.setSeconds(wegoTokenResponse.expires_in);

  const wegoUserInformation: WegoUserInformation = await retrieveWegoUserInformation(apiBaseUrl);

  const user: User = mapWegoUserInformationToUser(wegoUserInformation, LoggedInType.Wego, expireBy);

  // const google = await loadGoogleOneTapClientLibrary();

  // google.accounts.id.storeCredential({ id: email, password: password });

  return user;
};

/**
 * @description Refresh Wego access token
 *
 * @param apiBaseUrl
 * @returns expire by date in milliseconds.
 */
export const wegoRefreshSignIn = async (apiBaseUrl: string): Promise<number> => {
  const wegoTokenResponse: WegoTokenResponse = await wegoRefreshToken(apiBaseUrl);

  const tokenCreationDate = Date.now();

  return tokenCreationDate + wegoTokenResponse.expires_in * 1000;
};

/**
 * @description Update user information.
 *
 * @param apiBaseUrl
 * @param updateUser
 */
export const updateWegoUserInformation = async (
  apiBaseUrl: string,
  updateUser: UpdateUser,
): Promise<WegoUserInformation> => {
  const data = {
    user: {
      ...(!!updateUser.csRestoreId && { cs_restore_id: updateUser.csRestoreId }),
      ...(!!updateUser.phoneCountryCode && { phone_country_code: updateUser.phoneCountryCode }),
      ...(!!updateUser.phoneNumber && { phone_number: updateUser.phoneNumber }),
    },
  };

  const response = await axios.patch<WegoUserInformation>(`${apiBaseUrl}/users/update`, data, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

/**
 * @description Sign up an account with Wego
 *
 * @param apiBaseUrl
 * @param wegoClientId
 * @param email
 * @param password
 * @param optInReceivePromotionsFromWego
 * @param countryCode
 * @param locale
 * @param source "mobile_web / desktop_web"
 * @param deviceType "ios / android / desktop"
 */
export const wegoSignUp = async (
  apiBaseUrl: string,
  wegoClientId: string,
  email: string,
  password: string,
  optInReceivePromotionsFromWego: boolean,
  countryCode: string,
  locale: string,
  source: 'desktop_web' | 'mobile_web',
  deviceType: 'desktop' | 'ios' | 'android',
): Promise<User> => {
  await wegoSignUp$(
    apiBaseUrl,
    email,
    password,
    optInReceivePromotionsFromWego,
    countryCode,
    locale,
    source,
    deviceType,
  );

  const user = await wegoSignIn(apiBaseUrl, wegoClientId, email, password);

  return user;
};

export const wegoSignOut = async (apiBaseUrl: string): Promise<void> => {
  const response = await axios.get(`${apiBaseUrl}/users/revoke_access_token`, {
    withCredentials: true,
  });

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
};

export const wegoChangePassword = async (
  apiBaseUrl: string,
  currentPassword: string,
  newPassword: string,
): Promise<void> => {
  const data = {
    user: {
      current_password: currentPassword,
      password: newPassword,
    },
  };

  const response = await axios.post(`${apiBaseUrl}/users/update_password`, data, {
    withCredentials: true,
  });

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
};

export const wegoResetPassword = async (
  apiBaseUrl: string,
  email: string,
  locale: string,
): Promise<void> => {
  const data = {
    user: { email, locale },
  };

  const response = await axios.post(`${apiBaseUrl}/users/forgot_password`, data);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
};

export const wegoResendConfirmation = async (apiBaseUrl: string, email: string): Promise<void> => {
  const data = {
    user: { email },
  };

  const response = await axios.post(`${apiBaseUrl}/users/resend_confirmation`, data);

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }
};

/**
 * Utility function to map WegoUserInformation to User object.
 *
 * @param wegoUserInformation - The user information retrieved from Wego.
 * @param loggedInType - The type of login (Google, Facebook, etc.).
 * @param expireBy - Expiration date of the token in milliseconds.
 * @returns User object.
 */
const mapWegoUserInformationToUser = (
  wegoUserInformation: WegoUserInformation,
  loggedInType: LoggedInType,
  expireBy: number,
): User => {
  return {
    loggedInType,
    displayName: !!wegoUserInformation.name.trim()
      ? wegoUserInformation.name
      : wegoUserInformation.email,
    title: wegoUserInformation.title,
    firstName: wegoUserInformation.first_name,
    lastName: wegoUserInformation.last_name,
    nationality: wegoUserInformation.nationality,
    countryCode: wegoUserInformation.country_code,
    phoneCountryCode: wegoUserInformation.phone_country_code,
    phoneNumber: wegoUserInformation.phone_number,
    email: wegoUserInformation.email,
    csRestoreId: wegoUserInformation.cs_restore_id,
    photoUrl: wegoUserInformation.photo_url ?? undefined,
    userHash: wegoUserInformation.user_hash,
    idHash: wegoUserInformation.id_hash,
    sprinklrUserHash: wegoUserInformation.sprinklr_user_hash,
    accountPhoneNumber: wegoUserInformation.account_phone_number,
    accountPhoneCountryCode: wegoUserInformation.account_phone_country_code,
    expireBy,
  };
};

import { useEffect, useState } from 'react';

type PreferencesSideNavigationAccordionProps = {
  className?: string;
  defaultOpen?: boolean;
  children?: React.ReactNode;
};

const PreferencesSideNavigationAccordion: React.FC<PreferencesSideNavigationAccordionProps> = ({
  className,
  defaultOpen,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <button
      type='button'
      className={className}
      data-open={isOpen}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      {children}
    </button>
  );
};

export default PreferencesSideNavigationAccordion;

import { dateApiFormat, enMonthLabels, enShortDayLabels } from '@alexis/helpers/date';
import React, { useEffect, useMemo, useState } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateNumber } from '@wego/alexis/helpers/translation';

import { rearrangeDayLabels } from '@helpers/date';

import styles from '../styles/components/Calendar.module.scss';

interface CalendarProps {
  className?: string;
  dayLabels?: Array<string>;
  firstDayOfWeek?: number;
  limitFromMilliseconds?: number;
  limitTillMilliseconds?: number;
  locale: string;
  month: number;
  monthLabels?: Array<string>;
  onDateSelected(value: Date): void;
  publicHolidayMap?: PublicHolidayMap;
  selectedFromDateMilliseconds?: number;
  selectedToDateMilliseconds?: number;
  year: number;
  activeOutboundDateMilliseconds: number;
}

const Calendar: React.FC<CalendarProps> = ({
  className,
  dayLabels,
  firstDayOfWeek,
  limitFromMilliseconds,
  limitTillMilliseconds,
  locale,
  month,
  monthLabels,
  onDateSelected,
  publicHolidayMap,
  selectedFromDateMilliseconds,
  selectedToDateMilliseconds,
  year,
  activeOutboundDateMilliseconds,
}) => {
  const [defaultMonthLabels] = useState<Array<string>>([...enMonthLabels]);
  const [defaultDayLabels, setDefaultDayLabels] = useState<Array<string>>([]);

  const days = useMemo<Array<JSX.Element>>(() => {
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(year, month, 0, 23, 59, 59);
    const todayDateMilliseconds = new Date().setHours(0, 0, 0, 0);

    const days: Array<JSX.Element> = [];

    for (let n = 1; n < 43; n++) {
      const dayIndexOffset = firstDayOfWeek === 7 ? 1 : firstDayOfWeek === 6 ? 2 : 0;
      const dayIndex = (n - dayIndexOffset) % 7;
      const day = firstDayOfMonth.getDay();
      const firstDayOfMonthMilliseconds = firstDayOfMonth.getTime();
      const lastDayOfMonthMilliseconds = lastDayOfMonth.getTime();

      if (day === dayIndex && firstDayOfMonthMilliseconds <= lastDayOfMonthMilliseconds) {
        const date = firstDayOfMonth.getDate();
        const isPublicHoliday =
          (!!publicHolidayMap && !!publicHolidayMap[dateApiFormat(firstDayOfMonth)]) || false;
        const isToday = firstDayOfMonthMilliseconds === todayDateMilliseconds;
        const isSelectedFromDate =
          selectedFromDateMilliseconds &&
          selectedFromDateMilliseconds === firstDayOfMonthMilliseconds;
        const isInSelectedDateRange =
          selectedFromDateMilliseconds &&
          selectedToDateMilliseconds &&
          firstDayOfMonthMilliseconds > selectedFromDateMilliseconds &&
          firstDayOfMonthMilliseconds < selectedToDateMilliseconds;
        const isSelectedToDate =
          selectedToDateMilliseconds && selectedToDateMilliseconds === firstDayOfMonthMilliseconds;
        const isDisabled =
          (limitFromMilliseconds === undefined &&
            firstDayOfMonthMilliseconds < todayDateMilliseconds) ||
          (limitFromMilliseconds !== undefined &&
            firstDayOfMonthMilliseconds < limitFromMilliseconds) ||
          (limitTillMilliseconds !== undefined &&
            firstDayOfMonthMilliseconds > limitTillMilliseconds);

        days.push(
          <div
            data-index={n}
            data-testid={dateApiFormat(new Date(firstDayOfMonthMilliseconds))}
            data-pw={`${
              !!isInSelectedDateRange || !!isSelectedFromDate || !!isSelectedToDate
                ? 'datePicker_dateInSelectedRange'
                : isDisabled
                ? 'datePicker_unavailableDate'
                : 'datePicker_availableDate'
            }`}
            key={n}
            className={clsx(
              !!isSelectedFromDate && styles.fromDate,
              !!isSelectedToDate && styles.toDate,
              !!selectedToDateMilliseconds && styles.hasToDate,
              !!isInSelectedDateRange && styles.inRange,
            )}
            onClick={
              !isDisabled ? () => onDateSelected(new Date(firstDayOfMonthMilliseconds)) : undefined
            }
          >
            <div
              data-pw={`${isPublicHoliday ? 'datePicker_publicHoliday' : ''}`}
              className={clsx(
                styles.date,
                isPublicHoliday && styles.publicHoliday,
                isToday && styles.today,
                !isDisabled && styles.selectable,
                !!isDisabled && styles.disabled,
                firstDayOfMonthMilliseconds === activeOutboundDateMilliseconds && styles.active,
              )}
            >
              {translateNumber(date, locale === 'fa')}
            </div>
          </div>,
        );
        firstDayOfMonth.setDate(date + 1);
      } else {
        if ((n === 29 || n === 36) && firstDayOfMonthMilliseconds >= lastDayOfMonthMilliseconds) {
          break;
        }
        days.push(<div key={n} data-index={n}></div>);
      }
    }
    return days;
  }, [
    locale,
    month,
    year,
    firstDayOfWeek,
    limitFromMilliseconds,
    limitTillMilliseconds,
    publicHolidayMap,
    selectedFromDateMilliseconds,
    selectedToDateMilliseconds,
    activeOutboundDateMilliseconds,
  ]);

  useEffect(() => {
    setDefaultDayLabels(rearrangeDayLabels(dayLabels || enShortDayLabels, firstDayOfWeek));
  }, [dayLabels, firstDayOfWeek]);

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.month}>
        {monthLabels ? monthLabels[month - 1] : defaultMonthLabels[month - 1]}{' '}
        {translateNumber(year, locale === 'fa')}
      </div>
      <div className={styles.dayLabels}>
        {defaultDayLabels.map((defaultDayLabel, index) => (
          <div key={index}>{defaultDayLabel}</div>
        ))}
      </div>
      <div className={styles.days}>{days}</div>
    </div>
  );
};

export default Calendar;

import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { translateText } from '@wego/alexis/helpers/translation';

import { getLocaleParam } from '@helpers/locale';
import { isWegoShopcashEnabled } from '@helpers/shopcash';

import AirplaneIcon from '@icons/airplane.svg';
import DashboardIcon from '@icons/dashboard.svg';
import HotelIcon from '@icons/hotel.svg';
import LogoutIcon from '@icons/logout.svg';
import PhoneIcon from '@icons/phone.svg';
import WalletIcon from '@icons/wallet.svg';

import {
  getCurrentSiteState,
  getLocaleState,
  getShopCashUserState,
  getTranslationsState,
} from '@redux/selectors';

import styles from './DropdownProfile.module.scss';

type DropdownProfileProps = {
  onLogout?: () => void;
};

const PROFILE_MENU = [
  {
    labelKey: 'dashboard',
    icon: DashboardIcon,
    href: '/preferences',
  },
  {
    labelKey: 'hotel_bookings',
    icon: HotelIcon,
    href: '/preferences/trips/hotels',
  },
  {
    labelKey: 'flight_bookings',
    icon: AirplaneIcon,
    href: '/preferences/trips/flights',
  },
  {
    labelKey: 'wallet',
    icon: WalletIcon,
    href: '/preferences/profile/wallet',
  },
  {
    labelKey: 'contact_us',
    icon: PhoneIcon,
    href: '/preferences/help/contact-us',
  },
  {
    labelKey: 'account_logout',
    icon: LogoutIcon,
    href: '/logout',
  },
];

const DropdownProfile = ({ onLogout }: DropdownProfileProps) => {
  const translations = useSelector(getTranslationsState);
  const locale = useSelector(getLocaleState);
  const currentSite = useSelector(getCurrentSiteState);
  const { pathname, search } = useLocation();
  const [searchParams] = useSearchParams();
  const shopCashUser = useSelector(getShopCashUserState);

  const localeParam = getLocaleParam();

  const isNavActive = (href: string) => {
    return pathname + search === href;
  };

  const shouldShowWallet = isWegoShopcashEnabled(currentSite.countryCode) && !!shopCashUser;

  const profileMenuWalletCheck = PROFILE_MENU.filter((menu) => {
    if (!shouldShowWallet && menu.labelKey === 'wallet') {
      return false;
    }
    return true;
  });

  const profileMenu = profileMenuWalletCheck.map((menu) => {
    const href = `${localeParam ? '/' + localeParam : ''}${menu.href}${
      searchParams.toString() ? '?' + searchParams.toString() : ''
    }`;

    return {
      ...menu,
      href,
    };
  });

  return (
    <div className={styles.dropdown}>
      <div className={styles.triangle} />
      <div className={styles.dropdownContainer}>
        <div className={styles.dropdownItems}>
          {profileMenu.map((item) => {
            if (item.labelKey === 'account_logout') {
              return (
                <button key={item.labelKey} className={styles.dropdownItem} onClick={onLogout}>
                  {!!item.icon && <item.icon className={styles.linkIcon} />}
                  <span className={styles.linkText}>
                    {translateText(translations[item.labelKey], locale)}
                  </span>
                </button>
              );
            }

            return (
              <Link
                key={item.labelKey}
                to={item.href}
                className={styles.dropdownItem}
                data-active={isNavActive(item.href)}
                aria-current={isNavActive(item.href) ? 'true' : undefined}
              >
                {!!item.icon && <item.icon className={styles.linkIcon} />}
                <span className={styles.linkText}>
                  {translateText(translations[item.labelKey], locale)}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DropdownProfile;

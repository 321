import { appendSearchParams } from '@alexis/helpers/searchParam';
import axios from 'axios';

export async function getMarketingPartnerPromo(
  apiBaseUrl: string,
  locale: string,
  currentSiteCode: string,
  appType: 'WEB_APP' | 'MOBILE_WEB_APP',
  deviceType: 'DESKTOP' | 'MOBILE',
): Promise<MarketingPartnerPromo> {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const hasBowOnlyParamAsTrue = urlSearchParams.get('bow_only') === 'true';

  const searchParams: any = {
    locale,
    siteCode: currentSiteCode.toUpperCase(),
    appType,
    deviceType,
    ...(hasBowOnlyParamAsTrue && { bowOnly: true }),
  };

  const url = appendSearchParams(
    `${apiBaseUrl}/server-driven-configs/v1/marketing/carousels/search`,
    searchParams,
  );

  const response = await axios.get<MarketingPartnerPromo>(url, { withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import { getSiteConfigByHostname } from '@alexis/helpers/site';

import { PRICE_OPTIONS_LOCAL_STORAGE_KEY } from '../../constants/localStorage';
import { PriceOptionsActionTypes } from '../actions/types';

const currentSite = getSiteConfigByHostname(window.location.hostname);

const priceOptions = localStorageGet<PriceOptions>(PRICE_OPTIONS_LOCAL_STORAGE_KEY) || {
  showTotalPrice: false,
  isIncludingTaxOrFee: /(?:AT|AU|DE|GB|NL|NO|US|SV)/i.test(currentSite.countryCode),
  isInit: true,
};

const initialState: PriceOptions = priceOptions;

export default function (
  state: PriceOptions = initialState,
  action: { type: PriceOptionsActionTypes; payload: boolean },
): PriceOptions {
  switch (action.type) {
    case PriceOptionsActionTypes.ChangeShowTotalPrice: {
      const currentPriceOptions = { ...state, showTotalPrice: action.payload };
      localStorageSave(PRICE_OPTIONS_LOCAL_STORAGE_KEY, currentPriceOptions);
      return currentPriceOptions;
    }
    case PriceOptionsActionTypes.ChangeIsIncludingTaxOrFee: {
      const currentPriceOptions = { ...state, isIncludingTaxOrFee: action.payload };
      localStorageSave(PRICE_OPTIONS_LOCAL_STORAGE_KEY, currentPriceOptions);
      return currentPriceOptions;
    }
    default:
      return state;
  }
}

import { useContext } from 'react';

import { NEW_RATING_SCALE } from '@constants/hotels';

import { GlobalContext } from '@context/GlobalContext';

import {
  getNewReviewRatingTypeColorClassName,
  getNewReviewRatingTypeDisplay,
  getReviewRatingTypeColorClassName,
  getReviewRatingTypeDisplay,
} from '@helpers/review';

export const useReviewRatingType = () => {
  const { userCentricCoreConfigs } = useContext(GlobalContext);
  const isNewRatingScale =
    userCentricCoreConfigs.find((coreConfig) => coreConfig.key === NEW_RATING_SCALE)?.value === '1';

  if (isNewRatingScale) {
    return {
      getReviewRatingTypeDisplay: getNewReviewRatingTypeDisplay,
      getReviewRatingTypeColorClassName: getNewReviewRatingTypeColorClassName,
    };
  }

  return {
    getReviewRatingTypeDisplay,
    getReviewRatingTypeColorClassName,
  };
};

import { appendSearchParams } from '@alexis/helpers/searchParam';
import axios from 'axios';
import type { CancelToken } from 'axios';

import { HotelComparisionProvider } from '@wegoTypes/hotels/hotelComparisonProvider';

export async function getHotelComparisonProviders(
  apiBaseUrl: string,
  currentSiteCode: string,
  locale: string,
  cityCode: string,
  countryCode: string,
  checkInDate: string,
  cancelToken: CancelToken,
): Promise<Array<HotelComparisionProvider>> {
  const url = appendSearchParams(`${apiBaseUrl}/compare/hotels/search`, {
    locale,
    site_code: currentSiteCode.toUpperCase(),
    city_code: cityCode,
    location_country_code: countryCode,
    check_in: checkInDate,
    device_type: 'DESKTOP',
  });

  const response = await axios.get<Array<HotelComparisionProvider>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getFlightComparisonProviders(
  currentSiteCode: string,
  locale: string,
  departureAirportCode: string | undefined,
  departureCityCode: string,
  departureCountryCode: string,
  departureDate: string,
  arrivalAirportCode: string | undefined,
  arrivalCityCode: string,
  arrivalCountryCode: string,
  returnDate: string | undefined,
  placementType: 'home' | 'search' | 'leaderboard',
  cancelToken?: CancelToken,
): Promise<Array<FlightComparisonProvider>> {
  const url = appendSearchParams(`${API_BASE_URL}/compare/flights/search`, {
    site_code: currentSiteCode.toUpperCase(),
    locale,
    ...(departureAirportCode && { departure_airport_code: departureAirportCode }),
    departure_city_code: departureCityCode,
    departure_country_code: departureCountryCode,
    departure_date: departureDate,
    ...(arrivalAirportCode && { arrival_airport_code: arrivalAirportCode }),
    arrival_city_code: arrivalCityCode,
    arrival_country_code: arrivalCountryCode,
    ...(returnDate && { return_date: returnDate }),
    placement_type: placementType,
    device_type: 'DESKTOP',
  });

  const response = await axios.get<Array<FlightComparisonProvider>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

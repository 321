import { ShopCashActionTypes } from '../actions/types';

const initialState: ShopCashUser | null = null;

export default function (
  state: ShopCashUser | null = initialState,
  action: { type: ShopCashActionTypes; payload: ShopCashUser },
): ShopCashUser | null {
  switch (action.type) {
    case ShopCashActionTypes.ChangeShopCashUser: {
      return {
        ...state,
        ...(action.payload as ShopCashUser),
      };
    }
    case ShopCashActionTypes.ResetShopCashUser: {
      return null;
    }
    default:
      return state;
  }
}

import { currenciesConfig } from '@alexis/helpers/currency';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import { locales, sitesConfig } from '@alexis/helpers/site';
import cookies from 'js-cookie';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { WEGO_LOCALE_COOKIE_KEY } from '@constants/cookie';

import { isFlightProduct } from '@helpers/flight';
import { isHotelProduct } from '@helpers/hotel';
import {
  flightsRoute,
  flightsRouteTranslation,
  hotelsRoute,
  hotelsRouteTranslation,
} from '@helpers/routeTranslation';

import useLocaleParam from '@hooks/useLocaleParam';
import { useViewportSize } from '@hooks/useViewportSize';

import { changeLocale } from '@redux/actions/localeActions';

import { Currency } from '@wegoTypes/currency';
import type { Translations } from '@wegoTypes/translations';

import { changeCurrency } from '../../redux/actions/currencyActions';
import styles from './SiteLocaleAndCurrencyPicker.module.scss';

interface SiteLocaleAndCurrencyPickerProps {
  currency: Currency;
  currentSite: CurrentSite;
  locale: string;
  productParam: string;
  theme: Theme;
  translations: Translations;
}

const SiteLocaleAndCurrencyPicker: React.FC<SiteLocaleAndCurrencyPickerProps> = ({
  currency,
  currentSite,
  locale,
  productParam,
  theme,
  translations,
}) => {
  const localeParam = useLocaleParam();

  const { pathname, search, state } = useLocation();
  const dispatch = useDispatch();
  const { width } = useViewportSize();

  const [isCurrentSiteExpanded, setIsCurrentSiteExpanded] = useState<boolean>(false);
  const [isCurrentSiteCompact, setIsCurrentSiteCompact] = useState<boolean>(true);
  const [isLocaleExpanded, setIsLocaleExpanded] = useState<boolean>(false);
  const [isLocaleCompact, setIsLocaleCompact] = useState<boolean>(true);
  const [isCurrencyExpanded, setIsCurrencyExpanded] = useState<boolean>(false);
  const [isCurrencyCompact, setIsCurrencyCompact] = useState<boolean>(true);
  const [isAllPickerForTabletViewExpanded, setIsAllPickerForTabletViewExpanded] =
    useState<boolean>(false);

  const isTablet = width < 1024;

  const countries = useMemo<Array<{ currentSite: CurrentSite; href: string }>>(() => {
    let clonePathname = pathname.slice();

    const toBeSortedCountries = sitesConfig.map((siteConfig) => {
      if (localeParam) {
        clonePathname = clonePathname.replace(`/${localeParam}`, '');
      }

      if (productParam) {
        clonePathname = clonePathname.replace(`/${productParam}`, '');
      }

      const origin = `${window.location.protocol}//${
        isDevelopmentEnvironment(window.location.hostname) ||
        isStagingEnvironment(window.location.hostname)
          ? `${siteConfig.countryCode}-beta.wegostaging.com`
          : siteConfig.host
      }`;

      if (productParam && isFlightProduct(productParam)) {
        return {
          currentSite: siteConfig,
          href: `${origin}/${flightsRouteTranslation(
            siteConfig.defaultLocale,
          )}${clonePathname}${search}`,
        };
      } else if (productParam && isHotelProduct(productParam)) {
        return {
          currentSite: siteConfig,
          href: `${origin}/${hotelsRouteTranslation(
            siteConfig.defaultLocale,
          )}${clonePathname}${search}`,
        };
      } else {
        return {
          currentSite: siteConfig,
          href: `${origin}${clonePathname}${search}`,
        };
      }
    });

    // make the active item to the front of the array
    const activeCountryIndex = toBeSortedCountries.findIndex(
      (item) => item.currentSite.countryCode === currentSite.countryCode,
    );
    if (activeCountryIndex > -1) {
      const [item] = toBeSortedCountries.splice(activeCountryIndex, 1);
      toBeSortedCountries.unshift(item);
    }

    return toBeSortedCountries;
  }, [currentSite.countryCode, localeParam, pathname, productParam, search]);

  const showedCountries = useMemo(
    () => (isCurrentSiteCompact ? countries.slice(0, 3) : countries),
    [countries, isCurrentSiteCompact],
  );

  const allLocales = useMemo<
    Array<{ code: string; name: string; pathname: string; search: string }>
  >(() => {
    let clonePathname = pathname.slice();

    if (localeParam) {
      clonePathname = clonePathname.replace(`/${localeParam}`, '');
    }

    if (productParam) {
      clonePathname = clonePathname.replace(`/${productParam}`, '');
    }

    const toBeSortedLocales = locales.map((locale) => {
      const newLocaleParam =
        locale.code !== currentSite.defaultLocale &&
        currentSite.supportedLocales.includes(locale.code)
          ? locale.code
          : '';

      const searchParams = new URLSearchParams(search);
      searchParams.delete('ulang');

      if (!currentSite.supportedLocales.includes(locale.code)) {
        searchParams.append('ulang', locale.code);
      }

      if (productParam && isFlightProduct(productParam)) {
        return {
          code: locale.code,
          name: locale.name,
          pathname: `${flightsRoute(currentSite, newLocaleParam)}${clonePathname}`,
          search: searchParams.toString(),
        };
      } else if (productParam && isHotelProduct(productParam)) {
        return {
          code: locale.code,
          name: locale.name,
          pathname: `${hotelsRoute(currentSite, newLocaleParam)}${clonePathname}`,
          search: searchParams.toString(),
        };
      } else {
        return {
          code: locale.code,
          name: locale.name,
          pathname: `${newLocaleParam ? `/${newLocaleParam}` : ''}${
            clonePathname === '/' ? '' : clonePathname
          }`,
          search: searchParams.toString(),
        };
      }
    });

    // make the active item to the front of the array
    const activeLocaleIndex = toBeSortedLocales.findIndex((item) => item.code === locale);
    if (activeLocaleIndex > -1) {
      const [item] = toBeSortedLocales.splice(activeLocaleIndex, 1);
      toBeSortedLocales.unshift(item);
    }

    return toBeSortedLocales;
  }, [currentSite, locale, localeParam, pathname, productParam, search]);

  const selectedLocale = useMemo(() => {
    return allLocales.find((allLocale) => allLocale.code === locale)!;
  }, [locale, allLocales]);

  const showedLocales = useMemo(
    () => (isLocaleCompact ? allLocales.slice(0, 3) : allLocales),
    [allLocales, isLocaleCompact],
  );

  const currencies = useMemo<Array<Currency>>(() => {
    const toBeSortedCurrencies = currenciesConfig.sort((a, b) => a.code.localeCompare(b.code));

    // make the active item to the front of the array
    const activeCurrencyIndex = toBeSortedCurrencies.findIndex(
      (item) => item.code === currency.code,
    );
    if (activeCurrencyIndex > -1) {
      const [item] = toBeSortedCurrencies.splice(activeCurrencyIndex, 1);
      toBeSortedCurrencies.unshift(item);
    }

    return toBeSortedCurrencies;
  }, [currency.code]);

  const showedCurrencies = useMemo(
    () => (isCurrencyCompact ? currencies.slice(0, 3) : currencies),
    [currencies, isCurrencyCompact],
  );

  const handleOnDropdownButtonClick = useCallback<
    (dropdownType: 'currentSite' | 'locale' | 'currency' | 'allPicker') => void
  >((dropdownType) => {
    switch (dropdownType) {
      case 'currentSite':
        setIsCurrentSiteExpanded((prev) => !prev);
        break;
      case 'locale':
        setIsLocaleExpanded((prev) => !prev);
        break;
      case 'currency':
        setIsCurrencyExpanded((prev) => !prev);
        break;
      case 'allPicker':
        setIsAllPickerForTabletViewExpanded((prev) => !prev);
        break;
    }
  }, []);

  const handleCompactChanged = useCallback<
    (dropdownType: 'currentSite' | 'locale' | 'currency') => void
  >((dropdownType) => {
    switch (dropdownType) {
      case 'currentSite':
        setIsCurrentSiteCompact((prev) => !prev);
        setIsLocaleCompact(true);
        setIsCurrencyCompact(true);
        break;
      case 'locale':
        setIsLocaleCompact((prev) => !prev);
        setIsCurrentSiteCompact(true);
        setIsCurrencyCompact(true);
        break;
      case 'currency':
        setIsCurrencyCompact((prev) => !prev);
        setIsCurrentSiteCompact(true);
        setIsLocaleCompact(true);
        break;
    }
  }, []);

  const handleOnLocaleClick = useCallback<(locale: string) => void>((locale) => {
    cookies.set(WEGO_LOCALE_COOKIE_KEY, locale);
    setIsLocaleExpanded(false);
    setIsAllPickerForTabletViewExpanded(false);
    setIsLocaleCompact(true);
    dispatch(changeLocale(locale));
  }, []);

  const handleOnCurrencyClick = useCallback<(currency: Currency) => void>(
    (currency: Currency) => {
      dispatch(changeCurrency(currency));
      setIsCurrencyExpanded(false);
      setIsAllPickerForTabletViewExpanded(false);
      setIsCurrencyCompact(true);
    },
    [dispatch],
  );

  // Set all picker focus to false if body is clicked
  useEffect(() => {
    if (
      isCurrentSiteExpanded ||
      isLocaleExpanded ||
      isCurrencyExpanded ||
      isAllPickerForTabletViewExpanded
    ) {
      const handleBodyOnClick = (): void => {
        if (isCurrentSiteExpanded) {
          setIsCurrentSiteExpanded(false);
        }

        if (isLocaleExpanded) {
          setIsLocaleExpanded(false);
        }

        if (isCurrencyExpanded) {
          setIsCurrencyExpanded(false);
        }

        if (isAllPickerForTabletViewExpanded) {
          setIsAllPickerForTabletViewExpanded(false);
          setIsLocaleCompact(true);
          setIsCurrencyCompact(true);
          setIsCurrentSiteCompact(true);
        }
      };

      document.body.addEventListener('click', handleBodyOnClick);

      return () => {
        document.body.removeEventListener('click', handleBodyOnClick);
      };
    }
  }, [
    isCurrencyExpanded,
    isCurrentSiteExpanded,
    isLocaleExpanded,
    isAllPickerForTabletViewExpanded,
  ]);

  if (isTablet) {
    return (
      <div className={clsx(styles.container, theme === 'white' && styles.whiteBackground)}>
        <button
          aria-label={translations.country_region as string}
          className={clsx(
            styles.allPickers,
            isAllPickerForTabletViewExpanded && styles.expanded,
            styles.isTablet,
          )}
          onClick={() => handleOnDropdownButtonClick('allPicker')}
        >
          <i
            className={clsx(
              styles.countryFlags,
              styles.small,
              theme === 'white' && styles.withLightBackground,
              styles[`${currentSite.countryCode.toLowerCase()}`],
            )}
          ></i>
          <i className={clsx(styles.triangle, styles.down)}></i>
        </button>

        {isAllPickerForTabletViewExpanded ? (
          <div className={clsx(styles.dropdown, styles.all)} onClick={(e) => e.stopPropagation()}>
            {/* Compact Current Site */}
            <div className={styles.compactSection}>
              <div className={styles.label}>{translations.country_region}</div>

              <div className={clsx(styles.content, styles.allContent)}>
                {showedCountries.map((country) => (
                  <a
                    key={country.currentSite.countryCode}
                    data-pw='header_countryOption'
                    className={clsx(
                      styles.item,
                      currentSite.countryCode === country.currentSite.countryCode && styles.active,
                    )}
                    href={country.href}
                    rel='noreferrer'
                  >
                    <i
                      className={clsx(
                        styles.countryFlags,
                        styles.small,
                        styles.withLightBackground,
                        styles[`${country.currentSite.countryCode.toLowerCase()}`],
                      )}
                    ></i>
                    <div className={styles.name}>
                      {translations[`pos_${country.currentSite.countryCode.toLowerCase()}`]}
                    </div>
                  </a>
                ))}
              </div>

              <div
                className={styles.compactToggle}
                onClick={() => handleCompactChanged('currentSite')}
              >
                {isCurrentSiteCompact
                  ? `+ ${translations.show_more}`
                  : `- ${translations.show_less}`}
              </div>
            </div>

            {/* Compact Locale */}
            <div className={styles.compactSection}>
              <div className={styles.label}>{translations.language}</div>

              <div className={clsx(styles.content, styles.allContent)}>
                {showedLocales.map((locale$) => (
                  <Link
                    data-pw='header_languageOption'
                    className={clsx(styles.item, locale === locale$.code && styles.active)}
                    key={locale$.code}
                    onClick={() => handleOnLocaleClick(locale$.code)}
                    replace={true}
                    state={state}
                    to={{ pathname: locale$.pathname, search: locale$.search }}
                  >
                    <div className={styles.code}>{locale$.code.replace('zh-', '')}</div>
                    <div className={styles.name}>{locale$.name}</div>
                  </Link>
                ))}
              </div>

              <div className={styles.compactToggle} onClick={() => handleCompactChanged('locale')}>
                {isLocaleCompact ? `+ ${translations.show_more}` : `- ${translations.show_less}`}
              </div>
            </div>

            {/* Compact Currency */}
            <div className={styles.compactSection}>
              <div className={styles.label}>{translations.currency}</div>

              <div className={clsx(styles.content, styles.allContent)}>
                {showedCurrencies.map((currency$) => (
                  <div
                    data-pw='header_currencyOption'
                    className={clsx(styles.item, currency.code === currency$.code && styles.active)}
                    key={currency$.code}
                    onClick={() => handleOnCurrencyClick(currency$)}
                  >
                    <div className={styles.code}>{currency$.code}</div>
                    <div className={styles.name}>{currency$.name}</div>
                  </div>
                ))}
              </div>

              <div
                className={styles.compactToggle}
                onClick={() => handleCompactChanged('currency')}
              >
                {isCurrencyCompact ? `+ ${translations.show_more}` : `- ${translations.show_less}`}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className={clsx(styles.container, theme === 'white' && styles.whiteBackground)}>
      {/* Current site */}
      <button
        aria-label={translations.country_region as string}
        className={clsx(styles.currentSite, isCurrentSiteExpanded && styles.expanded)}
        onClick={() => handleOnDropdownButtonClick('currentSite')}
      >
        <i
          className={clsx(
            styles.countryFlags,
            styles.small,
            theme === 'white' && styles.withLightBackground,
            styles[`${currentSite.countryCode.toLowerCase()}`],
          )}
        ></i>
      </button>
      <span className={styles.separator}>|</span>
      {/* Current site dropdown */}
      {isCurrentSiteExpanded ? (
        <div
          className={clsx(styles.dropdown, styles.countries)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.label}>{translations.country_region}</div>

          <div className={styles.content}>
            {countries.map((country) => (
              <a
                key={country.currentSite.countryCode}
                data-pw='header_countryOption'
                className={clsx(
                  styles.item,
                  currentSite.countryCode === country.currentSite.countryCode && styles.active,
                )}
                href={country.href}
                rel='noreferrer'
              >
                <i
                  className={clsx(
                    styles.countryFlags,
                    styles.small,
                    styles.withLightBackground,
                    styles[`${country.currentSite.countryCode.toLowerCase()}`],
                  )}
                ></i>
                <div className={styles.name}>
                  {translations[`pos_${country.currentSite.countryCode.toLowerCase()}`]}
                </div>
              </a>
            ))}
          </div>
        </div>
      ) : null}

      {/* Selected locale */}
      <button
        aria-label={translations.language as string}
        className={clsx(styles.selectedLocale, isLocaleExpanded && styles.expanded)}
        onClick={() => handleOnDropdownButtonClick('locale')}
      >
        <>
          <Link
            className={clsx(styles.localeCode, styles.fw600)}
            onClick={() => handleOnLocaleClick(selectedLocale.code)}
            replace={true}
            state={state}
            to={{ pathname: selectedLocale.pathname, search: selectedLocale.search }}
          >
            {selectedLocale.code.replace('zh-', '')}
          </Link>
        </>
      </button>

      {/* Supported locale dropdown */}
      {isLocaleExpanded ? (
        <div className={clsx(styles.dropdown, styles.locales)} onClick={(e) => e.stopPropagation()}>
          <div className={styles.label}>{translations.language}</div>

          <div className={styles.content}>
            {allLocales.map((locale$) => (
              <Link
                data-pw='header_languageOption'
                className={clsx(styles.item, locale === locale$.code && styles.active)}
                key={locale$.code}
                onClick={() => handleOnLocaleClick(locale$.code)}
                replace={true}
                state={state}
                to={{ pathname: locale$.pathname, search: locale$.search }}
              >
                <div className={styles.code}>{locale$.code.replace('zh-', '')}</div>
                <div className={styles.name}>{locale$.name}</div>
              </Link>
            ))}
          </div>
        </div>
      ) : null}

      <span className={styles.separator}>|</span>

      {/* Currency */}
      <button
        aria-label={translations.currency as string}
        className={clsx(styles.currency, isCurrencyExpanded && styles.expanded, styles.fw600)}
        onClick={() => handleOnDropdownButtonClick('currency')}
      >
        {currency.code}
      </button>

      {/* Currency dropdown */}
      {isCurrencyExpanded ? (
        <div
          className={clsx(styles.dropdown, styles.currencies)}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.label}>{translations.currency}</div>

          <div className={styles.content}>
            {currencies.map((currency$) => (
              <div
                data-pw='header_currencyOption'
                className={clsx(styles.item, currency.code === currency$.code && styles.active)}
                key={currency$.code}
                onClick={() => handleOnCurrencyClick(currency$)}
              >
                <div className={styles.code}>{currency$.code}</div>
                <div className={styles.name}>{translations[currency$.translationKey]}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SiteLocaleAndCurrencyPicker;

import { dateApiFormat } from '@alexis/helpers/date';
import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import { appendSearchParams } from '@alexis/helpers/searchParam';
import { providerCodesForBaggageFilter } from '@pages/FlightSearchResult/constants';
import { SliderFiltersUniqueForEachLeg } from '@pages/FlightSearchResult/types';
import type { CabinClass } from 'flights/cabinClass';
import type { LegViewModel } from 'flights/legViewModel';
import type { TripViewModel } from 'flights/tripViewModel';
import { cloneDeep } from 'lodash-es';

import { addCookie, getCookie } from '@wego/alexis/helpers/cookie';
import { translateNumber, translateText } from '@wego/alexis/helpers/translation';

import { getLocationDetails } from '@apis/places';

import { LOW_RATE_CURRENCIES, WEGO_GREAT_ESCAPE_BASE_URL } from '@constants/flight';
import {
  FLIGHT_RECENT_SEARCHES_FOR_COMPARING_LOCAL_STORAGE_KEY,
  FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY,
  FLIGHT_COMPARISON_PROVIDERS,
} from '@constants/localStorage';

import { flightsRouteTranslation } from '@helpers/routeTranslation';

import { Currency } from '@wegoTypes/currency';
import type { FlightRecentSearch, RecentSearchLeg } from '@wegoTypes/flights/flightRecentSearch';
import type { FlightSearch } from '@wegoTypes/flights/flightSearch';
import type { FlightSearchType } from '@wegoTypes/flights/flightSearchType';
import { FlightFareResultTrip } from '@wegoTypes/flights/metasearch/flightFareResultTrip';
import type { FlightFareResultTripLeg } from '@wegoTypes/flights/metasearch/flightFareResultTripLeg';
import type { FlightFareResultTripLegSegment } from '@wegoTypes/flights/metasearch/flightFareResultTripLegSegment';
import type { FlightSearchResultLeg } from '@wegoTypes/flights/metasearch/flightSearchResultLeg';
import type { FlightSearchResultLegSegment } from '@wegoTypes/flights/metasearch/flightSearchResultLegSegment';
import type { Translations } from '@wegoTypes/translations';

import { FlightSearchResultSearchParams } from '../types/flights/flightSearchParams';

export const hasBnplPaymentOptionForFareDisplay = (
  paymentMethodsFromApi: Array<HomepageFlightsPaymentMethod> | undefined,
  fare: FlightFareResultTripFare,
) => {
  if (paymentMethodsFromApi && paymentMethodsFromApi.length > 0) {
    return fare.paymentFees.some((paymentFee) => {
      return paymentMethodsFromApi.some((paymentMethodFromApi) => {
        return (
          paymentMethodFromApi.paymentType === 'PAYLATER' &&
          paymentMethodFromApi.id === paymentFee.paymentMethodId
        );
      });
    });
  }
  return false;
};

export const computeFeeTextForFare = (
  fare: FlightFareResultTripFare,
  currency: Currency,
  paymentMethods: Array<FlightSearchResultPaymentMethod>,
  priceOptions: PriceOptions,
  translations: Translations,
  locale: string,
): string => {
  if (fare.paymentFees.length === 0) {
    return translations.unknow_payment_fee as string;
  }

  const cardId = fare.paymentFees[0].paymentMethodId;
  const card = paymentMethods.filter((obj) => obj.id == cardId)[0];
  const cardName = card ? card.name : '';
  const fee = priceOptions.showTotalPrice
    ? fare.paymentFees[0].totalAmount
    : fare.paymentFees[0].amount;
  let feeString = format(fee, currency, locale);
  isBigAmount(fee, currency) && (feeString += '.000');
  feeString = currency.symbolFirst ? currency.symbol + feeString : feeString + currency.symbol;

  return translateText(translations.flight_fee, locale, feeString, cardName);
};

export const getNameForDataTracking = (label: string, legs: FlightSearchResultSearchLeg[]) => {
  const { departureAirport, departureCity, arrivalAirport, arrivalCity } = legs[0];

  if (isMulticity(legs)) {
    const legLabels = legs.map(
      (leg) =>
        `${leg.departureAirport?.code || leg.departureCity.code}-${
          leg.arrivalAirport?.code || leg.arrivalCity.code
        }`,
    );

    return `${label}|${legLabels.join('|')}`;
  }

  return `${label}|${departureAirport ? departureAirport.code : departureCity.code}|${
    arrivalAirport ? arrivalAirport.code : arrivalCity.code
  }|${departureCity.enName}, ${departureCity.countryEnName} to ${arrivalCity.enName}, ${
    arrivalCity.countryEnName
  }`;
};

export const deleteFilterStatesFromSearchParam = (searchParams: URLSearchParams) => {
  searchParams.delete(FlightSearchResultSearchParams.SelectedPaymentMethod);
  searchParams.delete(FlightSearchResultSearchParams.Stops);
  searchParams.delete(FlightSearchResultSearchParams.Providers);
  searchParams.delete(FlightSearchResultSearchParams.Price);
  searchParams.delete(FlightSearchResultSearchParams.Experience);
  searchParams.delete(FlightSearchResultSearchParams.DepartLegDepartureTime);
  searchParams.delete(FlightSearchResultSearchParams.DepartLegArrivalTime);
  searchParams.delete(FlightSearchResultSearchParams.ReturnLegDepartureTime);
  searchParams.delete(FlightSearchResultSearchParams.ReturnLegArrivalTime);
  searchParams.delete(FlightSearchResultSearchParams.DepartLegDepartureTimeBlocks);
  searchParams.delete(FlightSearchResultSearchParams.DepartLegArrivalTimeBlocks);
  searchParams.delete(FlightSearchResultSearchParams.ReturnLegDepartureTimeBlocks);
  searchParams.delete(FlightSearchResultSearchParams.ReturnLegArrivalTimeBlocks);
  searchParams.delete(FlightSearchResultSearchParams.DepartDuration);
  searchParams.delete(FlightSearchResultSearchParams.ReturnDuration);
  searchParams.delete(FlightSearchResultSearchParams.StopoverDuration);
  searchParams.delete(FlightSearchResultSearchParams.TripOptions);
  searchParams.delete(FlightSearchResultSearchParams.Airlines);
  searchParams.delete(FlightSearchResultSearchParams.Alliances);
  searchParams.delete(FlightSearchResultSearchParams.Origin);
  searchParams.delete(FlightSearchResultSearchParams.Destination);
  searchParams.delete(FlightSearchResultSearchParams.StopoverOptions);
  searchParams.delete(FlightSearchResultSearchParams.Stopovers);
  searchParams.delete(FlightSearchResultSearchParams.ProviderCodes);

  return searchParams;
};

export const cabinClassDisplay = (cabinClass: CabinClass, translations: Translations) => {
  switch (cabinClass) {
    case 'economy':
      return translations.economy as string;
    case 'premium_economy':
      return translations.premium_economy as string;
    case 'business':
      return translations.business_class as string;
    case 'first':
      return translations.first as string;
    default:
      return '';
  }
};

export const flightLegPlaceCodeDisplay = (
  leg: RecentSearchLeg,
  type: 'departure' | 'arrival',
): string => {
  if (type === 'departure') {
    return leg.departureAirportCode || leg.departureCityCode;
  }

  return leg.arrivalAirportCode || leg.arrivalCityCode;
};

// Stop code and flight experience have different data structure between selected value, data sent to search_view, and data send to action_event
const convertStopCodeAndFlightExperienceDataForActionEvent = (value: number | string): string => {
  switch (value) {
    case 0:
      return 'direct';

    case 1:
      return 'one_stop';

    case 2:
      return 'more_than_one_stop';

    case 'no_overnight_flight':
      return 'no_overnight';

    case 'no_long_stopover':
      return 'no_long_stop';

    default:
      return value.toString();
  }
};

export const convertFilterStateToDataForTracking = (
  filterState: FlightMulticityFilterState<string | number>[],
  isMultiCitySearch: boolean,
  isActionEvent: boolean,
  isPerLegSearch?: boolean,
  activeLeg?: number,
): (string | number)[] => {
  // search_view have different data structure compared to action_event
  if (isPerLegSearch && activeLeg !== undefined) {
    const filterValuesForActiveLeg =
      filterState.find((item) => item.leg === activeLeg)?.values || [];

    return filterValuesForActiveLeg.map((value) =>
      isActionEvent ? convertStopCodeAndFlightExperienceDataForActionEvent(value) : value,
    );
  }

  if (!isMultiCitySearch) {
    return filterState[0].values.map((value) =>
      isActionEvent ? convertStopCodeAndFlightExperienceDataForActionEvent(value) : value,
    );
  }

  return filterState
    .map((legFilter) => {
      return legFilter.values.map(
        (value) =>
          `${legFilter.leg + 1}_${
            isActionEvent ? convertStopCodeAndFlightExperienceDataForActionEvent(value) : value
          }`,
      );
    })
    .flat();
};

export const passengerCountDisplay = (
  adultCount: number,
  childCount: number,
  infantCount: number,
  translations: Translations,
  locale: string,
) => {
  if (childCount === 0 && infantCount === 0) {
    return translateText(translations.adults_count, locale, adultCount);
  }
  return translateText(translations.passenger_count, locale, adultCount + childCount + infantCount);
};

export const mergeMinMax = (arr: (NumberRange | undefined)[]): NumberRange => {
  const items = arr.filter((item) => Boolean(item)) as NumberRange[];

  return items.reduce(
    (acc, item) => {
      acc.max = Math.max(acc.max, item.max);
      acc.min = Math.min(acc.min, item.min);
      return acc;
    },
    { max: -Infinity, min: Infinity },
  );
};

export const mergeDuplicateNameAirlines = (
  airlines: FlightMetaSearchFilterOption[],
): FlightMetaSearchMergedAirlinesFilter[] => {
  const arrayHashmap = airlines.reduce<FlightMetaSearchMergedAirlinesFilter[]>((acc, item) => {
    const existingItem = acc.find((i) => i.airline?.name === item.airline?.name);
    if (existingItem) {
      existingItem.name.push(item.name);
      if (item.price && existingItem.price) {
        existingItem.price = {
          ...existingItem.price,
          amount: Math.min(existingItem.price.amount, item.price.amount),
          totalAmount: Math.min(existingItem.price.totalAmount, item.price.totalAmount),
          amountUsd: Math.min(existingItem.price.amountUsd, item.price.amountUsd),
          totalAmountUsd: Math.min(existingItem.price.totalAmountUsd, item.price.totalAmountUsd),
        };
      }
    } else {
      acc.push({ ...item, name: [item.name] });
    }
    return acc;
  }, []);

  return arrayHashmap;
};

export const getMinMaxRangeOfASliderFilterUniqueForEachLeg = (
  departLegFilterParamString: string | null,
  returnLegFilterParamString: string | null,
  filterName: SliderFiltersUniqueForEachLeg,
  isRoundTripSearch: boolean,
  legFilters: Array<FlightMetaSearchFilterOption>,
  isPerLegSearch?: boolean,
  activeLeg?: number,
) => {
  const indexOfLegsWithFilterApplied: Array<number> = [];

  if (isPerLegSearch && activeLeg !== undefined) {
    if (!departLegFilterParamString?.split(':')[activeLeg]) {
      return undefined;
    }
    const activeLegFilter = legFilters[activeLeg];
    const filterMinMaxArray = activeLegFilter?.leg ? activeLegFilter.leg[filterName] : undefined;
    return filterMinMaxArray
      ? { [activeLeg + 1]: [filterMinMaxArray.min, filterMinMaxArray.max] }
      : undefined;
  }

  if (isRoundTripSearch) {
    if (departLegFilterParamString) {
      indexOfLegsWithFilterApplied.push(0);
    }

    if (returnLegFilterParamString) {
      indexOfLegsWithFilterApplied.push(1);
    }
  } else {
    departLegFilterParamString?.split(':').forEach((legFilter, index) => {
      if (legFilter.length) {
        indexOfLegsWithFilterApplied.push(index);
      }
    });
  }

  const filterMinMaxArray = legFilters.map((legFilter) =>
    legFilter.leg ? legFilter.leg[filterName] : undefined,
  );
  let filterMinMaxObj: GenzoRangeOfSliderFilter | undefined;

  filterMinMaxArray.forEach((filterRange, index) => {
    if (indexOfLegsWithFilterApplied.includes(index)) {
      filterMinMaxObj = {
        ...filterMinMaxObj,
        [index + 1]: filterRange ? [filterRange.min, filterRange.max] : undefined,
      };
    }
  });
  return filterMinMaxObj;
};

export const getSelectedRangeOfASliderFilterUniqueForEachLeg = (
  departLegSliderFilterParamString: string | null,
  returnLegSliderFilterParamString: string | null,
  isRoundTripSearch: boolean,
  isPerLegSearch?: boolean,
  activeLeg?: number,
) => {
  const departLegFilters = departLegSliderFilterParamString?.split(':');
  const returnLegFilter = returnLegSliderFilterParamString?.split(':')[0];

  if (isPerLegSearch && activeLeg !== undefined) {
    return departLegFilters && departLegFilters[activeLeg]
      ? { [activeLeg + 1]: departLegFilters[activeLeg].split(',').map((value) => +value) }
      : undefined;
  }

  let selectedFilterRangeObj: GenzoRangeOfSliderFilter | undefined;

  if (isRoundTripSearch) {
    if (!!departLegSliderFilterParamString && !!departLegFilters) {
      const departLegFilterRange = departLegFilters[0].split(',');

      selectedFilterRangeObj = {
        '1': [+departLegFilterRange[0], +departLegFilterRange[1]],
      };
    }

    if (returnLegFilter) {
      const returnLegFilterRange = returnLegFilter.split(',');

      selectedFilterRangeObj = {
        ...selectedFilterRangeObj,
        '2': [+returnLegFilterRange[0], +returnLegFilterRange[1]],
      };
    }
  } else {
    if (!!departLegSliderFilterParamString && !!departLegFilters) {
      departLegFilters.forEach((legFilter, index) => {
        if (legFilter.length) {
          selectedFilterRangeObj = {
            ...selectedFilterRangeObj,
            [index + 1]: legFilter.split(',').map((value) => +value),
          };
        }
      });
    }
  }

  return selectedFilterRangeObj;
};

export const getTransitDurationMinMaxForLegsWithFilterApplied = (
  stopoverDurationParamString: string | null,
  isRoundTripSearch: boolean,
  legFilters: Array<FlightMetaSearchFilterOption>,
  isPerLegSearch?: boolean,
  activeLeg?: number,
) => {
  const indexOfLegsWithStopoverDurationFilter: Array<number> = [];

  if (isPerLegSearch && activeLeg !== undefined) {
    if (!stopoverDurationParamString?.split(':')[activeLeg]) {
      return undefined;
    }

    const activeLegFilter = legFilters[activeLeg];
    const filterMinMaxArray = activeLegFilter?.leg
      ? activeLegFilter.leg.stopoverDurations
      : undefined;
    return filterMinMaxArray ? [filterMinMaxArray.min, filterMinMaxArray.max] : undefined;
  }

  if (stopoverDurationParamString) {
    if (isRoundTripSearch) {
      indexOfLegsWithStopoverDurationFilter.push(0, 1);
    } else {
      stopoverDurationParamString?.split(':').forEach((legFilter, index) => {
        if (legFilter.length) {
          indexOfLegsWithStopoverDurationFilter.push(index);
        }
      });
    }
  }

  const durationsMinMaxArray = legFilters.map((legFilter) => legFilter.leg?.stopoverDurations);
  let transitDurationMinMaxObj: GenzoRangeOfSliderFilter | undefined;

  durationsMinMaxArray.forEach((duration, index) => {
    if (indexOfLegsWithStopoverDurationFilter.includes(index)) {
      if (isRoundTripSearch && index === 1) {
        transitDurationMinMaxObj = {
          ...transitDurationMinMaxObj,
          [index + 1]: durationsMinMaxArray[0]
            ? [durationsMinMaxArray[0].min, durationsMinMaxArray[0].max]
            : undefined,
        };
      } else {
        transitDurationMinMaxObj = {
          ...transitDurationMinMaxObj,
          [index + 1]: duration ? [duration.min, duration.max] : undefined,
        };
      }
    }
  });
  return transitDurationMinMaxObj;
};

export const getSelectedRangeOfTransitDurationFilter = (
  stopoverDurationParamString: string | null,
  isRoundTripSearch: boolean,
  isPerLegSearch?: boolean,
  activeLeg?: number,
) => {
  let selectedTransitDurationsObj: GenzoRangeOfSliderFilter | undefined;

  const transitDurations = stopoverDurationParamString?.split(':');

  if (!!stopoverDurationParamString && !!transitDurations) {
    if (isPerLegSearch && activeLeg !== undefined) {
      return transitDurations[activeLeg].split(',').map((value) => +value);
    }
    if (isRoundTripSearch) {
      const transitDurationRange = transitDurations[0].split(',');

      selectedTransitDurationsObj = {
        '1': [+transitDurationRange[0], +transitDurationRange[1]],
      };

      selectedTransitDurationsObj = {
        ...selectedTransitDurationsObj,
        '2': [+transitDurationRange[0], +transitDurationRange[1]],
      };
    } else {
      if (!!stopoverDurationParamString && !!transitDurations) {
        transitDurations.forEach((legFilter, index) => {
          if (legFilter.length) {
            selectedTransitDurationsObj = {
              ...selectedTransitDurationsObj,
              [index + 1]: legFilter.split(',').map((value) => +value),
            };
          }
        });
      }
    }
  }
  return selectedTransitDurationsObj;
};

export const getSelectedTimeBlocksForGenzoTracking = (
  departLegSelectedTimeBlocksParam: string | null,
  returnLegSelectedTimeBlocksParam: string | null,
  isRoundTripSearch: boolean,
  isReturnLegSelection?: boolean,
) => {
  let selectedFilterObj: GenzoFlightTimeFilter = {};

  const departLegSelectedTimeBlocksForEachLeg = departLegSelectedTimeBlocksParam?.split(':');
  const returnLegSelectedTimeBlocks = returnLegSelectedTimeBlocksParam?.split(':')[0];

  if (isRoundTripSearch) {
    if (
      !!departLegSelectedTimeBlocksParam &&
      !!departLegSelectedTimeBlocksForEachLeg &&
      !isReturnLegSelection
    ) {
      selectedFilterObj = {
        '1': departLegSelectedTimeBlocksForEachLeg[0].split(',') as FlightFilterTimeBlock[],
      };
    }

    if (!!returnLegSelectedTimeBlocksParam && !!returnLegSelectedTimeBlocks) {
      selectedFilterObj['2'] = returnLegSelectedTimeBlocks.split(',') as FlightFilterTimeBlock[];
    }
  } else {
    if (!!departLegSelectedTimeBlocksParam && !!departLegSelectedTimeBlocksForEachLeg) {
      departLegSelectedTimeBlocksForEachLeg.forEach((departLegSelectedTimeBlocks, index) => {
        if (departLegSelectedTimeBlocks.length) {
          selectedFilterObj[`${index + 1}` as '1' | '2' | '3' | '4' | '5' | '6'] =
            departLegSelectedTimeBlocks.split(',') as FlightFilterTimeBlock[];
        }
      });
    }
  }

  return Object.keys(selectedFilterObj).length ? selectedFilterObj : undefined;
};

/**
 *
 * @param legSearches
 * @returns csin-cdxb-2022-11-02:cdxb-csin-2022-11-03
 */
export function convertLegSearchesToParam(legSearches: Array<LegSearch>): string {
  const legSearchPaths = legSearches.map<string>((legSearch) => {
    const outboundCode = `${legSearch.outboundPlace!.type === 'city' ? 'c' : ''}${
      legSearch.outboundPlace!.code
    }`;
    const inboundCode = `${(legSearch.inboundPlace! as Place).type === 'city' ? 'c' : ''}${
      (legSearch.inboundPlace! as Place).code
    }`;
    const outboundDate = dateApiFormat(new Date(legSearch.outboundDateMilliseconds!));

    if (!!legSearch.inboundDateMilliseconds) {
      const inboundDate = dateApiFormat(new Date(legSearch.inboundDateMilliseconds));

      return `${outboundCode}-${inboundCode}-${outboundDate}:${inboundCode}-${outboundCode}-${inboundDate}`;
    }
    return `${outboundCode}-${inboundCode}-${outboundDate}`;
  });

  return legSearchPaths.join(':').toLowerCase();
}

/**
 *
 * @param legParam csin-cdxb-2022-11-02:cdxb-csin-2022-11-03
 * @returns Legs
 * @throws 'Invalid leg param'
 */
export function convertLegParamToLegs(legParam: string): Array<Leg> {
  const legSearchesString = legParam.split(':');

  return legSearchesString.reduce<Array<Leg>>((accumulator: Array<Leg>, currentValue: string) => {
    const currentValueSplit = currentValue.split('-');

    if (currentValueSplit.length === 5) {
      const outboundCode = currentValueSplit[0];
      const inboundCode = currentValueSplit[1];
      const outboundDate = `${currentValueSplit[2]}-${currentValueSplit[3]}-${currentValueSplit[4]}`;

      if (/^(?:[0-9]{4}\-[0-9]{2}\-[0-9]{2})$/.test(outboundDate)) {
        accumulator.push({
          outboundDate,
          ...(outboundCode.length === 4 && {
            departureCityCode: outboundCode.slice(1).toUpperCase(),
          }),
          ...(outboundCode.length === 3 && { departureAirportCode: outboundCode.toUpperCase() }),
          ...(inboundCode.length === 4 && { arrivalCityCode: inboundCode.slice(1).toUpperCase() }),
          ...(inboundCode.length === 3 && { arrivalAirportCode: inboundCode.toUpperCase() }),
        });
      } else {
        throw new Error('Invalid leg param');
      }
    } else {
      throw new Error('Invalid leg param');
    }

    return accumulator;
  }, []);
}

export function isRoundTrip(legs: Array<Leg>): boolean {
  if (legs.length === 2) {
    const firstLeg = legs[0];
    const firstLegOutboundCityCode = (firstLeg.departureAirportCode ?? firstLeg.departureCityCode)!;
    const firstLegInboundCityCode = (firstLeg.arrivalAirportCode ?? firstLeg.arrivalCityCode)!;

    const secondLeg = legs[1];
    const secondLegOutboundCityCode = (secondLeg.departureAirportCode ??
      secondLeg.departureCityCode)!;
    const secondLegInboundCityCode = (secondLeg.arrivalAirportCode ?? secondLeg.arrivalCityCode)!;

    if (
      secondLegOutboundCityCode.toLowerCase() === firstLegInboundCityCode.toLowerCase() &&
      secondLegInboundCityCode.toLowerCase() === firstLegOutboundCityCode.toLowerCase()
    ) {
      return true;
    }
  }
  return false;
}

export const isMulticity = (legs: Leg[]) => {
  return legs.length > 1 && !isRoundTrip(legs);
};

export const getNearestMulticityLegSearchOutboundDateMilliseconds = (
  direction: 'previous' | 'next',
  index: number,
  legSearches: LegSearch[],
) => {
  if (direction === 'previous') {
    for (let i = index - 1; i >= 0; i--) {
      if (legSearches[i].outboundDateMilliseconds) {
        return legSearches[i].outboundDateMilliseconds;
      }
    }
    return undefined;
  }

  for (let i = index + 1; i < legSearches.length - 1; i++) {
    if (legSearches[i].outboundDateMilliseconds) {
      return legSearches[i].outboundDateMilliseconds;
    }
  }
  return undefined;
};

const notInThePastFlightSearch = (flightSearch: FlightRecentSearch) => {
  const today = new Date();
  const todayDateMilliseconds = today.setHours(0, 0, 0, 0);
  const flightSearchOutboundDateMilliseconds = new Date(
    flightSearch.legSearches[0].outboundDate,
  ).setHours(0, 0, 0, 0);

  return flightSearchOutboundDateMilliseconds >= todayDateMilliseconds;
};

const isTheSameSearch = (
  previousFlightSearch: FlightRecentSearch,
  newFlightSearch: FlightRecentSearch,
): boolean => {
  const newSearchToCompare = {
    legSearches: newFlightSearch.legSearches,
    adultCount: newFlightSearch.adultCount,
    childCount: newFlightSearch.childCount,
    infantCount: newFlightSearch.infantCount,
    cabinClass: newFlightSearch.cabinClass,
    paymentMethods: newFlightSearch.paymentMethods,
  };

  const currentSearchToCompare = {
    legSearches: previousFlightSearch.legSearches,
    adultCount: previousFlightSearch.adultCount,
    childCount: previousFlightSearch.childCount,
    infantCount: previousFlightSearch.infantCount,
    cabinClass: previousFlightSearch.cabinClass,
    paymentMethods: previousFlightSearch.paymentMethods,
  };

  return JSON.stringify(currentSearchToCompare) === JSON.stringify(newSearchToCompare);
};

export const isDistinctSearch = (
  flightRecentSearchesInStore: Array<FlightRecentSearch>,
  newFlightSearch: FlightRecentSearch,
): boolean => {
  return flightRecentSearchesInStore.every((search) => {
    return !isTheSameSearch(search, newFlightSearch);
  });
};

export const getFlightRecentSearchesForComparing = (): Array<FlightRecentSearch> => {
  const localStorageFlightRecentSearches =
    localStorageGet<Array<FlightRecentSearch>>(
      FLIGHT_RECENT_SEARCHES_FOR_COMPARING_LOCAL_STORAGE_KEY,
    ) || [];

  return localStorageFlightRecentSearches.filter(notInThePastFlightSearch);
};

export const saveFlightRecentSearchForComparing = (newFlightSearch: FlightRecentSearch) => {
  if (notInThePastFlightSearch(newFlightSearch)) {
    let flightSearchesInStore = getFlightRecentSearchesForComparing();

    if (!isDistinctSearch(flightSearchesInStore, newFlightSearch)) {
      // If there is existing same search in the store, filter it out so we can append the new version of that same search with updated price to the front
      flightSearchesInStore = flightSearchesInStore.filter(
        (search) => !isTheSameSearch(search, newFlightSearch),
      );
    }

    // Keeping maximum of 6 searches including the new one
    const flightSearchesToSave = [newFlightSearch, ...flightSearchesInStore.slice(0, 5)];

    localStorageSave<Array<FlightRecentSearch>>(
      FLIGHT_RECENT_SEARCHES_FOR_COMPARING_LOCAL_STORAGE_KEY,
      flightSearchesToSave,
    );
  }
};

export const emptyFlightRecentSearchForComparing = () => {
  localStorageSave<Array<FlightRecentSearch>>(
    FLIGHT_RECENT_SEARCHES_FOR_COMPARING_LOCAL_STORAGE_KEY,
    [],
  );
};

export function isFlightProduct(productParam: string): boolean {
  return /^(?:flights|fluge|vuelos|vols|tiket\-pesawat|voli|penerbangan|vluchten|loty|voos|flyg|ve\-may\-bay)$/i.test(
    productParam,
  );
}

export function groupTripViewModelBasedOnOutboundAirlineCodeAndPriceMap(
  tripViewModels: Array<TripViewModel>,
  numberOfDecimalsInCurrency: number = 0,
): { [key: string]: Array<TripViewModel> } {
  const groupedTripViewModelMap: { [key: string]: Array<TripViewModel> } = tripViewModels.reduce(
    (accumulator: { [key: string]: Array<TripViewModel> }, tripViewModel: TripViewModel, index) => {
      const tripFare: FlightSearchResultFare = tripViewModel.filteredFares[0]?.fare;

      const tripOutboundLeg = tripViewModel.legs[0]?.leg;

      const groupKey = tripViewModel.isPlaceholder
        ? `placeholder-${index}`
        : `${tripOutboundLeg.airlineCodes[0]}-${tripFare.price.amountWithFraction.toFixed(
            numberOfDecimalsInCurrency,
          )}${tripViewModel.sponsored ? 'sponsored' : ''}`;

      if (!!accumulator[groupKey]) {
        const tripDurationMinutes = durationMinutes(tripViewModel);

        for (let i = 0; i < accumulator[groupKey].length; i++) {
          if (
            i === accumulator[groupKey].length - 1 ||
            tripDurationMinutes < durationMinutes(accumulator[groupKey][i])
          ) {
            accumulator[groupKey].splice(i, 0, tripViewModel);
            break;
          }
        }
      } else {
        accumulator[groupKey] = [tripViewModel];
      }

      return accumulator;
    },
    {},
  );

  return groupedTripViewModelMap;
}

export function convertDurationInMinutesToHoursAndMinutes(
  tripViewModel: TripViewModel,
  locale: string,
  translations: Translations,
): string {
  const travelTimeInMinutes = tripViewModel.legs.reduce((accumulator, leg) => {
    return accumulator + leg.leg.durationMinutes;
  }, 0);

  let travelTimeHours =
    Math.floor(travelTimeInMinutes / 60) &&
    Math.floor(travelTimeInMinutes / 60)
      .toString()
      .padStart(2, '0');
  let travelTimeMinutes =
    travelTimeInMinutes % 60 && (travelTimeInMinutes % 60).toString().padStart(2, '0');

  travelTimeHours = travelTimeHours && translateNumber(travelTimeHours, locale === 'fa');
  travelTimeMinutes = travelTimeMinutes && translateNumber(travelTimeMinutes, locale === 'fa');

  const hourString = travelTimeHours ? `${travelTimeHours}${translations.hour_short_lbl}` : '';
  const separator = travelTimeHours && travelTimeMinutes ? ' ' : '';
  const minuteString = travelTimeMinutes
    ? `${travelTimeMinutes}${translations.minute_short_lbl}`
    : '';

  const travelTime = `${hourString}${separator}${minuteString}`;

  return travelTime;
}

export function hasBnplPaymentOptionForTripCardDisplay(
  paymentMethodsFromApi: Array<HomepageFlightsPaymentMethod> | undefined,
  flightFares: Array<FlightSearchResultFare>,
): boolean {
  if (!paymentMethodsFromApi || flightFares.length === 0) {
    return false;
  }

  const cheapestFare = flightFares.reduce((previous, current) => {
    return current.price.totalAmount < previous.price.totalAmount ? current : previous;
  });

  const hasBnplFare = cheapestFare.paymentFees.some((paymentFee) =>
    paymentMethodsFromApi.some(
      (paymentMethodFromApi) =>
        paymentMethodFromApi.paymentType === 'PAYLATER' &&
        paymentMethodFromApi.id === paymentFee.paymentMethodId,
    ),
  );

  return hasBnplFare;
}

export const format = (amount: number, currency: Currency, locale: string) => {
  let separator = currency.separator;
  let mod = 0;
  let amountStr = '';

  // note: currency's decimal and separator are inverted for certain locales
  // for example netherlands (nl-NL) but wego does not implement this
  if (amount < 100) {
    amountStr = amount.toFixed(2);
    mod = amountStr.length - 3 > 3 ? amountStr.length % 3 : 0;
  } else {
    if (isBigAmount(amount, currency)) {
      amount = amount / 1000;
    }
    amountStr = amount.toFixed(0);
    mod = amountStr.length > 3 ? amountStr.length % 3 : 0;
  }

  // checks for modulus and inserts separator between substring and the remainder of price in string format
  let format = translateNumber(
    (mod ? amountStr.substr(0, mod) + separator : '') +
      amountStr.substr(mod).replace(/(\d{3})(?=\d)/g, '$1' + separator),
    locale === 'fa',
  );

  return format;
};

export const isBigAmount = (amount: number, currency: Currency): boolean => {
  if (currency !== undefined) {
    return LOW_RATE_CURRENCIES.includes(currency.code) && amount > 99999;
  } else {
    return false;
  }
};

export const constructGreatEscapeLink = (
  domain: string,
  sessionId: string,
  additionalParams?: Record<string, any>,
  outboundDate?: string,
  inboundDate?: string,
  outboundPlace?: Place,
  adultCount?: number,
  childCount?: number,
  infantCount?: number,
  currencyCode?: string,
  isDirectFlightOnly?: boolean,
  paymentMethodIds?: string,
  searchType?: FlightSearchType,
): string => {
  let outboundCode = '';

  if (outboundPlace) {
    const airportCodes = outboundPlace.airportCodes || [];

    if (outboundPlace.type === 'airport' || airportCodes.includes(outboundPlace.code!)) {
      outboundCode = outboundPlace.code!;
    } else {
      outboundCode = airportCodes[0];
    }
  }

  const params = {
    domain,
    session_id: sessionId,
    ...(adultCount && { adt: adultCount }),
    ...(childCount && { chd: childCount }),
    ...(infantCount && { in1: infantCount }),
    ...(currencyCode && { cc: currencyCode }),
    ...(searchType && { rr: searchType === 'roundTrip' ? 'True' : 'False' }),
    ...(outboundCode && { o1: outboundCode }),
    ...(outboundCode && { d1: 'EXPL' }),
    ...(inboundDate && { dd2: inboundDate }),
    ...(outboundDate && { dd1: outboundDate }),
    direct: isDirectFlightOnly ? 'True' : 'False',
    sort: 'price',
    order: 'asc',
    ...(paymentMethodIds && { payment_methods: paymentMethodIds }),
    ...(additionalParams && { ...additionalParams }),
  };

  const link = appendSearchParams(WEGO_GREAT_ESCAPE_BASE_URL, params);

  return link;
};

export function getFlightSearchLocationCodesFromLocalStorage(): Array<string> {
  const flightSearch = localStorageGet<FlightSearch>(FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY);
  let locationCodes: string[] = [];

  if (!!flightSearch) {
    flightSearch.legSearches.map((legSearch) => {
      if (!!legSearch.outboundPlace) {
        locationCodes.push(
          `${legSearch.outboundPlace.type === 'airport' ? '' : 'c'}${legSearch.outboundPlace.code}`,
        );
      }

      if (!!legSearch.inboundPlace && legSearch.inboundPlace !== 'Anywhere') {
        locationCodes.push(
          `${legSearch.inboundPlace.type === 'airport' ? '' : 'c'}${legSearch.inboundPlace.code}`,
        );
      }
    });
  }
  return locationCodes;
}

function frameUpdatedFlightSearch(
  flightSearch: FlightSearch,
  locationDetails: Array<Place>,
  locale: string,
): FlightSearch {
  const updatedLegSearches = flightSearch.legSearches.map((legSearch) => {
    let outboundPlace;
    let inboundPlace;
    if (!!legSearch.outboundPlace) {
      outboundPlace = locationDetails.find((locationDetail) => {
        if (legSearch.outboundPlace!.type === 'airport') {
          return locationDetail.airportCode === legSearch.outboundPlace!.code;
        } else {
          return locationDetail.cityCode === legSearch.outboundPlace!.code;
        }
      });
    }

    if (!!legSearch.inboundPlace && legSearch.inboundPlace !== 'Anywhere') {
      inboundPlace = locationDetails.find((locationDetail) => {
        if (legSearch.inboundPlace !== 'Anywhere') {
          if (legSearch.inboundPlace!.type === 'airport') {
            return locationDetail.airportCode === legSearch.inboundPlace!.code;
          } else {
            return locationDetail.cityCode === legSearch.inboundPlace!.code;
          }
        }
      });
    }

    return { ...legSearch, inboundPlace, outboundPlace };
  });

  return { ...flightSearch, legSearches: updatedLegSearches, locale };
}

export async function getUpdatedFlightSearchDetails(locale: string) {
  const flightSearch = localStorageGet<FlightSearch>(FLIGHT_RECENT_SEARCH_LOCAL_STORAGE_KEY);
  let locationCodes = getFlightSearchLocationCodesFromLocalStorage();

  const locationDetails = await getLocationDetails(locale, locationCodes);

  let newFlightSearch: FlightSearch | null = flightSearch;

  if (!!flightSearch) {
    newFlightSearch = frameUpdatedFlightSearch(flightSearch, locationDetails, locale);
  }

  return newFlightSearch;
}

export const getComparisonProviderLabelText = (
  flightComparisonProviders: Array<FlightComparisonProvider>,
  translations: Translations,
) => {
  const firstProvider = flightComparisonProviders[0];

  if (firstProvider) {
    switch (firstProvider.provider.category) {
      case 'flights':
        return translations.compare_websites;
      case 'hotels':
        return translations.cau_hotel_offers_2;
      case 'others':
        return translations.cau_other_offers_2;
      default:
        return translations.compare_websites;
    }
  }

  return '';
};

export function filterFlightFareResultFares(
  flightFareResultTrip: FlightFareResultTrip,
  selectedPaymentMethodIds: number[],
  providerCodesSearchParam: string,
  providersSearchParam: string,
  isApplyBaggageFilter?: boolean,
): {
  filteredNormalFares: FlightFareResultTripFare[];
  filteredRecommendedFares: FlightFareResultTripFare[];
} {
  let filterFares = [...(flightFareResultTrip.fares || [])];
  let originalRecommendedFares = [...(flightFareResultTrip.recommendedFares || [])];

  if (selectedPaymentMethodIds.length > 0) {
    filterFares = filterFares.filter((fare) =>
      selectedPaymentMethodIds.some((selectedPaymentMethodId) =>
        fare.paymentFees.some((fee) => fee.paymentMethodId === selectedPaymentMethodId),
      ),
    );
    originalRecommendedFares = originalRecommendedFares.filter((fare) =>
      selectedPaymentMethodIds.some((selectedPaymentMethodId) =>
        fare.paymentFees.some((fee) => fee.paymentMethodId === selectedPaymentMethodId),
      ),
    );
  }

  if (!!providerCodesSearchParam || !!providersSearchParam || isApplyBaggageFilter) {
    const isWegoFareProvider = providersSearchParam.split(',').includes('wego');
    const isAirlineProvider = providersSearchParam.split(',').includes('airline');
    const wegoFares = filterFares.filter((fare) => {
      const providerCodes = providerCodesSearchParam.split(',');

      const consolidatedProviderCodes = isApplyBaggageFilter
        ? providerCodes.concat(providerCodesForBaggageFilter)
        : providerCodes;

      if (!!fare.provider) {
        return (
          consolidatedProviderCodes.includes(fare.provider.code) ||
          (isWegoFareProvider && fare.provider.wegoFare) ||
          (isAirlineProvider && fare.provider.type === 'airline')
        );
      }
      return false;
    });
    const wegoRecommendedFares = originalRecommendedFares.filter((fare) => {
      if (fare.provider) {
        const providerCodes = providerCodesSearchParam.split(',');

        const consolidatedProviderCodes = isApplyBaggageFilter
          ? providerCodes.concat(providerCodesForBaggageFilter)
          : providerCodes;
        return (
          consolidatedProviderCodes.includes(fare.provider.code) ||
          (isWegoFareProvider && fare.provider.wegoFare) ||
          (isAirlineProvider && fare.provider.type === 'airline')
        );
      }
      return false;
    });

    filterFares = wegoFares;
    originalRecommendedFares = wegoRecommendedFares;
  }

  return {
    filteredNormalFares: filterFares,
    filteredRecommendedFares: originalRecommendedFares,
  };
}

export const getOperatingAirlinesInformation = (
  leg: FlightFareResultTripLeg | FlightSearchResultLeg,
  translations: Translations,
) => {
  const segmentsOperatedByOtherAirlines =
    (leg.segments as (FlightFareResultTripLegSegment | FlightSearchResultLegSegment)[]).filter(
      (segment: FlightFareResultTripLegSegment | FlightSearchResultLegSegment) =>
        !!segment.operatingAirlineCode,
    ) || [];

  const isMainAirlineOperatedByOtherAirline = !!segmentsOperatedByOtherAirlines.find(
    (segment: FlightFareResultTripLegSegment | FlightSearchResultLegSegment) =>
      segment.airlineCode === leg.airlineCodes[0] && !!segment.operatingAirlineCode,
  );

  if (isMainAirlineOperatedByOtherAirline) {
    return translations.operated_other_airlines;
  }

  if (segmentsOperatedByOtherAirlines.length > 0) {
    return translations.partially_operated_other_airlines;
  }

  return '';
};

export const getHeightOfHighlightTags = (
  highlightTags: Array<'best' | 'cheapest' | 'cheapest_direct'>,
): number => {
  if (!highlightTags?.length) {
    return 0;
  }
  if (highlightTags.includes('best') && highlightTags.length > 1) {
    return 60;
  }
  return 30;
};

export function mapFlightTimeFilterParamToLegs(
  flightTimeFilterParam: string | null,
  legs: Leg[],
): Array<FlightMulticityFilterState<FlightFilterTimeBlock>> {
  let initiaFlightTimeBlockValues: Array<FlightMulticityFilterState<FlightFilterTimeBlock>> =
    legs.map((_, index) => ({
      leg: index,
      values: [],
    }));

  if (flightTimeFilterParam) {
    initiaFlightTimeBlockValues = flightTimeFilterParam.split(':').map((item, index) => ({
      leg: index,
      values: item ? (item.split(',') as FlightFilterTimeBlock[]) : [],
    }));
  }

  return initiaFlightTimeBlockValues;
}

export function getUpdatedLegSearchesWhenLegSearchFieldChange(
  legSearches: LegSearch[],
  legSearchIndex: number,
  field: FlightSearchFields,
  value: InboundPlace | number,
) {
  const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);
  const legSearch = cloneLegSearches[legSearchIndex];

  switch (field) {
    case 'outboundPlace': {
      legSearch.outboundPlace = value as OutboundPlace;
      break;
    }
    case 'inboundPlace': {
      legSearch.inboundPlace = value as InboundPlace;

      if (
        legSearch.inboundPlace !== 'Anywhere' &&
        legSearchIndex < cloneLegSearches.length - 1 &&
        cloneLegSearches[legSearchIndex + 1].outboundPlace === undefined
      ) {
        cloneLegSearches[legSearchIndex + 1].outboundPlace = value as OutboundPlace;
      }
      break;
    }
    case 'swapOutboundInboundPlace': {
      legSearch.outboundPlace = legSearches[legSearchIndex].inboundPlace as Place;
      legSearch.inboundPlace = legSearches[legSearchIndex].outboundPlace;
      break;
    }
    case 'outboundDateMilliseconds': {
      legSearch.outboundDateMilliseconds = value as number | undefined;

      if (
        !!legSearch.inboundDateMilliseconds &&
        (value as number) > legSearch.inboundDateMilliseconds
      ) {
        legSearch.inboundDateMilliseconds = undefined;
      }

      if (legSearchIndex < cloneLegSearches.length - 1) {
        for (let i = legSearchIndex + 1; i < cloneLegSearches.length; i++) {
          const nextLegSearch = cloneLegSearches[i];
          if (
            nextLegSearch.outboundDateMilliseconds &&
            (value as number) > nextLegSearch.outboundDateMilliseconds
          )
            nextLegSearch.outboundDateMilliseconds = value as number;
        }
      }

      break;
    }
    case 'inboundDateMilliseconds': {
      legSearch.inboundDateMilliseconds = value as number | undefined;
      break;
    }
  }

  return cloneLegSearches;
}

export function getUpdatedLegSearchesWhenAddingFlight(legSearches: LegSearch[]) {
  const cloneLegSearches = cloneDeep<Array<LegSearch>>(legSearches);

  const lastIndex = cloneLegSearches.length - 1;

  cloneLegSearches.push({
    outboundPlace: cloneLegSearches[lastIndex].inboundPlace as OutboundPlace,
    inboundPlace: undefined,
    outboundDateMilliseconds: undefined,
    inboundDateMilliseconds: undefined,
  });

  return cloneLegSearches;
}

export const renderLegLabel = ({
  depart,
  arrival,
  isRtl,
}: {
  depart: string;
  arrival: string;
  isRtl: boolean;
}) => (isRtl ? `${arrival} ← ${depart}` : `${depart} → ${arrival}`);

export const getRange = ({
  activeThumb,
  max,
  min,
  values,
  minimumDistance = 10,
}: {
  values: number[] | number;
  activeThumb: number;
  max: number;
  min: number;
  minimumDistance?: number;
}) => {
  if (typeof values !== 'number' && values.length === 2) {
    if (values[1] - values[0] < minimumDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(values[0], max - minimumDistance);
        return [clamped, clamped + minimumDistance];
      } else {
        const clamped = Math.max(values[1], min + minimumDistance);
        return [clamped - minimumDistance, clamped];
      }
    } else {
      return values;
    }
  }
};

export function getGenzoValuesForBookingOptionsFilter(
  bookingOptions: string[],
  isSearchViewEvent?: boolean,
) {
  return bookingOptions.map((value) => {
    if (isSearchViewEvent) {
      return value === 'wego' ? 'instant_book' : 'book_direct';
    }
    return value === 'wego' ? 'bow' : 'airline_website';
  });
}

export function getPreCheckedStatusForFlightComparisonProviders(
  flightComparisonProviders: FlightComparisonProvider[],
) {
  const flightComparisonProvidersStringFromCookie = getCookie(FLIGHT_COMPARISON_PROVIDERS);

  const flightComparisonProvidersFromCookie = flightComparisonProvidersStringFromCookie
    ? (JSON.parse(flightComparisonProvidersStringFromCookie) as { [key: string]: boolean })
    : undefined;

  if (flightComparisonProvidersFromCookie) {
    flightComparisonProviders.forEach((flightComparisonProvider) => {
      const localStorageFlightComparisonProvider =
        flightComparisonProvidersFromCookie[flightComparisonProvider.provider.code];

      if (localStorageFlightComparisonProvider !== undefined) {
        flightComparisonProvider.preChecked = localStorageFlightComparisonProvider;
      }
    });
  }
}

export function updateFlightComparisonProviderCheckedStatus(code: string, isSelected: boolean) {
  const flightComparisonProvidersStringFromCookie = getCookie(FLIGHT_COMPARISON_PROVIDERS);

  const flightComparisonProvidersFromCookie = flightComparisonProvidersStringFromCookie
    ? (JSON.parse(flightComparisonProvidersStringFromCookie) as { [key: string]: boolean })
    : undefined;

  addCookie(
    FLIGHT_COMPARISON_PROVIDERS,
    JSON.stringify({
      ...flightComparisonProvidersFromCookie,
      ...{ [code]: isSelected },
    }),
    Date.now() + 24 * 60 * 60 * 1000,
  );
}

export function checkIfFlightSearchResultPage(pathname: string, localeParam: string): boolean {
  const flightParams = flightsRouteTranslation(localeParam);
  const regex = `${flightParams}/searches`;

  return new RegExp(regex, 'i').test(pathname);
}

export function checkHasSabreFare<TFare extends { providerCode: string }>(fares: TFare[]): boolean {
  return fares.some((fare) => /^(.*\.)?a\.wego\.com$/.test(fare.providerCode));
}

export function getFlightSearchResultDataDD({
  tripViewModel,
  index,
  dataDD,
  sabreFareDataDD,
}: {
  tripViewModel: TripViewModel;
  index: number;
  dataDD: Record<string, string | number | boolean>;
  sabreFareDataDD: Record<string, string | number | boolean>;
}) {
  const hasSabreFare = checkHasSabreFare(tripViewModel.filteredFares.map((fare) => fare.fare));

  const activeDataDD = index === 0 ? dataDD : ({} as Partial<typeof dataDD>);
  const activeSabreFareDataDD = hasSabreFare
    ? sabreFareDataDD
    : ({} as Partial<typeof sabreFareDataDD>);

  const combinedDataDD = {
    ...activeDataDD,
    ...activeSabreFareDataDD,
    'data-dd': [activeDataDD?.['data-dd'], activeSabreFareDataDD['data-dd']]
      .filter(Boolean)
      .join(' '),
  };

  return combinedDataDD;
}

export function durationMinutes(tripViewModel: TripViewModel): number {
  return tripViewModel.legs.reduce((totalDurationInMinutes: number, leg: LegViewModel): number => {
    return totalDurationInMinutes + leg.leg.durationMinutes;
  }, 0);
}

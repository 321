import { notUndefinedAndNull } from '@helpers/array';

export const cleanHomepageData = (homepage: Homepage): Homepage => {
  const flights = structuredClone(homepage.flights);
  const hotels = structuredClone(homepage.hotels);

  flights.cities = flights.cities.filter(notUndefinedAndNull);
  flights.countries = flights.countries.filter(notUndefinedAndNull);
  flights.domesticCities = flights.domesticCities.filter(notUndefinedAndNull);
  flights.paymentMethods = flights.paymentMethods.filter(notUndefinedAndNull);
  flights.popularAirlines = flights.popularAirlines.filter(notUndefinedAndNull);
  flights.popularProviders = flights.popularProviders.filter(notUndefinedAndNull);

  hotels.cities = hotels.cities.filter(notUndefinedAndNull);
  hotels.countries = hotels.countries.filter(notUndefinedAndNull);
  hotels.domesticCities = hotels.domesticCities.filter(notUndefinedAndNull);
  hotels.popularBrands = hotels.popularBrands.filter(notUndefinedAndNull);
  hotels.popularProviders = hotels.popularProviders.filter(notUndefinedAndNull);

  return { flights, hotels };
};

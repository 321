import { locales } from '@alexis/helpers/site';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateText } from '@wego/alexis/helpers/translation';

import { isWegoShopcashEnabled } from '@helpers/shopcash';

import BusinessOutlineIcon from '@icons/businessOutline.svg';
import ChevronRightSmallIcon from '@icons/chevron-right-small.svg';
import DashboardIcon from '@icons/dashboard.svg';
import HelpIcon from '@icons/help.svg';
import ProfileIcon from '@icons/profile.svg';
import SettingsIcon from '@icons/settings.svg';

import {
  getCurrentSiteState,
  getLocaleState,
  getShopCashUserState,
  getTranslationsState,
} from '@redux/selectors';

import UserSummaryInfo from '../UserSummaryInfo';
import PreferencesSideNavigationLink from './PreferenceSideNavigationLink';
import styles from './PreferencesSideNavigation.module.scss';
import PreferencesSideNavigationAccordion from './PreferencesSideNavigationAccordion';

type SidebarNavItem = {
  labelKey: string;
  icon?: React.FC<React.SVGAttributes<SVGElement>>;
  href?: string;
  children?: SidebarNavItem[];
};

const SIDEBAR_NAV_ITEMS: SidebarNavItem[] = [
  {
    labelKey: 'dashboard',
    icon: DashboardIcon,
    href: '/preferences',
  },
  {
    labelKey: 'my_profile',
    icon: ProfileIcon,
    children: [
      // {
      //   labelKey: 'my_personal_info',
      //   href: '/preferences/profile/personal-info',
      // },
      {
        labelKey: 'my_wallet',
        href: '/preferences/profile/wallet',
      },
      {
        labelKey: 'preferred_payment_method',
        href: '/preferences/profile/payment-methods',
      },
    ],
  },
  {
    labelKey: 'my_trips',
    icon: BusinessOutlineIcon,
    children: [
      {
        labelKey: 'hotel_bookings',
        href: '/preferences/trips/hotels',
      },
      {
        labelKey: 'flight_bookings',
        href: '/preferences/trips/flights',
      },
      // {
      //   labelKey: 'add_edit_traveler',
      //   href: '/preferences/trips/traveler',
      // },
    ],
  },
  {
    labelKey: 'settings',
    icon: SettingsIcon,
    children: [
      // {
      //   labelKey: 'change_password',
      //   href: '/preferences/settings/password',
      // },
      // {
      //   labelKey: 'link_account',
      //   href: '/preferences/settings/link-account',
      // },
      // {
      //   labelKey: 'notifications',
      //   href: '/preferences/settings/notifications',
      // },
      {
        labelKey: 'terms_of_use',
        href: 'https://company.wego.com/terms-of-service',
      },
      {
        labelKey: 'privacy_policy',
        href: 'https://company.wego.com/data-privacy-policy',
      },
    ],
  },
  {
    labelKey: 'help_center',
    icon: HelpIcon,
    children: [
      // {
      //   labelKey: 'faqs',
      //   href: '/preferences/help/faqs',
      // },
      {
        labelKey: 'contact_us',
        href: '/preferences/help/contact-us',
      },
    ],
  },
];

type PreferencesSideNavigationProps = {
  className?: string;
  localeParam?: string;
};

const PreferencesSideNavigation: React.FC<PreferencesSideNavigationProps> = ({
  className,
  localeParam,
}: PreferencesSideNavigationProps) => {
  const localeCodes = locales.map(({ code }) => code);
  const localeRegex = new RegExp(`^/(${localeCodes.join('|')})`);

  const { pathname } = useLocation();

  const locale = useSelector(getLocaleState);
  const translations = useSelector(getTranslationsState);

  const currentSite = useSelector(getCurrentSiteState);
  const shopCashUser = useSelector(getShopCashUserState);
  const shouldShowWallet = isWegoShopcashEnabled(currentSite.countryCode) && !!shopCashUser;

  const sidebarNavItems = SIDEBAR_NAV_ITEMS.map((item) => {
    if (item.labelKey === 'my_profile' && !shouldShowWallet) {
      return {
        ...item,
        children: item.children?.filter((child) => child.labelKey !== 'my_wallet'),
      };
    }

    if (item.labelKey === 'settings')
      return {
        ...item,
        children: [
          ...(item.children || []),
          {
            labelKey: 'delete_account',
            href: pathname.replace(localeRegex, '') + `?modal=delete-account`,
          },
        ],
      };

    return item;
  });

  const isNavActive = (href: string) => {
    return pathname.replace(localeRegex, '') === href;
  };

  return (
    <div className={clsx(styles.container, className)}>
      <UserSummaryInfo />
      <ul className={styles.navItems}>
        {sidebarNavItems.map((item) => {
          if (item.children?.length)
            return (
              <li key={item.labelKey}>
                <PreferencesSideNavigationAccordion
                  className={styles.navItem}
                  defaultOpen={!!item.children.find((subItem) => isNavActive(subItem.href!))}
                >
                  {!!item.icon && <item.icon />}
                  <span>
                    {translations[item.labelKey]
                      ? translateText(translations[item.labelKey], locale)
                      : item.labelKey}
                  </span>
                </PreferencesSideNavigationAccordion>
                <ul className={styles.navSubItems}>
                  {item.children.map((child) => (
                    <li key={child.labelKey}>
                      <PreferencesSideNavigationLink
                        localeParam={localeParam}
                        to={child.href!}
                        className={styles.navSubItem}
                        data-active={isNavActive(child.href!)}
                      >
                        <span>
                          {translations[child.labelKey]
                            ? translateText(translations[child.labelKey], locale)
                            : child.labelKey}
                        </span>
                        <ChevronRightSmallIcon className={styles.icon} />
                      </PreferencesSideNavigationLink>
                    </li>
                  ))}
                </ul>
              </li>
            );

          if (item.href)
            return (
              <li key={item.labelKey}>
                <PreferencesSideNavigationLink
                  localeParam={localeParam}
                  to={item.href}
                  className={styles.navItem}
                  data-active={isNavActive(item.href!)}
                >
                  {!!item.icon && <item.icon />}
                  <span>{translateText(translations[item.labelKey], locale)}</span>
                  <ChevronRightSmallIcon className={styles.icon} />
                </PreferencesSideNavigationLink>
              </li>
            );
        })}
        {/*   <li>
          <button type='button' className={styles.navItem} onClick={handleLogOut}>
            <LogoutIcon />
            <span>{translateText(translations.logout, locale)}</span>
          </button>
        </li> */}
      </ul>
    </div>
  );
};

export default PreferencesSideNavigation;

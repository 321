export function rearrangeDayLabels(
  dayLabels: Array<string>,
  firstDayOfWeek?: number,
): Array<string> {
  const cloneDefaultDayLabels = [...dayLabels];

  if (firstDayOfWeek !== undefined && firstDayOfWeek === 7) {
    // Fri and Sat weekends
    // Do nothing because [enShortDayLabels] is already in that sequence.
  } else if (firstDayOfWeek !== undefined && firstDayOfWeek === 6) {
    // Thur and Fri weekends
    cloneDefaultDayLabels.unshift(cloneDefaultDayLabels.pop() || '');
  } else {
    // Sat and Sun weekends
    cloneDefaultDayLabels.push(cloneDefaultDayLabels.shift() || '');
  }
  return cloneDefaultDayLabels;
}

import { useSelector } from 'react-redux';

import { getPlaces } from '@apis/places';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { getLocaleState, getCurrentSiteState } from '@redux/selectors';

export const getPlacesKey = 'get-places';

type GetPlacesParams = {
  query: string;
  isCity: boolean;
  isDistrict: boolean;
  isHotel: boolean;
  isRegion: boolean;
  isActivity: boolean;
  isAttraction: boolean;
  minimumHotelCount?: number;
  minimumAirportCount?: number;
};

export const useGetPlaces = (
  params: GetPlacesParams,
  options?: UseAxiosQueryOptions<Array<Place>>,
) => {
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  const {
    query,
    isCity,
    isDistrict,
    isHotel,
    isRegion,
    isActivity,
    isAttraction,
    minimumHotelCount,
    minimumAirportCount,
  } = params;

  return useAxiosQuery({
    queryKey: [getPlacesKey, { locale, code: currentSiteCode.countryCode, ...params }],
    queryFn: () =>
      getPlaces(
        locale,
        currentSiteCode.countryCode,
        query,
        isCity,
        isDistrict,
        isHotel,
        isRegion,
        isActivity,
        isAttraction,
        undefined,
        minimumHotelCount,
        minimumAirportCount,
      ),
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  });
};

import { BehaviorSubject } from 'rxjs';

const triggerSubject = new BehaviorSubject<boolean>(false);

export const triggerService = {
  trigger$: triggerSubject.asObservable(),

  triggerFunctionTarget: () => {
    triggerSubject.next(true);
  },

  resetTrigger: () => {
    triggerSubject.next(false);
  },
};

import { clsx } from '@alexis/helpers/clsx';
import { ReactNode } from 'react';

import styles from '@components/home/Propositions/Propositions.module.scss';

const PropositionItem: React.FC<PropositionItemProp> = ({
  isBowOnly,
  pageType,
  defaultText,
  flightsText,
  hotelsText,
  image,
}) => {
  const BowOnlyIcon = image.bowOnly;

  return (
    <div className={clsx(styles.proposition, !isBowOnly && styles.itemsCenter)}>
      <PropositionIcon
        bowOnlyIcon={<BowOnlyIcon />}
        defaultImageSrc={image.default}
        isBowOnly={isBowOnly}
      />

      <PropositionContent
        isBowOnly={isBowOnly}
        pageType={pageType}
        hotelsText={hotelsText.heading}
        flightsText={flightsText.heading}
        defaultText={defaultText.heading}
        contentType='heading'
      />

      <PropositionContent
        isBowOnly={isBowOnly}
        pageType={pageType}
        hotelsText={hotelsText.description}
        flightsText={flightsText.description}
        defaultText={defaultText.description}
        contentType='description'
      />
    </div>
  );
};

export default PropositionItem;

type Content = {
  heading: string;
  description: string;
};

type PropositionItemProp = {
  isBowOnly: boolean;
  pageType: string;
  hotelsText: Content;
  flightsText: Content;
  defaultText: Content;
  image: {
    bowOnly: React.FC<React.SVGAttributes<SVGElement>>;
    default: string;
  };
};

type PropositionIconProp = {
  bowOnlyIcon: ReactNode;
  defaultImageSrc: string;
  isBowOnly: boolean;
};

type PropositionTextProp = {
  isBowOnly: boolean;
  pageType: string;
  hotelsText: string;
  flightsText: string;
  defaultText: string;
  contentType: 'heading' | 'description';
};

const PropositionContent: React.FC<PropositionTextProp> = ({
  isBowOnly,
  pageType,
  defaultText,
  flightsText,
  hotelsText,
  contentType,
}) => {
  const isHeading = contentType === 'heading';
  const renderText = () => {
    if (isBowOnly) {
      if (pageType === 'hotels') {
        return hotelsText;
      }

      return flightsText;
    }

    return defaultText;
  };

  return (
    <div
      className={clsx(isHeading ? styles.title : styles.message, !isBowOnly && styles.textCenter)}
    >
      {renderText()}
    </div>
  );
};
export const PropositionIcon: React.FC<PropositionIconProp> = ({
  bowOnlyIcon,
  defaultImageSrc,
  isBowOnly,
}) => {
  return isBowOnly ? (
    <div className={styles.bowOnlyContainer}>{bowOnlyIcon}</div>
  ) : (
    <img alt='proposition' src={defaultImageSrc} height='100' width='100' />
  );
};

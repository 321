import React from 'react';
import { useLocation } from 'react-router-dom';

import { translateText } from '@wego/alexis/helpers/translation';
import { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { CLOUDINARY_DAILY_KEY } from '@constants/cloudinary';

import { cloudinaryImageTransformations } from '@helpers/imageTransformations';
import { airlinesRoute } from '@helpers/routeTranslation';

import { Translations } from '@wegoTypes/translations';

import styles from './PopularAirlines.module.scss';

interface PopularAirlinesProps {
  translations: Translations;
  locale: string;
  countryName: string;
  popularAirlines: Array<HomepageFlightsPopularAirline>;
  pageType: string;
  currentSite: CurrentSite;
  localeParam: string;
}

const PopularAirlines: React.FC<PopularAirlinesProps> = ({
  translations,
  locale,
  countryName,
  popularAirlines,
  pageType,
  currentSite,
  localeParam,
}) => {
  const { search } = useLocation();
  if (!['home', 'flights'].includes(pageType) || popularAirlines.length <= 0) return null;

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <div className={styles.title}>
          {translateText(translations.popular_airlines_in, locale, countryName)}
        </div>
        <div className={styles.message}>{translations.book_cheap_flights}</div>
      </div>

      <div className={styles.logos}>
        {popularAirlines.slice(0, 8).map((popularAirline) => {
          return (
            <a
              key={popularAirline.code}
              className={styles.logo}
              href={`${airlinesRoute(currentSite, localeParam)}/${
                popularAirline.permalink
              }-${popularAirline.code.toLowerCase()}${search}`}
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={cloudinaryImageTransformations(
                  `https://assets.wego.com/image/upload/${CLOUDINARY_DAILY_KEY}/flights/airlines_rectangular/${popularAirline.code}.png`,
                  'h_64',
                  'c_fit',
                  'f_auto',
                  'fl_lossy',
                  'q_auto:low',
                )}
                alt={popularAirline.name}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default PopularAirlines;

import { AuthContext } from '@vendor/react-oauth2-code-pkce';
import { useCallback, useContext } from 'react';

import { localStorageClear, localStorageGet } from '@wego/alexis/helpers/localStorage';

import { NEW_AUTH } from '@constants/localStorage';

import { GlobalContext } from '@context/GlobalContext';

const authenticatedRoutes = ['/preferences'];

export const useIsAuthNew = () => {
  const { userCentricCoreConfigs } = useContext(GlobalContext);
  const auth = useContext(AuthContext);
  const isAuthNew =
    userCentricCoreConfigs?.find((coreConfig) => coreConfig.key === 'dweb_universal_login')
      ?.value === '1';
  // used to detect if the user has used new login
  const usedNewLogin: boolean = localStorageGet(NEW_AUTH) === true;

  const currentRoute = window.location.pathname;

  const login = useCallback(
    (method?: 'popup' | 'redirect') => {
      const url = new URL(window.location.href);
      url.searchParams.delete('re-authenticate');

      auth.logIn(
        undefined,
        {
          redirect_path: currentRoute + url.search,
        },
        method || 'popup',
      );
    },
    [auth.logIn, currentRoute],
  );

  const logout = useCallback(() => {
    localStorageClear(NEW_AUTH);
    const isAuthenticatedRoute = authenticatedRoutes.some((route) => currentRoute.includes(route));
    if (isAuthenticatedRoute) {
      auth.logOut();
      return;
    }

    auth.logOut(undefined, undefined, {
      post_logout_redirect_path: currentRoute + window.location.search,
    });
  }, [auth.logOut, currentRoute]);

  return {
    isAuthNew,
    auth,
    login,
    logout,
    coreConfigsLoaded: userCentricCoreConfigs?.length > 0,
    usedNewLogin,
  };
};

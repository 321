import { PORTAL_DRAWER_Z_INDEX } from '@constants/layout';

import XCloseSmallIcon from '@icons/x-close-small.svg';

import Portal from '../Portal';
import styles from './Drawer.module.scss';

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
};

const Drawer: React.FC<DrawerProps> = ({ open, onClose, title, children }) => {
  return (
    <Portal zIndex={PORTAL_DRAWER_Z_INDEX}>
      <div data-testid='drawer' className={styles.container} data-open={open}>
        <div data-testid='drawer-backdrop' className={styles.backdrop} onClick={onClose}></div>
        <section className={styles.drawer}>
          <header className={styles.drawerHeader}>
            <h3>{title}</h3>
            <button data-testid='drawer-close-button' type='button' onClick={onClose}>
              <XCloseSmallIcon />
            </button>
          </header>
          <div className={styles.drawerBody}>{children}</div>
        </section>
      </div>
    </Portal>
  );
};

export default Drawer;

import { dateApiFormat } from '@alexis/helpers/date';
import { CurrentSite } from 'currentSite';
import type { SortOrder } from 'sortOrder';

import { dateDisplayFormat, getLocalDate, numberOfNights } from '@wego/alexis/helpers/date';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@wego/alexis/helpers/environment';
import { translateNumber } from '@wego/alexis/helpers/translation';
import { translateText } from '@wego/alexis/helpers/translation';

import { PROD_TS_CODES, STAGING_TS_CODES } from '@constants/tsCodes';

import { hotelsRoute } from '@helpers/routeTranslation';

import { Currency } from '@wegoTypes/currency';
import { GuestRoom } from '@wegoTypes/hotels/guestRoom';
import { HotelComparisionProvider } from '@wegoTypes/hotels/hotelComparisonProvider';
import { HotelMetaSearch } from '@wegoTypes/hotels/hotelMetaSearch';
import { HotelSearch } from '@wegoTypes/hotels/hotelSearch';
import { HotelViewModel } from '@wegoTypes/hotels/hotelViewModel';
import { HotelMetaSearchHotelReview } from '@wegoTypes/hotels/metasearch/hotelMetaSearchHotelReview';
import { HotelSearchResultHotel } from '@wegoTypes/hotels/metasearch/hotelSearchResultHotel';
import { MetasearchHotelRatePrice } from '@wegoTypes/hotels/metasearch/metasearchHotelRatePrice';
import { MetasearchHotelRateProvider } from '@wegoTypes/hotels/metasearch/metasearchHotelRateProvider';
import { PlacesHotelsPopularCity } from '@wegoTypes/hotels/placesHotelsPopularCity';
import { RateViewModel } from '@wegoTypes/hotels/rateViewModel';
import { Translations } from '@wegoTypes/translations';

import { HotelSortType } from '../types/hotels/hotelSortType';
import { notUndefinedAndNull } from './array';
import { convertHotelRatePriceToPrice } from './price';

export function isAirbnb(propertyTypeId: number): boolean {
  return propertyTypeId === 39;
}

export function sortHotelRates<
  T extends {
    price: MetasearchHotelRatePrice;
    provider: MetasearchHotelRateProvider;
  },
>(
  rates: Array<T>,
  sortOrder: SortOrder,
  priceOptions: PriceOptions,
  providerSearchParam: string | null,
  wegoHotelIdSearchParam: string | null,
): Array<T> {
  return [...rates].sort((aRate: T, bRate: T) => {
    const amountA = convertHotelRatePriceToPrice(
      aRate.price,
      priceOptions.showTotalPrice,
      priceOptions.isIncludingTaxOrFee,
    );
    const amountB = convertHotelRatePriceToPrice(
      bRate.price,
      priceOptions.showTotalPrice,
      priceOptions.isIncludingTaxOrFee,
    );
    const topProviderCode =
      !!wegoHotelIdSearchParam && !!providerSearchParam ? window.atob(providerSearchParam) : null;

    // Move provider to the top
    if (aRate.provider.code === topProviderCode && bRate.provider.code === topProviderCode) {
      // happens when only one provider
      return amountA.amount - amountB.amount;
    } else {
      // happens when multiple providers
      if (aRate.provider.code === topProviderCode) {
        return -1;
      }

      if (bRate.provider.code === topProviderCode) {
        return 1;
      }
    }

    if (amountA === amountB) {
      if (aRate.provider.directBooking) return -1;
      if (bRate.provider.directBooking) return 1;

      return bRate.price.ecpc - aRate.price.ecpc;
    }

    return sortOrder === 'asc' ? amountA.amount - amountB.amount : amountB.amount - amountA.amount;
  });
}

export function sortHotelViewModelBasedOnScore(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: SortOrder = 'desc',
): Array<HotelViewModel> {
  // Sorting based on score is always in descending because the higher the score the better the hotel.
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      if (sortOrder === 'asc') {
        return aHotelViewModel.score - bHotelViewModel.score;
      }
      return bHotelViewModel.score - aHotelViewModel.score;
    },
  );
}

export function sortHotelViewModelBasedOnPrice(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: SortOrder,
  isIncludingTaxOrFee: boolean,
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelRate: RateViewModel = aHotelViewModel.filteredRates[0];
      const bHotelRate: RateViewModel = bHotelViewModel.filteredRates[0];

      if (isIncludingTaxOrFee) {
        const aHotelRateWithTax = aHotelRate.price.taxInclusive
          ? aHotelRate.price.amount
          : aHotelRate.price.amount + aHotelRate.price.taxAmount;
        const bHotelRateWithTax = bHotelRate.price.taxInclusive
          ? bHotelRate.price.amount
          : bHotelRate.price.amount + bHotelRate.price.taxAmount;

        const totalHotelRateWithTax =
          sortOrder === 'asc'
            ? aHotelRateWithTax - bHotelRateWithTax
            : bHotelRateWithTax - aHotelRateWithTax;
        // Target BoW to first place if there is equal price
        if (totalHotelRateWithTax === 0 && aHotelRate.provider.directBooking) {
          return -1;
        }

        if (totalHotelRateWithTax === 0 && !bHotelRate.provider.directBooking) {
          return 0;
        }

        return totalHotelRateWithTax;
      } else {
        const aHotelRateWithoutTax = aHotelRate.price.taxInclusive
          ? aHotelRate.price.amount - aHotelRate.price.taxAmount
          : aHotelRate.price.amount;
        const bHotelRateWithoutTax = bHotelRate.price.taxInclusive
          ? bHotelRate.price.amount - bHotelRate.price.taxAmount
          : bHotelRate.price.amount;

        const totalHotelRateWithoutTax =
          sortOrder === 'asc'
            ? aHotelRateWithoutTax - bHotelRateWithoutTax
            : bHotelRateWithoutTax - aHotelRateWithoutTax;
        // Target BoW to first place if there is equal price
        if (totalHotelRateWithoutTax === 0 && aHotelRate.provider.directBooking) {
          return -1;
        }

        if (totalHotelRateWithoutTax === 0 && !bHotelRate.provider.directBooking) {
          return 0;
        }

        return totalHotelRateWithoutTax;
      }
    },
  );
}

export function sortHotelViewModelBasedOnDiscount(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: SortOrder = 'desc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelRate: RateViewModel = aHotelViewModel.filteredRates[0];
      const bHotelRate: RateViewModel = bHotelViewModel.filteredRates[0];

      const aHotelRateDiscountPercentage =
        (aHotelRate.usualPrice?.discountToUsualAmount || 0) * 100;
      const bHotelRateDiscountPercentage =
        (bHotelRate.usualPrice?.discountToUsualAmount || 0) * 100;

      if (sortOrder === 'asc') {
        return aHotelRateDiscountPercentage - bHotelRateDiscountPercentage;
      }
      return bHotelRateDiscountPercentage - aHotelRateDiscountPercentage;
    },
  );
}

export function sortHotelViewModelBasedOnSaving(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: SortOrder = 'desc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelRate: RateViewModel = aHotelViewModel.filteredRates[0];
      const bHotelRate: RateViewModel = bHotelViewModel.filteredRates[0];

      const aHotelRateSavings = aHotelRate.usualPrice
        ? aHotelRate.usualPrice.usualAmountUsd -
          (aHotelRate.price.taxInclusive
            ? aHotelRate.price.amountUsd
            : aHotelRate.price.amountUsd + aHotelRate.price.taxAmountUsd)
        : 0;
      const bHotelRateSavings = bHotelRate.usualPrice
        ? bHotelRate.usualPrice.usualAmountUsd -
          (bHotelRate.price.taxInclusive
            ? bHotelRate.price.amountUsd
            : bHotelRate.price.amountUsd + bHotelRate.price.taxAmountUsd)
        : 0;

      if (sortOrder === 'asc') {
        return aHotelRateSavings - bHotelRateSavings;
      }
      return bHotelRateSavings - aHotelRateSavings;
    },
  );
}

export function sortHotelViewModelBasedOnReviewScore(
  hotelViewModels: Array<HotelViewModel>,
  reviewerGroup: 'ALL' | 'BUSINESS' | 'COUPLE' | 'FAMILY' | 'SOLO',
  sortOrder: SortOrder = 'desc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelAllReviewerGroup: HotelMetaSearchHotelReview | undefined =
        aHotelViewModel.hotel.reviews.find((review) => review.reviewerGroup === reviewerGroup);
      const bHotelAllReviewerGroup: HotelMetaSearchHotelReview | undefined =
        bHotelViewModel.hotel.reviews.find((review) => review.reviewerGroup === reviewerGroup);

      if (!aHotelAllReviewerGroup && !!bHotelAllReviewerGroup) {
        return 1;
      } else if (!!aHotelAllReviewerGroup && !bHotelAllReviewerGroup) {
        return -1;
      } else if (!aHotelAllReviewerGroup && !bHotelAllReviewerGroup) {
        return 0;
      } else {
        if (sortOrder === 'asc') {
          return aHotelAllReviewerGroup!.score - bHotelAllReviewerGroup!.score;
        }
        return bHotelAllReviewerGroup!.score - aHotelAllReviewerGroup!.score;
      }
    },
  );
}

export function sortHotelBasedOnNearestToYou(
  hotelViewModels: Array<HotelViewModel>,
  userlatitude: number,
  userLongitude: number,
  sortOrder: SortOrder = 'asc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelDistanceToYou = getDistanceInKmBetweenTwoPoints(
        userlatitude,
        userLongitude,
        aHotelViewModel.hotel.latitude,
        aHotelViewModel.hotel.longitude,
      );
      const bHotelDistanceToYou = getDistanceInKmBetweenTwoPoints(
        userlatitude,
        userLongitude,
        bHotelViewModel.hotel.latitude,
        bHotelViewModel.hotel.longitude,
      );

      if (aHotelDistanceToYou === 0 && bHotelDistanceToYou > 0) {
        return 1;
      } else if (aHotelDistanceToYou > 0 && bHotelDistanceToYou === 0) {
        return -1;
      } else if (aHotelDistanceToYou === 0 && bHotelDistanceToYou === 0) {
        return 0;
      } else {
        if (sortOrder === 'asc') {
          return aHotelDistanceToYou - bHotelDistanceToYou;
        }
        return bHotelDistanceToYou - aHotelDistanceToYou;
      }
    },
  );
}

export function sortHotelViewModelBasedOnDistance(
  hotelViewModels: Array<HotelViewModel>,
  type: 'cityCentre' | 'searchLocation' | 'airport',
  sortOrder: SortOrder = 'asc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort((aHotelViewModel, bHotelViewModel) => {
    // Mapping type to the correct distance property
    const distanceTypeMap: Record<typeof type, keyof HotelSearchResultHotel> = {
      cityCentre: 'distanceToCityCentre',
      searchLocation: 'distanceToSearchLocation',
      airport: 'distanceToNearestAirport',
    };

    const distanceKey = distanceTypeMap[type];

    const aDistance = Number(aHotelViewModel.hotel[distanceKey]);
    const bDistance = Number(bHotelViewModel.hotel[distanceKey]);

    // Handle cases where distances are undefined
    const aIsNaN = isNaN(aDistance);
    const bIsNaN = isNaN(bDistance);

    if (aIsNaN !== bIsNaN) return aIsNaN ? 1 : -1;
    if (aIsNaN && bIsNaN) return 0;

    // Sorting based on sortOrder
    return sortOrder === 'asc' ? aDistance - bDistance : bDistance - aDistance;
  });
}

export function sortHotelViewModelBasedOnNearestToCityCentre(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: SortOrder = 'asc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelDistanceToCityCentre = aHotelViewModel.hotel.distanceToCityCentre;
      const bHotelDistanceToCityCentre = bHotelViewModel.hotel.distanceToCityCentre;

      if (aHotelDistanceToCityCentre === undefined && bHotelDistanceToCityCentre !== undefined) {
        return 1;
      } else if (
        aHotelDistanceToCityCentre !== undefined &&
        !bHotelDistanceToCityCentre === undefined
      ) {
        return -1;
      } else if (
        aHotelDistanceToCityCentre === undefined &&
        bHotelDistanceToCityCentre === undefined
      ) {
        return 0;
      } else {
        if (sortOrder === 'asc') {
          return aHotelDistanceToCityCentre! - bHotelDistanceToCityCentre!;
        }
        return bHotelDistanceToCityCentre! - aHotelDistanceToCityCentre!;
      }
    },
  );
}

export function sortHotelViewModelBasedOnNearestToAirport(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: SortOrder = 'asc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelDistanceToNearestAirport = aHotelViewModel.hotel.distanceToNearestAirport;
      const bHotelDistanceToNearestAirport = bHotelViewModel.hotel.distanceToNearestAirport;

      if (
        aHotelDistanceToNearestAirport === undefined &&
        bHotelDistanceToNearestAirport !== undefined
      ) {
        return 1;
      } else if (
        aHotelDistanceToNearestAirport !== undefined &&
        !bHotelDistanceToNearestAirport === undefined
      ) {
        return -1;
      } else if (
        aHotelDistanceToNearestAirport === undefined &&
        bHotelDistanceToNearestAirport === undefined
      ) {
        return 0;
      } else {
        if (sortOrder === 'asc') {
          return aHotelDistanceToNearestAirport! - bHotelDistanceToNearestAirport!;
        }
        return bHotelDistanceToNearestAirport! - aHotelDistanceToNearestAirport!;
      }
    },
  );
}

export function sortHotelViewModelBasedOnStar(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: SortOrder,
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelStar = aHotelViewModel.hotel.star;
      const bHotelStar = bHotelViewModel.hotel.star;

      if (!aHotelStar && !!bHotelStar) {
        return 1;
      } else if (!!aHotelStar && !bHotelStar) {
        return -1;
      } else if (!aHotelStar && !bHotelStar) {
        return 0;
      } else {
        if (sortOrder === 'asc') {
          return aHotelStar! - bHotelStar!;
        }
        return bHotelStar! - aHotelStar!;
      }
    },
  );
}

// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function getDistanceInKmBetweenTwoPoints(
  latitudeA: number,
  longitudeA: number,
  latitudeB: number,
  longitudeB: number,
): number {
  const p = 0.017453292519943295; // Math.PI / 180Í
  const c = Math.cos;
  const a =
    0.5 -
    c((latitudeB - latitudeA) * p) / 2 +
    (c(latitudeA * p) * c(latitudeB * p) * (1 - c((longitudeB - longitudeA) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export function isRateWithinRange(
  rateViewModel: RateViewModel,
  minPriceUsd: number,
  maxPriceUsd: number,
  showTotalPrice: boolean,
  isIncludingTaxOrFee: boolean,
): boolean {
  const { taxInclusive, amountUsd, totalAmountUsd, taxAmountUsd, totalTaxAmountUsd } =
    rateViewModel.price;

  let toBeCalculatedAmountUsd = taxInclusive ? amountUsd - taxAmountUsd : amountUsd;
  let toBeCalculatedTaxAmountUsd = taxAmountUsd;

  if (showTotalPrice) {
    toBeCalculatedAmountUsd = taxInclusive ? totalAmountUsd - totalTaxAmountUsd : totalAmountUsd;
    toBeCalculatedTaxAmountUsd = totalTaxAmountUsd;
  }

  let calculatedAmountUsd = toBeCalculatedAmountUsd;

  if (isIncludingTaxOrFee) {
    calculatedAmountUsd = toBeCalculatedAmountUsd + toBeCalculatedTaxAmountUsd;
  }

  return calculatedAmountUsd >= minPriceUsd && calculatedAmountUsd <= maxPriceUsd;
}

export function filterHotelViewModelBasedOnFilters(
  hotelViewModels: Array<HotelViewModel>,
  hotelNameSearchQuery: string,
  priceOptions: PriceOptions,
  isTodayDeals: boolean,
  selectedUsdPrices: Array<number>,
  selectedReviewScores: Array<number>,
  selectedAmenityIds: Array<number>,
  selectedBookingOptions: Array<string>,
  selectedBrandIds: Array<number>,
  selectedChainIds: Array<number>,
  selectedDistrictIds: Array<number>,
  selectedPropertyTypeIds: Array<number>,
  selectedRateAmenityIds: Array<number>,
  selectedReviewerGroups: Array<string>,
  selectedStarIds: Array<number>,
  selectedRateProviderCodes: Array<string>,
): Array<HotelViewModel> {
  return hotelViewModels.filter((hotelViewModel) => {
    const filteredRates = hotelViewModel.rates.filter((rate) => {
      const isTodayDealsFilter = isTodayDeals ? !!rate.usualPrice : true;

      const bookingOptionsFilter =
        selectedBookingOptions.length > 0
          ? selectedBookingOptions.some(
              (selectedBookingOption) =>
                (selectedBookingOption === 'wego' && rate.provider!.directBooking) ||
                (selectedBookingOption === 'hotels' && rate.provider!.isHotelWebsite) ||
                (selectedBookingOption === 'ota' && rate.provider.type === 'OTA'),
            )
          : true;

      const usdPricesFilter =
        selectedUsdPrices.length === 2
          ? isRateWithinRange(
              rate,
              selectedUsdPrices[0],
              selectedUsdPrices[1],
              priceOptions.showTotalPrice,
              priceOptions.isIncludingTaxOrFee,
            )
          : true;

      const rateAmenityIdsFilter =
        selectedRateAmenityIds.length > 0
          ? selectedRateAmenityIds.every((selectedRateAmenityId) =>
              rate.rateAmenityIds.includes(selectedRateAmenityId),
            )
          : true;

      const rateProviderCodesFilter =
        selectedRateProviderCodes.length > 0
          ? selectedRateProviderCodes.some(
              (selectedRateProviderCode) =>
                rate.provider.code.toLowerCase() === selectedRateProviderCode.toLowerCase(),
            )
          : true;

      return (
        isTodayDealsFilter &&
        bookingOptionsFilter &&
        usdPricesFilter &&
        rateAmenityIdsFilter &&
        rateProviderCodesFilter
      );
    });

    hotelViewModel.filteredRates = sortHotelRates(filteredRates, 'asc', priceOptions, null, null);

    let reviewScoreFilter = true;
    if (
      selectedReviewScores.length === 2 &&
      !(selectedReviewScores[0] === -1 && selectedReviewScores[1] === 101)
    ) {
      const allReviewerGroup = hotelViewModel.hotel.reviews[0];

      if (selectedReviewScores[0] === 0) {
        reviewScoreFilter = !!allReviewerGroup
          ? allReviewerGroup.score >= selectedReviewScores[0] &&
            allReviewerGroup.score < selectedReviewScores[1]
          : isAirbnb(hotelViewModel.hotel.propertyTypeId)
          ? true
          : false;
      } else {
        reviewScoreFilter =
          !!allReviewerGroup &&
          allReviewerGroup.score > selectedReviewScores[0] &&
          allReviewerGroup.score < selectedReviewScores[1];
      }
    }

    const reviewerGroupsFilter =
      selectedReviewerGroups.length > 0
        ? selectedReviewerGroups.some((selectedReviewerGroup) => {
            const reviewerGroup = hotelViewModel.hotel.reviews.find(
              (review) => review.reviewerGroup === selectedReviewerGroup,
            );

            return !!reviewerGroup && reviewerGroup.score >= 80 && reviewerGroup.count >= 100;
          })
        : true;

    const hotelNameSearchQueryFilter =
      hotelNameSearchQuery.length > 0
        ? hotelViewModel.hotel.name.toLowerCase().includes(hotelNameSearchQuery.toLowerCase())
        : true;
    const starIdsFilter =
      selectedStarIds.length > 0
        ? selectedStarIds.some((selectedStarId) => hotelViewModel.hotel.star === selectedStarId)
        : true;
    const propertyTypeIdsFilter =
      selectedPropertyTypeIds.length > 0
        ? selectedPropertyTypeIds.some(
            (selectedPropertyTypeId) =>
              hotelViewModel.hotel.propertyTypeId === selectedPropertyTypeId,
          )
        : true;
    const districtIdsFilter =
      selectedDistrictIds.length > 0
        ? selectedDistrictIds.some(
            (selectedDistrictId) => hotelViewModel.hotel.districtId === selectedDistrictId,
          )
        : true;
    const amenityIdsFilter =
      selectedAmenityIds.length > 0
        ? selectedAmenityIds.every((selectedAmenityId) =>
            hotelViewModel.hotel.amenityIds.includes(selectedAmenityId),
          )
        : true;
    const chainIdsFilter =
      selectedChainIds.length > 0
        ? selectedChainIds.some(
            (selectedChainId) => hotelViewModel.hotel.chainId === selectedChainId,
          )
        : true;
    const brandIdsFilter =
      selectedBrandIds.length > 0
        ? selectedBrandIds.some(
            (selectedBrandId) => hotelViewModel.hotel.brandId === selectedBrandId,
          )
        : true;

    return (
      hotelNameSearchQueryFilter &&
      filteredRates.length > 0 &&
      starIdsFilter &&
      reviewScoreFilter &&
      reviewerGroupsFilter &&
      propertyTypeIdsFilter &&
      districtIdsFilter &&
      amenityIdsFilter &&
      chainIdsFilter &&
      brandIdsFilter
    );
  });
}

/**
 *
 * @param guestRooms
 * @returns 2:0-1:12:12-1
 */
export function convertGuestRoomsToSearchParam(guestRooms: Array<GuestRoom>): string {
  return guestRooms.reduce((accumulator: string, currentValue: GuestRoom) => {
    return `${accumulator.length > 0 ? `${accumulator}-` : ''}${currentValue.adultsCount}${
      currentValue.childrenAges.length > 0 ? `:${currentValue.childrenAges.join(':')}` : ''
    }`;
  }, '');
}

/**
 *
 * @param guestSearchParam 2:0-1:12:12-1
 * @returns
 */
export function convertGuestSearchParamToGuestRooms(guestSearchParam: string): Array<GuestRoom> {
  const guestRoomsString = guestSearchParam.split('-');

  return guestRoomsString.map<GuestRoom>((guestRoomString: string) => {
    const guestRoomValues = guestRoomString.split(':');

    const adultsCount = parseInt(guestRoomValues.shift() || '0');
    const childrenCount = guestRoomValues.length;
    const childrenAges = guestRoomValues.map((guestRoomValue) => parseInt(guestRoomValue));

    return { adultsCount, childrenCount, childrenAges };
  });
}

export function getGuestsCount(guestRooms: Array<GuestRoom>): number {
  return guestRooms.reduce(
    (accumulator: number, currentValue: GuestRoom) =>
      accumulator + currentValue.adultsCount + currentValue.childrenCount,
    0,
  );
}

export function getHotelSortTypeSearchParamValue(hotelSortType: HotelSortType): string {
  switch (hotelSortType) {
    case HotelSortType.Recommended:
      return 'popularity';
    case HotelSortType.Price:
      return 'price';
    case HotelSortType.Discount:
      return 'discount';
    case HotelSortType.Saving:
      return 'saving';
    case HotelSortType.AllReviewScore:
      return 'all_review_score';
    case HotelSortType.SoloReviewScore:
      return 'solo_review_score';
    case HotelSortType.BusinessReviewScore:
      return 'business_review_score';
    case HotelSortType.FamilyReviewScore:
      return 'family_review_score';
    case HotelSortType.CoupleReviewScore:
      return 'couple_review_score';
    case HotelSortType.NearestToYou:
      return 'distance_to_you';
    case HotelSortType.NearestToCityCentre:
      return 'distance_to_city_center';
    case HotelSortType.NearestToAirport:
      return 'distance_to_nearest_airport';
    case HotelSortType.NearestToTargetLocation:
      return 'distance_to_location';
    case HotelSortType.Star:
      return 'stars';
    default:
      return 'popularity';
  }
}

export function getHotelSortType(sortTypeSearchParam: string): HotelSortType {
  switch (sortTypeSearchParam.toLowerCase()) {
    case 'popularity':
      return HotelSortType.Recommended;
    case 'price':
      return HotelSortType.Price;
    case 'discount':
      return HotelSortType.Discount;
    case 'saving':
      return HotelSortType.Saving;
    case 'all_review_score':
      return HotelSortType.AllReviewScore;
    case 'solo_review_score':
      return HotelSortType.SoloReviewScore;
    case 'business_review_score':
      return HotelSortType.BusinessReviewScore;
    case 'family_review_score':
      return HotelSortType.FamilyReviewScore;
    case 'couple_review_score':
      return HotelSortType.CoupleReviewScore;
    case 'distance_to_you':
      return HotelSortType.NearestToYou;
    case 'distance_to_city_center':
      return HotelSortType.NearestToCityCentre;
    case 'distance_to_nearest_airport':
      return HotelSortType.NearestToAirport;
    case 'distance_to_location':
      return HotelSortType.NearestToTargetLocation;
    case 'stars':
      return HotelSortType.Star;
    default:
      return HotelSortType.Recommended;
  }
}

export function getHotelSortTypeEventValue(
  hotelSortType: HotelSortType,
  order: 'asc' | 'desc',
): string {
  switch (hotelSortType) {
    case HotelSortType.Recommended:
      return 'recommended';
    case HotelSortType.Price:
      if (order === 'asc') {
        return 'price_lowest';
      }
      return 'price_highest';
    case HotelSortType.Discount:
      return 'price_best_discount';
    case HotelSortType.Saving:
      return 'price_biggest_savings';
    case HotelSortType.AllReviewScore:
      return 'best_reviews';
    case HotelSortType.NearestToYou:
      return 'nearest_to_user';
    case HotelSortType.NearestToCityCentre:
      return 'distance_city';
    case HotelSortType.NearestToAirport:
      return 'distance_airport';
    case HotelSortType.NearestToTargetLocation:
      return 'distance_location';
    case HotelSortType.Star:
      if (order === 'asc') {
        return 'stars_1to5';
      }
      return 'stars_5to1';
    default:
      return 'recommended';
  }
}

export function isHotelProduct(productParam: string): boolean {
  return /^(?:hotels|hoteles|hotel|hotele|hoteis|hotell|khach\-san)$/i.test(productParam);
}

const now = new Date();
export function getDailyVersion(): string {
  const mm = (now.getMonth() + 1).toString().padStart(2, '0');
  const dd = now.getDate().toString().padStart(2, '0');
  return `v${now.getFullYear()}${mm}${dd}`;
}

export function mod(dividend: number, divisor: number) {
  return ((dividend % divisor) + divisor) % divisor;
}

export function addOneDay(dateString: string): string {
  if (!dateString) return '';
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
export function getPlaceLabel(place: Place | PlacesHotelsPopularCity) {
  const placeLabel: Array<string | undefined> = [];

  if (place.name && place.name.trim().length > 0) {
    placeLabel.push(place.name);
  }

  if (place.type === 'city') {
    placeLabel.push(place.stateName);
    if (place.name !== place.countryName) {
      placeLabel.push(place.countryName);
    }
  } else if (place.type === 'hotel') {
    placeLabel.push(...[place.cityName, place.stateCode, place.countryName]);
  } else {
    placeLabel.push(...[place.cityName, place.stateName, place.countryName]);
  }
  return placeLabel.filter(notUndefinedAndNull).join(', ');
}

export function getMetaSearchPlace(
  hotelMetaSearch: HotelMetaSearch,
  hotelRecentSearch?: HotelSearch | null,
) {
  const { city, country, searchType, district, hotel, region } = hotelMetaSearch.search!;

  const isSameCity = city && hotelRecentSearch && hotelRecentSearch.place?.cityCode === city.code;

  const place: Place = {
    activityCount: undefined,
    airportCode: undefined,
    airportCount: undefined,
    airportId: undefined,
    airportName: undefined,
    airportPermalink: undefined,
    cityCode: city ? city.code : undefined,
    cityEnName: undefined,
    cityEnPermalink: undefined,
    cityId: undefined,
    cityName: city ? city.name : undefined,
    cityPermalink: undefined,
    code: undefined,
    countryCode: country?.code,
    countryEnName: '',
    countryEnPermalink: undefined,
    countryId: undefined,
    countryName: country?.name,
    countryPermalink: '',
    distance: 0,
    districtEnName: undefined,
    districtId: district ? district.id : undefined,
    districtName: district ? district.name : undefined,
    districtPermalink: undefined,
    featured: 0,
    hotelCount: undefined,
    id: searchType === 'HOTEL' ? hotel!.id : 0,
    lat: undefined,
    locationId: undefined,
    long: undefined,
    name:
      searchType === 'CITY'
        ? city!.name
        : searchType === 'DISTRICT'
        ? district!.name
        : searchType === 'REGION'
        ? region!.name
        : hotel?.name || '',
    permalink: undefined,
    regionEnName: undefined,
    regionId: region ? region.id : undefined,
    regionName: region ? region.name : undefined,
    regionPermalink: undefined,
    reviewCount: 0,
    reviewScore: 0,
    slug: undefined,
    stateCode: isSameCity ? hotelRecentSearch.place.stateCode : undefined,
    stateEnName: isSameCity ? hotelRecentSearch.place.stateEnName : undefined,
    stateId: isSameCity ? hotelRecentSearch.place.stateId : undefined,
    stateName: isSameCity ? hotelRecentSearch.place.stateName : undefined,
    statePermalink: isSameCity ? hotelRecentSearch.place.statePermalink : undefined,
    type: searchType.toLowerCase() as 'city' | 'region' | 'district' | 'hotel',
    uuid: undefined,
    worldRegionCode: undefined,
    worldRegionEnName: undefined,
    worldRegionId: undefined,
    worldRegionName: undefined,
    worldRegionPermalink: undefined,
  };

  return place;
}

const isBigValueCurrency = (currencyCode: string) => {
  return /COP|IDR|IRR|KRW|LBP|VND/.test(currencyCode.toUpperCase());
};

export function getHotelPriceByRating(
  hotelRateViewModel: RateViewModel,
  priceOptions: PriceOptions,
  currency: Currency,
  exchangeRate: ExchangeRate,
  locale: string,
) {
  const bestDeal = hotelRateViewModel;
  let showAdditionalZeroPlaceholder = false;

  const hotelPrice = convertHotelRatePriceToPrice(
    bestDeal.price,
    priceOptions.showTotalPrice,
    priceOptions.isIncludingTaxOrFee,
  );
  const isPriceCurrencySameAsSelectedCurrency = hotelPrice.currencyCode === currency.code;

  let amount = isPriceCurrencySameAsSelectedCurrency
    ? hotelPrice.amount
    : hotelPrice.amountUsd * exchangeRate.rate;

  if (amount < 0) {
    amount = amount * -1;
  }

  if (isBigValueCurrency(currency.code) && amount > 99999) {
    amount = amount / 1000;
    showAdditionalZeroPlaceholder = true;
  } else {
    showAdditionalZeroPlaceholder = false;
  }

  const stringAmount = amount.toFixed(0);
  const stringAmountLength = stringAmount.length;

  let modValue: number = 0;

  modValue = stringAmountLength > 3 ? stringAmountLength % 3 : 0;

  const amountDisplayed = translateNumber(
    `${
      !!modValue ? `${stringAmount.substring(0, modValue)}${currency.separator}` : ''
    }${stringAmount.substring(modValue).replace(/(\d{3})(?=\d)/g, `$1${currency.separator}`)}`,
    locale === 'fa',
  );

  return amountDisplayed && showAdditionalZeroPlaceholder
    ? `${amountDisplayed}${translateNumber(`${currency.separator}000`, locale === 'fa')}`
    : amountDisplayed;
}

export const getHotelComparisonProviderLabelText = (
  hotelComparisonProviders: Array<HotelComparisionProvider>,
  translations: Translations,
) => {
  const firstProvider = hotelComparisonProviders[0];

  if (firstProvider) {
    switch (firstProvider.provider.category) {
      case 'hotels':
        return translations.compare_websites;
      case 'flights':
        return translations.cau_flight_offers;
      case 'others':
        return translations.cau_other_offers_2;
      default:
        return translations.compare_websites;
    }
  }

  return '';
};

export const countryCodeToFlagEmoji = (countryCode: string): string => {
  if (!countryCode) return '';
  return countryCode
    .toUpperCase()
    .split('')
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5))
    .join('');
};

export const postedDateDisplay = (
  translations: Translations,
  postedAt: Date,
  locale: string,
): string => {
  const nights = numberOfNights(postedAt.getTime(), new Date().getTime());
  const months = Math.floor(nights / 30);

  if (months > 3) {
    // Format date as DD-MM-YYYY
    const dateFormated = `${postedAt.getFullYear()}-${String(postedAt.getMonth() + 1).padStart(
      2,
      '0',
    )}-${String(postedAt.getDate()).padStart(2, '0')}`;
    return dateDisplayFormat(
      getLocalDate(dateFormated),
      translations.short_months as Array<string>,
      translations.short_weekdays as Array<string>,
      locale === 'fa',
      1,
    );
  } else if (months >= 1) {
    return translateText(translations.months_ago, locale, months);
  }
  return translateText(translations.days_ago, locale, nights);
};

export const getHotelDealPromotionCheckoutDate = (leg: Leg[]): string => {
  const departureDate = leg[0].outboundDate;
  if (leg.length === 1) {
    // one-way flight
    const date = new Date(departureDate);
    date.setDate(date.getDate() + 2);
    return date.toISOString().split('T')[0];
  }

  const returnDate = leg[1].outboundDate;
  if (returnDate === departureDate) {
    const date = new Date(returnDate);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  }

  return returnDate;
};

export const getPartnerName = (tsCode: string | null) => {
  if (tsCode) {
    if (
      isDevelopmentEnvironment(window.location.hostname) ||
      isStagingEnvironment(window.location.hostname)
    ) {
      return STAGING_TS_CODES[tsCode];
    }
    return PROD_TS_CODES[tsCode];
  }
  return 'Google';
};

export const onBookAgainLink = (
  currentSite: CurrentSite,
  localeParam: string | undefined,
  hotelCityCode: string,
  wegoHotelId: string,
) => {
  const today = new Date();
  const tomorrow = new Date(today);
  const dayAfterTomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  dayAfterTomorrow.setDate(tomorrow.getDate() + 1);

  return `${hotelsRoute(currentSite, localeParam)}/searches/${hotelCityCode}/${dateApiFormat(
    tomorrow,
  )}/${dateApiFormat(dayAfterTomorrow)}/${wegoHotelId}?open_calendar=true`;
};

import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';

type SiftType = [
  ['_setAccount', string],
  ['_setUserId', string],
  ['_setSessionId', string],
  ['_trackPageview'],
];

declare global {
  interface Window {
    _sift: SiftType;
  }
}

enum BEACON_KEY {
  production = '36260b3af0',
  development = '8068a11dbf',
}

export const injectSiftSnippet = (userId: string, sessionId: string): Promise<SiftType> => {
  return new Promise((resolve) => {
    const elementId: string = 'siftSnippet';
    const document$: Document = document;
    const existingScriptElement = document$.getElementById(elementId);
    const beaconKey =
      isDevelopmentEnvironment(window.location.hostname) ||
      isStagingEnvironment(window.location.hostname)
        ? BEACON_KEY.development
        : BEACON_KEY.production;

    const sift = window._sift || [];
    sift.push(['_setAccount', beaconKey]);
    sift.push(['_setUserId', userId]);
    sift.push(['_setSessionId', sessionId]);
    sift.push(['_trackPageview']);

    if (!existingScriptElement) {
      const scriptElement = document$.createElement('script');
      scriptElement.id = elementId;
      scriptElement.defer = true;
      scriptElement.src = 'https://cdn.sift.com/s.js';
      scriptElement.onload = () => {
        resolve(window._sift);
      };
      document$.head.appendChild(scriptElement);
    } else {
      resolve(window._sift);
    }
  });
};

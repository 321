import { dateApiFormat } from '@alexis/helpers/date';

export const getSubsequentMonthDateFromCurrentDate = (
  currentDate: Date,
  offset: number,
  isStartDate: boolean,
) => {
  const newDate = new Date(currentDate);
  newDate.setMonth(newDate.getMonth() + offset);
  const year = newDate.getFullYear();
  const month = newDate.getMonth() + 1;

  return dateApiFormat(new Date(year, month - 1, isStartDate ? 1 : 0));
};

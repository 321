import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import type { User } from '@wegoTypes/authentication';
import { Currency } from '@wegoTypes/currency';
import { HotelMetaSearch } from '@wegoTypes/hotels/hotelMetaSearch';
import type { Translations } from '@wegoTypes/translations';

// import type { FlightSearch } from 'flightSearch';
// import type { HotelSearch } from 'hotelSearch';
// import type { HotelMetaSearch } from 'hotelMetaSearch';

export const getCurrentSiteState = (store: { currentSite: CurrentSite }) => store.currentSite;

export const getLocaleState = (store: { locale: string }) => store.locale;

export const getTranslationsState = (store: { translations: Translations }) => store.translations;

export const getIsRtlState = (store: { isRtl: boolean }) => store.isRtl;

export const getCurrencyState = (store: { currency: Currency }) => store.currency;

export const getExchangeRateState = (store: { exchangeRate: ExchangeRate }) => store.exchangeRate;

export const getApiBaseUrlState = (store: { apiBaseUrl: string }) => store.apiBaseUrl;

export const getDomainBasedApiBaseUrlState = (store: { domainBasedApiBaseUrl: string }) =>
  store.domainBasedApiBaseUrl;

export const getTriggerSearchCounterState = (store: { triggerSearchCounter: number }) =>
  store.triggerSearchCounter;

export const getUserState = (store: { user: User | null }) => store.user;

// export const getInterstitialProviderMapState = (store: { interstitialProviderMap: InterstitialProviderMap }) => store.interstitialProviderMap;

export const getPriceOptionsState = (store: { priceOptions: PriceOptions }) => store.priceOptions;

export const getPageViewIdState = (store: { pageViewId: PageViewIdState }) => store.pageViewId;

// export const getFlightSearchState = (store: { flightSearch: FlightSearch }) => store.flightSearch;

// export const getFlightRecentSearchesState = (store: { flightRecentSearches: Array<FlightSearch> }) => store.flightRecentSearches;

// export const getHotelSearchState = (store: { hotelSearch: HotelSearch }) => store.hotelSearch;

// export const getHotelRecentSearchesState = (store: { hotelRecentSearches: Array<HotelSearch> }) => store.hotelRecentSearches;

export const getHotelMetaSearchState = (store: { hotelMetaSearch: HotelMetaSearch }) =>
  store.hotelMetaSearch;

export const getShopCashUserState = (store: { shopCashUser: ShopCashUser }) => store.shopCashUser;

import React from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import Image from '@components/Image';

import ArrowRoundedIcon from '@icons/arrow_rounded.svg';
import CloseIcon from '@icons/close.svg';

import type { Translations } from '@wegoTypes/translations';

import styles from './ShopCashInfo.module.scss';

interface ShopCashInfoProps {
  onClose: () => void;
  translations: Translations;
  className?: string;
}

const ShopCashInfo: React.FC<ShopCashInfoProps> = ({ onClose, translations, className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <CloseIcon className={styles.closeIcon} onClick={onClose} />

      <div className={styles.title}>{translations.whats_the_difference}</div>

      <div className={styles.subTitle}>{translations.available_balance}</div>
      <p>{translations.available_balance_explanation}</p>

      <div className={styles.subTitle}>{translations.pending_balance}</div>
      <p>{translations.pending_balance_explanation}</p>

      <div className={styles.subTitle}>{translations.how_does_it_work}</div>
      <div className={styles.cashbackSteps}>
        <div className={styles.cashbackStep}>
          <Image
            className={styles.illustration}
            src='https://assets.wego.com/image/upload/v1646380783/web/shopcash/book_and_earn.png'
          />
          <div className={styles.cashbackStepText}>
            <div className={styles.subTitle}>{translations.book_and_earn}</div>
            <p>{translations.book_and_earn_explanation}</p>
          </div>
        </div>
        <ArrowRoundedIcon className={styles.arrowIcon} />
        <div className={styles.cashbackStep}>
          <Image
            className={styles.illustration}
            src='https://assets.wego.com/image/upload/v1646380783/web/shopcash/cashback_approval.png'
          />
          <div className={styles.cashbackStepText}>
            <div className={styles.subTitle}>{translations.cashback_approval}</div>
            <p>{translations.cashback_approval_explanation}</p>
          </div>
        </div>
        <ArrowRoundedIcon className={styles.arrowIcon} />
        <div className={styles.cashbackStep}>
          <Image
            className={styles.illustration}
            src='https://assets.wego.com/image/upload/v1646380783/web/shopcash/cashback_in_your_bank.png'
          />
          <div className={styles.cashbackStepText}>
            <div className={styles.subTitle}>{translations.cashback_in_your_bank}</div>
            <p>{translations.cashback_in_your_bank_explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCashInfo;

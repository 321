import type { SearchDetails, SearchFormCollapseViewPlace } from './type';

export const mapLegSearchesToSearchDetails = (legSearches: LegSearch[]): SearchDetails[] => {
  return legSearches.map((legSearch) => {
    return {
      inboundDateMilliseconds: legSearch.inboundDateMilliseconds,
      inboundPlace: {
        cityName: (legSearch.inboundPlace as Place | PlacesFlightsPopularCity)?.cityName,
        code: (legSearch.inboundPlace as Place | PlacesFlightsPopularCity)?.code,
      } as SearchFormCollapseViewPlace,
      outboundDateMilliseconds: legSearch.outboundDateMilliseconds,
      outboundPlace: {
        cityName: legSearch.outboundPlace?.cityName,
        code: legSearch.outboundPlace?.code,
      } as SearchFormCollapseViewPlace,
    };
  });
};

export const CF_TURNSTILE_SITEKEY_STAGING = '0x4AAAAAAAPd8TmUM7-dvTKb';

export const CF_TURNSTILE_SITEKEY_PRODUCTION = '0x4AAAAAAAPefjvGG0oT5XlD';

export const GTM_ID = 'GTM-K5FWXG4';

export const GTM_CLIENT_TYPE = 'WEB_APP';

export const GTM_AUTH_STAGING = 'obU596jZkQ8pXq1df8v0OA';

export const GTM_AUTH_PRODUCTION = 'JhTkGQKxflcbelVyldTavg';

export const GTM_PREVIEW_STAGING = 'env-5';

export const GTM_PREVIEW_PRODUCTION = 'env-2';

export const HOMEPAGE_SEARCH_FORM_DESIGN_UPDATE_KEY = 'HOMEPAGE_SEARCH_FORM_DESIGN_UPDATE';

import useGetPaymentFees from '@pages/FlightBookingPage/hooks/useGetPaymentFees';
import useGetPaymentOrderData from '@pages/FlightBookingPage/hooks/useGetPaymentOrderData';
import { getPaymentOptions, PaymentOptionsResponse } from '@wego/payments-react-component';
import { PaymentOptionsRequest } from '@wego/payments-react-component/dist/types/components/PaymentForm/models';
import { FlightBookingDetailsSessionStorage } from 'flightBooking';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { sessionStorageGet } from '@wego/alexis/helpers/sessionStorage';

import {
  getApiBaseUrlState,
  getCurrencyState,
  getCurrentSiteState,
  getUserState,
} from '@redux/selectors';

const useGetPaymentOptions = (paymentOrderId: string) => {
  const apiBaseUrl = useSelector(getApiBaseUrlState);
  const currency = useSelector(getCurrencyState);
  const currentSite = useSelector(getCurrentSiteState);

  const [isLoading, setIsLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState<PaymentOptionsResponse[]>([]);
  const user = useSelector(getUserState);
  const mainPax =
    sessionStorageGet<FlightBookingDetailsSessionStorage>(paymentOrderId)?.contactDetails ||
    undefined;
  const { data: paymentOrderData } = useGetPaymentOrderData(paymentOrderId);
  const { data: paymentFees, isLoading: isGettingPaymentMethodFees } = useGetPaymentFees(
    paymentOrderId || '',
  );

  const loggedInUser = user
    ? {
        email: user.email,
        fullName: user.displayName,
        phoneNumber: user.phoneNumber?.toString() ?? '',
        userHash: user.userHash,
      }
    : undefined;

  const primaryRecipient = {
    email: mainPax?.email ?? '',
    fullName: `${mainPax?.fullName}`,
    phoneNumber: mainPax?.phoneNumber?.toString() ?? '',
  };
  useEffect(() => {
    if (paymentOrderId) {
      setIsLoading(true);

      const initPaymentOption = async () => {
        const req: PaymentOptionsRequest = {
          paymentBaseUrl: `${apiBaseUrl}/payments`,
          siteCode: currentSite.countryCode,
          clientCode: 'bow_fli',
          userCurrencyCode: currency.code,
          paymentCurrencyCode: currency.code,
          appType: `WEB_APP`,
        };
        try {
          const paymentOptions = await getPaymentOptions(req);
          setPaymentOptions(paymentOptions);
        } catch (error) {
          console.error('Error getting payment options', error);
        } finally {
          setIsLoading(false);
        }
      };

      initPaymentOption();
    }
  }, [apiBaseUrl, paymentOrderId]);

  return {
    paymentOptions,
    isLoading,
    paymentFees,
    loggedInUser,
    primaryRecipient,
    isGettingPaymentMethodFees,
    orderData: {
      ...paymentOrderData,
      metadata: { ...paymentOrderData?.metadata, evaluationRef: paymentOrderId },
    },
  };
};

export default useGetPaymentOptions;

import { MetasearchHotelRate } from '@wegoTypes/hotels/metasearch/metasearchHotelRate';
import { MetasearchHotelRatePrice } from '@wegoTypes/hotels/metasearch/metasearchHotelRatePrice';
import { RateViewModel } from '@wegoTypes/hotels/rateViewModel';

export function convertFlightSearchResultFarePriceToPrice(
  flightPrice: FlightSearchResultFarePrice,
  passengerCount: number,
  showTotalPrice: boolean,
  isIncludingFee: boolean,
): Price {
  const {
    currencyCode,
    amountWithFraction,
    amountUsd,
    originalAmount,
    originalAmountUsd,
    totalAmountWithFraction,
    totalAmountUsd,
  } = flightPrice;

  let calculatedAmount: number = 0;
  let calculatedAmountUsd: number = 0;

  if (showTotalPrice) {
    if (isIncludingFee) {
      calculatedAmount = totalAmountWithFraction;
      calculatedAmountUsd = totalAmountUsd;
    } else {
      calculatedAmount = originalAmount * passengerCount;
      calculatedAmountUsd = originalAmountUsd * passengerCount;
    }
  } else {
    if (isIncludingFee) {
      calculatedAmount = amountWithFraction;
      calculatedAmountUsd = amountUsd;
    } else {
      calculatedAmount = originalAmount;
      calculatedAmountUsd = originalAmountUsd;
    }
  }

  return { amount: calculatedAmount, amountUsd: calculatedAmountUsd, currencyCode };
}

export function convertHotelRatePriceToPrice(
  hotelRatePrice: MetasearchHotelRatePrice,
  showTotalPrice: boolean,
  isIncludingTax: boolean,
): Price {
  const {
    currencyCode,
    taxInclusive,
    amount,
    amountUsd,
    totalAmount,
    totalAmountUsd,
    taxAmount,
    taxAmountUsd,
    totalTaxAmount,
    totalTaxAmountUsd,
    localTaxAmount,
    localTaxAmountUsd,
    totalLocalTaxAmount,
    totalLocalTaxAmountUsd,
  } = hotelRatePrice;

  let calculatedAmount: number = 0;
  let calculatedAmountUsd: number = 0;

  let toBeCalculatedAmount = taxInclusive ? amount - taxAmount : amount;
  let toBeCalculatedAmountUsd = taxInclusive ? amountUsd - taxAmountUsd : amountUsd;
  let toBeCalculatedTaxAmount = taxAmount;
  let toBeCalculatedTaxAmountUsd = taxAmountUsd;
  let toBeCalculatedLocalTaxAmount = localTaxAmount;
  let toBeCalculatedLocalTaxAmountUsd = localTaxAmountUsd;

  if (showTotalPrice) {
    toBeCalculatedAmount = taxInclusive ? totalAmount - totalTaxAmount : totalAmount;
    toBeCalculatedAmountUsd = taxInclusive ? totalAmountUsd - totalTaxAmountUsd : totalAmountUsd;
    toBeCalculatedTaxAmount = totalTaxAmount;
    toBeCalculatedTaxAmountUsd = totalTaxAmountUsd;
    toBeCalculatedLocalTaxAmount = totalLocalTaxAmount;
    toBeCalculatedLocalTaxAmountUsd = totalLocalTaxAmountUsd;
  }

  if (isIncludingTax) {
    calculatedAmount =
      toBeCalculatedAmount +
      toBeCalculatedTaxAmount +
      (toBeCalculatedLocalTaxAmount > 0 ? toBeCalculatedLocalTaxAmount : 0);
    calculatedAmountUsd =
      toBeCalculatedAmountUsd +
      toBeCalculatedTaxAmountUsd +
      (toBeCalculatedLocalTaxAmountUsd > 0 ? toBeCalculatedLocalTaxAmountUsd : 0);
  } else {
    calculatedAmount = toBeCalculatedAmount;
    calculatedAmountUsd = toBeCalculatedAmountUsd;
  }

  return { amount: calculatedAmount, amountUsd: calculatedAmountUsd, currencyCode };
}

export function getBestDealUsualPrice(
  bestDeal: MetasearchHotelRate | undefined | RateViewModel,
  priceOptions: PriceOptions,
): Price | undefined {
  if (!!bestDeal && !!bestDeal.usualPrice) {
    const {
      taxInclusive,
      amount,
      taxAmount,
      amountUsd,
      taxAmountUsd,
      totalTaxAmount,
      totalAmount,
      totalTaxAmountUsd,
      totalAmountUsd,
    } = bestDeal.price;

    const { usualAmount, usualAmountUsd, usualTotalAmount, usualTotalAmountUsd } =
      bestDeal.usualPrice;

    const usualPriceTaxAmount = usualAmount * (taxAmount / amount);
    const usualPriceTaxAmountUsd = usualAmountUsd * (taxAmountUsd / amountUsd);
    const usualPriceTotalTaxAmount = usualTotalAmount * (totalTaxAmount / totalAmount);
    const usualPriceTotalTaxAmountUsd = usualTotalAmountUsd * (totalTaxAmountUsd / totalAmountUsd);

    let calculatedAmount: number = 0;
    let calculatedAmountUsd: number = 0;

    let toBeCalculatedAmount = taxInclusive ? usualAmount - usualPriceTaxAmount : usualAmount;
    let toBeCalculatedAmountUsd = taxInclusive
      ? usualAmountUsd - usualPriceTaxAmountUsd
      : usualAmountUsd;
    let toBeCalculatedTaxAmount = usualPriceTaxAmount;
    let toBeCalculatedTaxAmountUsd = usualPriceTaxAmountUsd;

    if (priceOptions.showTotalPrice) {
      toBeCalculatedAmount = taxInclusive
        ? usualTotalAmount - usualPriceTotalTaxAmount
        : usualTotalAmount;
      toBeCalculatedAmountUsd = taxInclusive
        ? usualTotalAmountUsd - usualPriceTotalTaxAmountUsd
        : usualTotalAmountUsd;
      toBeCalculatedTaxAmount = usualPriceTotalTaxAmount;
      toBeCalculatedTaxAmountUsd = usualPriceTotalTaxAmountUsd;
    }

    if (priceOptions.isIncludingTaxOrFee) {
      calculatedAmount = toBeCalculatedAmount + toBeCalculatedTaxAmount;
      calculatedAmountUsd = toBeCalculatedAmountUsd + toBeCalculatedTaxAmountUsd;
    } else {
      calculatedAmount = toBeCalculatedAmount;
      calculatedAmountUsd = toBeCalculatedAmountUsd;
    }

    return {
      amount: calculatedAmount,
      amountUsd: calculatedAmountUsd,
      currencyCode: bestDeal.price.currencyCode,
    };
  }
  return undefined;
}

import { retrieveWegoUserInformation } from '@alexis/helpers/authentications';
import { addCookie } from '@alexis/helpers/cookie';
import { getCurrencyConfig } from '@alexis/helpers/currency';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import useFlightSearchPageParams from '@pages/FlightSearchResult/hooks/useFlightSearchPageParams';
import { AuthProvider, TAuthConfig } from '@vendor/react-oauth2-code-pkce';
import { isEmpty } from 'lodash';
import { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import { clsx } from '@wego/alexis/helpers/clsx';
import { localStorageClear, localStorageSave } from '@wego/alexis/helpers/localStorage';
import { translateText } from '@wego/alexis/helpers/translation';
import { LoggedInType } from '@wego/alexis/src/types/helpers/loggedInType';
import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import AuthMagicLink from '@components/AuthMagicLink';
import Header from '@components/Header';

import { FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY } from '@constants/flight';
import { NEW_AUTH } from '@constants/localStorage';

import { GlobalContext } from '@context/GlobalContext';

import { checkIfFlightSearchResultPage } from '@helpers/flight';
import { getHrefLangs } from '@helpers/seo';
import { webEngageTrackLogin } from '@helpers/webEngage';

import { useClarityCustomTag } from '@hooks/useClarity';
import { useIsAuthNew } from '@hooks/useIsAuthNew';
import useLocaleParam from '@hooks/useLocaleParam';

import { changeUser } from '@redux/actions/userActions';

import type { User } from '@wegoTypes/authentication';
import { Currency, CurrencyCodes } from '@wegoTypes/currency';
import { Translations } from '@wegoTypes/translations';

import { isFlightProduct } from '../helpers/flight';
import { isHotelProduct } from '../helpers/hotel';
import { changeCurrency } from '../redux/actions/currencyActions';
import ContentErrorBoundary from './ErrorBoundary/ContentError/ContentErrorBoundary';
import Footer from './Footer';
import styles from './Layout.module.scss';

interface LayoutProps {
  currency: Currency;
  currentSite: CurrentSite;
  domainBasedApiBaseUrl: string;
  exchangeRate: ExchangeRate;
  isRtl: boolean;
  locale: string;
  shopCashUser: ShopCashUser | null;
  translations: Translations;
  user: User | null;
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
}

type LocationType = {
  pathname: string;
  search: string;
  state?: {
    isRouteChange: boolean;
  };
};

type AuthConfig = {
  locale: string;
  siteCode: string;
  domainBasedApiBaseUrl: string;
  dispatch: Dispatch<any>;
  wegoAnalyticsClientId?: string;
  wegoAnalyticsClientSessionId?: string;
  coreConfigs: CoreConfig[];
  enabled: boolean;
  onRemoveCodeParams: () => void;
  setAuthButtonLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const baseUrl = () => {
  return isDevelopmentEnvironment(window.location.hostname) ||
    isStagingEnvironment(window.location.hostname)
    ? 'wegostaging'
    : 'wego';
};

const getUserInfo = async (domainBasedApiBaseUrl: string, dispatch: Dispatch<any>) => {
  const wegoUserInformation = await retrieveWegoUserInformation(domainBasedApiBaseUrl);
  const expireByDate = new Date();
  expireByDate.setDate(expireByDate.getDate() + 1);

  const userData: User = {
    loggedInType: LoggedInType.Wego,
    displayName: !!wegoUserInformation.name.trim()
      ? wegoUserInformation.name
      : wegoUserInformation.email,
    title: wegoUserInformation.title,
    firstName: wegoUserInformation.first_name,
    lastName: wegoUserInformation.last_name,
    nationality: wegoUserInformation.nationality,
    countryCode: wegoUserInformation.country_code,
    phoneCountryCode: wegoUserInformation.phone_country_code,
    phoneNumber: wegoUserInformation.phone_number,
    email: wegoUserInformation.email,
    csRestoreId: wegoUserInformation.cs_restore_id,
    photoUrl: wegoUserInformation.photo_url ?? undefined,
    userHash: wegoUserInformation.user_hash,
    idHash: wegoUserInformation.id_hash,
    sprinklrUserHash: wegoUserInformation.sprinklr_user_hash,
    accountPhoneNumber: wegoUserInformation.account_phone_number,
    accountPhoneCountryCode: wegoUserInformation.account_phone_country_code,
    expireBy: expireByDate.getTime(),
  };
  dispatch(changeUser(userData));
  webEngageTrackLogin(userData);
  return userData;
};

const authConfig = ({
  locale,
  siteCode,
  domainBasedApiBaseUrl,
  dispatch,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
  coreConfigs,
  enabled,
  onRemoveCodeParams,
  setAuthButtonLoading,
}: AuthConfig) => {
  const authKeys = coreConfigs
    .filter((x) => x.key.startsWith('auth_'))
    .reduce((acc: { [key: string]: string }, cur) => {
      acc[cur.key] = cur.value;
      return acc;
    }, {});
  return {
    clientId: '9a644c3268def69b22ac3f9b82125edb64d0b5075a7f862f3dd83e96606d47fb',
    extraAuthParameters: {
      locale: locale ?? 'en',
      site_code: siteCode ?? 'SA',
      app_type: 'WEB_APP',
      wc: wegoAnalyticsClientId || '',
      ws: wegoAnalyticsClientSessionId || '',
      vendor_id: '',
      additional_attributes: isEmpty(authKeys) ? '' : btoa(JSON.stringify(authKeys)),
    },
    authorizationEndpoint: `https://auth.${baseUrl()}.com/users/oauth/authorize`,
    tokenEndpoint: `${domainBasedApiBaseUrl}/users/oauth/token`,
    logoutEndpoint: `https://auth.${baseUrl()}.com/user-auth/v2/users/logout`,
    redirectUri: window.location.origin,
    scope: 'users',
    decodeToken: true,
    clearURL: true,
    autoLogin: false,
    enabled,
    postLogin: () => {
      setAuthButtonLoading(true);
      localStorageSave(NEW_AUTH, true);
      getUserInfo(domainBasedApiBaseUrl, dispatch).finally(() => {
        setAuthButtonLoading(false);
      });

      onRemoveCodeParams();
    },
    onRefreshTokenExpire: ({ logOut }) => {
      localStorageClear(NEW_AUTH);
      logOut(undefined, undefined, { post_logout_redirect_path: window.location.pathname });
    },
  } as TAuthConfig;
};

function Layout({
  currency,
  currentSite,
  domainBasedApiBaseUrl,
  exchangeRate,
  isRtl,
  locale,
  shopCashUser,
  translations,
  user,
  wegoAnalyticsClientSessionId,
  wegoAnalyticsClientId,
}: LayoutProps) {
  const [headerTheme, setHeaderTheme] = useState<Theme>('dark');
  const [showProductLinks, setShowProductLinks] = useState<boolean>(false);
  const [authButtonLoading, setAuthButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const { pathname, state } = useLocation() as LocationType;

  const { fareCode } = useParams();
  const navigate = useNavigate();
  const { isAuthNew } = useIsAuthNew();

  const [searchParams, setSearchParams] = useSearchParams();
  const currencySearchParam = searchParams.get('wego_currency') || searchParams.get('currency');
  const sessionIdParam = searchParams.get('session_id');
  const hasBowOnlyParamAsTrue = searchParams.get('bow_only') === 'true';

  const checkIsHomePage = (pathname: string): boolean => {
    return !/(?:searches|booking|handoff|preferences)/i.test(pathname);
  };

  const isHomePage = useMemo<boolean>(() => {
    return checkIsHomePage(pathname);
  }, [pathname]);

  const isPreferencesPage = pathname.includes('/preferences');

  const isInHotelCheckoutPage = pathname.includes('/booking/checkout');
  const isBookingComparisonAndCheckoutPage = !!fareCode || isInHotelCheckoutPage;
  const isInFlightComparisonPage = !!fareCode && !pathname.includes('/booking');
  const isInFlightBookingPage = !!fareCode && pathname.includes('/booking');

  // Get locale param
  const localeParam = useLocaleParam();

  const { userCentricCoreConfigs } = useContext(GlobalContext);

  const flightSearch2CoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY,
  );
  const isFlightSearch2 = flightSearch2CoreConfig?.value === '1';
  useClarityCustomTag(FLIGHT_SEARCH_2_V2_2_CORE_CONFIG_KEY, flightSearch2CoreConfig?.value);

  const { isMultiCity } = useFlightSearchPageParams();

  const isFlightSearchResultPage2 = useMemo<boolean>(
    () => isFlightSearch2 && !isMultiCity && checkIfFlightSearchResultPage(pathname, localeParam),
    [isFlightSearch2, isMultiCity, localeParam, pathname],
  );

  // Get product param
  const productParam = useMemo<string>(() => {
    const productParam = pathname.split('/')[!!localeParam ? 2 : 1];

    if (isFlightProduct(productParam) || isHotelProduct(productParam)) {
      return productParam;
    }
    return '';
  }, [pathname, localeParam]);

  useEffect(() => {
    if (state?.isRouteChange !== undefined) {
      navigate({ pathname }, { replace: true, state: {} });
    }
  }, [navigate, pathname, state?.isRouteChange]);

  // Set html direction and lang upon locale and rtl change
  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('lang', locale);

    const app = document.getElementById('app');
    if (app) {
      app.removeAttribute('dir');
      if (isRtl) {
        app.setAttribute('dir', 'rtl');
      }
    }
  }, [isRtl, locale]);

  useEffect(() => {
    if (isHomePage) {
      setHeaderTheme('dark');
      setShowProductLinks(false);

      const handleWindowScroll = (ev: Event) => {
        const window = ev.currentTarget as Window;

        const isCurrentlyOnHomePage = checkIsHomePage(window.location.pathname);

        if (!isCurrentlyOnHomePage) {
          return;
        }

        if (window.scrollY > 0) {
          setHeaderTheme('white');
          setShowProductLinks(true);
        } else {
          setHeaderTheme('dark');
          setShowProductLinks(false);
        }
      };

      window.addEventListener('scroll', handleWindowScroll);

      return () => {
        window.removeEventListener('scroll', handleWindowScroll);
      };
    } else {
      setHeaderTheme('white');
      setShowProductLinks(true);
    }
  }, [isHomePage]);

  // If the valid currency query param exists, set the currency state with it.
  // Immediately remove the wego_currency param, so no need more logic for removing it.
  //
  // Context:
  // Setting the currency to the user desired currency for traffic from
  // Google Hotel and other meta distribution partners.
  useEffect(() => {
    if (!!currencySearchParam) {
      const currency = getCurrencyConfig(currencySearchParam as CurrencyCodes);
      if (!!currency) {
        dispatch(changeCurrency(currency));
      }

      searchParams.delete('wego_currency');
      searchParams.delete('currency');
      setSearchParams(searchParams, { replace: true });
    }
  }, [currencySearchParam]);

  // Setting  cookie values from search params
  useEffect(() => {
    // No need to set wg_source, wg_medium, wg_campaign
    // No need to set ts_code because it's already handled in alexis
    if (sessionIdParam) {
      const nowMilliseconds = Date.now();

      addCookie(
        'wego_analytics_client_session_id',
        sessionIdParam,
        // 30 mins
        new Date(nowMilliseconds + 0.5 * 60 * 60 * 1000).getTime(),
        'lax',
        true,
      );
    }
  }, [sessionIdParam]);

  const getHostNameWithoutWWW = useCallback<(hostname: string) => string>((hostname: string) => {
    const hostNameWithoutWWW = hostname.replace('www.', '');

    return hostNameWithoutWWW.replace(/^./, hostNameWithoutWWW[0].toUpperCase());
  }, []);

  const title = useMemo<string>(() => {
    return translateText(
      translations.home_title,
      locale,
      getHostNameWithoutWWW(window.location.hostname),
    );
  }, [locale, translations, getHostNameWithoutWWW]);

  const metaDescription = useMemo<string>(() => {
    return translateText(
      translations.home_des,
      locale,
      getHostNameWithoutWWW(window.location.hostname),
    );
  }, [locale, translations, getHostNameWithoutWWW]);

  const onRemoveCodeParams = () => {
    const code = searchParams.get('code');
    if (code) {
      searchParams.delete('code');
      navigate(
        { pathname, search: decodeURIComponent(searchParams.toString()) },
        { replace: true, state },
      );
    }
  };

  return (
    <>
      <AuthProvider
        authConfig={authConfig({
          locale,
          siteCode: currentSite.countryCode,
          domainBasedApiBaseUrl,
          dispatch,
          wegoAnalyticsClientId: wegoAnalyticsClientId as string,
          wegoAnalyticsClientSessionId: wegoAnalyticsClientSessionId as string,
          coreConfigs: userCentricCoreConfigs,
          enabled: isAuthNew,
          onRemoveCodeParams,
          setAuthButtonLoading,
        })}
      >
        <Helmet>
          <title>{title}</title>
          <meta name='description' content={metaDescription} />
          <link rel='canonical' href={`${window.location.origin}${window.location.pathname}`} />

          {getHrefLangs(locale).map(({ hreflang, url }) => {
            return <link key={hreflang} rel='alternate' hrefLang={hreflang} href={url} />;
          })}

          {isStagingEnvironment(window.location.hostname) ? (
            <meta name='robots' content='noindex' />
          ) : null}

          {hasBowOnlyParamAsTrue ? <meta name='robots' content='none' /> : null}
        </Helmet>

        <AuthMagicLink />

        <Header
          className={clsx(
            styles.header,
            isHomePage && styles.sticky,
            isHomePage && headerTheme === 'white' && styles.isWhiteTheme,
            !isHomePage && styles.notHome,
            isPreferencesPage && styles.narrow,
            isFlightSearchResultPage2 && styles.flightSearchResultPage,
            isInHotelCheckoutPage && styles.hotelBookingResultPage,
            isInFlightComparisonPage && styles.flightComparisonPage,
            isInFlightBookingPage && styles.flightBookingPage,
          )}
          currency={currency}
          currentSite={currentSite}
          domainBasedApiBaseUrl={domainBasedApiBaseUrl}
          exchangeRate={exchangeRate}
          authButtonLoading={authButtonLoading}
          isBookingComparisonAndCheckoutPage={isBookingComparisonAndCheckoutPage}
          locale={locale}
          productParam={productParam}
          setAuthButtonLoading={setAuthButtonLoading}
          shopCashUser={shopCashUser}
          showProductLinks={showProductLinks}
          theme={headerTheme}
          translations={translations}
          user={user}
          wegoAnalyticsClientId={wegoAnalyticsClientId}
          wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
        />

        <ContentErrorBoundary>
          <Outlet />
        </ContentErrorBoundary>

        {!isBookingComparisonAndCheckoutPage ? (
          <Footer
            className={clsx(
              styles.footer,
              !isHomePage && styles.notHome,
              isPreferencesPage && styles.narrow,
            )}
            currentSite={currentSite}
            locale={locale}
            translations={translations}
            wegoAnalyticsClientId={wegoAnalyticsClientId}
            wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
          />
        ) : (
          <footer className={styles.footerCheckoutPage}>
            {translateText(translations.all_rights_footer, locale, new Date().getFullYear())}
          </footer>
        )}

        <div id='layoutPortalRoot' className={styles.layoutPortalRoot}></div>
      </AuthProvider>
    </>
  );
}

export default Layout;

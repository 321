import { dateApiFormat } from '@alexis/helpers/date';
import { appendSearchParams } from '@alexis/helpers/searchParam';
import axios from 'axios';
import type { CancelToken } from 'axios';

import { PoiDetails, Predictions } from '@wegoTypes/hotels/metasearch/hotelMapSearchPoi';
import { PlacesHotelsPopularCity } from '@wegoTypes/hotels/placesHotelsPopularCity';

export async function getCities(
  apiBaseUrl: string,
  codes: Array<string>,
  locale: string,
  cancelToken: CancelToken,
): Promise<Array<Place>> {
  const url = appendSearchParams(`${apiBaseUrl}/places/cities`, { codes, locale });

  const response = await axios.get<Array<Place>>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getNearestPlaces(
  locale: string,
  isCity: boolean,
  cancelToken?: CancelToken,
  latitude?: number,
  longitude?: number,
): Promise<Array<Place>> {
  const searchParams = {
    locale,
    ...(isCity && { 'types[]': 'city' }),
    ...(!!latitude && { latitude }),
    ...(!!longitude && { longitude }),
  };

  const url = appendSearchParams(`${API_BASE_URL}/places/search/nearest`, searchParams);

  const response = await axios.get<Array<Place>>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getHotelsPopularCities(
  apiBaseUrl: string,
  locale: string,
  currentSiteCode: string,
  cancelToken: CancelToken,
): Promise<Array<PlacesHotelsPopularCity>> {
  const searchParams = {
    locale,
    site_code: currentSiteCode.toUpperCase(),
  };

  const url = appendSearchParams(`${apiBaseUrl}/places/cities/popular/hotels`, searchParams);

  const response = await axios.get<Array<PlacesHotelsPopularCity>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getFlightsPopularCities(
  locale: string,
  currentSiteCode: string,
  isDeparture: boolean,
  perPageCount: number,
): Promise<Array<PlacesFlightsPopularCity>> {
  const searchParams = {
    locale,
    site_code: currentSiteCode.toUpperCase(),
    ...(isDeparture && { city_type: 'departure' }),
    per_page: perPageCount,
  };

  const url = appendSearchParams(`${API_BASE_URL}/places/cities/popular/flights`, searchParams);

  const response = await axios.get<Array<PlacesFlightsPopularCity>>(url, { withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getPlaces(
  locale: string,
  currentSiteCode: string,
  query: string,
  isCity: boolean,
  isDistrict: boolean,
  isHotel: boolean,
  isRegion: boolean,
  isActivity: boolean,
  isAttraction: boolean,
  cancelToken?: CancelToken,
  minimumHotelCount?: number,
  minimumAirportCount?: number,
): Promise<Array<Place>> {
  const searchParams = {
    locale,
    site_code: currentSiteCode.toUpperCase(),
    ...(!!query && { query }),
    ...((isCity || isDistrict || isHotel || isRegion || isActivity || isAttraction) && {
      types: [
        isCity && 'city',
        isDistrict && 'district',
        isHotel && 'hotel',
        isRegion && 'region',
        isActivity && 'activity',
        isAttraction && 'poi',
      ].filter(Boolean),
    }),
    ...(!!minimumHotelCount && { min_hotels: minimumHotelCount }),
    ...(!!minimumAirportCount && { min_airports: minimumAirportCount }),
  };

  const url = appendSearchParams(`${API_BASE_URL}/places/search`, searchParams);

  const response = await axios.get<Array<Place>>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getPlace(locale: string, code: string): Promise<Place> {
  const isCity = code.at(0) === 'c';

  const searchParams = {
    locale,
    ...(isCity ? { 'city_codes[]': code.slice(1) } : { 'airport_codes[]': code }),
  };

  const url = appendSearchParams(`${API_BASE_URL}/places/v1/locations`, searchParams);

  const response = await axios.post<{ cities: Array<Place>; airports: Array<Place> }>(
    url,
    {},
    { withCredentials: true },
  );

  if (response.status === 200) {
    return response.data[isCity ? 'cities' : 'airports'][0];
  }

  throw new Error(response.statusText);
}

export async function getFlightsAutocomplete(
  locale: string,
  currentSiteCode: string,
  searchFor: 'origin' | 'destination',
  query: string,
  minimumAirportCount: number,
): Promise<Array<Place>> {
  const searchParams = {
    locale,
    site_code: currentSiteCode.toUpperCase(),
    search_for: searchFor,
    ...(!!query && { query }),
    ...(!!minimumAirportCount && { min_airports: minimumAirportCount }),
  };

  const url = appendSearchParams(`${API_BASE_URL}/autocomplete/flights/v1/search`, searchParams);

  const response = await axios.get<Array<Place>>(url, { withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

type GetCityByCode = {
  cityCode: string;
  locale: string;
  cancelToken: CancelToken;
};
export async function getCityByCode({
  cityCode,
  locale,
  cancelToken,
}: GetCityByCode): Promise<{ city: Place } | null> {
  const searchParams = {
    language: locale,
  };
  const url = appendSearchParams(`${API_BASE_URL}/places/v2/cities/${cityCode}`, searchParams);
  const response = await axios.get<{ city: Place }>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getPublicHolidays(
  apiBaseUrl: string,
  locale: string,
  currentSiteCode: string,
  fromDate: Date,
  toDate: Date,
) {
  const searchParams = {
    locale,
    site_code: currentSiteCode.toUpperCase(),
    start_date: dateApiFormat(fromDate),
    end_date: dateApiFormat(toDate),
  };

  const url = appendSearchParams(`${apiBaseUrl}/places/v2/holidays`, searchParams);

  const response = await axios.get<{ publicHolidays: Array<PlacesHoliday> }>(url, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getHomepage(
  apiBaseUrl: string,
  locale: string,
  currentSiteCode: string,
  cancelToken?: CancelToken,
): Promise<Homepage> {
  const searchParams = {
    language: locale,
    site_code: currentSiteCode.toUpperCase(),
  };

  const url = appendSearchParams(`${apiBaseUrl}/places/homepages`, searchParams);

  const response = await axios.get<Homepage>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export type TravelThemesSearchParams = {
  departureCityCode: string;
  locale: string;
  currencyCode: string;
  siteCode: string;
  bowOnly?: boolean;
};

export async function getTravelThemes(
  apiBaseUrl: string,
  searchParams: TravelThemesSearchParams,
): Promise<Array<PlacesTravelTheme>> {
  const mapSearchParamsToApiParams = {
    departure_city_code: searchParams.departureCityCode,
    locale: searchParams.locale,
    currency_code: searchParams.currencyCode,
    site_code: searchParams.siteCode,
    per_page: 10,
    ...(searchParams?.bowOnly && { bow_only: searchParams.bowOnly }),
  };

  const url = appendSearchParams(
    `${apiBaseUrl}/places/cities/flights/themes`,
    mapSearchParamsToApiParams,
  );

  const response = await axios.get<Array<PlacesTravelTheme>>(url, { withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export type TravelThemeCitiesSearchParams = {
  departureCityCode: string;
  locale: string;
  currencyCode: string;
  siteCode: string;
  bowOnly?: boolean;
};

export async function getTravelThemeCities(
  apiBaseUrl: string,
  travelThemeId: number,
  searchParams: TravelThemeCitiesSearchParams,
): Promise<Array<PlacesTravelThemeCity>> {
  const mapSearchParamsToApiParams = {
    departure_city_code: searchParams.departureCityCode,
    locale: searchParams.locale,
    currency_code: searchParams.currencyCode,
    site_code: searchParams.siteCode,
    per_page: 9,
    ...(searchParams?.bowOnly && { bow_only: searchParams.bowOnly }),
  };

  const url = appendSearchParams(
    `${apiBaseUrl}/places/cities/themes/${travelThemeId}/flights`,
    mapSearchParamsToApiParams,
  );

  const response = await axios.get<Array<PlacesTravelThemeCity>>(url, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export const getLocationDetails = async (
  locale: string,
  codes: string[],
  cancelToken?: CancelToken,
): Promise<Array<Place>> => {
  let cityCodes: string[] = [];
  let airportCodes: string[] = [];
  codes.forEach((code) => {
    const isCity = code.at(0) === 'c';
    if (isCity) {
      cityCodes.push(code.slice(1));
    } else {
      airportCodes.push(code);
    }
  });
  const searchParams = {
    locale,
    airport_codes: airportCodes,
    city_codes: cityCodes,
  };

  let url = appendSearchParams(`${API_BASE_URL}/places/v1/locations`, searchParams);

  const response = await axios.post<{
    cities: Array<Place>;
    airports: Array<Place>;
  }>(url, {}, { withCredentials: true, cancelToken });

  if (response.status === 200) {
    const airports = response.data.airports ?? [];
    const cities = response.data.cities ?? [];
    return airports.concat(cities);
  }

  throw new Error(response.statusText);
};

export async function getMapPoi(
  apiBaseUrl: string,
  locale: string,
  query: string,
  longitude: number,
  latitude: number,
  cancelToken: CancelToken,
): Promise<Predictions> {
  const searchParams = {
    ...(!!query && { input: query }),
    locale,
    ...(!!longitude && { longitude }),
    ...(!!latitude && { latitude }),
  };

  const url = appendSearchParams(`${apiBaseUrl}/hotels/autosuggest/places/search`, searchParams);

  const response = await axios.get<Predictions>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export async function getMapPoiDetail(
  apiBaseUrl: string,
  placeId: string,
  cancelToken: CancelToken,
): Promise<PoiDetails> {
  const url = `${apiBaseUrl}/hotels/autosuggest/places/${placeId}/details`;

  const response = await axios.get<PoiDetails>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeIsRtl } from '../redux/actions/isRtlActions';

interface IsRtlProps {
  locale: string;
}

const IsRtl: React.FC<IsRtlProps> = ({ locale }): null => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeIsRtl(locale));
  }, [locale]);

  return null;
};

export default IsRtl;

import { useEffect, useState } from 'react';

//TODO: This is temporary to handle old and new login versions, what the product wants is, when user clicks the login button, we show a spinner on the button if the core configs aren't loaded yet. Once we support the new login version 100%, we can remove this.
export const useHandleLoginEffect = (coreConfigsLoaded: boolean, onLogin: () => void) => {
  const [userIsLoggingIn, setUserIsLoggingIn] = useState(false);
  const [loginEffectLoading, setLoginEffectLoading] = useState(false);

  useEffect(() => {
    if (!userIsLoggingIn) {
      return;
    }

    setLoginEffectLoading(true);

    if (coreConfigsLoaded) {
      onLogin();
      setUserIsLoggingIn(false);
      setLoginEffectLoading(false);
    }
  }, [userIsLoggingIn, coreConfigsLoaded, onLogin]);

  return { loginEffectLoading, setUserIsLoggingIn };
};

import { sitesConfig } from '@alexis/helpers/site';

import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { flightsRouteTranslation, hotelsRouteTranslation } from '@helpers/routeTranslation';

function constructHreflangAttribute(locale: string, siteCode: string): string {
  let hreflangValue = '';

  if (locale.match(/^zh-/)) {
    if (locale.slice(-2) === siteCode.toLowerCase()) {
      hreflangValue = locale;
    } else {
      hreflangValue = `${locale.slice(0, 3)}${siteCode}`;
    }
  } else if (locale === 'en' && siteCode === 'US') {
    hreflangValue = 'en';
  } else if (locale === 'ru' && siteCode === 'US') {
    hreflangValue = 'ru';
  } else {
    hreflangValue = `${locale}-${siteCode}`;
  }

  return hreflangValue.toLowerCase();
}

function constructHreflangUrlByLocale(
  locale: string,
  siteConfig: CurrentSite,
  currentLocale: string,
): string {
  const localeParam = locale === siteConfig.defaultLocale ? '' : `/${locale}`;
  const baseUrl = `https://${siteConfig.host}${localeParam}`;
  if (window.location.pathname.includes(flightsRouteTranslation(currentLocale))) {
    return `${baseUrl}/${flightsRouteTranslation(locale)}`;
  } else if (window.location.pathname.includes(hotelsRouteTranslation(currentLocale))) {
    return `${baseUrl}/${hotelsRouteTranslation(locale)}`;
  }

  return baseUrl;
}

type HrefLangType = { hreflang: string; url: string };

export function getHrefLangs(currentLocale: string): Array<HrefLangType> {
  if (/(?:searches|booking|handoff|preferences)/i.test(window.location.pathname)) return [];
  let hrefLangs: Array<HrefLangType> = [];

  for (const siteConfig of sitesConfig) {
    siteConfig.supportedLocales.forEach((supportedLocale) => {
      hrefLangs.push({
        hreflang: constructHreflangAttribute(supportedLocale, siteConfig.countryCode),
        url: constructHreflangUrlByLocale(supportedLocale, siteConfig, currentLocale),
      });
    });
  }
  return hrefLangs;
}

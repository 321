import React from 'react';

import { translateText } from '@wego/alexis/helpers/translation';

import { cloudinaryImageTransformations } from '@helpers/imageTransformations';

import { Translations } from '@wegoTypes/translations';

import styles from './TravelPartners.module.scss';

interface TravelPartnersProps {
  translations: Translations;
  locale: string;
  countryName: string;
  popularProviders: Array<HomepageHotelsPopularProvider>;
}

const TravelPartners: React.FC<TravelPartnersProps> = ({
  translations,
  locale,
  countryName,
  popularProviders,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const hasBowOnlyParamAsTrue = searchParams.get('bow_only') === 'true';

  if (popularProviders.length <= 0 || hasBowOnlyParamAsTrue) return null;

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <div className={styles.title}>{translations.our_travel_partners}</div>
        <div className={styles.message}>
          {translateText(translations.wego_searches_for_best_deals_on, locale, countryName)}
        </div>
      </div>

      <div className={styles.logos}>
        {popularProviders.slice(0, 8).map((popularProvider) => (
          <div key={popularProvider.code} className={styles.logo}>
            <img
              src={cloudinaryImageTransformations(
                `https://assets.wego.com/image/upload/v20220803/providers/rectangular_logos/${popularProvider.code}.png`,
                'h_64',
                'c_fit',
                'f_auto',
                'fl_lossy',
                'q_auto:low',
              )}
              alt={popularProvider.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TravelPartners;

import { getInitialLocale } from '@helpers/locale';

import { LocaleActionTypes } from '../actions/types';

export default function (
  state: string = getInitialLocale(),
  action: { type: LocaleActionTypes; payload: string },
): string {
  switch (action.type) {
    case LocaleActionTypes.ChangeLocale:
      return action.payload;
    default:
      return state;
  }
}

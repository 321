import { useSelector } from 'react-redux';

import Image from '@components/Image';

import { cloudinaryImageTransformations } from '@helpers/imageTransformations';

import AppGalleryButtonAr from '@icons/app-gallery-button-ar.svg';
import AppGalleryButtonEn from '@icons/app-gallery-button-en.svg';
import AppStoreButtonAr from '@icons/app-store-button-ar.svg';
import AppStoreButtonEn from '@icons/app-store-button-en.svg';
import GooglePlayButtonAr from '@icons/google-play-button-ar.svg';
import GooglePlayButtonEn from '@icons/google-play-button-en.svg';
import TickIcon from '@icons/tick.svg';

import { getLocaleState, getTranslationsState } from '@redux/selectors';

import styles from './InstallBanner.module.scss';

export default function InstallBanner() {
  const locale = useSelector(getLocaleState);
  const translations = useSelector(getTranslationsState);

  const isAr = locale === 'ar';
  const assetUrlLocale = isAr ? 'ar' : 'en';

  const phone = `https://zen.wego.com/cdn-cgi/image/width=600/web/illustrations/download-app-phone_${assetUrlLocale}.png`;
  const AppStoreButton = isAr ? AppStoreButtonAr : AppStoreButtonEn;
  const GooglePlayButton = isAr ? GooglePlayButtonAr : GooglePlayButtonEn;
  const AppGalleryButton = isAr ? AppGalleryButtonAr : AppGalleryButtonEn;

  return (
    <div className={styles.installBanner}>
      <div className={styles.imageContainer}>
        <Image className={styles.image} isLazy={true} src={phone} />
      </div>

      <div className={styles.content}>
        <div className={styles.title}>{translations.title_install_banner}</div>

        {[
          translations.Install_banner_usp1,
          translations.Install_banner_usp2,
          translations.Install_banner_usp3,
        ].map((usp) => (
          <div key={usp as string} className={styles.messageContainer}>
            <div className={styles.tickContainer}>
              <TickIcon className={styles.icon} />
            </div>
            {usp}
          </div>
        ))}

        <div className={styles.scanToDownload}>{translations.scan_to_download}</div>

        <div className={styles.appsContainer}>
          <Image
            className={styles.qrCode}
            isLazy={true}
            src={cloudinaryImageTransformations(
              'https://assets.wego.com/image/upload/v202010050/web/install_banner/qr_code.png',
              'h_120',
              'w_120',
              'f_auto',
              'fl_lossy',
              'q_auto:low',
            )}
          />

          <div className={styles.apps}>
            <a
              aria-label='Android Play Store'
              href='https://wegotravel.onelink.me/pGV9/c04fbc77'
              target='_blank'
              rel='noreferrer'
            >
              <GooglePlayButton />
            </a>

            <a
              aria-label='Apple App Store'
              href='https://wegotravel.onelink.me/pGV9/c04fbc77'
              target='_blank'
              rel='noreferrer'
            >
              <AppStoreButton />
            </a>

            <a
              aria-label='Huawei App Gallery'
              href='https://wegotravel.onelink.me/pGV9/c04fbc77'
              target='_blank'
              rel='noreferrer'
            >
              <AppGalleryButton />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

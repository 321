import Spinner from '@alexis/components/Spinner';
import {
  facebookSignIn,
  googleOneTapSignIn,
  loadFacebookClientLibrary,
  wegoSignIn,
} from '@alexis/helpers/authentications';
import { toISOStringWithTimezone } from '@alexis/helpers/date';
import { genzoTrackActionEvent, genzoTrackPageView } from '@alexis/helpers/genzo';
import { generateCUID } from '@alexis/helpers/identity';
import type { LoginProps } from 'props/loginProps';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateText } from '@wego/alexis/helpers/translation';
import Input from '@wego/alexis/src/react/Input/Input';
import { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { GlobalContext } from '@context/GlobalContext';

import { isGoogleBot } from '@helpers/bots';

import { getCurrentSiteState, getIsRtlState, getUserState } from '@redux/selectors';

import CloseIcon from '../icons/close.svg';
import styles from '../styles/components/Login.module.scss';

const Login: React.FC<LoginProps> = ({
  apiBaseUrl,
  className,
  domainBasedApiBaseUrl,
  facebookAppId,
  googleClientId,
  locale,
  onClose,
  onForgetPassword,
  onLogin,
  onSendConfirmationEmail,
  onSignUp,
  translations,
  wegoClientId,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
}): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [isEmailValidated, setIsEmailValidated] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>();
  const [password, setPassword] = useState<string>('');
  const [isPasswordValidated, setIsPasswordValidated] = useState<boolean>(false);
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { webEngageAnonymousId } = useContext(GlobalContext);
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const user = useSelector(getUserState);
  const isRtl = useSelector(getIsRtlState);

  // Load facebook sdk
  loadFacebookClientLibrary(facebookAppId);

  const pageViewId = useMemo<string>(() => {
    return generateCUID();
  }, []);

  useEffect(() => {
    if (user) return;

    const triggerGoogleOneTapSignIn = async (): Promise<void> => {
      try {
        const user = await googleOneTapSignIn(domainBasedApiBaseUrl, googleClientId, wegoClientId);

        onLogin(user);
      } catch (error) {}
    };

    if (!isGoogleBot) triggerGoogleOneTapSignIn();
  }, [domainBasedApiBaseUrl, googleClientId, onLogin, user, wegoClientId]);

  useEffect(() => {
    if (isEmailValidated) {
      setIsEmailValidated(false);
    }

    if (emailHasError) {
      setEmailHasError(false);
    }

    if (!!emailErrorMessage) {
      setEmailErrorMessage(undefined);
    }
  }, [emailErrorMessage, emailHasError, isEmailValidated]);

  useEffect(() => {
    if (isPasswordValidated) {
      setIsPasswordValidated(false);
    }

    if (passwordHasError) {
      setPasswordHasError(false);
    }

    if (!!passwordErrorMessage) {
      setPasswordErrorMessage(undefined);
    }
  }, [isPasswordValidated, passwordErrorMessage, passwordHasError]);

  // Genzo Page View Tracking
  useEffect(() => {
    if (
      !isGoogleBot &&
      !!wegoAnalyticsClientId &&
      !!wegoAnalyticsClientSessionId &&
      !!webEngageAnonymousId
    ) {
      const clientData = {
        id: wegoAnalyticsClientId,
        session_id: wegoAnalyticsClientSessionId,
      };

      const pageData = {
        name: 'Login Modal',
        product: 'Login',
        base_type: 'login_modal',
        sub_type: 'login_modal',
        locale: locale,
        site_code: currentSite.countryCode,
        url: window.location.href,
        referrer_url: document.referrer,
      };

      const data = {
        id: pageViewId,
        client: clientData,
        page: pageData,
        created_at: toISOStringWithTimezone(new Date()),
        external_services: {
          crm_id: webEngageAnonymousId,
        },
      };

      genzoTrackPageView(apiBaseUrl, data);
    }
  }, [
    apiBaseUrl,
    currentSite.countryCode,
    locale,
    pageViewId,
    webEngageAnonymousId,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
  ]);

  async function handleFacebookSignIn(): Promise<void> {
    try {
      setIsLoading(true);

      const user = await facebookSignIn(domainBasedApiBaseUrl, wegoClientId);

      onLogin(user);
    } catch (error) {}

    setIsLoading(false);
  }

  async function handleGoogleSignIn(): Promise<void> {
    try {
      setIsLoading(true);

      const user = await googleOneTapSignIn(domainBasedApiBaseUrl, googleClientId, wegoClientId);

      onLogin(user);
    } catch (error) {}

    setIsLoading(false);
  }

  async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();

    let hasError = false;
    setEmailHasError(false);
    setPasswordHasError(false);
    setLoginErrorMessage(undefined);

    if (!/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsEmailValidated(true);
      setEmailHasError(true);

      if (email.length === 0) {
        setEmailErrorMessage(translations.required_fields as string);
      } else {
        setEmailErrorMessage(
          translateText(translations.invalid, locale, translations.email_address as string),
        );
      }
      hasError = true;
    }

    if (password.length === 0) {
      setIsPasswordValidated(true);
      setPasswordHasError(true);

      setPasswordErrorMessage(translations.required_fields as string);
      hasError = true;
    }

    if (!hasError) {
      setIsEmailValidated(true);
      setIsPasswordValidated(true);

      try {
        setIsLoading(true);
        const user = await wegoSignIn(domainBasedApiBaseUrl, wegoClientId, email, password);

        if (wegoAnalyticsClientId && wegoAnalyticsClientSessionId) {
          const eventData = {
            id: pageViewId,
            category: 'user_signin',
            object: 'signin',
            action: 'clicked',
            value: '',
          };

          genzoTrackActionEvent(
            apiBaseUrl,
            wegoAnalyticsClientId,
            wegoAnalyticsClientSessionId,
            user.userHash,
            eventData,
          );
        }

        onLogin(user);
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);

          setEmailHasError(true);
          setPasswordHasError(true);

          setLoginErrorMessage(translations.login_error as string);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      }
      setIsLoading(false);
    }
  }

  return (
    <form
      className={clsx(styles.container, className)}
      onSubmit={handleFormSubmit}
      noValidate={true}
    >
      <div className={styles.header}>
        <CloseIcon className={styles.icon} onClick={onClose} />
        <div className={styles.title}>{translations.users_login}</div>
        <div className={styles.subtitle}>
          {translations.users_sessions_new_header_action_text}{' '}
          <span onClick={onSignUp}>{translations.sign_up}</span>
        </div>
      </div>
      <div className={styles.content}>
        <button
          type='button'
          className={clsx(styles.oAuthProvider, styles.facebook)}
          onClick={handleFacebookSignIn}
        >
          <i className={clsx(styles.oAuthProviderLogo, styles.facebook)}></i>
          {translations.facebook_login}
        </button>

        <button
          id='googleSignIn'
          type='button'
          className={clsx(styles.oAuthProvider, styles.google)}
          onClick={handleGoogleSignIn}
        ></button>

        <div className={styles.strikethrough} data-text={translations.log_in_w_email}></div>

        {!!loginErrorMessage ? (
          <div className={styles.loginErrorMessage}>{loginErrorMessage}</div>
        ) : null}

        <Input
          autoComplete={'on'}
          className={styles.input}
          hasError={emailHasError}
          id={'email'}
          isRtl={isRtl}
          isValidated={isEmailValidated}
          message={emailErrorMessage}
          name={'email'}
          onChange={(value: string) => setEmail(value.trim())}
          placeholder={translations.email_address as string}
          type={'text'}
          value={email}
        />

        <Input
          autoComplete={'off'}
          className={styles.input}
          hasError={passwordHasError}
          id={'password'}
          isRtl={isRtl}
          isValidated={isPasswordValidated}
          message={passwordErrorMessage}
          name={'password'}
          onChange={setPassword}
          placeholder={translations.password as string}
          type={'password'}
          value={password}
        />

        <div className={styles.link} onClick={onForgetPassword}>
          {translations.forgot_password}
        </div>

        <div className={styles.link} onClick={onSendConfirmationEmail}>
          {translations.no_confirmation}
        </div>

        <button type='submit' className={styles.login} disabled={isLoading}>
          {isLoading ? <Spinner className={styles.spinner} /> : translations.login}
        </button>
      </div>
    </form>
  );
};

export default Login;

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import LocaleNavigator from '@components/LocaleNavigator';

import { SprinklrChatProvider } from '@context/Sprinklrchat';

import DatadogProvider from './context/Datadog';
import { getStore, injectReducers } from './redux/store';
import Routes from './routes/Routes';

const store = getStore();

const queryClient = new QueryClient();

const App: React.FC = (): JSX.Element => {
  return (
    <StrictMode>
      <BrowserRouter>
        <LocaleNavigator>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <SprinklrChatProvider>
                <DatadogProvider>
                  <Routes onInjectReducers={injectReducers} />
                </DatadogProvider>
              </SprinklrChatProvider>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </LocaleNavigator>
      </BrowserRouter>
    </StrictMode>
  );
};

export default App;

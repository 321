import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';

export type UseAxiosQueryOptions<
  TQueryFnData,
  TData = TQueryFnData,
  TError = Error,
> = UseQueryOptions<TQueryFnData, TError, TData> & {
  select?: (data: TQueryFnData) => TData;
  onSuccessCallback?: (data: TData) => void;
  onErrorCallback?: (error: TError) => void;
  onSettledCallback?: () => void;
};

export function useAxiosQuery<TQuery, TData = TQuery, TError = Error>(
  options: UseAxiosQueryOptions<TQuery, TData, TError>,
) {
  const { data, error, isError, isLoading, fetchStatus, ...rest } = useQuery<TQuery, TError, TData>(
    options,
  );

  useEffect(() => {
    if (error && fetchStatus !== 'fetching') {
      options.onErrorCallback?.(error);
    }
  }, [error, data]);

  useEffect(() => {
    if (data && fetchStatus) {
      options.onSuccessCallback?.(data);
    }
  }, [data, fetchStatus]);

  useEffect(() => {
    if (data || error) {
      options.onSettledCallback?.();
    }
  }, [data, error]);

  return {
    ...rest,
    data,
    error,
    isError,
    fetchStatus,
    isLoading: isLoading && fetchStatus !== 'idle',
  };
}

import { useSelector } from 'react-redux';

import { getPaymentOrderData } from '@apis/flight';

import { useAxiosQuery } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

const useGetPaymentOrderData = (paymentOrderId: string) => {
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [
      'getPaymentOrderData',
      {
        locale,
        currentSiteCode: currentSiteCode.countryCode,
        paymentOrderId,
      },
    ],
    queryFn: () => getPaymentOrderData(paymentOrderId),
    enabled: !!paymentOrderId,
  });
};

export default useGetPaymentOrderData;

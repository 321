import { createStore, combineReducers, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';

import apiBaseUrl from './reducers/apiBaseUrl';
import currency from './reducers/currency';
import currentSite from './reducers/currentSite';
import domainBasedApiBaseUrl from './reducers/domainBasedApiBaseUrl';
import exchangeRate from './reducers/exchangeRate';
import hotelMetaSearch from './reducers/hotelMetaSearch';
import isRtl from './reducers/isRtl';
import locale from './reducers/locale';
import pageViewId from './reducers/pageViewId';
import priceOptions from './reducers/priceOptions';
import shopCashUser from './reducers/shopCashUser';
import translations from './reducers/translations';
import triggerSearchCounter from './reducers/triggerSearchCounter';
import user from './reducers/user';

// Define the Reducers that will always be present in the application
export let reducers: { [key: string]: any } = {
  apiBaseUrl,
  currency,
  currentSite,
  domainBasedApiBaseUrl,
  exchangeRate,
  hotelMetaSearch,
  isRtl,
  locale,
  pageViewId,
  priceOptions,
  shopCashUser,
  translations,
  triggerSearchCounter,
  user,
  coreConfig: (state = []) => state,
  webEngage: (state = {}) => state,
};

// export const getReducers = (): { [key: string]: any; } => {
//   return reducers;
// }

export const injectReducers = (asyncReducers: { [key: string]: any }): void => {
  const newReducers = { ...reducers };

  Object.keys(asyncReducers).map((key) => {
    if (!newReducers[key]) {
      newReducers[key] = asyncReducers[key];
    }
  });

  if (Object.entries(reducers).length !== Object.entries(newReducers).length) {
    store.replaceReducer(combineReducers(newReducers));
    reducers = newReducers;
  }
};

const store: Store = createStore(combineReducers(reducers), applyMiddleware(thunk));

export const getStore = (): Store => {
  return store;
};

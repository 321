export const handlePreviousItem = (
  activeIndex: number,
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
  containerId: string,
  itemPrefix: string,
) => {
  if (activeIndex >= 1) {
    setActiveIndex((prev) => prev - 1);

    const itemElement = document.getElementById(`${itemPrefix}-${activeIndex}`);
    const searchResultContainer = document.getElementById(containerId);

    if (searchResultContainer && itemElement) {
      const itemElementTop = itemElement.clientHeight * (activeIndex - 1);
      const differences = searchResultContainer.scrollTop - itemElementTop;

      if (differences > 0) {
        searchResultContainer.scrollTop -= differences;
      }
    }
  }
};

export const handleNextItem = (
  activeIndex: number,
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
  totalItems: number,
  containerId: string,
  itemPrefix: string,
) => {
  if (activeIndex < totalItems - 1) {
    setActiveIndex((prev) => prev + 1);

    const itemElement = document.getElementById(`${itemPrefix}-${activeIndex}`);
    const searchResultContainer = document.getElementById(containerId);

    if (itemElement && searchResultContainer) {
      const itemElementBottom = itemElement.clientHeight * (activeIndex + 2);
      const differences = itemElementBottom - searchResultContainer.clientHeight;

      if (differences > 0 && searchResultContainer.scrollTop <= differences) {
        searchResultContainer.scrollTop = differences;
      }
    }
  }
};

import { useSelector } from 'react-redux';

import { getUserState } from '@redux/selectors';

import { getInitialismOfName } from './helper';
import styles from './UserSummaryInfo.module.scss';

function UserSummaryInfo() {
  const user = useSelector(getUserState);
  return (
    <div className={styles.container}>
      <div className={styles.avatar}>{getInitialismOfName(user?.displayName)}</div>
      <div className={styles.info}>
        {user?.displayName && <div className={styles.name}>{user.displayName}</div>}
        {user?.email && <div className={styles.email}>{user.email}</div>}
      </div>
    </div>
  );
}

export default UserSummaryInfo;

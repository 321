import { Translations } from 'translations';

import { translateText } from '@wego/alexis/helpers/translation';

import PropositionItem from '@components/home/Propositions/PropositionItem';

import HeadsetIcon from '@icons/headset.svg';
import MultipleCardIcon from '@icons/multiple_card.svg';
import PercentVoucherIcon from '@icons/percent_voucher.svg';
import TranslateIcon from '@icons/translate.svg';

import styles from './Propositions.module.scss';

type PropositionsProp = {
  bowOnly: string | null;
  translations: Translations;
  locale: string;
  countryName: string;
  pageType: string;
};

const Propositions: React.FC<PropositionsProp> = ({
  bowOnly,
  translations,
  locale,
  countryName,
  pageType,
}) => {
  const isBowOnly = bowOnly === 'true';

  return (
    <div className={styles.propositions}>
      <PropositionItem
        isBowOnly={isBowOnly}
        pageType={pageType}
        hotelsText={{
          heading: translations.usp_hotel_deals_title as string,
          description: translations.usp_hotel_deals as string,
        }}
        flightsText={{
          heading: translations.usp_flight_deals_title as string,
          description: translations.usp_flight_deals_description as string,
        }}
        defaultText={{
          heading: translateText(translations.top_travel_app_in, locale, countryName),
          description: translations.highly_rated_in as string,
        }}
        image={{
          bowOnly: PercentVoucherIcon,
          default: 'https://zen.wego.com/web/illustrations/look-no-further.png',
        }}
      />

      <PropositionItem
        isBowOnly={isBowOnly}
        pageType={pageType}
        hotelsText={{
          heading: translations.usp_payments_title as string,
          description: translations.usp_payments_description as string,
        }}
        flightsText={{
          heading: translations.usp_payments_title as string,
          description: translations.usp_payments_description as string,
        }}
        defaultText={{
          heading: translations.shop_with_confidence as string,
          description: translations.no_hidden_fees as string,
        }}
        image={{
          bowOnly: MultipleCardIcon,
          default: 'https://zen.wego.com/web/illustrations/shop-with-confidence.png',
        }}
      />

      <PropositionItem
        isBowOnly={isBowOnly}
        pageType={pageType}
        hotelsText={{
          heading: translations.usp_customer_service_title as string,
          description: translations.usp_customer_service_description as string,
        }}
        flightsText={{
          heading: translations.usp_customer_service_title as string,
          description: translations.usp_customer_service_description as string,
        }}
        defaultText={{
          heading: translations.pay_the_way_you_want as string,
          description: translations.your_preferred_methods as string,
        }}
        image={{
          bowOnly: HeadsetIcon,
          default: 'https://zen.wego.com/web/illustrations/pay-the-way-you-want.png',
        }}
      />

      <PropositionItem
        isBowOnly={isBowOnly}
        pageType={pageType}
        hotelsText={{
          heading: translations.usp_languages_title as string,
          description: translations.usp_languages_description as string,
        }}
        flightsText={{
          heading: translations.usp_languages_title as string,
          description: translations.usp_languages_description as string,
        }}
        defaultText={{
          heading: translations.instant_booking as string,
          description: translations.for_selected_sellers as string,
        }}
        image={{
          bowOnly: TranslateIcon,
          default: 'https://zen.wego.com/web/illustrations/instant-booking.png',
        }}
      />
    </div>
  );
};

export default Propositions;

export const PAYMENT_METHOD_LOGOS: { [key: string]: string } = Object.freeze({
  airtelmoney: 'airtelMoney',
  amex: 'amex',
  applepay: 'applePay',
  bnpl_3_installments: 'tabby',
  bnpl_4_installments: 'tabby',
  emi: 'emi',
  freecharge: 'freecharge',
  jiomoney: 'jioMoney',
  knet: 'knet',
  masc: 'masterCard',
  madc: 'mada',
  mobikwik: 'mobiKwik',
  netbanking: 'netBanking',
  olamoney: 'olaMoney',
  phonepe: 'phonePe',
  rupay: 'ruPay',
  upi: 'upi',
  visc: 'visa',
});

export const SHOW_POPULAR_FLAG = 'hmeta317_show_popular_flag';

export const TEST_DEFAULT_DATE = 'hmeta238_test_default_date';

export const MAX_RADIUS_IN_KM = 200;
export const NUMBER_OF_HOTEL_MARKERS = 50;

export const HIDING_TRAVELLER_TYPE_SCORE = 'dw_hmeta687_hiding_traveller_type_score_in_review';

export const DISPLAY_3_LATEST_REVIEWS = 'w_hmeta613_latest_reviews_in_srp';

export const NEW_RATING_SCALE = 'w_hmeta753_rescaling_ratings';

export const REVIEW_KEY_PHRASE = [
  'room',
  'service',
  'breakfast',
  'wifi',
  'location',
  'clean',
  'noise',
  'parking',
];

export const ENHANCED_HOTEL_RATES = 'dw_hmeta781_enhancing_hotel_rates_in_hoteldetails_page';

export const HOTEL_IMAGES_CAROUSEL_CURSOR_NAVIGATION =
  'dw_hmeta782_hotel_images_carousel_with_cursor_movement';

export const ADDING_TRAVELER_TYPE_BOX = 'dw_hmeta_837_adding_traveler_type_box';

export const ENHANCED_HOTEL_IMAGES = 'dw_hmeta874_enhancing_hotel_detail_images_section';

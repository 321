import { FetchError } from './errors';
import { TTokenRequest } from './types';

function buildUrlEncodedRequest(request: TTokenRequest): string {
  let queryString = '';
  for (const [key, value] of Object.entries(request)) {
    queryString += `${queryString ? '&' : ''}${key}=${encodeURIComponent(value)}`;
  }
  return queryString;
}

export async function postWithXForm(url: string, request: TTokenRequest): Promise<Response> {
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: buildUrlEncodedRequest(request),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }).then(async (response: Response) => {
    if (!response.ok) {
      const responseBody = await response.text();
      throw new FetchError(response.status, response.statusText, responseBody);
    }
    return response;
  });
}

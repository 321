import { isEnvironmentProduction } from '@alexis/helpers/environment';
import React, { useEffect } from 'react';

import { loadGPTAds, unloadGPTAds, refreshGPTAds } from '@helpers/ads';

import { useViewportSize } from '@hooks/useViewportSize';

import styles from './NtoBannerAd.module.scss';

const EUROPEAN_MARKETS = ['DE', 'ES', 'IT', 'FR', 'SE', 'NL', 'GB'];

const POS_WITH_NTO_BANNER_AD = [
  'AE',
  'BH',
  'EG',
  'IN',
  'JO',
  'KW',
  'MA',
  'OM',
  'QA',
  'SA',
  'PK',
  'TR',
  ...EUROPEAN_MARKETS,
];

interface NtoBannerAdProps {
  countryCode: string;
  locale: string;
  isUserLoggedIn: boolean;
}

function NtoBannerAd({ countryCode, locale, isUserLoggedIn }: NtoBannerAdProps) {
  const searchParams = new URLSearchParams(window.location.search);
  const hasBowOnlyParamAsTrue = searchParams.get('bow_only') === 'true';

  const { width } = useViewportSize();

  const showBannerInWidth944 = width >= 944 + 64 && width < 1136 + 64;
  const showBannerInWidth704 = width < 944 + 64;

  const widthOfBanner = showBannerInWidth704 ? 704 : showBannerInWidth944 ? 944 : 1136;

  // Initialize GPT Ads
  useEffect(() => {
    const initializeAds = async (): Promise<void> => {
      const hostname = window.location.hostname;

      const targetParams: { [key: string]: string } = {
        _cctld: countryCode,
        _locale: locale,
        _env: isEnvironmentProduction(hostname) ? 'production' : 'staging',
        _logged_in: isUserLoggedIn ? 'true' : 'false',
        _page_type: 'homepage',
      };

      const slotsConfigurations = [
        {
          adUnitPath: '/1033625/Homepage_NTO_Banner',
          size: [[widthOfBanner, 300]] as googletag.GeneralSize,
          htmlDivId: 'ntoBannerAd',
          targetParams: targetParams,
        },
      ];

      const slots = await loadGPTAds(slotsConfigurations, true, true, true);

      await refreshGPTAds(slots);
    };

    initializeAds();

    return () => {
      unloadGPTAds();
    };
  }, [countryCode, locale, isUserLoggedIn, widthOfBanner]);

  if (!POS_WITH_NTO_BANNER_AD.includes(countryCode) || hasBowOnlyParamAsTrue) return null;

  return <div id='ntoBannerAd' className={styles.ntoAdContainer}></div>;
}

export default NtoBannerAd;

import { getCurrencyConfig } from '@alexis/helpers/currency';
import { getSiteConfigByHostname } from '@alexis/helpers/site';
import cookies from 'js-cookie';

import { WEGO_CURRENCY_COOKIE_KEY } from '@constants/cookie';

import { Currency, CurrencyCodes } from '@wegoTypes/currency';

import { CurrencyActionTypes } from '../actions/types';

const currentSite = getSiteConfigByHostname(window.location.hostname);

const cookieCurrency = cookies.get(WEGO_CURRENCY_COOKIE_KEY);

const currency =
  (cookieCurrency ? getCurrencyConfig(cookieCurrency as CurrencyCodes) : undefined) ??
  getCurrencyConfig(currentSite.currencyCode as CurrencyCodes)!;

const initialState: Currency = currency;

export default function (
  state: Currency = initialState,
  action: { type: CurrencyActionTypes; payload: Currency },
): Currency {
  switch (action.type) {
    case CurrencyActionTypes.ChangeCurrency: {
      const currentCurrency = action.payload;
      cookies.set(WEGO_CURRENCY_COOKIE_KEY, action.payload.code);
      return currentCurrency;
    }
    default:
      return state;
  }
}

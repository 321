import type { Country } from '@wego/alexis/types/helpers/country';

export const countriesConfig: Array<Country> = [
  {
    name: 'Afghanistan',
    label: 'pos_af',
    callingCode: 93,
    code: 'AF',
  },
  {
    name: 'Åland Islands',
    label: 'pos_ax',
    callingCode: 358,
    code: 'AX',
  },
  {
    name: 'Albania',
    label: 'pos_al',
    callingCode: 355,
    code: 'AL',
  },
  {
    name: 'Algeria',
    label: 'pos_dz',
    callingCode: 213,
    code: 'DZ',
  },
  {
    name: 'American Samoa',
    label: 'pos_as',
    callingCode: 1684,
    code: 'AS',
  },
  {
    name: 'Andorra',
    label: 'pos_ad',
    callingCode: 376,
    code: 'AD',
  },
  {
    name: 'Angola',
    label: 'pos_ao',
    callingCode: 244,
    code: 'AO',
  },
  {
    name: 'Anguilla',
    label: 'pos_ai',
    callingCode: 1264,
    code: 'AI',
  },
  {
    name: 'Antarctica',
    label: 'pos_aq',
    callingCode: 672,
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    label: 'pos_ag',
    callingCode: 1268,
    code: 'AG',
  },
  {
    name: 'Argentina',
    label: 'pos_ar',
    callingCode: 54,
    code: 'AR',
  },
  {
    name: 'Armenia',
    label: 'pos_am',
    callingCode: 374,
    code: 'AM',
  },
  {
    name: 'Aruba',
    label: 'pos_aw',
    callingCode: 297,
    code: 'AW',
  },
  {
    name: 'Australia',
    label: 'pos_au',
    callingCode: 61,
    code: 'AU',
  },
  {
    name: 'Austria',
    label: 'pos_at',
    callingCode: 43,
    code: 'AT',
  },
  {
    name: 'Azerbaijan',
    label: 'pos_az',
    callingCode: 994,
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    label: 'pos_bs',
    callingCode: 1242,
    code: 'BS',
  },
  {
    name: 'Bahrain',
    label: 'pos_bh',
    callingCode: 973,
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    label: 'pos_bd',
    callingCode: 880,
    code: 'BD',
  },
  {
    name: 'Barbados',
    label: 'pos_bb',
    callingCode: 1246,
    code: 'BB',
  },
  {
    name: 'Belarus',
    label: 'pos_by',
    callingCode: 375,
    code: 'BY',
  },
  {
    name: 'Belgium',
    label: 'pos_be',
    callingCode: 32,
    code: 'BE',
  },
  {
    name: 'Belize',
    label: 'pos_bz',
    callingCode: 501,
    code: 'BZ',
  },
  {
    name: 'Benin',
    label: 'pos_bj',
    callingCode: 229,
    code: 'BJ',
  },
  {
    name: 'Bermuda',
    label: 'pos_bm',
    callingCode: 1441,
    code: 'BM',
  },
  {
    name: 'Bhutan',
    label: 'pos_bt',
    callingCode: 975,
    code: 'BT',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    label: 'pos_bo',
    callingCode: 591,
    code: 'BO',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    label: 'pos_bq',
    callingCode: 5997,
    code: 'BQ',
  },
  {
    name: 'Bosnia and Herzegovina',
    label: 'pos_ba',
    callingCode: 387,
    code: 'BA',
  },
  {
    name: 'Botswana',
    label: 'pos_bw',
    callingCode: 267,
    code: 'BW',
  },
  {
    name: 'Bouvet Island',
    label: 'pos_bv',
    callingCode: 47,
    code: 'BV',
  },
  {
    name: 'Brazil',
    label: 'pos_br',
    callingCode: 55,
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    label: 'pos_io',
    callingCode: 246,
    code: 'IO',
  },
  {
    name: 'Virgin Islands (British)',
    label: 'pos_vg',
    callingCode: 1284,
    code: 'VG',
  },
  {
    name: 'Virgin Islands (U.S.)',
    label: 'pos_vi',
    callingCode: 1340,
    code: 'VI',
  },
  {
    name: 'Brunei Darussalam',
    label: 'pos_bn',
    callingCode: 673,
    code: 'BN',
  },
  {
    name: 'Bulgaria',
    label: 'pos_bg',
    callingCode: 359,
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    label: 'pos_bf',
    callingCode: 226,
    code: 'BF',
  },
  {
    name: 'Burundi',
    label: 'pos_bi',
    callingCode: 257,
    code: 'BI',
  },
  {
    name: 'Cambodia',
    label: 'pos_kh',
    callingCode: 855,
    code: 'KH',
  },
  {
    name: 'Cameroon',
    label: 'pos_cm',
    callingCode: 237,
    code: 'CM',
  },
  {
    name: 'Canada',
    label: 'pos_ca',
    callingCode: 1,
    code: 'CA',
  },
  {
    name: 'Cabo Verde',
    label: 'pos_cv',
    callingCode: 238,
    code: 'CV',
  },
  {
    name: 'Cayman Islands',
    label: 'pos_ky',
    callingCode: 1345,
    code: 'KY',
  },
  {
    name: 'Central African Republic',
    label: 'pos_cf',
    callingCode: 236,
    code: 'CF',
  },
  {
    name: 'Chad',
    label: 'pos_td',
    callingCode: 235,
    code: 'TD',
  },
  {
    name: 'Chile',
    label: 'pos_cl',
    callingCode: 56,
    code: 'CL',
  },
  {
    name: 'China',
    label: 'pos_cn',
    callingCode: 86,
    code: 'CN',
  },
  {
    name: 'Christmas Island',
    label: 'pos_cx',
    callingCode: 61,
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    label: 'pos_cc',
    callingCode: 61,
    code: 'CC',
  },
  {
    name: 'Colombia',
    label: 'pos_co',
    callingCode: 57,
    code: 'CO',
  },
  {
    name: 'Comoros',
    label: 'pos_km',
    callingCode: 269,
    code: 'KM',
  },
  {
    name: 'Congo',
    label: 'pos_cg',
    callingCode: 242,
    code: 'CG',
  },
  {
    name: 'Congo (Democratic Republic of the)',
    label: 'pos_cd',
    callingCode: 243,
    code: 'CD',
  },
  {
    name: 'Cook Islands',
    label: 'pos_ck',
    callingCode: 682,
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    label: 'pos_cr',
    callingCode: 506,
    code: 'CR',
  },
  {
    name: 'Croatia',
    label: 'pos_hr',
    callingCode: 385,
    code: 'HR',
  },
  {
    name: 'Cuba',
    label: 'pos_cu',
    callingCode: 53,
    code: 'CU',
  },
  {
    name: 'Curaçao',
    label: 'pos_cw',
    callingCode: 599,
    code: 'CW',
  },
  {
    name: 'Cyprus',
    label: 'pos_cy',
    callingCode: 357,
    code: 'CY',
  },
  {
    name: 'Czech Republic',
    label: 'pos_cz',
    callingCode: 420,
    code: 'CZ',
  },
  {
    name: 'Denmark',
    label: 'pos_dk',
    callingCode: 45,
    code: 'DK',
  },
  {
    name: 'Djibouti',
    label: 'pos_dj',
    callingCode: 253,
    code: 'DJ',
  },
  {
    name: 'Dominica',
    label: 'pos_dm',
    callingCode: 1767,
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    label: 'pos_do',
    callingCode: 18,
    code: 'DO',
  },
  {
    name: 'Ecuador',
    label: 'pos_ec',
    callingCode: 593,
    code: 'EC',
  },
  {
    name: 'Egypt',
    label: 'pos_eg',
    callingCode: 20,
    code: 'EG',
  },
  {
    name: 'El Salvador',
    label: 'pos_sv',
    callingCode: 503,
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    label: 'pos_gq',
    callingCode: 240,
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    label: 'pos_er',
    callingCode: 291,
    code: 'ER',
  },
  {
    name: 'Estonia',
    label: 'pos_ee',
    callingCode: 372,
    code: 'EE',
  },
  {
    name: 'Ethiopia',
    label: 'pos_et',
    callingCode: 251,
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    label: 'pos_fk',
    callingCode: 500,
    code: 'FK',
  },
  {
    name: 'Faroe Islands',
    label: 'pos_fo',
    callingCode: 298,
    code: 'FO',
  },
  {
    name: 'Fiji',
    label: 'pos_fj',
    callingCode: 679,
    code: 'FJ',
  },
  {
    name: 'Finland',
    label: 'pos_fi',
    callingCode: 358,
    code: 'FI',
  },
  {
    name: 'France',
    label: 'pos_fr',
    callingCode: 33,
    code: 'FR',
  },
  {
    name: 'French Guiana',
    label: 'pos_gf',
    callingCode: 594,
    code: 'GF',
  },
  {
    name: 'French Polynesia',
    label: 'pos_pf',
    callingCode: 689,
    code: 'PF',
  },
  {
    name: 'French Southern Territories',
    label: 'pos_tf',
    callingCode: 33,
    code: 'TF',
  },
  {
    name: 'Gabon',
    label: 'pos_ga',
    callingCode: 241,
    code: 'GA',
  },
  {
    name: 'Gambia',
    label: 'pos_gm',
    callingCode: 220,
    code: 'GM',
  },
  {
    name: 'Georgia',
    label: 'pos_ge',
    callingCode: 995,
    code: 'GE',
  },
  {
    name: 'Germany',
    label: 'pos_de',
    callingCode: 49,
    code: 'DE',
  },
  {
    name: 'Ghana',
    label: 'pos_gh',
    callingCode: 233,
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    label: 'pos_gi',
    callingCode: 350,
    code: 'GI',
  },
  {
    name: 'Greece',
    label: 'pos_gr',
    callingCode: 30,
    code: 'GR',
  },
  {
    name: 'Greenland',
    label: 'pos_gl',
    callingCode: 299,
    code: 'GL',
  },
  {
    name: 'Grenada',
    label: 'pos_gd',
    callingCode: 1473,
    code: 'GD',
  },
  {
    name: 'Guadeloupe',
    label: 'pos_gp',
    callingCode: 590,
    code: 'GP',
  },
  {
    name: 'Guam',
    label: 'pos_gu',
    callingCode: 1671,
    code: 'GU',
  },
  {
    name: 'Guatemala',
    label: 'pos_gt',
    callingCode: 502,
    code: 'GT',
  },
  {
    name: 'Guernsey',
    label: 'pos_gg',
    callingCode: 44,
    code: 'GG',
  },
  {
    name: 'Guinea',
    label: 'pos_gn',
    callingCode: 224,
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    label: 'pos_gw',
    callingCode: 245,
    code: 'GW',
  },
  {
    name: 'Guyana',
    label: 'pos_gy',
    callingCode: 592,
    code: 'GY',
  },
  {
    name: 'Haiti',
    label: 'pos_ht',
    callingCode: 509,
    code: 'HT',
  },
  {
    name: 'Heard Island and McDonald Islands',
    label: 'pos_hm',
    callingCode: 672,
    code: 'HM',
  },
  {
    name: 'Holy See',
    label: 'pos_va',
    callingCode: 379,
    code: 'VA',
  },
  {
    name: 'Honduras',
    label: 'pos_hn',
    callingCode: 504,
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    label: 'pos_hk',
    callingCode: 852,
    code: 'HK',
  },
  {
    name: 'Hungary',
    label: 'pos_hu',
    callingCode: 36,
    code: 'HU',
  },
  {
    name: 'Iceland',
    label: 'pos_is',
    callingCode: 354,
    code: 'IS',
  },
  {
    name: 'India',
    label: 'pos_in',
    callingCode: 91,
    code: 'IN',
  },
  {
    name: 'Indonesia',
    label: 'pos_id',
    callingCode: 62,
    code: 'ID',
  },
  {
    name: "Côte d'Ivoire",
    label: 'pos_ci',
    callingCode: 225,
    code: 'CI',
  },
  {
    name: 'Iran (Islamic Republic of)',
    label: 'pos_ir',
    callingCode: 98,
    code: 'IR',
  },
  {
    name: 'Iraq',
    label: 'pos_iq',
    callingCode: 964,
    code: 'IQ',
  },
  {
    name: 'Ireland',
    label: 'pos_ie',
    callingCode: 353,
    code: 'IE',
  },
  {
    name: 'Isle of Man',
    label: 'pos_im',
    callingCode: 44,
    code: 'IM',
  },
  {
    name: 'Israel',
    label: 'pos_il',
    callingCode: 972,
    code: 'IL',
  },
  {
    name: 'Italy',
    label: 'pos_it',
    callingCode: 39,
    code: 'IT',
  },
  {
    name: 'Jamaica',
    label: 'pos_jm',
    callingCode: 1876,
    code: 'JM',
  },
  {
    name: 'Japan',
    label: 'pos_jp',
    callingCode: 81,
    code: 'JP',
  },
  {
    name: 'Jersey',
    label: 'pos_je',
    callingCode: 44,
    code: 'JE',
  },
  {
    name: 'Jordan',
    label: 'pos_jo',
    callingCode: 962,
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    label: 'pos_kz',
    callingCode: 7,
    code: 'KZ',
  },
  {
    name: 'Kenya',
    label: 'pos_ke',
    callingCode: 254,
    code: 'KE',
  },
  {
    name: 'Kiribati',
    label: 'pos_ki',
    callingCode: 686,
    code: 'KI',
  },
  {
    name: 'Kuwait',
    label: 'pos_kw',
    callingCode: 965,
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    label: 'pos_kg',
    callingCode: 996,
    code: 'KG',
  },
  {
    name: "Lao People's Democratic Republic",
    label: 'pos_la',
    callingCode: 856,
    code: 'LA',
  },
  {
    name: 'Latvia',
    label: 'pos_lv',
    callingCode: 371,
    code: 'LV',
  },
  {
    name: 'Lebanon',
    label: 'pos_lb',
    callingCode: 961,
    code: 'LB',
  },
  {
    name: 'Lesotho',
    label: 'pos_ls',
    callingCode: 266,
    code: 'LS',
  },
  {
    name: 'Liberia',
    label: 'pos_lr',
    callingCode: 231,
    code: 'LR',
  },
  {
    name: 'Libya',
    label: 'pos_ly',
    callingCode: 218,
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    label: 'pos_li',
    callingCode: 423,
    code: 'LI',
  },
  {
    name: 'Lithuania',
    label: 'pos_lt',
    callingCode: 370,
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    label: 'pos_lu',
    callingCode: 352,
    code: 'LU',
  },
  {
    name: 'Macao',
    label: 'pos_mo',
    callingCode: 853,
    code: 'MO',
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    label: 'pos_mk',
    callingCode: 389,
    code: 'MK',
  },
  {
    name: 'Madagascar',
    label: 'pos_mg',
    callingCode: 261,
    code: 'MG',
  },
  {
    name: 'Malawi',
    label: 'pos_mw',
    callingCode: 265,
    code: 'MW',
  },
  {
    name: 'Malaysia',
    label: 'pos_my',
    callingCode: 60,
    code: 'MY',
  },
  {
    name: 'Maldives',
    label: 'pos_mv',
    callingCode: 960,
    code: 'MV',
  },
  {
    name: 'Mali',
    label: 'pos_ml',
    callingCode: 223,
    code: 'ML',
  },
  {
    name: 'Malta',
    label: 'pos_mt',
    callingCode: 356,
    code: 'MT',
  },
  {
    name: 'Marshall Islands',
    label: 'pos_mh',
    callingCode: 692,
    code: 'MH',
  },
  {
    name: 'Martinique',
    label: 'pos_mq',
    callingCode: 596,
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    label: 'pos_mr',
    callingCode: 222,
    code: 'MR',
  },
  {
    name: 'Mauritius',
    label: 'pos_mu',
    callingCode: 230,
    code: 'MU',
  },
  {
    name: 'Mayotte',
    label: 'pos_yt',
    callingCode: 262,
    code: 'YT',
  },
  {
    name: 'Mexico',
    label: 'pos_mx',
    callingCode: 52,
    code: 'MX',
  },
  {
    name: 'Micronesia (Federated States of)',
    label: 'pos_fm',
    callingCode: 691,
    code: 'FM',
  },
  {
    name: 'Moldova (Republic of)',
    label: 'pos_md',
    callingCode: 373,
    code: 'MD',
  },
  {
    name: 'Monaco',
    label: 'pos_mc',
    callingCode: 377,
    code: 'MC',
  },
  {
    name: 'Mongolia',
    label: 'pos_mn',
    callingCode: 976,
    code: 'MN',
  },
  {
    name: 'Montenegro',
    label: 'pos_me',
    callingCode: 382,
    code: 'ME',
  },
  {
    name: 'Montserrat',
    label: 'pos_ms',
    callingCode: 1664,
    code: 'MS',
  },
  {
    name: 'Morocco',
    label: 'pos_ma',
    callingCode: 212,
    code: 'MA',
  },
  {
    name: 'Mozambique',
    label: 'pos_mz',
    callingCode: 258,
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    label: 'pos_mm',
    callingCode: 95,
    code: 'MM',
  },
  {
    name: 'Namibia',
    label: 'pos_na',
    callingCode: 264,
    code: 'NA',
  },
  {
    name: 'Nauru',
    label: 'pos_nr',
    callingCode: 674,
    code: 'NR',
  },
  {
    name: 'Nepal',
    label: 'pos_np',
    callingCode: 977,
    code: 'NP',
  },
  {
    name: 'Netherlands',
    label: 'pos_nl',
    callingCode: 31,
    code: 'NL',
  },
  {
    name: 'New Caledonia',
    label: 'pos_nc',
    callingCode: 687,
    code: 'NC',
  },
  {
    name: 'New Zealand',
    label: 'pos_nz',
    callingCode: 64,
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    label: 'pos_ni',
    callingCode: 505,
    code: 'NI',
  },
  {
    name: 'Niger',
    label: 'pos_ne',
    callingCode: 227,
    code: 'NE',
  },
  {
    name: 'Nigeria',
    label: 'pos_ng',
    callingCode: 234,
    code: 'NG',
  },
  {
    name: 'Niue',
    label: 'pos_nu',
    callingCode: 683,
    code: 'NU',
  },
  {
    name: 'Norfolk Island',
    label: 'pos_nf',
    callingCode: 672,
    code: 'NF',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    label: 'pos_kp',
    callingCode: 850,
    code: 'KP',
  },
  {
    name: 'Northern Mariana Islands',
    label: 'pos_mp',
    callingCode: 1670,
    code: 'MP',
  },
  {
    name: 'Norway',
    label: 'pos_no',
    callingCode: 47,
    code: 'NO',
  },
  {
    name: 'Oman',
    label: 'pos_om',
    callingCode: 968,
    code: 'OM',
  },
  {
    name: 'Pakistan',
    label: 'pos_pk',
    callingCode: 92,
    code: 'PK',
  },
  {
    name: 'Palau',
    label: 'pos_pw',
    callingCode: 680,
    code: 'PW',
  },
  {
    name: 'Palestine, State of',
    label: 'pos_ps',
    callingCode: 970,
    code: 'PS',
  },
  {
    name: 'Panama',
    label: 'pos_pa',
    callingCode: 507,
    code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    label: 'pos_pg',
    callingCode: 675,
    code: 'PG',
  },
  {
    name: 'Paraguay',
    label: 'pos_py',
    callingCode: 595,
    code: 'PY',
  },
  {
    name: 'Peru',
    label: 'pos_pe',
    callingCode: 51,
    code: 'PE',
  },
  {
    name: 'Philippines',
    label: 'pos_ph',
    callingCode: 63,
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    label: 'pos_pn',
    callingCode: 64,
    code: 'PN',
  },
  {
    name: 'Poland',
    label: 'pos_pl',
    callingCode: 48,
    code: 'PL',
  },
  {
    name: 'Portugal',
    label: 'pos_pt',
    callingCode: 351,
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    label: 'pos_pr',
    callingCode: 1,
    code: 'PR',
  },
  {
    name: 'Qatar',
    label: 'pos_qa',
    callingCode: 974,
    code: 'QA',
  },
  {
    name: 'Republic of Kosovo',
    label: 'pos_xk',
    callingCode: 383,
    code: 'XK',
  },
  {
    name: 'Réunion',
    label: 'pos_re',
    callingCode: 262,
    code: 'RE',
  },
  {
    name: 'Romania',
    label: 'pos_ro',
    callingCode: 40,
    code: 'RO',
  },
  {
    name: 'Russian Federation',
    label: 'pos_ru',
    callingCode: 7,
    code: 'RU',
  },
  {
    name: 'Rwanda',
    label: 'pos_rw',
    callingCode: 250,
    code: 'RW',
  },
  {
    name: 'Saint Barthélemy',
    label: 'pos_bl',
    callingCode: 590,
    code: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    label: 'pos_sh',
    callingCode: 290,
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    label: 'pos_kn',
    callingCode: 1869,
    code: 'KN',
  },
  {
    name: 'Saint Lucia',
    label: 'pos_lc',
    callingCode: 1758,
    code: 'LC',
  },
  {
    name: 'Saint Martin (French part)',
    label: 'pos_mf',
    callingCode: 590,
    code: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    label: 'pos_pm',
    callingCode: 508,
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    label: 'pos_vc',
    callingCode: 1784,
    code: 'VC',
  },
  {
    name: 'Samoa',
    label: 'pos_ws',
    callingCode: 685,
    code: 'WS',
  },
  {
    name: 'San Marino',
    label: 'pos_sm',
    callingCode: 378,
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    label: 'pos_st',
    callingCode: 239,
    code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    label: 'pos_sa',
    callingCode: 966,
    code: 'SA',
  },
  {
    name: 'Senegal',
    label: 'pos_sn',
    callingCode: 221,
    code: 'SN',
  },
  {
    name: 'Serbia',
    label: 'pos_rs',
    callingCode: 381,
    code: 'RS',
  },
  {
    name: 'Seychelles',
    label: 'pos_sc',
    callingCode: 248,
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    label: 'pos_sl',
    callingCode: 232,
    code: 'SL',
  },
  {
    name: 'Singapore',
    label: 'pos_sg',
    callingCode: 65,
    code: 'SG',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    label: 'pos_sx',
    callingCode: 1721,
    code: 'SX',
  },
  {
    name: 'Slovakia',
    label: 'pos_sk',
    callingCode: 421,
    code: 'SK',
  },
  {
    name: 'Slovenia',
    label: 'pos_si',
    callingCode: 386,
    code: 'SI',
  },
  {
    name: 'Solomon Islands',
    label: 'pos_sb',
    callingCode: 677,
    code: 'SB',
  },
  {
    name: 'Somalia',
    label: 'pos_so',
    callingCode: 252,
    code: 'SO',
  },
  {
    name: 'South Africa',
    label: 'pos_za',
    callingCode: 27,
    code: 'ZA',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    label: 'pos_gs',
    callingCode: 500,
    code: 'GS',
  },
  {
    name: 'Korea (Republic of)',
    label: 'pos_kr',
    callingCode: 82,
    code: 'KR',
  },
  {
    name: 'South Sudan',
    label: 'pos_ss',
    callingCode: 211,
    code: 'SS',
  },
  {
    name: 'Spain',
    label: 'pos_es',
    callingCode: 34,
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    label: 'pos_lk',
    callingCode: 94,
    code: 'LK',
  },
  {
    name: 'Sudan',
    label: 'pos_sd',
    callingCode: 249,
    code: 'SD',
  },
  {
    name: 'Suriname',
    label: 'pos_sr',
    callingCode: 597,
    code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    label: 'pos_sj',
    callingCode: 4779,
    code: 'SJ',
  },
  {
    name: 'Swaziland',
    label: 'pos_sz',
    callingCode: 268,
    code: 'SZ',
  },
  {
    name: 'Sweden',
    label: 'pos_se',
    callingCode: 46,
    code: 'SE',
  },
  {
    name: 'Switzerland',
    label: 'pos_ch',
    callingCode: 41,
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    label: 'pos_sy',
    callingCode: 963,
    code: 'SY',
  },
  {
    name: 'Taiwan',
    label: 'pos_tw',
    callingCode: 886,
    code: 'TW',
  },
  {
    name: 'Tajikistan',
    label: 'pos_tj',
    callingCode: 992,
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of',
    label: 'pos_tz',
    callingCode: 255,
    code: 'TZ',
  },
  {
    name: 'Thailand',
    label: 'pos_th',
    callingCode: 66,
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    label: 'pos_tl',
    callingCode: 670,
    code: 'TL',
  },
  {
    name: 'Togo',
    label: 'pos_tg',
    callingCode: 228,
    code: 'TG',
  },
  {
    name: 'Tokelau',
    label: 'pos_tk',
    callingCode: 690,
    code: 'TK',
  },
  {
    name: 'Tonga',
    label: 'pos_to',
    callingCode: 676,
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    label: 'pos_tt',
    callingCode: 1868,
    code: 'TT',
  },
  {
    name: 'Tunisia',
    label: 'pos_tn',
    callingCode: 216,
    code: 'TN',
  },
  {
    name: 'Turkey',
    label: 'pos_tr',
    callingCode: 90,
    code: 'TR',
  },
  {
    name: 'Turkmenistan',
    label: 'pos_tm',
    callingCode: 993,
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    label: 'pos_tc',
    callingCode: 1649,
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    label: 'pos_tv',
    callingCode: 688,
    code: 'TV',
  },
  {
    name: 'Uganda',
    label: 'pos_ug',
    callingCode: 256,
    code: 'UG',
  },
  {
    name: 'Ukraine',
    label: 'pos_ua',
    callingCode: 380,
    code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    label: 'pos_ae',
    callingCode: 971,
    code: 'AE',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    label: 'pos_gb',
    callingCode: 44,
    code: 'GB',
  },
  {
    name: 'United States of America',
    label: 'pos_us',
    callingCode: 1,
    code: 'US',
  },
  {
    name: 'United States Minor Outlying Islands',
    label: 'pos_um',
    callingCode: 1,
    code: 'UM',
  },
  {
    name: 'Uruguay',
    label: 'pos_uy',
    callingCode: 598,
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    label: 'pos_uz',
    callingCode: 998,
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    label: 'pos_vu',
    callingCode: 678,
    code: 'VU',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    label: 'pos_ve',
    callingCode: 58,
    code: 'VE',
  },
  {
    name: 'Viet Nam',
    label: 'pos_vn',
    callingCode: 84,
    code: 'VN',
  },
  {
    name: 'Wallis and Futuna',
    label: 'pos_wf',
    callingCode: 681,
    code: 'WF',
  },
  {
    name: 'Western Sahara',
    label: 'pos_eh',
    callingCode: 212,
    code: 'EH',
  },
  {
    name: 'Yemen',
    label: 'pos_ye',
    callingCode: 967,
    code: 'YE',
  },
  {
    name: 'Zambia',
    label: 'pos_zm',
    callingCode: 260,
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    label: 'pos_zw',
    callingCode: 263,
    code: 'ZW',
  },
];

export const getCountryConfig = (code: string): Country | undefined => {
  return countriesConfig.find((countryConfig) => countryConfig.code === code.toUpperCase())!;
};

export function convertCallingCodeToNumber(callingCode: string): number {
  return parseInt(callingCode.replace('+', ''));
}

export function getCountryConfigByCallingCode(
  countryCode: string,
  callingCode: string,
): Country | undefined {
  return countriesConfig.find(
    (countryConfig) =>
      countryConfig.code === countryCode.toUpperCase() &&
      countryConfig.callingCode === convertCallingCodeToNumber(callingCode),
  );
}

import { useSelector } from 'react-redux';

import { getCityRecommendedHotels } from '@apis/hotel';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getLocaleState } from '@redux/selectors';

import { HotelRecommendedHotel } from '@wegoTypes/hotels/hotelRecommendedHotel';

type GetRecommendedHotelsParams = {
  cityCode: string;
};

export const getRecommendedHotelsKey = 'recommended-hotels';

export const useGetCityRecommendedHotels = <TResult = HotelRecommendedHotel[]>(
  params: GetRecommendedHotelsParams,
  options?: UseAxiosQueryOptions<HotelRecommendedHotel[], TResult>,
) => {
  const apiBaseUrl = API_BASE_URL;
  const locale = useSelector(getLocaleState);

  return useAxiosQuery({
    queryKey: [
      getRecommendedHotelsKey,
      {
        cityCode: params.cityCode,
        locale,
      },
    ],
    queryFn: () => getCityRecommendedHotels(apiBaseUrl, params.cityCode, locale),
    ...options,
  });
};

import { clsx } from '@alexis/helpers/clsx';
import { dateDisplayFormat } from '@alexis/helpers/date';
import { CabinClass } from 'flights/cabinClass';
import React from 'react';
import { useSelector } from 'react-redux';

import { useFlightContext } from '@context/FlightContext';

import { getIsRtlState, getLocaleState, getTranslationsState } from '@redux/selectors';

import { cabinClassDisplay, passengerCountDisplay } from '../../../helpers/flight';
import styles from './SearchFormCollapseView.module.scss';
import type { SearchDetails } from './type';

export interface SearchFormCollapseViewProps {
  adultCount: number;
  cabinClass: CabinClass;
  childCount: number;
  infantCount: number;
  isMultiCitySearch: boolean;
  isPlaceAndDateDetailsParsedFromUrl: boolean;
  searchDetails: SearchDetails[];
  onEditClick: () => void;
}

const SearchFormCollapseView: React.FC<SearchFormCollapseViewProps> = ({
  adultCount,
  cabinClass,
  childCount,
  infantCount,
  isMultiCitySearch,
  isPlaceAndDateDetailsParsedFromUrl,
  searchDetails,
  onEditClick,
}) => {
  const translations = useSelector(getTranslationsState);
  const locale = useSelector(getLocaleState);
  const isRtl = useSelector(getIsRtlState);

  const { isFlightSearch2AndNotMulticity } = useFlightContext();

  const departLeg = searchDetails[0];

  const renderLegLabel = (depart: string, arrival: string) => {
    if (isRtl) {
      return `${arrival} - ${depart}`;
    }

    return `${depart} - ${arrival}`;
  };

  return (
    <div className={clsx(styles.container, isFlightSearch2AndNotMulticity && styles.flightSearch2)}>
      {isMultiCitySearch && (
        <div className={styles.legSearchContainer}>
          {searchDetails.map((searchDetail, index) => (
            <div
              key={index}
              className={clsx(styles.legSearch, searchDetails.length > 2 && styles.withMaxWidth)}
            >
              <div className={styles.index}>{index + 1}</div>
              <div>
                <div className={styles.code}>
                  {renderLegLabel(
                    searchDetail.outboundPlace?.code || '',
                    searchDetail.inboundPlace?.code || '',
                  )}
                </div>
                <div className={styles.date}>
                  {searchDetail.outboundDateMilliseconds
                    ? dateDisplayFormat(
                        new Date(searchDetail.outboundDateMilliseconds),
                        translations.short_months as Array<string>,
                        translations.short_weekdays as Array<string>,
                        locale === 'fa',
                        5,
                      )
                    : ''}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={styles.actionBar}>
        <div className={styles.legsAndCabinClass}>
          {!isMultiCitySearch && (
            <div className={styles.legs}>
              {departLeg.outboundPlace?.cityName} ({departLeg.outboundPlace?.code}) -{' '}
              {departLeg.inboundPlace?.cityName} ({departLeg.inboundPlace?.code})
            </div>
          )}
          <div className={styles.cabinClassAndPassengerCount}>
            {!isMultiCitySearch && isPlaceAndDateDetailsParsedFromUrl && (
              <>
                <span>
                  {!!departLeg.outboundDateMilliseconds &&
                    dateDisplayFormat(
                      new Date(departLeg.outboundDateMilliseconds),
                      translations.short_months as Array<string>,
                      translations.short_weekdays as Array<string>,
                      locale === 'fa',
                      0,
                    )}
                  {!!departLeg.inboundDateMilliseconds && <> - </>}
                  {!!departLeg.inboundDateMilliseconds &&
                    dateDisplayFormat(
                      new Date(departLeg.inboundDateMilliseconds),
                      translations.short_months as Array<string>,
                      translations.short_weekdays as Array<string>,
                      locale === 'fa',
                      0,
                    )}
                </span>
                <i className={styles.divider}></i>
              </>
            )}
            <span>{cabinClassDisplay(cabinClass, translations)}</span>
            <i className={styles.divider}></i>
            <span>
              {passengerCountDisplay(adultCount, childCount, infantCount, translations, locale)}
            </span>
          </div>
        </div>

        <button className={styles.editButton} onClick={onEditClick}>
          {translations.edit_page}
        </button>
      </div>
    </div>
  );
};

export default SearchFormCollapseView;

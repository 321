import { localStorageClear, localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';

import type { User } from '@wego/alexis/types/helpers/user';

import { USER_LOCAL_STORAGE_KEY } from '../../constants/localStorage';
import { UserActionTypes } from '../actions/types';

const user = localStorageGet<User>(USER_LOCAL_STORAGE_KEY);

const initialState: User | null = user;

export default function (
  state: User | null = initialState,
  action: { type: UserActionTypes; payload: User },
): User | null {
  switch (action.type) {
    case UserActionTypes.ChangeUser: {
      const currentUser = action.payload;
      localStorageSave(USER_LOCAL_STORAGE_KEY, currentUser);
      return currentUser;
    }
    case UserActionTypes.ResetUser: {
      localStorageClear(USER_LOCAL_STORAGE_KEY);
      return null;
    }
    default:
      return state;
  }
}

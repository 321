import { getSiteConfigByHostname } from '@alexis/helpers/site';
import cookies from 'js-cookie';

import { locales } from '@wego/alexis/helpers/site';

import { WEGO_LOCALE_COOKIE_KEY } from '@constants/cookie';

import { isGoogleBot } from './bots';

const isLocale = (str: string) => locales.find((locale) => locale.code === str);

export const isRtl = (locale: string) => /(?:fa|ar)/i.test(locale);

export const getLocaleParam = (pathname = window.location.pathname) => {
  const firstSegment = pathname?.split('/')[1];

  return isLocale(firstSegment) ? firstSegment : undefined;
};

export const getLocaleSearchParam = (
  searchParams = new URLSearchParams(window.location.search),
) => {
  const ulangSearchParam = searchParams.get('ulang');

  if (ulangSearchParam && isLocale(ulangSearchParam)) return ulangSearchParam;
};

const getLocaleCookie = () => {
  const localeCookie = cookies.get(WEGO_LOCALE_COOKIE_KEY) || '';

  return isLocale(localeCookie) ? localeCookie : undefined;
};

export const getPreferredLocale = (pathname = window.location.pathname) => {
  const localeCookie = getLocaleCookie();
  const currentSite = getSiteConfigByHostname(window.location.hostname);

  if (localeCookie) {
    return localeCookie;
  }

  if (
    currentSite.countryCode === 'AE' &&
    !isGoogleBot &&
    !/^ar\b/.test(navigator.language) &&
    !['/hotels', '/flights'].includes(pathname)
  ) {
    return 'en';
  }
};

export const getInitialLocale = () => {
  const localeParam = getLocaleParam();
  const localeSearchParam = getLocaleSearchParam();
  const preferredLocale = getPreferredLocale();
  const currentSite = getSiteConfigByHostname(window.location.hostname);

  const urlLocale = localeSearchParam || localeParam;

  if (urlLocale) return urlLocale;

  return preferredLocale || currentSite.defaultLocale;
};

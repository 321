export const flightsPaths: Array<string> = [
  'flights',
  'fluge',
  'vuelos',
  'vols',
  'tiket-pesawat',
  'voli',
  'penerbangan',
  'vluchten',
  'loty',
  'voos',
  'flyg',
  've-may-bay',
];

export const flightSearchResultsPaths: Array<string> = [
  'searches/:leg/:cabinClass/:adultCount::childCount::infantCount',
];

const hotelLocalizedPaths = [
  'hotels',
  'hoteles',
  'hotel',
  'hotele',
  'hoteis',
  'hotell',
  'khach-san',
];
const hotelsHandoffPaths = ['hotels-handoff'];

export const hotelsPaths: Array<string> = [...hotelLocalizedPaths, ...hotelsHandoffPaths];

export const preferencesPaths: Array<string> = ['preferences'];

export const hotelSearchResultsPaths: Array<string> = [
  'searches/:cityCode/:checkInDate/:checkOutDate',
  'searches/:cityCode/:checkInDate/:checkOutDate/d:districtId',
  'searches/q:regionId/:checkInDate/:checkOutDate',
  /* Legacy */
  'searches/:cityCode/:checkInDate/:checkOutDate/:roomCount/:guestCount',
  'searches/q:regionId/:checkInDate/:checkOutDate/:roomCount/:guestCount',
];

export const hotelDetailsPaths: Array<string> = [
  'searches/:cityCode/:checkInDate/:checkOutDate/:hotelId',
  'searches/q:regionId/:checkInDate/:checkOutDate/:hotelId',
  /* Legacy */
  'searches/:cityCode/:checkInDate/:checkOutDate/:roomCount/:guestCount/:hotelId',
  'searches/q:regionId/:checkInDate/:checkOutDate/:roomCount/:guestCount/:hotelId',
];

export const hotelBookingPaths: Array<string> = ['booking/checkout', 'booking/confirm'];

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useLocaleParam = () => {
  const { pathname } = useLocation();

  const localeParam = useMemo<string>(() => {
    const localeParam = pathname.split('/')[1];

    if (/^(?:[a-zA-Z]{2}|[a-zA-Z]{2}-[a-zA-Z]{2})$/i.test(localeParam)) {
      return localeParam;
    }
    return '';
  }, [pathname]);

  return localeParam;
};

export default useLocaleParam;

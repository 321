import { TravelThemeCitiesSearchParams, getTravelThemeCities } from '@apis/places';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

type GetTravelThemeCitiesParams = {
  travelThemeId: number;
  searchParams: TravelThemeCitiesSearchParams;
};

const getTravelThemeCitiesKey = 'travel-theme-cities';

export const useGetTravelThemeCities = <TResult = Array<PlacesTravelThemeCity>>(
  params: GetTravelThemeCitiesParams,
  options?: UseAxiosQueryOptions<Array<PlacesTravelThemeCity>, TResult>,
) => {
  const apiBaseUrl = API_BASE_URL;

  return useAxiosQuery({
    queryKey: [
      getTravelThemeCitiesKey,
      {
        ...params,
      },
    ],
    queryFn: () => getTravelThemeCities(apiBaseUrl, params.travelThemeId, params.searchParams),
    ...options,
  });
};

import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { translateText } from '@wego/alexis/helpers/translation';

import { getLocaleState, getTranslationsState } from '@redux/selectors';

import { Translations } from '@wegoTypes/translations';

export function useHotelMetaData() {
  const locale: string = useSelector(getLocaleState);
  const translations: Translations = useSelector(getTranslationsState);

  const getHostNameWithoutWWW = useCallback<(hostname: string) => string>((hostname: string) => {
    const hostNameWithoutWWW = hostname.replace('www.', '');

    return hostNameWithoutWWW.replace(/^./, hostNameWithoutWWW[0].toUpperCase());
  }, []);

  const title = useMemo<string>(() => {
    return translateText(
      translations.hotel_title,
      locale,
      getHostNameWithoutWWW(window.location.hostname),
    );
  }, [locale, translations, getHostNameWithoutWWW]);

  const metaDescription = useMemo<string>(() => {
    return translateText(
      translations.hotel_des,
      locale,
      getHostNameWithoutWWW(window.location.hostname),
    );
  }, [locale, translations, getHostNameWithoutWWW]);

  return { title, description: metaDescription };
}

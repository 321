import React, { PropsWithChildren } from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import styles from './GuestRoomSelector.module.scss';

interface GuestRoomSelectorProps {
  className?: string;
  isFocus: boolean;
  onFocus(): void;
  placeholder: string;
  style?: React.CSSProperties;
  value?: string;
}

const GuestRoomSelector: React.FC<PropsWithChildren<GuestRoomSelectorProps>> = ({
  children,
  className,
  isFocus,
  onFocus,
  placeholder,
  style,
  value,
}): JSX.Element => {
  return (
    <div
      className={clsx(styles.container, className, isFocus && styles.focus)}
      onClick={(e) => e.stopPropagation()}
      style={style}
    >
      <div className={styles.inputContainer} onClick={onFocus}>
        <div className={styles.input} data-testid='guest-room-input-value'>
          {value}
        </div>
        <label>{placeholder}</label>

        <i className={clsx(styles.triangle, isFocus ? styles.up : styles.down)}></i>
      </div>

      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default GuestRoomSelector;

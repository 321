import React from 'react';
import { useSelector } from 'react-redux';

import Button from '@components/Button';

import ContentErrorIcon from '@icons/unable_load_content.svg';

import { getTranslationsState } from '@redux/selectors';

import styles from './ContentError.module.scss';

type ContentErrorProps = {
  isErrorReload: boolean;
  resetErrorBoundary: () => void;
};

const ContentError = ({ isErrorReload, resetErrorBoundary }: ContentErrorProps) => {
  const translations = useSelector(getTranslationsState);

  const onErrorRetry = () => {
    if (isErrorReload) {
      window.location.reload();
    } else {
      resetErrorBoundary();
    }
  };

  return (
    <div className={styles.contentError}>
      <ContentErrorIcon className={styles.icon} />
      <div className={styles.content}>
        <h3>{translations.error_found_title_msg}</h3>
        <p>{translations.error_found_detail_msg}</p>
        <Button variant='outline' colorScheme='grey' onClick={onErrorRetry}>
          {translations.click_to_retry}
        </Button>
      </div>
    </div>
  );
};

export default ContentError;

import Checkbox from '@alexis/components/Checkbox';
import { dateDisplayFormat, dateApiFormat, getLocalDate } from '@alexis/helpers/date';
import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import { appendSearchParams } from '@alexis/helpers/searchParam';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { cloneDeep } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateText } from '@wego/alexis/helpers/translation';
import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { getHotelComparisonProviders } from '@apis/compare';
import { getCityByCode, getHotelsPopularCities, getNearestPlaces, getPlaces } from '@apis/places';

import DatePicker from '@components/DatePicker';
import InputSelector from '@components/InputSelector';

import { HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY } from '@constants/localStorage';

import {
  convertGuestRoomsToSearchParam,
  getGuestsCount,
  getHotelComparisonProviderLabelText,
  getHotelSortTypeSearchParamValue,
  getPlaceLabel,
} from '@helpers/hotel';
import { handleNextItem, handlePreviousItem } from '@helpers/hotels';
import { hotelsRoute } from '@helpers/routeTranslation';

import { resetHotelMetaSearch } from '@redux/actions/hotelMetaSearchActions';
import {
  getCurrencyState,
  getCurrentSiteState,
  getIsRtlState,
  getLocaleState,
  getTranslationsState,
} from '@redux/selectors';

import { Currency } from '@wegoTypes/currency';
import { GuestRoom } from '@wegoTypes/hotels/guestRoom';
import { HotelComparisionProvider } from '@wegoTypes/hotels/hotelComparisonProvider';
import { HotelSearch } from '@wegoTypes/hotels/hotelSearch';
import { HotelSortType } from '@wegoTypes/hotels/hotelSortType';
import { PlacesHotelsPopularCity } from '@wegoTypes/hotels/placesHotelsPopularCity';
import { Translations } from '@wegoTypes/translations';

import GuestRoomPicker from '../GuestRoomPicker';
import GuestRoomSelector from '../GuestRoomSelector';
import HotelDateSelector from '../HotelDateSelector';
import styles from './HotelSearchForm.module.scss';

interface HotelSearchFormProps {
  geolocationCoordinates: GeolocationCoordinates | undefined;
  localeParam: string | undefined;
  nearestCityByIP: Place | undefined;
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
}

const HotelSearchForm: React.FC<HotelSearchFormProps> = ({
  geolocationCoordinates,
  localeParam,
  nearestCityByIP,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
}): JSX.Element => {
  const apiBaseUrl: string = API_BASE_URL;
  const currency: Currency = useSelector(getCurrencyState);
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const isRtl: boolean = useSelector(getIsRtlState);
  const locale: string = useSelector(getLocaleState);
  const translations: Translations = useSelector(getTranslationsState);

  const placePickerRef = useRef<HTMLInputElement>(null);
  const keyPressRef = useRef(false);

  const [isLoadingPopularCities, setIsLoadingPopularCities] = useState(false);

  const [destinationSearchQuery, setDestinationSearchQuery] = useState<string>('');
  const [destinationSearchQueryPlaces, setDestinationSearchQueryPlaces] = useState<Array<Place>>(
    [],
  );
  const [destinationSearchActiveItemIndex, setDestinationSearchActiveItemIndex] =
    useState<number>(0);

  const [placePickerPlaceholder, setPlacePickerPlaceholder] = useState<string>('');

  const [nearestCityByGeolocation, setNearestCityByGeolocation] = useState<Place>();
  const [popularCities, setPopularCities] = useState<Array<PlacesHotelsPopularCity>>([]);

  const [selectedDate, setSelectedDate] = useState<Date>(); // State used by date picker component

  const [selectedPlace, setSelectedPlace] = useState<Place | PlacesHotelsPopularCity>();
  const [checkInDateMilliseconds, setCheckInDateMilliseconds] = useState<number>();
  const [checkOutDateMilliseconds, setCheckOutDateMilliseconds] = useState<number>();
  const [guestRooms, setGuestRooms] = useState<Array<GuestRoom>>([
    { adultsCount: 2, childrenCount: 0, childrenAges: [] },
  ]);

  const [isFreeCancellation, setIsFreeCancellation] = useState<boolean>(false);

  const [hotelComparisionProviders, setHotelComparisionProviders] = useState<
    Array<HotelComparisionProvider>
  >([]);

  const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const limitFromMilliseconds = yesterdayDate.setHours(0, 0, 0, 0);
  const limitTillDate = new Date();
  limitTillDate.setHours(0, 0, 0, 0);
  const limitToMilliseconds = limitTillDate.setMonth(limitTillDate.getMonth() + 11);

  const dispatch = useDispatch();

  const { search, state } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const openCalendarSearchParam = searchParams.get('open_calendar');
  const tsCodeSearchParam = searchParams.get('ts_code');
  const bowOnlyParam = searchParams.get('bow_only');
  const locationCodeParam = searchParams.get('location_code');
  const checkInParam = searchParams.get('check_in');
  const checkOutParam = searchParams.get('check_out');

  const hotelRecentSearch = useMemo<HotelSearch | null>(() => {
    return localStorageGet<HotelSearch>(HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY);
  }, []);

  //#region Pickers focus state
  const [isPlacePickerFocus, setIsPlacePickerFocus] = useState<boolean>(false);

  const [isDatePickerFromFocus, setIsDatePickerFromFocus] = useState<boolean>(false);
  const [isDatePickerToFocus, setIsDatePickerToFocus] = useState<boolean>(false);

  const [isGuestRoomFocus, setIsGuestRoomFocus] = useState<boolean>(false);
  //#endregion

  //#region Pickers validated and has error state
  const [isDestinationSearchQueryValidated, setIsDestinationSearchQueryValidated] =
    useState<boolean>(false);
  const [destinationSearchQueryHasError, setDestinationSearchQueryHasError] =
    useState<boolean>(false);

  const [isCheckInDateValidated, setIsCheckInDateValidated] = useState<boolean>(false);
  const [checkInDateHasError, setCheckInDateHasError] = useState<boolean>(false);

  const [isCheckOutDateValidated, setIsCheckOutDateValidated] = useState<boolean>(false);
  const [checkOutDateHasError, setCheckOutDateHasError] = useState<boolean>(false);
  //#endregion

  // Set all picker focus to false if body is clicked
  useEffect(() => {
    if (isPlacePickerFocus || isDatePickerFromFocus || isDatePickerToFocus || isGuestRoomFocus) {
      const handleBodyOnClick = (): void => {
        if (isPlacePickerFocus) {
          setIsPlacePickerFocus(false);
        }

        if (isDatePickerFromFocus) {
          setIsDatePickerFromFocus(false);
        }

        if (isDatePickerToFocus) {
          setIsDatePickerToFocus(false);
        }

        if (isGuestRoomFocus) {
          setIsGuestRoomFocus(false);
        }
      };

      document.body.addEventListener('click', handleBodyOnClick);

      return () => {
        document.body.removeEventListener('click', handleBodyOnClick);
      };
    }
  }, [isPlacePickerFocus, isDatePickerFromFocus, isDatePickerToFocus, isGuestRoomFocus]);

  const setDefaultDate = useCallback(() => {
    if (checkInParam && checkOutParam) {
      const checkInDateFormatted = dateApiFormat(new Date(checkInParam));
      const checkOutDateFormatted = dateApiFormat(new Date(checkOutParam));
      setCheckInDateMilliseconds(getLocalDate(checkInDateFormatted).getTime());
      setCheckOutDateMilliseconds(getLocalDate(checkOutDateFormatted).getTime());
      return;
    }

    const today = dateApiFormat(new Date());
    const tomorrow = dateApiFormat(new Date(new Date().setDate(new Date().getDate() + 1)));

    setCheckInDateMilliseconds(getLocalDate(today).getTime());
    setCheckOutDateMilliseconds(getLocalDate(tomorrow).getTime());
  }, []);

  useEffect(() => {
    if (!locationCodeParam) return;

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const fetchCityByCode = async () => {
      const cityResponse = await getCityByCode({
        locale,
        cancelToken: cancelTokenSource.token,
        cityCode: locationCodeParam,
      });

      if (cityResponse?.city) {
        setDefaultDate();
        setSelectedPlace(cityResponse.city);
        return;
      }

      setDefaultDate();
      setSelectedPlace(nearestCityByIP);
    };

    fetchCityByCode();

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [locationCodeParam, locale]);

  // Fill up form with nearest city by IP as selected place or from hotel recent search
  useEffect(() => {
    if (locationCodeParam) return;

    if (!!hotelRecentSearch && hotelRecentSearch.checkInDateMilliseconds >= limitFromMilliseconds) {
      setSelectedPlace(hotelRecentSearch.place);
      setCheckInDateMilliseconds(hotelRecentSearch.checkInDateMilliseconds);
      setCheckOutDateMilliseconds(hotelRecentSearch.checkOutDateMilliseconds);
      setGuestRooms(hotelRecentSearch.guestRooms);
      return;
    }

    setDefaultDate();
    setSelectedPlace(nearestCityByIP);
  }, [hotelRecentSearch, nearestCityByIP, locationCodeParam]);

  // Get place based on destinationSearchQuery
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    let getPlaceTimeout: NodeJS.Timeout | undefined;

    if (!!destinationSearchQuery && isPlacePickerFocus && !selectedPlace) {
      getPlaceTimeout = setTimeout(async (): Promise<void> => {
        try {
          const places = await getPlaces(
            locale,
            currentSite.countryCode,
            destinationSearchQuery,
            true,
            true,
            true,
            true,
            false,
            false,
            cancelTokenSource.token,
            1,
          );
          setDestinationSearchQueryPlaces(places);
        } catch (error) {
          setDestinationSearchQueryPlaces([]);
        }
      }, 150);
    } else {
      cancelTokenSource.cancel('Empty search query.');
      setDestinationSearchQueryPlaces([]);
    }

    return () => {
      cancelTokenSource.cancel('Component unmount.');

      if (!!getPlaceTimeout) {
        clearTimeout(getPlaceTimeout);
      }
    };
  }, [destinationSearchQuery, locale, isPlacePickerFocus, selectedPlace]);

  // Get popular cities
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const initPopularCities = async (
      apiBaseUrl: string,
      locale: string,
      currentSiteCode: string,
      cancelToken: CancelToken,
    ): Promise<void> => {
      try {
        setIsLoadingPopularCities(true);

        const popularCities = await getHotelsPopularCities(
          apiBaseUrl,
          locale,
          currentSiteCode,
          cancelToken,
        );

        setPopularCities(popularCities);
      } catch (error) {
        setPopularCities([]);
      }

      setIsLoadingPopularCities(false);
    };

    initPopularCities(apiBaseUrl, locale, currentSite.countryCode, cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [locale]);

  // Get nearest city based on geolocation
  useEffect(() => {
    if (!!geolocationCoordinates) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const initNearestCity = async (
        apiBaseUrl: string,
        geolocationCoordinates: GeolocationCoordinates,
        locale: string,
        cancelToken: CancelToken,
      ): Promise<void> => {
        try {
          const nearestCities = await getNearestPlaces(
            locale,
            true,
            cancelToken,
            geolocationCoordinates.latitude,
            geolocationCoordinates.longitude,
          );

          setNearestCityByGeolocation(nearestCities[0]);
        } catch (error) {
          setNearestCityByGeolocation(undefined);
        }
      };

      initNearestCity(apiBaseUrl, geolocationCoordinates, locale, cancelTokenSource.token);

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    }
  }, [geolocationCoordinates, locale]);

  // Get hotel comparison providers
  useEffect(() => {
    if (
      !!selectedPlace &&
      !!selectedPlace.cityCode &&
      !!checkInDateMilliseconds &&
      !(!!tsCodeSearchParam && /^(?:e056f)$/i.test(tsCodeSearchParam)) &&
      !bowOnlyParam
    ) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const initCompareUnits = async (
        apiBaseUrl: string,
        locale: string,
        selectedPlace: Place | PlacesHotelsPopularCity,
        checkInDateMilliseconds: number,
        cancelToken: CancelToken,
      ): Promise<void> => {
        try {
          const hotelComparisionProviders = await getHotelComparisonProviders(
            apiBaseUrl,
            currentSite.countryCode,
            locale,
            selectedPlace.cityCode!,
            selectedPlace.countryCode,
            dateApiFormat(new Date(checkInDateMilliseconds)),
            cancelToken,
          );

          const localStorageHotelComparisionProviders = localStorageGet<{ [key: string]: boolean }>(
            'hotelComparisionProviders',
          );

          if (!!localStorageHotelComparisionProviders) {
            hotelComparisionProviders.forEach((hotelComparisionProvider) => {
              const localStorageHotelComparisionProvider =
                localStorageHotelComparisionProviders[hotelComparisionProvider.provider.code];

              if (localStorageHotelComparisionProvider !== undefined) {
                hotelComparisionProvider.preChecked = localStorageHotelComparisionProvider;
              }
            });
          }

          setHotelComparisionProviders(hotelComparisionProviders);
        } catch (error) {
          setHotelComparisionProviders([]);
        }
      };

      initCompareUnits(
        apiBaseUrl,
        locale,
        selectedPlace,
        checkInDateMilliseconds,
        cancelTokenSource.token,
      );

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    } else {
      setHotelComparisionProviders([]);
    }
  }, [selectedPlace, checkInDateMilliseconds, locale]);

  //#region Place picker events and helper functions
  const handleOnPlacePickerChange = useCallback<(value: string) => void>((value: string) => {
    setIsDestinationSearchQueryValidated(false);
    setDestinationSearchQueryHasError(false);
    setDestinationSearchQuery(value);
    setSelectedPlace(undefined);
  }, []);

  const handleOnPlacePickerClear = useCallback(() => {
    setDestinationSearchQuery('');
    setSelectedPlace(undefined);

    placePickerRef.current!.focus();
  }, []);

  const handleOnPlacePickerFocus = useCallback(() => {
    setIsDatePickerFromFocus(false);
    setIsDatePickerToFocus(false);
    setIsGuestRoomFocus(false);

    setIsPlacePickerFocus(true);

    placePickerRef.current!.select();
  }, []);

  const handleOnPlacePickerKeyDown = useCallback<
    (event: React.KeyboardEvent<HTMLInputElement>) => void
  >(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Prevent multiple calls
      if (keyPressRef.current) return;
      keyPressRef.current = true;

      if (event.key === 'Enter') {
        event.preventDefault();

        if (
          !!event.currentTarget.value &&
          (destinationSearchQueryPlaces.length > 0 || !!nearestCityByGeolocation)
        ) {
          setSelectedPlace(
            destinationSearchQueryPlaces[destinationSearchActiveItemIndex] ||
              nearestCityByGeolocation,
          );

          setIsPlacePickerFocus(false);
          placePickerRef.current!.blur();

          if (!checkInDateMilliseconds) {
            setIsDatePickerFromFocus(true);
          }

          if (!!checkInDateMilliseconds && !checkOutDateMilliseconds) {
            setIsDatePickerToFocus(true);
          }
        }

        if (!event.currentTarget.value && !!nearestCityByGeolocation) {
          setSelectedPlace(nearestCityByGeolocation);

          setIsPlacePickerFocus(false);
          placePickerRef.current!.blur();

          if (!checkInDateMilliseconds) {
            setIsDatePickerFromFocus(true);
          }

          if (!!checkInDateMilliseconds && !checkOutDateMilliseconds) {
            setIsDatePickerToFocus(true);
          }
        }

        setDestinationSearchActiveItemIndex(0);
      }

      if (event.key === 'Tab') {
        setIsPlacePickerFocus(false);

        setIsDatePickerFromFocus(true);
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();

        handlePreviousItem(
          destinationSearchActiveItemIndex,
          setDestinationSearchActiveItemIndex,
          'destinationSearchQueryList',
          'destinationItem',
        );
      }
      if (event.key === 'ArrowDown') {
        event.preventDefault();

        handleNextItem(
          destinationSearchActiveItemIndex,
          setDestinationSearchActiveItemIndex,
          destinationSearchQueryPlaces.length,
          'destinationSearchQueryList',
          'destinationItem',
        );
      }

      requestAnimationFrame(() => (keyPressRef.current = false));
    },
    [
      placePickerRef,
      destinationSearchQueryPlaces,
      nearestCityByGeolocation,
      checkInDateMilliseconds,
      checkOutDateMilliseconds,
      handlePreviousItem,
      handleNextItem,
      destinationSearchActiveItemIndex,
    ],
  );

  const handleOnPlaceSelected = useCallback<(place: Place | PlacesHotelsPopularCity) => void>(
    (place: Place | PlacesHotelsPopularCity) => {
      setSelectedPlace(place);

      setIsPlacePickerFocus(false);
    },
    [],
  );

  const getPlaceDisplay = useCallback<(place: Place) => JSX.Element>(
    (place: Place) => {
      return (
        <>
          {place.type === 'city' ? <i className={clsx(styles.icon, styles.cityGrey)}></i> : null}
          {place.type === 'region' ? (
            <i className={clsx(styles.icon, styles.regionGrey)}></i>
          ) : null}
          {place.type === 'district' ? (
            <i className={clsx(styles.icon, styles.districtGrey)}></i>
          ) : null}
          {place.type === 'hotel' ? <i className={clsx(styles.icon, styles.hotelGrey)}></i> : null}

          <div className={styles.labelAndHotelCount}>
            <div className={styles.label}>{getPlaceLabel(place)}</div>

            {place.type !== 'hotel' ? (
              <div className={styles.hotelCount}>
                {translateText(translations.hotels_count, locale, place.hotelCount!)}
              </div>
            ) : null}
          </div>
        </>
      );
    },
    [translations],
  );

  useEffect(() => {
    if (isPlacePickerFocus) {
      setPlacePickerPlaceholder(translations.search_by as string);
    } else {
      setPlacePickerPlaceholder(translations.destination as string);
    }
  }, [isPlacePickerFocus, translations]);

  useEffect(() => {
    if (!!selectedPlace) {
      setDestinationSearchQuery(getPlaceLabel(selectedPlace));
    }
    setIsDestinationSearchQueryValidated(false);
    setDestinationSearchQueryHasError(false);
  }, [selectedPlace]);
  //#endregion

  //#region Date picker events and helper functions
  const handleOnDatePickerFromFocus = useCallback(() => {
    setIsPlacePickerFocus(false);
    setIsGuestRoomFocus(false);

    setIsDatePickerFromFocus(true);
    setIsDatePickerToFocus(false);
  }, []);

  const handleOnDatePickerToFocus = useCallback(() => {
    setIsPlacePickerFocus(false);
    setIsGuestRoomFocus(false);

    if (!checkInDateMilliseconds) {
      setIsDatePickerFromFocus(true);
      setIsDatePickerToFocus(false);
    } else {
      setIsDatePickerToFocus(true);
      setIsDatePickerFromFocus(false);
    }
  }, [checkInDateMilliseconds]);

  // Open calendar if open_calendar search param exist
  useEffect(() => {
    if (openCalendarSearchParam !== null) {
      const setDatePickerFocusTimeout: NodeJS.Timeout = setTimeout(() => {
        setIsDatePickerFromFocus(true);
        setIsDatePickerToFocus(false);
      }, 1500);

      return () => {
        clearTimeout(setDatePickerFocusTimeout);
      };
    }
  }, [openCalendarSearchParam]);

  useEffect(() => {
    if ((isDatePickerFromFocus || isDatePickerToFocus) && selectedDate) {
      const selectedDateMilliseconds = selectedDate.getTime();

      if (isDatePickerFromFocus) {
        setCheckInDateMilliseconds(selectedDateMilliseconds);

        if (!!checkOutDateMilliseconds && selectedDateMilliseconds > checkOutDateMilliseconds) {
          setCheckOutDateMilliseconds(undefined);
        }
        setIsCheckInDateValidated(false);
        setCheckInDateHasError(false);
        setIsDatePickerFromFocus(false);
        setIsDatePickerToFocus(true);
      } else if (isDatePickerToFocus) {
        if (!!checkInDateMilliseconds && checkInDateMilliseconds !== selectedDateMilliseconds) {
          setCheckOutDateMilliseconds(selectedDateMilliseconds);
          setIsCheckOutDateValidated(false);
          setCheckOutDateHasError(false);
          setIsDatePickerFromFocus(false);
          setIsDatePickerToFocus(false);
        }
      }

      setSelectedDate(undefined);
    }
  }, [isDatePickerFromFocus, isDatePickerToFocus, selectedDate]);
  //#endregion

  //#region Guest room picker events and helper functions
  const handleOnGuestRoomPickerFocus = useCallback(() => {
    setIsPlacePickerFocus(false);
    setIsDatePickerFromFocus(false);
    setIsDatePickerToFocus(false);

    setIsGuestRoomFocus(true);
  }, []);

  const guestRoomLabel = useCallback<(guestRooms: Array<GuestRoom>) => string | undefined>(
    (guestRooms: Array<GuestRoom>) => {
      const roomCount = guestRooms.length;
      if (roomCount > 0) {
        const guestCount = getGuestsCount(guestRooms);
        const childrenCount = guestRooms.reduce(
          (accumulator: number, currentValue: GuestRoom) =>
            accumulator + currentValue.childrenCount,
          0,
        );
        let guestLabel = translateText(translations.adults_count, locale, guestCount);

        if (childrenCount > 0) {
          guestLabel = translateText(translations.guest_count, locale, guestCount);
        }
        return translateText(
          translations.adult_in_room,
          locale,
          guestLabel,
          translateText(translations.room_count, locale, roomCount),
        );
      }
      return undefined;
    },
    [translations],
  );

  const handleOnAddRoom = useCallback(() => {
    setGuestRooms([...guestRooms, { adultsCount: 1, childrenCount: 0, childrenAges: [] }]);
  }, [guestRooms]);

  const handleOnRemoveRoom = useCallback<(roomIndex: number) => void>(
    (roomIndex: number) => {
      const cloneGuestRooms = [...guestRooms];

      cloneGuestRooms.splice(roomIndex, 1);

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );

  const handleOnAddGuest = useCallback<(type: 'adult' | 'child', roomIndex: number) => void>(
    (type: 'adult' | 'child', roomIndex: number) => {
      const cloneGuestRooms = [...guestRooms];

      const toBeUpdatedGuestRoom = cloneGuestRooms[roomIndex];

      if (type === 'adult') {
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          adultsCount: toBeUpdatedGuestRoom.adultsCount + 1,
        });
      } else {
        toBeUpdatedGuestRoom.childrenAges.push(12);
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          childrenCount: toBeUpdatedGuestRoom.childrenCount + 1,
        });
      }

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );

  const handleOnMinusGuest = useCallback<(type: 'adult' | 'child', roomIndex: number) => void>(
    (type: 'adult' | 'child', roomIndex: number) => {
      const cloneGuestRooms = [...guestRooms];

      const toBeUpdatedGuestRoom = cloneGuestRooms[roomIndex];

      if (type === 'adult') {
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          adultsCount: toBeUpdatedGuestRoom.adultsCount - 1,
        });
      } else {
        toBeUpdatedGuestRoom.childrenAges.pop();
        cloneGuestRooms.splice(roomIndex, 1, {
          ...toBeUpdatedGuestRoom,
          childrenCount: toBeUpdatedGuestRoom.childrenCount - 1,
        });
      }

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );

  const handleOnChildAgeChange = useCallback<
    (roomIndex: number, childAgeIndex: number, age: number) => void
  >(
    (roomIndex: number, childAgeIndex: number, age: number) => {
      const cloneGuestRooms = [...guestRooms];

      const toBeUpdatedGuestRoom = cloneGuestRooms[roomIndex];

      toBeUpdatedGuestRoom.childrenAges[childAgeIndex] = age;

      cloneGuestRooms.splice(roomIndex, 1, { ...toBeUpdatedGuestRoom });

      setGuestRooms(cloneGuestRooms);
    },
    [guestRooms],
  );
  //#endregion

  //#region Hotel comparision providers events and helper functions
  const handleHotelComparisonProviderToggle = useCallback<
    (code: string, isSelected: boolean) => void
  >(
    (code: string, isSelected: boolean) => {
      const cloneComparisionProviders =
        cloneDeep<Array<HotelComparisionProvider>>(hotelComparisionProviders);

      const comparisionProvider = cloneComparisionProviders.find(
        (cloneComparisionProvider) => cloneComparisionProvider.provider.code === code,
      );

      comparisionProvider!.preChecked = isSelected;

      const localStorageHotelComparisionProviders =
        localStorageGet<{ [key: string]: boolean }>('hotelComparisionProviders') || {};
      localStorageSave<{ [key: string]: boolean }>('hotelComparisionProviders', {
        ...localStorageHotelComparisionProviders,
        ...{ [code]: isSelected },
      });

      setHotelComparisionProviders(cloneComparisionProviders);
    },
    [hotelComparisionProviders],
  );
  //#endregion

  const removeParamsUponFormSubmit = (searchParams: URLSearchParams) => {
    searchParams.delete('location_code');
    searchParams.delete('check_in');
    searchParams.delete('check_out');
    searchParams.delete('open_calendar');
  };

  const handleFormSubmit = useCallback<(event: React.FormEvent<HTMLFormElement>) => void>(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      setIsDestinationSearchQueryValidated(true);
      setIsCheckInDateValidated(true);
      setIsCheckOutDateValidated(true);

      let hasError = false;

      if (!selectedPlace) {
        hasError = true;
        setDestinationSearchQueryHasError(true);
      }

      const yesterdayMilliseconds = yesterdayDate.setHours(0, 0, 0, 0);

      if (!checkInDateMilliseconds || checkInDateMilliseconds < yesterdayMilliseconds) {
        hasError = true;
        setCheckInDateHasError(true);
      }

      if (
        !checkOutDateMilliseconds ||
        (!!checkInDateMilliseconds && checkOutDateMilliseconds <= checkInDateMilliseconds)
      ) {
        hasError = true;
        setCheckOutDateHasError(true);
      }

      if (!hasError && !!selectedPlace && !!checkInDateMilliseconds && !!checkOutDateMilliseconds) {
        const checkInDate: string = dateApiFormat(new Date(checkInDateMilliseconds));
        const checkOutDate: string = dateApiFormat(new Date(checkOutDateMilliseconds));

        const searchParams = new URLSearchParams(search);
        removeParamsUponFormSubmit(searchParams);

        const newGuestsSearchParam = convertGuestRoomsToSearchParam(guestRooms);

        searchParams.append('guests', newGuestsSearchParam);

        if (selectedPlace.type !== 'hotel') {
          searchParams.append('sort', getHotelSortTypeSearchParamValue(HotelSortType.Recommended));
          searchParams.append('order', 'desc');
        }

        if (isFreeCancellation) {
          searchParams.append('free_amenities', '2');
        }

        let searchResultPathname = '';

        switch (selectedPlace.type) {
          case 'city':
            searchResultPathname = `${hotelsRoute(currentSite, localeParam)}/searches/${
              selectedPlace.cityCode
            }/${checkInDate}/${checkOutDate}`;
            break;
          case 'district':
            searchParams.append('districts', selectedPlace.districtId!.toString());
            searchResultPathname = `${hotelsRoute(currentSite, localeParam)}/searches/${
              selectedPlace.cityCode
            }/${checkInDate}/${checkOutDate}/d${selectedPlace.districtId}`;
            break;
          case 'region':
            searchResultPathname = `${hotelsRoute(currentSite, localeParam)}/searches/q${
              selectedPlace.regionId
            }/${checkInDate}/${checkOutDate}`;
            break;
          case 'hotel':
            searchResultPathname = `${hotelsRoute(currentSite, localeParam)}/searches/${
              selectedPlace.cityCode
            }/${checkInDate}/${checkOutDate}/${selectedPlace.id}`;
            break;
        }

        dispatch(resetHotelMetaSearch());

        localStorageSave<HotelSearch>(HOTEL_RECENT_SEARCH_LOCAL_STORAGE_KEY, {
          place: selectedPlace,
          checkInDateMilliseconds,
          checkOutDateMilliseconds,
          guestRooms,
        });

        const updatedSearchParams = searchParams.toString();

        if (hotelComparisionProviders.length > 0 && hotelComparisionProviders[0].preChecked) {
          const searchParams = {
            currency_code: currency.code,
            check_in: checkInDate,
            check_out: checkOutDate,
            rooms_count: guestRooms.length,
            guests_count: getGuestsCount(guestRooms),
            guests: newGuestsSearchParam,
            wc: wegoAnalyticsClientId,
            ws: wegoAnalyticsClientSessionId,
          };

          const handoffUrl = appendSearchParams(
            hotelComparisionProviders[0].handoffUrl,
            searchParams,
          );

          // setTimeout is to prevent popup blocker
          setTimeout(() => {
            window.location.href = handoffUrl;
          }, 1);

          window.open(
            `${window.location.origin}${searchResultPathname}${
              !!updatedSearchParams ? `?${updatedSearchParams}` : ''
            }`,
            '_blank',
          );
        } else {
          navigate(
            { pathname: searchResultPathname.toLowerCase(), search: updatedSearchParams },
            { state },
          );
        }
      }
    },
    [
      selectedPlace,
      checkInDateMilliseconds,
      checkOutDateMilliseconds,
      guestRooms,
      isFreeCancellation,
      hotelComparisionProviders,
      search,
      state,
      localeParam,
      currency,
      wegoAnalyticsClientId,
      wegoAnalyticsClientSessionId,
    ],
  );

  return (
    <form
      data-testid='hotel-search-form'
      data-is-loading={isLoadingPopularCities}
      className={clsx(styles.container)}
      onSubmit={handleFormSubmit}
      noValidate={true}
    >
      <div className={styles.label}>{translations.where_to_stay}</div>
      <div className={styles.selectors}>
        <div className={styles.placePickerContainer}>
          <InputSelector
            className={styles.inputSelector}
            hasError={destinationSearchQueryHasError}
            id='destinationSearchQuery'
            isFocus={isPlacePickerFocus}
            listContainerId='destinationSearchQueryList'
            isValidated={isDestinationSearchQueryValidated}
            onChange={handleOnPlacePickerChange}
            onClear={handleOnPlacePickerClear}
            onFocus={handleOnPlacePickerFocus}
            onKeyDown={handleOnPlacePickerKeyDown}
            placeholder={placePickerPlaceholder}
            ref={placePickerRef}
            value={destinationSearchQuery}
          >
            {/* Nearby and Popular Cities */}
            {destinationSearchQueryPlaces.length === 0 ? (
              <>
                {!!nearestCityByGeolocation ? (
                  <>
                    <div className={clsx(styles.category, styles.with8TopMargin)}>
                      {translations.nearby}
                    </div>

                    <div
                      className={clsx(styles.place, styles.with8TopMargin)}
                      onClick={() => handleOnPlaceSelected(nearestCityByGeolocation)}
                    >
                      {getPlaceDisplay(nearestCityByGeolocation)}
                    </div>
                  </>
                ) : null}

                {popularCities.length > 0 ? (
                  <>
                    <div className={clsx(styles.category, styles.with16TopMargin)}>
                      {translations.popular_cities}
                    </div>

                    <div className={styles.popularCities}>
                      {popularCities.map((popularCity) => (
                        <div
                          key={popularCity.code}
                          data-testid='popular-city'
                          className={styles.popularCity}
                          onClick={() => handleOnPlaceSelected(popularCity)}
                        >
                          <div>{popularCity.cityName}</div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            ) : null}

            {/* Place */}
            {!!destinationSearchQuery && destinationSearchQueryPlaces.length > 0 ? (
              <>
                {destinationSearchQueryPlaces.map((destinationSearchQueryPlace, index) => (
                  <div
                    key={index}
                    id={`destinationItem-${index}`}
                    className={clsx(
                      styles.place,
                      destinationSearchActiveItemIndex === index && styles.active,
                    )}
                    onClick={() => handleOnPlaceSelected(destinationSearchQueryPlace)}
                  >
                    {getPlaceDisplay(destinationSearchQueryPlace)}
                  </div>
                ))}
              </>
            ) : null}
          </InputSelector>
        </div>

        <div className={styles.datePickerContainer}>
          <HotelDateSelector
            className={styles.hotelDateSelector}
            fromHasError={checkInDateHasError}
            fromPlaceholder={translations.check_in as string}
            fromValue={
              !!checkInDateMilliseconds
                ? dateDisplayFormat(
                    new Date(checkInDateMilliseconds),
                    translations.short_months as Array<string>,
                    translations.short_weekdays as Array<string>,
                    locale === 'fa',
                    5,
                  )
                : ''
            }
            isFromFocus={isDatePickerFromFocus}
            isFromValidated={isCheckInDateValidated}
            isRange={true}
            isToFocus={isDatePickerToFocus}
            isToValidated={isCheckOutDateValidated}
            onFromFocus={handleOnDatePickerFromFocus}
            onToFocus={handleOnDatePickerToFocus}
            toHasError={checkOutDateHasError}
            toPlaceholder={translations.check_out as string}
            toValue={
              !!checkOutDateMilliseconds
                ? dateDisplayFormat(
                    new Date(checkOutDateMilliseconds),
                    translations.short_months as Array<string>,
                    translations.short_weekdays as Array<string>,
                    locale === 'fa',
                    5,
                  )
                : ''
            }
          >
            <DatePicker
              firstDayOfWeek={
                /^(?:BD|BH|DZ|EG|JO|KW|OM|QA|SA|TN)$/i.test(currentSite.countryCode) ? 7 : 1
              }
              isRtl={isRtl}
              limitFromMilliseconds={
                !!checkInDateMilliseconds && isDatePickerToFocus
                  ? checkInDateMilliseconds
                  : limitFromMilliseconds
              }
              limitTillMilliseconds={limitToMilliseconds}
              locale={locale}
              onDateSelected={setSelectedDate}
              selectedFromDateMilliseconds={checkInDateMilliseconds}
              selectedToDateMilliseconds={checkOutDateMilliseconds}
              translations={translations}
            />
          </HotelDateSelector>
        </div>

        <div className={styles.guestRoomContainer}>
          <GuestRoomSelector
            isFocus={isGuestRoomFocus}
            onFocus={handleOnGuestRoomPickerFocus}
            placeholder={translations.guest_rooms as string}
            value={guestRoomLabel(guestRooms)}
          >
            <GuestRoomPicker
              guestRooms={guestRooms}
              maximumGuestLimit={40}
              maximumRoomLimit={10}
              onAddRoom={handleOnAddRoom}
              onApply={() => setIsGuestRoomFocus(false)}
              onChildAgeChange={handleOnChildAgeChange}
              onMinus={handleOnMinusGuest}
              onPlus={handleOnAddGuest}
              onRemoveRoom={handleOnRemoveRoom}
            />
          </GuestRoomSelector>
        </div>
      </div>
      <div className={styles.filtersAndSearchButton}>
        <div className={styles.filters}>
          <div className={styles.filter} onClick={() => setIsFreeCancellation(!isFreeCancellation)}>
            <Checkbox className={styles.checkbox} isChecked={isFreeCancellation} />
            {translations.free_cancellation}
          </div>
        </div>

        <button type='submit'>{translations.search}</button>
      </div>

      {hotelComparisionProviders.length > 0 ? (
        <div className={styles.hotelComparisonProviders}>
          <div className={styles.label}>
            {getHotelComparisonProviderLabelText(hotelComparisionProviders, translations)}
          </div>

          {hotelComparisionProviders.map((hotelComparisionProvider) => (
            <div
              key={hotelComparisionProvider.provider.code}
              className={styles.hotelComparisionProvider}
              onClick={() =>
                handleHotelComparisonProviderToggle(
                  hotelComparisionProvider.provider.code,
                  !hotelComparisionProvider.preChecked,
                )
              }
            >
              <Checkbox
                className={styles.checkbox}
                isChecked={hotelComparisionProvider.preChecked}
              />
              {hotelComparisionProvider.provider.name}
            </div>
          ))}
        </div>
      ) : null}
    </form>
  );
};

export default HotelSearchForm;

// const isLocalhost = Boolean(
//   window.location.hostname === 'localhost' ||
//   // [::1] is the IPv6 localhost address.
//   window.location.hostname === '[::1]' ||
//   // 127.0.0.0/8 are considered localhost for IPv4.
//   window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
// );

export async function register(): Promise<void> {
  if ('serviceWorker' in navigator) {
    const { Workbox } = await import('workbox-window');

    const wb = new Workbox('/roxana-service-worker.js');

    const showSkipWaitingPrompt = async () => {
      wb.addEventListener('controlling', () => {
        window.location.reload();
      });

      wb.messageSkipWaiting();
    };

    wb.addEventListener('waiting', () => {
      showSkipWaitingPrompt();
    });

    await wb.register();
    document.dispatchEvent(new CustomEvent('registerServiceWorker'));
  }
}

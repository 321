import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useSearchParams } from 'react-router-dom';

import DeleteAccountPopup from '@components/DeleteAccountPopup';
import PreferencesLayout from '@components/preferences/PreferencesLayout';

interface PreferencesPathRouteProps {
  localeParam: string | undefined;
}

const PreferencesPathRoute: React.FC<PreferencesPathRouteProps> = ({
  localeParam,
}): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isDeleteAccountOpen = searchParams.get('modal') === 'delete-account';

  return (
    <>
      <Helmet>
        {/* // TODO: Update metadata */}
        {/* <title>{title}</title>
        <meta name='description' content={metaDescription} /> */}
        <link rel='canonical' href={`${window.location.origin}${window.location.pathname}`} />
      </Helmet>

      <PreferencesLayout localeParam={localeParam}>
        <Outlet />
      </PreferencesLayout>

      <DeleteAccountPopup
        isOpen={isDeleteAccountOpen}
        onClose={() => {
          searchParams.delete('modal');
          setSearchParams(searchParams, { replace: true });
        }}
      />
    </>
  );
};

export default PreferencesPathRoute;

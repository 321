import type { User } from '@wego/alexis/types/helpers/user';

import { UserActionTypes } from './types';

export const changeUser = (user: User): { type: UserActionTypes; payload: User } => ({
  type: UserActionTypes.ChangeUser,
  payload: user,
});

export const resetUser = (): { type: UserActionTypes; payload: null } => ({
  type: UserActionTypes.ResetUser,
  payload: null,
});

import { getSiteConfigByHostname } from '@alexis/helpers/site';

import { ExchangeRateActionTypes } from '../actions/types';

const currentSite = getSiteConfigByHostname(window.location.hostname);
const initialState: ExchangeRate = { code: currentSite.currencyCode, base: 'USD', rate: 1 };

export default function (
  state: ExchangeRate = initialState,
  action: { type: ExchangeRateActionTypes; payload: ExchangeRate },
): any {
  switch (action.type) {
    case ExchangeRateActionTypes.ChangeExchangeRate:
      return action.payload;
    default:
      return state;
  }
}

import { getInitialLocale } from '@helpers/locale';

import { TranslationsActionTypes } from '../actions/types';

const initialLocale = getInitialLocale();

const initialState: { [key: string]: string | Array<string> } = await import(
  `../../translations/${initialLocale}.json`
);

export default function (
  state: { [key: string]: string | Array<string> } = initialState,
  action: { type: TranslationsActionTypes; payload: { [key: string]: string | Array<string> } },
): { [key: string]: string | Array<string> } {
  switch (action.type) {
    case TranslationsActionTypes.ChangeTranslations:
      return action.payload;
    default:
      return state;
  }
}

import { useSelector } from 'react-redux';

import { getHomepage } from '@apis/places';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getCurrentSiteState, getLocaleState } from '@redux/selectors';

const getHomepageKey = 'homepage';

export const useGetHomepage = <TResult = Homepage>(
  options?: UseAxiosQueryOptions<Homepage, TResult>,
) => {
  const apiBaseUrl = API_BASE_URL;
  const locale = useSelector(getLocaleState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [getHomepageKey, { locale, currentSiteCode: currentSiteCode.countryCode }],
    queryFn: () => getHomepage(apiBaseUrl, locale, currentSiteCode.countryCode),
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  });
};

import { useSelector } from 'react-redux';

import { getCityPopularHotels } from '@apis/hotel';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getLocaleState, getCurrentSiteState, getCurrencyState } from '@redux/selectors';

import { HotelPopularHotel } from '@wegoTypes/hotels/hotelPopularHotel';

type GetPopularHotelsParams = {
  cityCode: string;
  perPageCount: number;
};

export const getPopularHotelsKey = 'popular-hotels';

export const useGetCityPopularHotels = <TResult = HotelPopularHotel[]>(
  params: GetPopularHotelsParams,
  options?: UseAxiosQueryOptions<HotelPopularHotel[], TResult>,
) => {
  const apiBaseUrl = API_BASE_URL;
  const locale = useSelector(getLocaleState);
  const currencyCode = useSelector(getCurrencyState);
  const currentSiteCode = useSelector(getCurrentSiteState);

  return useAxiosQuery({
    queryKey: [
      getPopularHotelsKey,
      {
        locale,
        currencyCode: currencyCode.code,
        currentSiteCode: currentSiteCode.countryCode,
        ...params,
      },
    ],
    queryFn: () =>
      getCityPopularHotels(
        apiBaseUrl,
        params.cityCode,
        locale,
        currencyCode.code,
        currentSiteCode.countryCode,
        params.perPageCount,
      ),
    ...options,
  });
};

import Spinner from '@alexis/components/Spinner';
import {
  facebookSignIn,
  googleOneTapSignUp,
  loadFacebookClientLibrary,
  wegoSignUp,
} from '@alexis/helpers/authentications';
import { toISOStringWithTimezone } from '@alexis/helpers/date';
import { genzoTrackActionEvent, genzoTrackPageView } from '@alexis/helpers/genzo';
import { generateCUID } from '@alexis/helpers/identity';
import type { SignUpProps } from 'props/signUpProps';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { clsx } from '@wego/alexis/helpers/clsx';
import { translateText } from '@wego/alexis/helpers/translation';
import Checkbox from '@wego/alexis/src/react/Checkbox/Checkbox';
import Input from '@wego/alexis/src/react/Input/Input';
import { CurrentSite } from '@wego/alexis/types/helpers/currentSite';

import { GlobalContext } from '@context/GlobalContext';

import { isGoogleBot } from '@helpers/bots';

import { getCurrentSiteState, getIsRtlState } from '@redux/selectors';

import BoltIcon from '../icons/bolt.svg';
import CloseIcon from '../icons/close.svg';
import DiscountIcon from '../icons/discount.svg';
import NotificationIcon from '../icons/notification.svg';
import styles from '../styles/components/SignUp.module.scss';

const SignUp: React.FC<SignUpProps> = ({
  apiBaseUrl,
  className,
  countryCode,
  domainBasedApiBaseUrl,
  facebookAppId,
  googleClientId,
  locale,
  onClose,
  onContinue,
  onLogin,
  onSendConfirmationEmail,
  onSignUp,
  translations,
  wegoClientId,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
}): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [isEmailValidated, setIsEmailValidated] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>();

  const [password, setPassword] = useState<string>('');
  const [isPasswordValidated, setIsPasswordValidated] = useState<boolean>(false);
  const [passwordHasError, setPasswordHasError] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>();
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [isRepeatPasswordValidated, setIsRepeatPasswordValidated] = useState<boolean>(false);
  const [repeatPasswordHasError, setRepeatPasswordHasError] = useState<boolean>(false);
  const [repeatPasswordErrorMessage, setRepeatPasswordErrorMessage] = useState<string>();

  const [optInReceivePromotionsFromWego, setOptInReceivePromotionsFromWego] =
    useState<boolean>(true);

  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { webEngageAnonymousId } = useContext(GlobalContext);
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const isRtl = useSelector(getIsRtlState);
  // Load facebook sdk
  loadFacebookClientLibrary(facebookAppId);

  const pageViewId = useMemo<string>(() => {
    return generateCUID();
  }, []);

  useEffect(() => {
    const triggerGoogleOneTapSignUp = async (): Promise<void> => {
      try {
        const user = await googleOneTapSignUp(domainBasedApiBaseUrl, googleClientId, wegoClientId);

        onSignUp(user);
      } catch (error) {}
    };

    triggerGoogleOneTapSignUp();
  }, []);

  useEffect(() => {
    if (isEmailValidated) {
      setIsEmailValidated(false);
    }

    if (emailHasError) {
      setEmailHasError(false);
    }

    if (!!emailErrorMessage) {
      setEmailErrorMessage(undefined);
    }
  }, [email]);

  useEffect(() => {
    if (isPasswordValidated) {
      setIsPasswordValidated(false);
    }

    if (passwordHasError) {
      setPasswordHasError(false);
    }

    if (!!passwordErrorMessage) {
      setPasswordErrorMessage(undefined);
    }
  }, [password]);

  useEffect(() => {
    if (isRepeatPasswordValidated) {
      setIsRepeatPasswordValidated(false);
    }

    if (repeatPasswordHasError) {
      setRepeatPasswordHasError(false);
    }

    if (!!repeatPasswordErrorMessage) {
      setRepeatPasswordErrorMessage(undefined);
    }
  }, [repeatPassword]);

  // Genzo Page View Tracking
  useEffect(() => {
    if (
      !isGoogleBot &&
      !!wegoAnalyticsClientId &&
      !!wegoAnalyticsClientSessionId &&
      !!webEngageAnonymousId
    ) {
      const clientData = {
        id: wegoAnalyticsClientId,
        session_id: wegoAnalyticsClientSessionId,
      };

      const pageData = {
        name: 'Signup Modal',
        product: 'Signup',
        base_type: 'signup_modal',
        sub_type: 'signup_modal',
        locale: locale,
        site_code: currentSite.countryCode,
        url: window.location.href,
        referrer_url: document.referrer,
      };

      const data = {
        id: pageViewId,
        client: clientData,
        page: pageData,
        created_at: toISOStringWithTimezone(new Date()),
        external_services: {
          crm_id: webEngageAnonymousId,
        },
      };

      genzoTrackPageView(apiBaseUrl, data);
    }
  }, [
    locale,
    pageViewId,
    wegoAnalyticsClientId,
    wegoAnalyticsClientSessionId,
    webEngageAnonymousId,
  ]);

  async function handleFacebookSignIn(): Promise<void> {
    try {
      setIsLoading(true);

      const user = await facebookSignIn(domainBasedApiBaseUrl, wegoClientId);

      onSignUp(user);
    } catch (error) {}

    setIsLoading(false);
  }

  async function handleGoogleSignIn(): Promise<void> {
    try {
      setIsLoading(true);

      const user = await googleOneTapSignUp(domainBasedApiBaseUrl, googleClientId, wegoClientId);

      onSignUp(user);
    } catch (error) {}

    setIsLoading(false);
  }

  async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();

    let hasError = false;
    setEmailHasError(false);
    setPasswordHasError(false);
    setRepeatPasswordHasError(false);

    if (!/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsEmailValidated(true);
      setEmailHasError(true);

      if (email.length === 0) {
        setEmailErrorMessage(translations.required_fields as string);
      } else {
        setEmailErrorMessage(
          translateText(translations.invalid, locale, translations.email_address as string),
        );
      }
      hasError = true;
    }

    if (password.length < 8) {
      setIsPasswordValidated(true);
      setPasswordHasError(true);

      if (password.length === 0) {
        setPasswordErrorMessage(translations.required_fields as string);
      } else {
        setPasswordErrorMessage(translations.password_criteria as string);
      }
      hasError = true;
    } else {
      if (repeatPassword.length === 0 || repeatPassword !== password) {
        setIsRepeatPasswordValidated(true);
        setRepeatPasswordHasError(true);

        if (repeatPassword.length === 0) {
          setRepeatPasswordErrorMessage(translations.required_fields as string);
        } else {
          setRepeatPasswordErrorMessage(translations.password_not_match as string);
        }
        hasError = true;
      }
    }

    if (!hasError) {
      setIsEmailValidated(true);
      setIsPasswordValidated(true);
      setIsRepeatPasswordValidated(true);

      try {
        setIsLoading(true);
        const user = await wegoSignUp(
          domainBasedApiBaseUrl,
          wegoClientId,
          email,
          password,
          optInReceivePromotionsFromWego,
          countryCode,
          locale,
          'desktop_web',
          'desktop',
        );

        if (wegoAnalyticsClientId && wegoAnalyticsClientSessionId) {
          const eventData = {
            id: pageViewId,
            category: 'user_signup',
            object: 'signup',
            action: 'clicked',
            value: '',
          };

          genzoTrackActionEvent(
            apiBaseUrl,
            wegoAnalyticsClientId,
            wegoAnalyticsClientSessionId,
            user?.userHash,
            eventData,
          );
        }

        onSignUp(user);

        setIsSignUpSuccessful(true);
      } catch (error: any) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);

          if (
            error.response.data.error === 'Duplicated Value' ||
            error.response.data.error_code === 110
          ) {
            setEmailHasError(true);
            setEmailErrorMessage(
              translateText(translations.name_taken, locale, translations.email_address as string),
            );
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
      }
      setIsLoading(false);
    }
  }

  return (
    <form
      className={clsx(styles.container, className)}
      onSubmit={handleFormSubmit}
      noValidate={true}
    >
      <div className={clsx(styles.content, isSignUpSuccessful && styles.successful)}>
        {!isSignUpSuccessful ? (
          <>
            <button
              type='button'
              className={clsx(styles.oAuthProvider, styles.facebook)}
              onClick={handleFacebookSignIn}
            >
              <i className={clsx(styles.oAuthProviderLogo, styles.facebook)}></i>
              {translations.signup_facebook}
            </button>

            <button
              id='googleSignUp'
              type='button'
              className={clsx(styles.oAuthProvider, styles.google)}
              onClick={handleGoogleSignIn}
            ></button>

            <div className={styles.strikethrough} data-text={translations.sign_up_w_email}></div>

            <Input
              autoComplete={'on'}
              className={styles.input}
              hasError={emailHasError}
              id={'email'}
              isRtl={isRtl}
              isValidated={isEmailValidated}
              message={emailErrorMessage}
              name={'email'}
              onChange={(value: string) => setEmail(value.trim())}
              placeholder={translations.email_address as string}
              type={'text'}
              value={email}
            />

            <Input
              autoComplete={'off'}
              className={styles.input}
              hasError={passwordHasError}
              id={'password'}
              isRtl={isRtl}
              isValidated={isPasswordValidated}
              message={passwordErrorMessage}
              name={'password'}
              onChange={setPassword}
              placeholder={translations.password as string}
              type={'password'}
              value={password}
            />

            <Input
              autoComplete={'off'}
              className={styles.input}
              hasError={repeatPasswordHasError}
              id={'repeatPassword'}
              isRtl={isRtl}
              isValidated={isRepeatPasswordValidated}
              message={repeatPasswordErrorMessage}
              name={'repeatPassword'}
              onChange={setRepeatPassword}
              placeholder={translations.repeat_password as string}
              type={'password'}
              value={repeatPassword}
            />

            <div
              className={styles.optInReceivePromotionsFromWego}
              onClick={() => setOptInReceivePromotionsFromWego(!optInReceivePromotionsFromWego)}
            >
              <Checkbox className={styles.checkbox} isChecked={optInReceivePromotionsFromWego} />
              {translations.users_newsletter}
            </div>

            <div
              className={styles.termsAndConditions}
              dangerouslySetInnerHTML={{ __html: translations.terms_and_conditions_html as string }}
            ></div>

            <br />

            <div className={styles.link} onClick={onSendConfirmationEmail}>
              {translations.no_confirmation}
            </div>

            <button type='submit' className={styles.signUp} disabled={isLoading}>
              {isLoading ? <Spinner className={styles.spinner} /> : translations.sign_up}
            </button>
          </>
        ) : null}

        {isSignUpSuccessful ? (
          <>
            <div
              className={styles.successMessage}
              dangerouslySetInnerHTML={{
                __html: translateText(
                  translations.sign_up_confirm,
                  locale,
                  `<span>${email}</span>`,
                ),
              }}
            ></div>

            <button type='button' className={styles.signUp} onClick={onContinue}>
              {translations.continue}
            </button>
          </>
        ) : null}
      </div>

      <div className={styles.rightHeader}>
        <CloseIcon className={styles.icon} onClick={onClose} />
        <div className={styles.title}>{translations.users_registrations}</div>
        <div className={styles.subtitle}>
          {translations.registrations} <span onClick={onLogin}>{translations.login}</span>
        </div>

        <div className={styles.propositions}>
          <div className={styles.proposition}>
            <div className={styles.iconContainer}>
              <BoltIcon className={styles.icon} />
            </div>
            <div className={styles.messageContainer}>
              <div>{translations.login_faster_booking}</div>
              <div className={styles.message}>{translations.login_book_quicker}</div>
            </div>
          </div>

          <div className={styles.proposition}>
            <div className={styles.iconContainer}>
              <NotificationIcon className={styles.icon} />
            </div>
            <div className={styles.messageContainer}>
              <div>{translations.price_alerts}</div>
              <div className={styles.message}>{translations.login_alerts}</div>
            </div>
          </div>

          <div className={styles.proposition}>
            <div className={styles.iconContainer}>
              <DiscountIcon className={styles.icon} />
            </div>
            <div className={styles.messageContainer}>
              <div>{translations.exclusive_offers}</div>
              <div className={styles.message}>{translations.login_discounts}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SignUp;

import { useSelector } from 'react-redux';

import { getUpdatedFlightSearchDetails } from '@helpers/flight';

import { UseAxiosQueryOptions, useAxiosQuery } from '@hooks/useAxiosQuery';

import { getLocaleState } from '@redux/selectors';

import { FlightSearch } from '@wegoTypes/flights/flightSearch';

export const updateFlightRecentSearchDetailsKey = 'update-flight-recent-details';

export const useGetUpdateFlightRecentSearchDetails = (
  options?: UseAxiosQueryOptions<FlightSearch | null>,
) => {
  const locale = useSelector(getLocaleState);

  return useAxiosQuery({
    queryKey: [updateFlightRecentSearchDetailsKey, { locale }],
    queryFn: () => getUpdatedFlightSearchDetails(locale),
    staleTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  });
};

export const isDevelopmentEnvironment = (hostname: string): boolean => {
  return /(\.wego:\d{1,}$|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/.test(hostname);
};

export const isStagingEnvironment = (hostname: string): boolean => {
  return /\wegostaging\.com$/.test(hostname);
};

export function isEnvironmentProduction(hostname: string): boolean {
  return !isDevelopmentEnvironment(hostname) && !isStagingEnvironment(hostname);
}

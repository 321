import React from 'react';
import { useSelector } from 'react-redux';

import { translateText } from '@wego/alexis/helpers/translation';

import Carousel from '@components/Carousel';

import { useGetCityRecommendedHotels } from '@hooks/api/hotels/useGetCityRecommendedHotels';

import {
  getCurrentSiteState,
  getIsRtlState,
  getLocaleState,
  getTranslationsState,
} from '@redux/selectors';

import { HotelSearch } from '@wegoTypes/hotels/hotelSearch';

import RecommendedHotelCard from './RecommendedHotelCard';
import styles from './RecommendedHotels.module.scss';

type RecommendedHotelsProps = {
  hotelRecentSearch: HotelSearch | null;
  nearestCityByIP: Place | undefined;
  localeParam: string;
};

const RecommendedHotels: React.FC<RecommendedHotelsProps> = ({
  hotelRecentSearch,
  nearestCityByIP,
  localeParam,
}) => {
  const translations = useSelector(getTranslationsState);
  const locale: string = useSelector(getLocaleState);
  const isRtl = useSelector(getIsRtlState);
  const currentSite = useSelector(getCurrentSiteState);

  const { data: recommendedHotels } = useGetCityRecommendedHotels(
    {
      cityCode: hotelRecentSearch?.place?.cityCode || nearestCityByIP?.code || '',
    },
    {
      enabled: !!hotelRecentSearch || !!nearestCityByIP,
    },
  );

  if (!recommendedHotels || recommendedHotels?.length === 0) {
    return null;
  }

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        {translateText(
          translations.hotels_in,
          locale,
          hotelRecentSearch?.place.cityName || nearestCityByIP?.cityName || '',
        )}
      </div>

      <Carousel
        className={styles.recommendedHotels}
        isRtl={isRtl}
        itemLength={recommendedHotels.length}
        itemWidth={288}
        scrollWidth={288}
      >
        {recommendedHotels.map((recommendedHotel) => (
          <RecommendedHotelCard
            cityCode={hotelRecentSearch?.place.code || nearestCityByIP?.code || ''}
            currentSite={currentSite}
            hotel={recommendedHotel}
            key={recommendedHotel.title}
            locale={locale}
            localeParam={localeParam}
            translations={translations}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default RecommendedHotels;

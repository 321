import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import type { User } from '@wego/alexis/types/helpers/user';

import ActionPopup from '@components/ActionPopup';

import { handleCopyToClipboard } from '@helpers/utils';

import {
  getTranslationsState,
  getUserState,
  getDomainBasedApiBaseUrlState,
} from '@redux/selectors';

import styles from './DeleteAccountPopup.module.scss';

type DeleteAccountPopupProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DeleteAccountPopup: FC<DeleteAccountPopupProps> = ({ onClose, isOpen }) => {
  const [step, setStep] = useState(0);
  const [userIdResponse, setUserIdResponse] = useState('');
  const [userIdCopied, setUserIdCopied] = useState(false);

  const user: User | null = useSelector(getUserState);
  const translations = useSelector(getTranslationsState);
  const domainBasedApiBaseUrl = useSelector(getDomainBasedApiBaseUrlState);

  const { isLoading, mutate } = useMutation({
    mutationFn: () => {
      if (!user) {
        throw new Error('User not found');
      }
      const url = `${domainBasedApiBaseUrl}/users/gdpr_requests`;
      const gdprData = {
        user: {
          gdpr_event: 'DELETE_ACCOUNT',
          user_hash: user?.userHash,
        },
      };

      return axios
        .post<{
          status: string;
          request_id: string;
        }>(url, gdprData, {
          headers: new Headers({ 'Content-Type': 'application/json' }),
          withCredentials: true,
        })
        .then((response) => response.data);
    },
    onSuccess: (data) => {
      setUserIdResponse(data.request_id);
      setStep(1);
    },
    onError: (error) => {
      console.error('Error deleting account', error);
    },
  });

  return (
    <>
      <ActionPopup
        isOpen={isOpen && step === 0}
        isSubmitting={isLoading}
        onClose={onClose}
        title={translations.delete_account}
        description={<p>{translations.delete_account_warning}</p>}
        secondaryButton={{
          label: translations.never_mind,
          onClick: onClose,
          borderWidth: 'thick',
        }}
        primaryButton={{
          label: isLoading ? translations.processing : translations.yes_delete_this_account,
          onClick: mutate,
          colorScheme: 'red',
        }}
      />

      <ActionPopup
        isOpen={isOpen && step === 1}
        onClose={onClose}
        title={'User ID'}
        description={
          <>
            <div className={styles.successDescription}>{translations.user_id_disclaimer}</div>

            <div className={styles.userIdContainer}>
              <h6>{translations.user_id}:</h6>
              <p>{userIdResponse}</p>
            </div>
          </>
        }
        secondaryButton={{
          label: translations.never_mind,
          onClick: onClose,
          borderWidth: 'thick',
        }}
        primaryButton={{
          label: userIdCopied ? translations.copied : translations.copy_user_id,
          onClick: () => {
            handleCopyToClipboard(userIdResponse);
            setUserIdCopied(true);
          },
          colorScheme: 'green',
        }}
      />
    </>
  );
};

export default DeleteAccountPopup;

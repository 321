import React from 'react';

import Image from '@components/Image';

import styles from './TopCityCard.module.scss';

interface TopCityCardProps {
  city: HomepageCity;
  href: string;
}

const TopCityCard: React.FC<TopCityCardProps> = ({ city, href }) => {
  return (
    <a className={styles.container} href={href} target='_blank' rel='noreferrer'>
      <Image
        className={styles.image}
        isLazy={true}
        src={`https://zen.wego.com/cdn-cgi/image/height=480/destinations/cities/${city.code}.jpg`}
      />

      <div className={styles.cityName}>{city.name}</div>
    </a>
  );
};

export default TopCityCard;

export function getInitialismOfName(displayName = ''): string {
  const names = displayName.trim().split(' ');

  if (names.length > 1) return `${names[0][0]}${names[1][0]}`.toUpperCase();

  if (displayName.length > 1) return `${displayName[0]}${displayName[1]}`.toUpperCase();

  if (displayName.length === 1) return displayName[0].toUpperCase();

  return 'WG';
}

import axios from 'axios';

export const getWegoIdToken = async (apiBaseUrl: string): Promise<string> => {
  const url = `${apiBaseUrl}/users/id_token`;

  const response = await axios.get<{ id_token: string }>(url, {
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data.id_token;
  }

  throw new Error(response.statusText);
};

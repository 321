export enum LocaleActionTypes {
  ChangeLocale = 'CHANGE_LOCALE',
}

export enum TranslationsActionTypes {
  ChangeTranslations = 'CHANGE_TRANSLATIONS',
}

export enum IsRtlActionTypes {
  ChangeIsRtl = 'CHANGE_IS_RTL',
}

export enum CurrencyActionTypes {
  ChangeCurrency = 'CHANGE_CURRENCY',
}

export enum ExchangeRateActionTypes {
  ChangeExchangeRate = 'CHANGE_EXCHANGE_RATE',
}

export enum UserActionTypes {
  ChangeUser = 'CHANGE_USER',
  ResetUser = 'RESET_USER',
}

export enum FlightSearchActionTypes {
  ChangeTripType = 'CHANGE_TRIP_TYPE',
  ChangeDepartureLocation = 'CHANGE_DEPARTURE_LOCATION',
  ChangeArrivalLocation = 'CHANGE_ARRIVAL_LOCATION',
  ChangeDepartureDate = 'CHANGE_DEPARTURE_DATE',
  ChangeReturnDate = 'CHANGE_RETURN_DATE',
  ChangeAdultCount = 'CHANGE_ADULT_COUNT_FLIGHT',
  ChangeChildrenCount = 'CHANGE_CHILDREN_COUNT_FLIGHT',
  ChangeInfantCount = 'CHANGE_INFANT_COUNT_FLIGHT',
  ChangeCabinClass = 'CHANGE_CABIN_CLASS',
  ChangePaymentMethods = 'CHANGE_PAYMENT_METHODS',
  AddPaymentMethod = 'ADD_PAYMENT_METHOD',
  RemovePaymentMethod = 'REMOVE_PAYMENT_METHOD',
  ChangeFlightSearch = 'CHANGE_FLIGHT_SEARCH',
}

export enum FlightRecentSearchesActionTypes {
  AddFlightRecentSearch = 'ADD_FLIGHT_RECENT_SEARCH',
}

export enum HotelRecentSearchesActionTypes {
  AddHotelRecentSearch = 'ADD_HOTEL_RECENT_SEARCH',
}

export enum HotelMetaSearchActionTypes {
  ChangeHotelMetaSearch = 'CHANGE_HOTEL_META_SEARCH',
  ChangeHotelRateProviders = 'CHANGE_HOTEL_RATE_PROVIDERS',
  ChangeHotelCities = 'CHANGE_HOTEL_CITIES',

  ChangeAmenitiesFilter = 'CHANGE_AMENITIES_FILTER',
  ChangeBookingOptionsFilter = 'CHANGE_BOOKING_OPTIONS_FILTER',
  ChangeBrandsFilter = 'CHANGE_BRANDS_FILTER',
  ChangeChainsFilter = 'CHANGE_CHAINS_FILTER',
  ChangeDistrictsFilter = 'CHANGE_DISTRICTS_FILTER',
  ChangePropertyTypesFilter = 'CHANGE_PROPERTY_TYPES_FILTER',
  ChangeRateAmenitiesFilter = 'CHANGE_RATE_AMENITIES_FILTER',
  ChangeReviewerGroupsFilter = 'CHANGE_REVIEWER_GROUPS_FILTER',
  ChangeStarsFilter = 'CHANGE_STARS_FILTER',

  ChangeHotelViewModels = 'CHANGE_HOTEL_VIEW_MODELS',
  ChangeSponsoredAdHotelViewModels = 'CHANGE_SPONSORED_AD_HOTEL_VIEW_MODELS',
  ChangeHotelLandmarks = 'CHANGE_HOTEL_LANDMARKS',
  ResetHotelMetaSearch = 'RESET_HOTEL_META_SEARCH',
}

export enum InterstitialProviderActionTypes {
  AddInterstitialProviderMap = 'ADD_INTERSTITIAL_PROVIDER_MAP',
}

export enum PriceOptionsActionTypes {
  ChangeShowTotalPrice = 'CHANGE_SHOW_TOTAL_PRICE',
  ChangeIsIncludingTaxOrFee = 'CHANGE_IS_INCLUDING_TAX_OR_FEE',
}

export enum ShopCashActionTypes {
  ChangeShopCashUser = 'CHANGE_SHOPCASH_USER',
  ResetShopCashUser = 'RESET_SHOPCASH_USER',
}

export enum PageViewIdActionTypes {
  ChangeHomePageViewId = 'CHANGE_HOME_PAGE_VIEW_ID',
}

import { getFlightFareCalendar } from '@apis/flight';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

import { FareCalendar, FareCalendarPayload } from '@wegoTypes/flights/metasearch/calendar';

const getFareCalendarKey = 'fare-calendar';

export const useGetFlightFareCalendar = (
  getFlightFareCalendarParams: FareCalendarPayload,
  options?: UseAxiosQueryOptions<FareCalendar>,
) => {
  return useAxiosQuery({
    queryKey: [getFareCalendarKey, { ...getFlightFareCalendarParams }],
    queryFn: () => getFlightFareCalendar(getFlightFareCalendarParams),
    staleTime: 1000 * 60 * 30,
    retry: (failureCount, error: any) => {
      // Don't retry if the error is 404 (Not Found)
      if (error?.response?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    ...options,
  });
};

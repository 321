import { useState, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

import { HotelViewModel } from '@wegoTypes/hotels/hotelViewModel';

const hotelHoveredSubject = new BehaviorSubject<HotelViewModel | undefined>(undefined);

export const setHotelHovered = (value: HotelViewModel | undefined) => {
  hotelHoveredSubject.next(value);
};

export const useHoverFlag = () => {
  const [hotelHovered, setHotelHoveredState] = useState<HotelViewModel | undefined>(undefined);

  useEffect(() => {
    const subscription = hotelHoveredSubject.subscribe((value) => {
      setHotelHoveredState(value);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return {
    hotelHovered,
  };
};

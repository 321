import React from 'react';

import { clsx } from '@wego/alexis/helpers/clsx';

import styles from './Checkbox.module.scss';

export type CheckboxProps = {
  className?: string;
  isChecked: boolean;
  onClick?(): void;
  style?: React.CSSProperties;
};

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  style,
  isChecked,
  onClick,
}): JSX.Element => {
  return (
    <div
      role='checkbox'
      className={clsx(styles.container, isChecked && styles.checked, className)}
      style={style}
      onClick={onClick}
    ></div>
  );
};

export default Checkbox;

export const sessionStorageSave = <T>(key: string, value: T): void => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } catch (_) {}
};

export const sessionStorageGet = <T>(key: string): T | null => {
  try {
    const value = window.sessionStorage.getItem(key);

    if (value) {
      return JSON.parse(value) as T;
    }
  } catch (_) {}
  return null;
};

export const sessionStorageClear = (key: string): void => {
  try {
    window.sessionStorage.removeItem(key);
  } catch (_) {}
};

import { getCookie } from '@alexis/helpers/cookie';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import React, { useEffect, useRef, useState, PropsWithChildren, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { clsx } from '@wego/alexis/helpers/clsx';
import { generateCUID } from '@wego/alexis/helpers/identity';

import { getUserState } from '@redux/selectors';

import styles from '../styles/components/Carousel.module.scss';

interface CarouselProps {
  className?: string;
  isRtl: boolean;
  itemLength: number;
  itemWidth: number;
  scrollWidth: number;
  isLightVersion?: boolean;
}

const Carousel: React.FC<PropsWithChildren<CarouselProps>> = ({
  children,
  className,
  isRtl,
  itemLength,
  itemWidth,
  scrollWidth,
  isLightVersion = false,
}) => {
  const containerDivRef = useRef<HTMLDivElement>(null);

  const [isItemOverflowed, setIsItemOverflowed] = useState<boolean>(false);
  const [maxScrollLeft, setMaxScrollLeft] = useState<number>(0);
  const [translateXPosition, setTranslateXPosition] = useState<number>(0);
  const user = useSelector(getUserState);
  const apiBaseUrl = API_BASE_URL;
  const params = useParams<{
    hotelId: string;
  }>();

  const pageViewId = useMemo<string>(() => {
    return generateCUID();
  }, []);

  useEffect(() => {
    const initializeScrollingValues = (): void => {
      if (!!containerDivRef.current) {
        const scrollWidth = itemLength * itemWidth;

        setIsItemOverflowed(scrollWidth > containerDivRef.current.clientWidth);
        setMaxScrollLeft((scrollWidth - containerDivRef.current.clientWidth) * (isRtl ? 1 : -1));
        setTranslateXPosition(0);
      }
    };

    window.addEventListener('resize', initializeScrollingValues);

    initializeScrollingValues();

    return () => {
      window.removeEventListener('resize', initializeScrollingValues);
    };
  }, [containerDivRef, itemLength, itemWidth, isRtl]);

  const handledGenzoActionEvent = useCallback(
    (value: string): void => {
      const wegoAnalyticsClientIdCookie = getCookie('wego_analytics_client_id');
      const wegoAnalyticsClientSessionIdCookie = getCookie('wego_analytics_client_session_id');
      if (isLightVersion && !!wegoAnalyticsClientIdCookie && !!wegoAnalyticsClientSessionIdCookie) {
        const eventData = {
          id: pageViewId,
          category: 'review_carousel_top_hoteldetails_dweb',
          object: 'hoteldetails_page',
          action: 'click',
          value: `${value}&${parseInt(params.hotelId!)}`,
        };
        genzoTrackActionEvent(
          apiBaseUrl,
          wegoAnalyticsClientIdCookie,
          wegoAnalyticsClientSessionIdCookie,
          user?.userHash,
          eventData,
        );
      }
    },
    [user, apiBaseUrl, pageViewId, params.hotelId, isLightVersion],
  );

  function handleLeftChevronClick(isRtl: boolean): void {
    if (isLightVersion) handledGenzoActionEvent('previous');
    if (isRtl) {
      setTranslateXPosition(Math.max(translateXPosition - scrollWidth, 0));
    } else {
      setTranslateXPosition(Math.min(translateXPosition + scrollWidth, 0));
    }
  }

  function handleRightChevronClick(isRtl: boolean): void {
    if (isLightVersion) handledGenzoActionEvent('next');
    if (isRtl) {
      setTranslateXPosition(Math.min(translateXPosition + scrollWidth, maxScrollLeft));
    } else {
      setTranslateXPosition(Math.max(translateXPosition - scrollWidth, maxScrollLeft));
    }
  }

  return (
    <div ref={containerDivRef} className={clsx(styles.container, className)}>
      <div className={styles.scrollPane}>
        <div style={{ transform: `translateX(${translateXPosition}px)` }}>{children}</div>
      </div>

      <button
        aria-label='Previous'
        type='button'
        className={styles.left}
        disabled={translateXPosition === 0 || !isItemOverflowed}
        onClick={() => handleLeftChevronClick(isRtl)}
      >
        <i className={clsx(styles.chevron, styles.left)}></i>
      </button>

      <button
        aria-label='Next'
        type='button'
        className={styles.right}
        disabled={translateXPosition === maxScrollLeft || !isItemOverflowed}
        onClick={() => handleRightChevronClick(isRtl)}
      >
        <i className={clsx(styles.chevron, styles.right)}></i>
      </button>
    </div>
  );
};

export default Carousel;
